export default {
  sizes: {
    sm: {
      tab: {
        py: "2",
        px: "3",
        fontSize: "xs",
      },
    },
    md: {
      tab: {
        py: "3",
        px: "5",
        fontSize: "sm",
      },
    },
    lg: {
      tab: {
        fontSize: "md",
        py: "4",
        px: "6",
      },
    },
  },
  baseStyle: {
    tab: {
      fontSize: "xs",
      fontWeight: "bold",
      color: "tabs.unselectedColor",
      _hover: {
        color: "tabs.bright",
        borderColor: "tabs.hoverBorder",
        backgroundColor: "tabs.hoverBackground",
      },
      _active: {
        color: "tabs.bright",
        borderColor: "tabs.activeBorder",
        backgroundColor: "tabs.activeBackground",
      },
      _selected: {
        color: "tabs.bright",
      },
    },
    tabpanel: {
      pt: "1",
      px: "0",
      pb: "0",
      paddingInlineStart: "0",
      paddingInlineEnd: "0",
    },
  },
  variants: {
    line: {
      tab: {
        borderTopRadius: "base",
        _hover: {
          backgroundColor: "gray.50",
          borderColor: "gray.100",
        },
        _active: {
          borderColor: "gray.100",
        },
        _focus: {
          boxShadow: "none", // may want to keep outline: https://www.a11yproject.com/posts/2013-01-25-never-remove-css-outlines/
        },
        _selected: {
          color: "tabs.bright",
        },
      },
      tablist: {
        borderColor: "gray.100",
      },
    },
    home: {
      tablist: {
        borderColor: "gray.100",
        borderBottomWidth: "1px",
        mx: { base: "0", lg: "8" },
      },
      tab: {
        borderBottomWidth: "2px",
        marginBottom: "-2px",
        borderColor: "transparent",
        _selected: {
          color: "blue.600",
          borderColor: "blue.600",
        },
        _hover: {
          color: "blue.600",
          bg: "transparent",
          borderColor: "transparent",
        },
        _active: {
          bg: "transparent",
        },
        px: { base: "1", lg: "4" },
        py: { base: "4", lg: "3" },
        _first: { pl: { base: "2", lg: "4" } },
        _last: { pr: { base: "2", lg: "4" } },
        flexGrow: { base: "1", lg: "unset" },
      },
      tabpanel: {
        pt: 0,
      },
    },
  },
};
