import { Box, Flex, keyframes } from "@chakra-ui/react";
import React from "react";

import AILoading from "../../../../components/LoadingIndicator/AILoading";
import AiNotesMessage from "./AiNotesMessage";

const ellipsis = keyframes`
  to {
    width: 1.25em;
  }
`;

const AiNotesLoading: React.FC<{ loadingText?: string }> = ({
  loadingText = "Generating notes",
}) => (
  <AiNotesMessage>
    <AILoading />
    <Flex align="center" fontWeight="500" fontSize="17px" color="gray.400">
      {loadingText}
      <Box width="1.25em">
        <Box
          animation={`${ellipsis} steps(4,end) 2s infinite`}
          overflow="hidden"
          whiteSpace="nowrap"
          width="0"
        >
          ...
        </Box>
      </Box>
    </Flex>
  </AiNotesMessage>
);

export default AiNotesLoading;
