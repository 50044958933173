import { useCallback } from "react";

import { useToast } from "../../components";
import { pluck } from "../../utils/array";
import { formatDetails, formatNote, formatTimestamps } from "../../utils/call";
import { copy, CopyData } from "../../utils/clipboard";
import { useSendGAEvent } from "../../utils/googleAnalytics";
import { notesListNoteTypes } from "../components/CallNotes/utils";
import {
  CallBetaFragment,
  CallNoteFragment,
  CallNoteType,
  CallQuestionFragment,
} from "../graphql";

type CopyNotesBetaCall = Pick<CallBetaFragment, "id" | "startTime"> & {
  candidate?: { fullName: string } | null;
  position?: { displayTitle: string } | null;
};

function getQuestionNotes(question: CallQuestionFragment): CopyData {
  const notesAndReactions = question.questionNotes.filter((n) =>
    notesListNoteTypes.includes(n.type)
  );
  const timestamps = question.questionNotes.filter(
    (n) => n.type === CallNoteType.Cue
  );

  let plain = "";
  let html = "";

  if (timestamps.length > 0 || notesAndReactions.length > 0) {
    if (question.description) {
      plain = plain.concat(`\n${question.description}`);
      html = html.concat(`<br>${question.description}`);
    }

    if (timestamps.length > 0) {
      plain = plain.concat(`\n${formatTimestamps(timestamps)}`);
      html = html.concat(`<br>${formatTimestamps(timestamps, true)}`);
    }

    plain = plain.concat(
      notesAndReactions
        .map((n) => formatNote(n))
        .join("")
        .concat("\n")
    );
    html = html.concat(
      notesAndReactions
        .map((n) => formatNote(n, true))
        .join("")
        .concat("<br>")
    );
  }

  return { html, plain };
}

export function useCopyQuestionNotes(
  question: CallQuestionFragment
): () => void {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  return useCallback(() => {
    const text = getQuestionNotes(question);
    sendGAEvent("copy_question_notes", "call_review");
    copy(text);
    toast({
      title: "Notes copied to clipboard",
      status: "success",
    });
  }, [question]);
}

export function useCopyNotes(args: {
  call: CopyNotesBetaCall;
  generalNotes: CallNoteFragment[];
  questions: CallQuestionFragment[];
}): () => void {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const { call, generalNotes, questions } = args;

  function getCallNotes(): CopyData {
    let plain = `${formatDetails(call, { withoutURL: true })}\n\n`;
    let html = `${formatDetails(call, { html: true })}<br><br>`;

    if (generalNotes.length) {
      plain += `General Notes${generalNotes
        .map((n) => formatNote(n))
        .join("")}\n`;

      html += `General Notes${generalNotes
        .map((n) => formatNote(n, true))
        .join("")}<br>`;
    }

    const questionNotes = questions.map(getQuestionNotes);

    plain += pluck(questionNotes, "plain").join("");
    html += pluck(questionNotes, "html").join("");

    return { html, plain };
  }

  return useCallback(() => {
    sendGAEvent("copy_notes", "call_review");
    const text = getCallNotes();
    copy(text);
    toast({
      title: "Notes copied to clipboard",
      status: "success",
    });
  }, [call, generalNotes, questions]);
}
