import { MetricName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import {
  canViewAllMetrics,
  hcaMetrics,
  ratingMetrics,
  scorecardMetrics,
} from "./utils";

const useEnabledMetrics = (): MetricName[] => {
  const currentUser = useCurrentUser();

  let enabledMetrics = [];

  if (canViewAllMetrics(currentUser)) {
    enabledMetrics.unshift(
      MetricName.TotalInterviews,
      MetricName.WeeklyInterviews,
      MetricName.TotalInterviewTime,
      MetricName.WeeklyInterviewTime,
      MetricName.CandidateTalkRatio,
      MetricName.LongestMonologue,
      MetricName.SpeakingRate,
      MetricName.Interactivity,
      MetricName.QuestionsAsked,
      MetricName.OnTimeInterviews
    );
  }

  if (useFeatureFlag("analytics:rating-metrics")) {
    enabledMetrics = enabledMetrics.concat(ratingMetrics);
  }

  if (useFeatureFlag("analytics:hca-metrics")) {
    enabledMetrics = enabledMetrics.concat(hcaMetrics);
  }

  if (useFeatureFlag("analytics:scorecard")) {
    enabledMetrics = enabledMetrics.concat(scorecardMetrics);
  }

  return enabledMetrics;
};

export default useEnabledMetrics;
