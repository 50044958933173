import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { Drawer, RouterLink } from "../../../components";
import { SearchState } from "../../../components/Search";
import config from "../../../config";
import { SearchResults } from "../Search/SearchResults";

export interface DrawerState {
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  isOpen: boolean;
}

interface SearchDrawerProps {
  children: (state: DrawerState) => React.ReactNode;
}

const SearchDrawer: React.FC<SearchDrawerProps> = ({ children }) => {
  const { onOpen, onClose, onToggle, isOpen } = useDisclosure();

  const drawerState = useMemo(
    () => ({ onOpen, onClose, onToggle, isOpen }),
    [onOpen, onClose, onToggle, isOpen]
  );

  if (config.appEnv === "test") {
    return <>{children(drawerState)}</>;
  }

  return (
    <>
      {children(drawerState)}
      <Drawer onClose={onClose} isOpen={isOpen} px={6}>
        <SearchState
          render={({ searchState: { query }, searchResults }) => {
            const nbHits = searchResults?.nbHits;
            let callCount;
            if (nbHits !== undefined) {
              if (nbHits === 0) {
                callCount = "No results found";
              } else if (nbHits === 1) {
                callCount = "1 result found";
              } else {
                callCount = `${nbHits} results found`;
              }
            }
            const safeQuery = query ? encodeURIComponent(query) : "";
            return (
              <>
                <Flex
                  fontSize="sm"
                  py={2}
                  borderBottom="1px"
                  borderColor="border"
                >
                  <Box mr="auto">{callCount}</Box>
                  <RouterLink to={`/search?q=${safeQuery}`}>
                    More Search Options
                  </RouterLink>
                </Flex>
                <SearchResults />
                <Box
                  width="100%"
                  color="gray.500"
                  fontSize="sm"
                  textAlign="center"
                  pb={6}
                >
                  Not finding what you&apos;re looking for?
                  <br />
                  <RouterLink to={`/search?q=${safeQuery}`}>
                    Check out more search options.
                  </RouterLink>
                </Box>
              </>
            );
          }}
        />
      </Drawer>
    </>
  );
};

/**
 * Adds search drawer to the DOM and renders `children` with access to
 * the search drawer state / controls
 */
export default React.memo(SearchDrawer);
