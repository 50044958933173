import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { IoCopyOutline, IoLinkSharp } from "react-icons/io5";

import useCopyLink from "../../CallNotes/useCopyLink";

const AiQuestionHoverMenu: React.FC<{
  callId: string;
  time: number;
  copyNotes: () => void;
}> = ({ callId, time, copyNotes }) => {
  const { handleCopyLink } = useCopyLink({
    callId,
    time,
  });

  return (
    <Flex alignItems="center" position="absolute" right="0" top="-28px">
      <Tooltip label="Link to this Moment">
        <Button
          leftIcon={<IoLinkSharp size="14" />}
          aria-label="Copy Link"
          variant="outline"
          size="sm"
          fontSize="12px"
          colorScheme="gray"
          borderRadius="md"
          mr={1}
          height="2.25rem"
          px={2}
          onClick={handleCopyLink}
        >
          Copy link
        </Button>
      </Tooltip>
      <ButtonGroup
        size="sm"
        spacing="0"
        bg="white"
        borderColor="border"
        borderRadius="md"
        variant="ghost"
        colorScheme="gray"
        borderWidth="1px" // leaving for consistency with note menu
        p="1px" // leaving for consistency with note menu
      >
        <Tooltip label="Copy to clipboard" placement="bottom-start">
          <IconButton
            aria-label="Copy to clipboard"
            icon={<IoCopyOutline />}
            onClick={copyNotes}
          />
        </Tooltip>
      </ButtonGroup>
    </Flex>
  );
};

export default AiQuestionHoverMenu;
