import {
  isChrome,
  isChromium,
  isDesktop,
  isEdgeChromium,
  isOpera,
} from "react-device-detect";

import { BrowserExtensionIntent } from "../main/graphql";
import {
  AlertConfig,
  FillScorecardPayload,
  FillScorecardResponse,
} from "./oneClickScorecard";

const BROWSER_EXTENSION_ID = "mbokiighkhdopgedihndpibkincpcgan";
const BETA_BROWSER_EXTENSION_ID = "opnlcljcbpodfkedodibhbnegdkhappp";

enum MessageRejectReason {
  EXTENSION_NOT_SUPPORTED = "EXTENSION_NOT_SUPPORTED",
  EXTENSION_NOT_INSTALLED = "EXTENSION_NOT_INSTALLED",
}

export type MessageAction =
  | "getVersion"
  | "getTabId"
  | "openUrl"
  | "openIA"
  | "toggleDrawer"
  | "openDrawer"
  | "loadingComplete"
  | "fillScorecard"
  | "setPostFillAlert"
  | "showAlert"
  | "clearAlert"
  | "setToastText"
  | "disableDrawerToast"
  | "enableDrawerToast"
  | "openDrawerToast"
  | "closeDrawerToast"
  | "gaEvent";

export interface MessagePayload {
  url?: string;
  tabId?: number;
  intent?: BrowserExtensionIntent;
  text?: string;
  scorecardData?: FillScorecardPayload;
  alert?: AlertConfig;
  windowAvailHeight?: number;
  gaEvent?: any;
}

export interface Message {
  action: MessageAction;
  payload?: MessagePayload;
  // deprecated properties
  // TODO: remove after next extension release
  openUrl?: string;
  iaUrl?: string;
}

export type MessageResponse =
  | {
      tabId?: number;
      version?: string;
      fillScorecardResult?: FillScorecardResponse;
    }
  | null
  | undefined;

/**
 * We use chrome's long-lived connections here to be
 * able to push events from the extension to the web app
 * https://developer.chrome.com/docs/extensions/mv3/messaging/#connect
 *
 * They are similar to chrome's one-time requests, but
 * they allow easier bi-directional messaging (since there is no
 * `runtime.onMessage` in this context / outside of the extension)
 */
let messagePort: chrome.runtime.Port;
export const initMessagePort = async (): Promise<chrome.runtime.Port> => {
  if (messagePort) return messagePort;

  if (
    !(isDesktop && (isChrome || isEdgeChromium || isChromium || isOpera)) ||
    typeof chrome?.runtime === "undefined" // Extra check for older Safari (#4427)
  ) {
    return Promise.reject(MessageRejectReason.EXTENSION_NOT_SUPPORTED);
  }

  messagePort = await new Promise((resolve, reject) => {
    const prodPort = chrome.runtime.connect(BROWSER_EXTENSION_ID);
    // The only way we can tell if the above failed is that the `onDisconnect` will
    // be fired right after `connect`, so we wait 100 ms (a guess) to allow for that
    const resolveProd = setTimeout(() => resolve(prodPort), 100);
    prodPort.onDisconnect.addListener(() => {
      clearTimeout(resolveProd);
      if (chrome.runtime.lastError) {
        const betaPort = chrome.runtime.connect(BETA_BROWSER_EXTENSION_ID);
        betaPort.onDisconnect.addListener(() => {
          if (chrome.runtime.lastError) {
            reject(MessageRejectReason.EXTENSION_NOT_INSTALLED);
          }
        });
        setTimeout(() => resolve(betaPort), 10);
      }
    });
  });

  return messagePort;
};

export const sendExtensionMessage = (
  message: Message
): Promise<MessageResponse> => {
  if (
    !(isDesktop && (isChrome || isEdgeChromium || isChromium || isOpera)) ||
    typeof chrome?.runtime === "undefined" // Extra check for older Safari (#4427)
  ) {
    return Promise.reject(MessageRejectReason.EXTENSION_NOT_SUPPORTED);
  }

  return new Promise((resolve, reject) => {
    // production extension get's priority when sending messages
    chrome.runtime.sendMessage(
      BROWSER_EXTENSION_ID,
      message,
      (response: MessageResponse) => {
        // if there was an error, try again with the beta extension ID
        if (chrome.runtime.lastError) {
          chrome.runtime.sendMessage(
            BETA_BROWSER_EXTENSION_ID,
            message,
            (betaResponse: MessageResponse) => {
              if (chrome.runtime.lastError) {
                reject(MessageRejectReason.EXTENSION_NOT_INSTALLED);
              } else {
                resolve(betaResponse);
              }
            }
          );
        } else {
          resolve(response);
        }
      }
    );
  });
};
