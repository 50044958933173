import { Box, Text, TextProps } from "@chakra-ui/react";
import React, { ReactElement, useEffect, useRef, useState } from "react";

import colorVars from "../../theme/css-color-variables";

export const ExpandableText: React.FC<
  Omit<TextProps, "title"> & { title: string | JSX.Element; maskBg?: string }
> = ({ title, maskBg = colorVars.gray[50], ...rest }) => {
  const textRef = useRef<HTMLInputElement | null>(null);
  const [hasMore, setHasMore] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect((): (() => void) => {
    const handleResize = (): void => {
      const el = textRef.current;
      if (el) {
        setHasMore(el.scrollHeight > el.clientHeight);
        setExpanded(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [textRef.current]);

  const lessBtnWidth = "40px";

  return (
    <Box flex="1" position="relative">
      <Text
        ref={textRef}
        noOfLines={expanded ? undefined : 2}
        _after={{
          content: '""',
          display: "inline-block",
          width: hasMore ? lessBtnWidth : 0,
        }}
        whiteSpace="pre-wrap"
        {...rest}
      >
        {title}
      </Text>
      {hasMore && (
        <Box
          position="absolute"
          marginLeft="auto"
          bottom="0"
          right="0"
          bg={`linear-gradient(270deg, ${maskBg} 65%, rgba(255, 255, 255, 0) 100%)`}
          w={expanded ? lessBtnWidth : "100px"}
        >
          <ToggleButton
            onClick={(e) => {
              setExpanded(!expanded);
              e.stopPropagation();
            }}
          >
            {expanded ? "Less" : "See More"}
          </ToggleButton>
        </Box>
      )}
    </Box>
  );
};

type ToggleButtonProps = TextProps & { onClick?: (e: any) => void };

const ToggleButton: React.FC<ToggleButtonProps> = ({
  children,
  onClick,
  ...rest
}): ReactElement<any, any> => (
  <Text
    display="block"
    fontWeight={500}
    color="gray.500"
    role="button"
    textAlign="right"
    onClick={(e) => onClick?.(e)}
    {...rest}
  >
    {children}
  </Text>
);
