import Avatar from "./avatar";
import Button from "./button";
import Card from "./card";
import Checkbox from "./checkbox";
import Drawer from "./drawer";
import FormLabel from "./formLabel";
import Input from "./input";
import Link from "./link";
import Menu from "./menu";
import Modal from "./modal";
import Radio from "./radio";
import Switch from "./switch";
import Table from "./table";
import Tabs from "./tabs";
import Tag from "./tag";
import Textarea from "./textarea";
import Tooltip from "./tooltip";

export default {
  Avatar,
  Button,
  Card,
  Input,
  Link,
  Menu,
  Modal,
  Radio,
  Textarea,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Checkbox,
  Drawer,
  FormLabel,
  Switch,
};
