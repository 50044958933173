import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Link,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import {
  Alert,
  LoadingIndicator,
  StatusWithText,
  useToast,
} from "../../../components";
import {
  useCurrentUserCalendarQuery,
  useUpdateCalendarSettingsMutation,
} from "../../graphql";

const CalendarSettings: React.FC = () => {
  const toast = useToast();
  const [isPolling, setIsPolling] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const msftAuth = searchParams.get("msft_auth");
  const { data, loading, stopPolling } = useCurrentUserCalendarQuery();
  const currentUser = data?.currentUser;
  const zoomSetupComplete =
    currentUser?.organization?.isZoomAuthorized ||
    currentUser?.organization?.zoomNotetakerEnabled ||
    false;
  const googleMeetEnabled =
    currentUser?.organization?.googleMeetEnabled || false;
  const videoMeetingEnabled = zoomSetupComplete || googleMeetEnabled;
  const calendars = currentUser?.calendars || [];

  const successToast = (): void => {
    toast({
      status: "success",
      title: "Integrations",
      description:
        "Your Calendar events will appear on the home screen momentarily.",
    });
  };

  useEffect(() => {
    if (msftAuth) {
      if (msftAuth === "success") {
        successToast();
      } else if (msftAuth === "failure") {
        toast({
          status: "error",
          title: "Integrations",
          description: "There was a problem authorizing calendar access",
        });
      }
      searchParams.delete("msft_auth");
      setSearchParams(searchParams, { replace: true });
    }
  });

  const primaryCalendar = calendars.find((cal) => {
    return cal.primary;
  });

  useEffect(() => {
    stopPolling();
    setIsPolling(false);
  }, [primaryCalendar]);

  const [
    updateCalendarSettings,
    {
      error: updateCalendarSettingsError,
      loading: updateCalendarSettingsLoading,
    },
  ] = useUpdateCalendarSettingsMutation({
    onCompleted: (data) => {
      if (data?.updateCalendarSettings?.currentUser) {
        toast({
          status: "success",
          title: "Update Calendar Settings",
          description: "Success!",
        });
      }
    },
    onError: () => {
      toast({
        status: "error",
        title: "Update Calendar Settings",
        description: "There was a problem - please try again",
      });
    },
  });
  const { register, handleSubmit, setValue } = useForm<{
    videoMeetingImportStrategy: string;
  }>({});
  const submitCalendarSettings = handleSubmit((values) => {
    if (!primaryCalendar) return;
    updateCalendarSettings({
      variables: {
        id: primaryCalendar.id,
        shouldImportVideoMeeting: values.videoMeetingImportStrategy,
      },
    });
  });

  useEffect(() => {
    setValue(
      "videoMeetingImportStrategy",
      primaryCalendar?.videoMeetingImportStrategy || "NONE"
    );
  }, [primaryCalendar]);

  // Presented to clear up any confusion for users who didn't realize they do
  // have to accept all the calendar scopes
  const googleScopesWarning =
    "It looks like you are a Google user, but you have not granted all the scopes required for your calendar to sync properly. Click below to log back in, and make sure to keep all permissions selected.";

  if (loading) {
    return <LoadingIndicator />;
  }
  return currentUser?.isCalendarAuthorized ? (
    <>
      <Box mb={6}>
        {currentUser.googleUserId &&
          (!currentUser.hasGrantedGoogleCalendarScope ||
            !currentUser.hasGrantedGoogleCalendarEventScope) && (
            <Alert
              status="warning"
              mb={4}
              canHide
              description={googleScopesWarning}
            />
          )}
        <Heading as="h1" size="md" mb={4}>
          Calendar Sync
        </Heading>
        <StatusWithText
          passed={currentUser.isGcalAuthorized}
          label="Google calendar"
          warn
        />
        <StatusWithText
          passed={currentUser.isMsftAuthorized}
          label="Microsoft calendar"
          warn
        />
        {!currentUser.isGcalAuthorized && (
          <Button
            mb="4"
            onClick={() => {
              window.location.href = "/sign-in?with=google";
            }}
          >
            Sync your Google calendar
          </Button>
        )}
        {!currentUser.isMsftAuthorized && (
          <Button
            mb="4"
            onClick={() => {
              window.location.href = "/sign-in?with=msft";
            }}
          >
            Sync your Microsoft calendar
          </Button>
        )}
        <Heading as="h2" size="md" mt={6} mb={4}>
          My Calendars
        </Heading>
        <UnorderedList>
          {calendars?.map((calendar) => {
            return (
              <ListItem
                fontWeight={calendar.primary ? "semibold" : "normal"}
                key={calendar.id}
              >
                {calendar.name}
              </ListItem>
            );
          })}
        </UnorderedList>
      </Box>
      {isPolling && <LoadingIndicator />}
      {primaryCalendar && (
        <Flex>
          {updateCalendarSettingsError && (
            <Alert
              status="error"
              title="Error"
              description={updateCalendarSettingsError.message}
            />
          )}
          <form onSubmit={submitCalendarSettings}>
            <Heading as="h2" size="md" mb={4}>
              Video Meeting Import Settings
            </Heading>
            <StatusWithText
              passed={zoomSetupComplete}
              label={
                zoomSetupComplete
                  ? "Zoom Setup Complete"
                  : "Zoom Setup Incomplete, please contact your Administrator."
              }
            />
            <StatusWithText
              passed={googleMeetEnabled}
              label={
                googleMeetEnabled
                  ? "Google Meet Enabled"
                  : "Google Meet Not Enabled, please contact your Administrator."
              }
            />
            {videoMeetingEnabled && (
              <>
                <FormControl id="videoMeetingImportStrategy" mb={6}>
                  <FormLabel>
                    {!primaryCalendar.canSetVideoMeetingImportStrategy
                      ? "An organization administrator has set this up for you. Please contact them to change this setting."
                      : "Use the settings below to have BrightHire automatically join and record Zoom or Google Meet interviews scheduled on your calendar. "}
                  </FormLabel>
                  <RadioGroup
                    name="videoMeetingImportStrategy"
                    defaultValue={primaryCalendar.videoMeetingImportStrategy}
                    mb={4}
                  >
                    <Stack spacing="4">
                      <Radio
                        {...register("videoMeetingImportStrategy")}
                        value="TAGGED"
                        isDisabled={
                          !primaryCalendar.canSetVideoMeetingImportStrategy
                        }
                      >
                        <b>Some</b>: Meetings with #brighthire in the event
                        description
                      </Radio>
                      <Radio
                        {...register("videoMeetingImportStrategy")}
                        value="NONE"
                        isDisabled={
                          !primaryCalendar.canSetVideoMeetingImportStrategy
                        }
                      >
                        <b>None</b>: You will need to{" "}
                        <Link href="https://help.brighthire.ai/en/articles/4439267-zoom-recording-meetings">
                          manually add BrightHire
                        </Link>
                        .
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <Button
                  type="submit"
                  mb={2}
                  isLoading={updateCalendarSettingsLoading}
                  isDisabled={!primaryCalendar.canSetVideoMeetingImportStrategy}
                >
                  Save
                </Button>
              </>
            )}
          </form>
        </Flex>
      )}
    </>
  ) : (
    <>
      {currentUser?.googleUserId &&
        (!currentUser.hasGrantedGoogleCalendarScope ||
          !currentUser.hasGrantedGoogleCalendarEventScope) && (
          <Alert
            status="warning"
            mb={4}
            canHide
            description={googleScopesWarning}
          />
        )}
      <Heading as="h1" size="md" mb={4}>
        Calendar Sync
      </Heading>
      <Flex flexDirection="column" alignItems="flex-start" w="auto">
        <Text mb="4">You currently have no synced calendars.</Text>
        <Button
          mb="4"
          onClick={() => {
            window.location.href = "/sign-in?with=google";
          }}
        >
          Sync your Google calendar
        </Button>
        <Button
          mb="4"
          onClick={() => {
            window.location.href = "/sign-in?with=msft";
          }}
        >
          Sync your Microsoft calendar
        </Button>
      </Flex>
    </>
  );
};

export default CalendarSettings;
