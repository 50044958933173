import { Box, Heading } from "@chakra-ui/react";
import React from "react";

import { LoadingIndicator, SortableTable } from "../../../components";
import { formatRelativeDate } from "../../../utils/datetime";
import { AuditLog, useAuditLogQuery, User } from "../../graphql";

type AuditLogItem = { __typename?: "AuditLog" } & Pick<
  AuditLog,
  "name" | "details" | "createdAt"
> & { user: { __typename?: "User" } & Pick<User, "id" | "email"> };

const ActivityPage: React.FC = () => {
  const { data, loading } = useAuditLogQuery();
  return (
    <Box w="500px">
      <Heading as="h1" size="md" pb={6}>
        Activity
      </Heading>

      {loading ? (
        <LoadingIndicator />
      ) : (
        <SortableTable
          columns={[
            {
              Header: "Name",
              accessor: "name",
              Cell: ({
                row: { original: log },
              }: {
                row: { original: AuditLogItem };
              }) => <>{log.name}</>,
            },
            {
              Header: "User",
              accessor: "user",
              Cell: ({
                row: { original: log },
              }: {
                row: { original: AuditLogItem };
              }) => <>{log.user?.email || "system"}</>,
            },
            {
              Header: "Details",
              accessor: "details",
              Cell: ({
                row: { original: log },
              }: {
                row: { original: AuditLogItem };
              }) => log.details,
            },
            {
              Header: "Created At",
              accessor: "createdAt",
              Cell: ({
                row: { original: log },
              }: {
                row: { original: AuditLogItem };
              }) => <>{formatRelativeDate(log.createdAt)}</>,
            },
          ]}
          data={data?.auditLog || []}
          initialSort={{ id: "createdAt", desc: false }}
        />
      )}
    </Box>
  );
};

export default ActivityPage;
