import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Text,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useCallback, useRef } from "react";
import ResizeTextarea from "react-textarea-autosize";

import { useSendGAEvent } from "../../../../utils/googleAnalytics";

type AiNotesEditableQuestionProps = {
  callAiNoteId: string;
  text: string;
  edited: boolean;
  updateAiNoteQuestion: (noteId: string, newValue: string) => void;
  canEdit: boolean;
  isCandidate: boolean;
};

const AiNotesEditableQuestion: React.FC<AiNotesEditableQuestionProps> = ({
  callAiNoteId,
  text,
  edited,
  updateAiNoteQuestion,
  canEdit,
  isCandidate,
}) => {
  const input = useRef<any>(null); // any type
  const moveCursor = useCallback(() => {
    if (input?.current) {
      const el = input.current;
      el.setSelectionRange(el.value.length, el.value.length);
    }
  }, [input]);
  const sendGAEvent = useSendGAEvent();

  return (
    <Box
      mr="4"
      whiteSpace="pre-line"
      fontWeight="medium"
      color="gray.900"
      w="100%"
      lineHeight="1.5"
    >
      <Editable
        defaultValue={text}
        isPreviewFocusable
        selectAllOnFocus={false}
        onSubmit={(newValue: string) => {
          updateAiNoteQuestion(callAiNoteId, newValue);
          LogRocket.track("ai-notes-edit-question");
          sendGAEvent("ai_notes_edit_question", "call_review");
        }}
        isDisabled={!canEdit}
        onEdit={moveCursor}
        w="100%"
        fontWeight={isCandidate ? "normal" : "medium"}
      >
        {({ isEditing }: { isEditing: boolean }) => {
          return (
            <>
              <Box as="span" w="100%" py="1">
                <EditablePreview display="inline" cursor="pointer" />
                {!isEditing && edited && (
                  <>
                    {" "}
                    <Text as="span" fontSize="xs" color="gray.600">
                      (edited)
                    </Text>
                  </>
                )}
              </Box>
              <EditableInput
                ref={input}
                as={ResizeTextarea}
                resize="none"
                overflow="hidden"
                py="0"
                mb="-5px"
                _focus={{ boxShadow: "none" }}
              />
            </>
          );
        }}
      </Editable>
    </Box>
  );
};

export default AiNotesEditableQuestion;
