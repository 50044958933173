import {
  ApolloClient,
  ApolloLink,
  concat,
  NormalizedCacheObject,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { HttpLink } from "@apollo/client/link/http";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";

import cache from "./cache";

const fetcher = (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  return window.fetch(input, init).then(async (res) => {
    const h = res.headers.get("X-BrightHire-Webpack-Manifest-Hash");
    if (h != null) {
      try {
        localStorage.setItem("server-webpack-manifest-hash", h);
      } catch (e) {
        Sentry.captureException(e);
      }
    }
    return Promise.resolve(res);
  });
};

const getApolloClient = ({
  uri = "/graphql",
}: {
  uri?: string;
} = {}): ApolloClient<NormalizedCacheObject> => {
  const httpLink = new HttpLink({
    uri,
    credentials: "same-origin",
    fetch: fetcher,
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) =>
            // eslint-disable-next-line no-console
            console.log(
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        }
        if (networkError) {
          // eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      httpLink,
    ]),
    cache,
  });

  client.defaultOptions = {
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "none",
    },
  };

  // logrocket session header
  LogRocket.getSessionURL((sessionURL) => {
    const logrocketMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          "X-LogRocket-URL": sessionURL,
        },
      }));
      return forward(operation);
    });
    const newLink = concat(logrocketMiddleware, client.link);
    client.setLink(newLink);
  });

  return client;
};

export default getApolloClient;
