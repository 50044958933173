import { Flex, FlexProps, List, ListItem } from "@chakra-ui/react";
import invariant from "invariant";
import React, { useRef, useState } from "react";

import { ContentEditable } from "../../../components";
import { formatDuration } from "../../../utils/datetime";
import { CallNoteType } from "../../graphql";
import MentionMenu from "../MentionMenu";
import MonospacedText from "../MonospacedText";
import Reactions from "./Reactions";
import Timestamp from "./Timestamp";
import { getNoteStyleProps } from "./utils";

type TimestampedNoteProps = {
  callId: string;
  callDuration: number;
  time: number;
  timestamp?: number;
  onChangeTimestamp(timestamp?: number): void;
  onAddNote(text: string, type: CallNoteType, time: number): void;
  onChange?(text?: string): void;
  isDisabled?: boolean;
  clipId?: string | null;
  placeholderColor?: { default?: string; hover?: string; focus?: string };
  timestampLeft?: boolean;
  hideEmptyInput?: boolean;
} & Omit<FlexProps, "onChange">;

const TimestampedNote = React.forwardRef<HTMLDivElement, TimestampedNoteProps>(
  (
    {
      callId,
      callDuration,
      time,
      timestamp,
      onChangeTimestamp,
      onAddNote,
      onChange,
      isDisabled = false,
      placeholder: placeholderProp,
      clipId,
      placeholderColor,
      timestampLeft,
      hideEmptyInput,
      ...rest
    },
    ref
  ) => {
    invariant(typeof ref !== "function", "ref cannot be function");
    const localRef = useRef<HTMLDivElement | null>(null);
    const [doesNoteHaveText, setDoesNoteHaveText] = useState(false);
    const inputRef = ref ?? localRef;
    const noteTime = timestamp ?? time;

    // reset doesNoteHaveText when note is submitted via input or reaction
    const handleAddNote = (
      text: string,
      type: CallNoteType,
      time: number
    ): void => {
      onAddNote(text, type, time);
      setDoesNoteHaveText(false);
    };

    const handleReactionChange = (type: CallNoteType): void => {
      let text = "";
      if (inputRef.current) {
        text = inputRef.current.textContent ?? "";
        inputRef.current.textContent = null;
      }
      handleAddNote(text, type, noteTime);
      onChangeTimestamp(undefined);
    };

    const [placeholder, setPlaceholder] = useState(placeholderProp);
    const onReactionHover = (type?: CallNoteType): void => {
      let placeholderText = placeholderProp;
      if (type === CallNoteType.Star) {
        placeholderText = "Noteworthy moment";
      } else if (type === CallNoteType.Flag) {
        placeholderText = "Potential red flag";
      }

      setPlaceholder(placeholderText);
    };

    const hideInput = hideEmptyInput && !doesNoteHaveText;
    const longDuration = callDuration >= 3600;

    return (
      <List mb="2">
        <ListItem>
          <Flex
            alignItems="flex-start"
            position="relative"
            visibility={hideInput ? "hidden" : "visible"}
            pointerEvents={hideInput ? "none" : "all"}
            {...rest}
          >
            <MentionMenu inputRef={inputRef} callId={callId} clipId={clipId} />
            {timestampLeft && (
              <Flex
                minW={getNoteStyleProps(true, longDuration).timestampCol.minW}
              >
                {doesNoteHaveText && (
                  <Timestamp
                    fontWeight="semibold"
                    {...getNoteStyleProps(timestampLeft).timestamp}
                  >
                    <MonospacedText text={formatDuration(noteTime)} />
                  </Timestamp>
                )}
              </Flex>
            )}
            <Reactions
              updateNoteReaction={handleReactionChange}
              onReactionHover={onReactionHover}
            />
            <ContentEditable
              ref={inputRef}
              fontSize="sm"
              placeholder={placeholder}
              placeholderColor={placeholderColor}
              isDisabled={isDisabled}
              clearOnSubmit
              onChange={(text) => {
                if (text && timestamp === undefined) {
                  onChangeTimestamp(time);
                } else if (!text && timestamp !== undefined) {
                  onChangeTimestamp(undefined);
                }
                if (text) {
                  onChange?.(text);
                }
                setDoesNoteHaveText(!!text);
              }}
              onSubmit={(text) => {
                onChangeTimestamp(undefined);
                if (text) {
                  handleAddNote(text, CallNoteType.Note, noteTime);
                }
              }}
              {...getNoteStyleProps().contentEditable}
            />
            {!timestampLeft && doesNoteHaveText && (
              <Timestamp
                minW={getNoteStyleProps(false, longDuration).timestampCol.minW}
                fontWeight="normal"
                {...getNoteStyleProps(timestampLeft).timestamp}
              >
                <MonospacedText text={formatDuration(noteTime)} />
              </Timestamp>
            )}
          </Flex>
        </ListItem>
      </List>
    );
  }
);

export default TimestampedNote;
