import { ApolloError } from "@apollo/client";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";

import { Alert, Pill } from "../../../components";

export interface FormData {
  content: string;
}

interface TemplateSettingsFormProps {
  title: string;
  instructions: string[];
  content: string;
  error: ApolloError | undefined;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
}

const TemplateSettingsForm: React.FC<TemplateSettingsFormProps> = ({
  title,
  instructions,
  content,
  error,
  isLoading,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      content,
    },
  });
  const registerContent = register("content");
  const values = watch();
  const textAreaRef = useRef<HTMLTextAreaElement | undefined>();
  const setVar = (key: keyof FormData, value: string) => {
    return () => {
      const current = getValues()[key];
      const start = textAreaRef.current?.selectionStart || current.length;

      let text = `${current.slice(0, start).trimRight()} `;
      text += value;
      if (current.length >= start) {
        text += ` ${current.slice(start).trimLeft()}`;
      }
      setValue(key, text);

      if (!textAreaRef.current) return;
      textAreaRef.current.focus();
      textAreaRef.current.selectionStart = start + value.length + 1;
      textAreaRef.current.selectionEnd = start + value.length + 1;
    };
  };

  const currentValue = values.content;

  return (
    <form
      autoComplete="on"
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData);
      })}
    >
      {error?.graphQLErrors?.map(({ message }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Alert mb={6} key={i} status="error" description={message} />
      ))}
      <FormControl
        id="content"
        mb={3}
        isRequired
        isInvalid={errors.content !== undefined}
      >
        <FormLabel>{title}</FormLabel>
        {instructions.map((instr, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <FormHelperText key={i} color="placeholder" mb={3} fontSize="sm">
            {instr}
          </FormHelperText>
        ))}

        <Textarea
          {...registerContent}
          ref={(r: HTMLTextAreaElement) => {
            textAreaRef.current = r;
            registerContent.ref(r);
          }}
          mb={4}
        />
        <Flex wrap="wrap">
          <Text color="placeholder" fontSize="sm" mr={2} mb={3} width="100%">
            Template Variables (click to add to template):
          </Text>

          <TemplateVar
            name="Your First Name"
            description="Your first name"
            disabled={currentValue.includes("{userFirstName}")}
            onClick={setVar("content", "{userFirstName}")}
          />
          <TemplateVar
            name="Your Last Name"
            description="Your last name"
            disabled={currentValue.includes("{userLastName}")}
            onClick={setVar("content", "{userLastName}")}
          />
          <TemplateVar
            name="Organization"
            description="Your organization name"
            disabled={currentValue.includes("{orgName}")}
            onClick={setVar("content", "{orgName}")}
          />
          <TemplateVar
            name="Callback Number"
            description="Your one-click call back number"
            disabled={currentValue.includes("{callbackNumber}")}
            onClick={setVar("content", "{callbackNumber}")}
          />
        </Flex>

        {errors.content !== undefined && (
          <FormErrorMessage>{errors.content.message}</FormErrorMessage>
        )}
      </FormControl>
      <Button type="submit" mt={4} isLoading={isLoading}>
        Save
      </Button>
    </form>
  );
};

interface TemplateVarProps {
  name: string;
  description: string;
  disabled: boolean;
  onClick: () => void;
}

const TemplateVar: React.FC<TemplateVarProps> = ({
  name,
  description,
  disabled,
  onClick,
}) => {
  return (
    <Pill
      text={name}
      tooltip={description}
      userSelect="none"
      cursor={disabled ? "not-allowed" : "pointer"}
      mr={3}
      mb={3}
      bg={disabled ? "blue.100" : "gray.100"}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
    />
  );
};

export default TemplateSettingsForm;
