import { Box, Flex, IconButton, Input, Text } from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { HiOutlineTrash } from "react-icons/hi";

import { CommonGuideItemProps } from "../types";
import GuideItemLabel from "./GuideItemLabel";

const GuideItemQuestion: FC<CommonGuideItemProps> = ({
  itemId,
  text,
  isEditing,
  onDelete,
  onUpdate,
}) => {
  const [storedValue, setStoredValue] = useState<string>(text);

  if (isEditing) {
    return (
      <Box width="100%">
        <GuideItemLabel text="Question" tooltipText={itemId} />
        <Flex direction="row" alignItems="center">
          <Input
            autoFocus={isEditing}
            value={storedValue}
            onChange={(e) => {
              setStoredValue(e.target.value);
              onUpdate(itemId, { description: e.target.value });
            }}
          />
          <IconButton
            variant="icon"
            aria-label="Delete Question"
            color="red.400"
            backgroundColor="transparent"
            icon={<HiOutlineTrash size={20} />}
            ml={6}
            onClick={onDelete}
          />
        </Flex>
      </Box>
    );
  }
  return (
    <Flex direction="row" alignItems="flex-start">
      <Text color="gray.400" fontWeight="600" mr={1}>
        Q:
      </Text>
      <Text color="gray.800">{text}</Text>
    </Flex>
  );
};

export default GuideItemQuestion;
