import { Box, BoxProps } from "@chakra-ui/react";
import React, { useEffect } from "react";

import DrawerOverlay from "./DrawerOverlay";

interface DrawerProps extends BoxProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  children,
  ...props
}) => {
  const targetRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    if (targetRef.current && isOpen) {
      document.body.style.overflow = "hidden";
    }
    if (targetRef.current && !isOpen) {
      document.body.style.overflow = "";
    }
    const cleanup = (): void => {
      if (targetRef.current) {
        document.body.style.overflow = "";
      }
    };
    return cleanup;
  }, [isOpen, targetRef]);

  return (
    <>
      {isOpen && <DrawerOverlay onClick={onClose} />}
      <Box
        ref={targetRef}
        position="fixed"
        top="12"
        bottom="0"
        right="0"
        overflowY="scroll"
        transform={isOpen ? "translateX(0)" : "translateX(100%)"}
        transition="transform 0.3s ease-out"
        width="500px"
        backgroundColor="white"
        zIndex={2000}
        {...props}
      >
        {children}
      </Box>
    </>
  );
};
