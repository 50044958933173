import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StyleProps,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { IoMdThumbsDown, IoMdThumbsUp } from "react-icons/io";
import { IoCopyOutline } from "react-icons/io5";

import { LoadingIndicator, useToast } from "../../../../components";
import useCurrentBreakpoint from "../../../../hooks/useCurrentBreakpoint";
import { copy } from "../../../../utils/clipboard";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  useCallAutoSummaryBetaQuery,
  useSummarizeCallMutation,
} from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../../hooks/useCurrentUser";

type AutoSummaryProps = StyleProps & {
  callId: string;
  seekAndPlay(time: number, segmentIndex: number): void;
};

const AutoSummary: React.FC<AutoSummaryProps> = ({
  callId,
  seekAndPlay,
  ...styles
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const { data: autoSummaryData } = useCallAutoSummaryBetaQuery({
    variables: { id: callId },
  });
  const summary = autoSummaryData?.call?.autoSummary;

  const {
    isOpen: isSummarizationOpen,
    onOpen: onOpenSummarization,
    onClose: onCloseSummarization,
  } = useDisclosure({ defaultIsOpen: false });
  const {
    isOpen: isSummarizationDebugOpen,
    onOpen: onOpenSummarizationDebug,
    onClose: onCloseSummarizationDebug,
  } = useDisclosure({ defaultIsOpen: false });
  const [prompt, setPrompt] = useState(
    `Write a detailed summary of the following:
{text}

DETAILED SUMMARY:
`
  );
  const [hasSentFeedback, setHasSentFeedback] = useState(false);
  const breakpoint = useCurrentBreakpoint();
  const currentUser = useCurrentUser();
  const isDebug =
    useFeatureFlag("autosummary_debug") && currentUser.internalUserRole != null;

  useEffect(() => setHasSentFeedback(false), [summary]);

  const [summarizeCallMutation, { loading }] = useSummarizeCallMutation({
    onError: (err) => {
      toast({
        title: "Auto summarize Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const summarize = useCallback(() => {
    if (callId) {
      if (!summary) {
        summarizeCallMutation({ variables: { callId } });
      }
      if (isDebug) {
        onOpenSummarizationDebug();
      } else {
        onOpenSummarization();
      }
    }
  }, [callId, summary, isDebug]);

  const forceSummarize = (): void => {
    if (callId) {
      if (!prompt.includes("{text}")) {
        toast({
          title: "Malformed Prompt",
          description: "Prompt must contain {text}",
          status: "error",
        });
      } else {
        summarizeCallMutation({ variables: { callId, prompt } });
      }
      onOpenSummarizationDebug();
    }
  };

  const copySummary = (): void => {
    if (summary) {
      copy(summary);
      toast({
        title: "Copied summary to clipboard",
        status: "success",
      });
    }
  };

  return (
    <>
      <Button
        fontSize="sm"
        fontWeight="500"
        borderRadius="6px"
        py={0}
        pr={0}
        pl={{ lg: 3, xl: 4 }}
        onClick={summarize}
        _hover={{ bg: "blue.700" }}
        rightIcon={
          <Text
            boxSize={10}
            py={1}
            backgroundColor="whiteAlpha.300"
            transform="rotate(-90deg);"
            fontSize="xs"
            fontStyle="italic"
            marginRight="-4"
            borderBottomRightRadius="6px"
            borderBottomLeftRadius="6px"
          >
            Beta
          </Text>
        }
        {...styles}
      >
        {breakpoint === "lg" ? "Summary" : "Interview Summary"}
      </Button>

      <Modal
        size="lg"
        isOpen={isSummarizationOpen}
        onClose={onCloseSummarization}
        closeOnOverlayClick
      >
        <ModalOverlay>
          <ModalContent maxHeight="80%" maxWidth="min(750px, 60%)">
            <ModalHeader
              borderBottom="1px solid"
              borderColor="gray.100"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              pb="12px"
            >
              <Flex alignItems="center">Interview Summary</Flex>
              <Button
                variant="link"
                onClick={copySummary}
                rightIcon={<Icon as={IoCopyOutline} size={20} />}
              >
                Copy summary
              </Button>
            </ModalHeader>
            <ModalBody overflowY="scroll" whiteSpace="pre-line">
              {loading || !summary ? (
                <>
                  <Box textAlign="center">
                    Hold tight, this takes a few moments.
                  </Box>
                  <Flex height="300px">
                    <LoadingIndicator />
                  </Flex>
                </>
              ) : (
                <>
                  {summary.split("\n").map((chunk, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box key={index} mb="2">
                      {chunk}
                    </Box>
                  ))}
                </>
              )}
            </ModalBody>
            <ModalFooter
              bg="gray.100"
              borderBottomRadius="md"
              fontWeight="bold"
              justifyContent="start"
              p="10px 30px"
            >
              {!hasSentFeedback ? (
                <>
                  Was this summary valuable?
                  <Button
                    bg="green.100"
                    border="2px solid white"
                    borderRadius="50%"
                    onClick={() => {
                      setHasSentFeedback(true);
                      sendGAEvent(
                        "autosummary",
                        "user_feedback",
                        "thumbs_up",
                        callId
                      );
                    }}
                    aria-label="thumbs-up"
                    h="25px"
                    m="0 5px 0 10px"
                    p="5px"
                    w="25px"
                    minWidth="0"
                  >
                    <Icon size="14px" color="green.500" as={IoMdThumbsUp} />
                  </Button>
                  <Button
                    bg="red.100"
                    border="2px solid white"
                    borderRadius="50%"
                    onClick={() => {
                      setHasSentFeedback(true);
                      sendGAEvent(
                        "autosummary",
                        "user_feedback",
                        "thumbs_down",
                        callId
                      );
                    }}
                    aria-label="thumbs-down"
                    h="25px"
                    m="0 5px"
                    p="5px"
                    w="25px"
                    minWidth="0"
                  >
                    <Icon size="14px" color="red.300" as={IoMdThumbsDown} />
                  </Button>
                </>
              ) : (
                <>Thank you for your feedback!</>
              )}
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
      <Modal
        size="lg"
        isOpen={isSummarizationDebugOpen}
        onClose={onCloseSummarizationDebug}
        closeOnOverlayClick
      >
        <ModalOverlay>
          <ModalContent maxHeight="80%" maxWidth="min(750px, 60%)">
            <ModalHeader
              borderBottom="1px solid"
              borderColor="gray.100"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              pb="12px"
            >
              <Flex alignItems="center">Interview Summary DEBUG</Flex>
              <Button
                variant="link"
                onClick={copySummary}
                rightIcon={<Icon as={IoCopyOutline} size={20} />}
              >
                Copy summary
              </Button>
            </ModalHeader>
            <ModalBody overflowY="scroll" whiteSpace="pre-line">
              {loading || !summary ? (
                <>
                  <Box textAlign="center">
                    Hold tight, this takes a few moments.
                  </Box>
                  <Flex height="300px">
                    <LoadingIndicator />
                  </Flex>
                </>
              ) : (
                <>
                  <Textarea
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                  />
                  <Button onClick={forceSummarize}>New summary</Button>
                  {summary.split("\n").map((chunk, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box key={index} mb="2">
                      {chunk}
                    </Box>
                  ))}
                </>
              )}
            </ModalBody>
            <ModalFooter
              bg="gray.100"
              borderBottomRadius="md"
              fontWeight="bold"
              justifyContent="start"
              p="10px 30px"
            >
              {!hasSentFeedback ? (
                <>
                  Was this summary valuable?
                  <Button
                    bg="green.100"
                    border="2px solid white"
                    borderRadius="50%"
                    onClick={() => {
                      setHasSentFeedback(true);
                      sendGAEvent(
                        "autosummary",
                        "user_feedback",
                        "thumbs_up",
                        callId
                      );
                    }}
                    aria-label="thumbs-up"
                    h="25px"
                    m="0 5px 0 10px"
                    p="5px"
                    w="25px"
                    minWidth="0"
                  >
                    <Icon size="14px" color="green.500" as={IoMdThumbsUp} />
                  </Button>
                  <Button
                    bg="red.100"
                    border="2px solid white"
                    borderRadius="50%"
                    onClick={() => {
                      setHasSentFeedback(true);
                      sendGAEvent(
                        "autosummary",
                        "user_feedback",
                        "thumbs_down",
                        callId
                      );
                    }}
                    aria-label="thumbs-down"
                    h="25px"
                    m="0 5px"
                    p="5px"
                    w="25px"
                    minWidth="0"
                  >
                    <Icon size="14px" color="red.300" as={IoMdThumbsDown} />
                  </Button>
                </>
              ) : (
                <>Thank you for your feedback!</>
              )}
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default AutoSummary;
