import { ApolloError } from "@apollo/client";
import { Box, BoxProps, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";

import { Alert } from "../../../../components";
import ReadOnlyEmptyNotes from "../../../../components/Images/ReadOnlyEmptyNotes";
import {
  CallNoteFragment,
  CallNoteType,
  CallQuestionFragment,
} from "../../../graphql";
import CallNotesSection from "../../CallNotes/beta/CallNotesSection";
import CallQuestionNotesSection, {
  CallQuestionNotesSectionProps,
} from "../../CallNotes/beta/CallQuestionNotesSection";
import useCurrentNote from "../../CallNotes/beta/useCurrentNote";
import { ClipRange } from "../../Interview/Clip/types";
import { RenderContent } from "../../utils";

export type NotesTabProps = BoxProps & {
  callId: string;
  clipId?: string;
  callDuration: number;
  interviewerIds: string[];
  notesReadOnly: boolean;
  questions: CallQuestionFragment[];
  generalNotes: CallNoteFragment[];
  headerSlot?: RenderContent;
  onClickTimestamp(t: number): void;
  onAddNote(note: {
    text: string;
    type: CallNoteType;
    time: number;
    questionId?: string;
  }): Promise<void>;
  setClipRange?(c: ClipRange | null): void;
  playerTime: number;
  error?: ApolloError;
  loading?: boolean;
};

const NotesTab: React.FC<NotesTabProps> = ({
  callId,
  clipId,
  callDuration,
  interviewerIds,
  notesReadOnly,
  questions,
  generalNotes,
  headerSlot,
  onClickTimestamp,
  setClipRange,
  onAddNote,
  playerTime,
  error,
  loading,
  ...rest
}) => {
  const answeredQuestions = questions.filter((q) => q.questionNotes.length);
  const unansweredQuestions = questions.filter((q) => !q.questionNotes.length);
  const currentNoteId = useCurrentNote(generalNotes, questions, playerTime);

  const showEmptyNotes =
    notesReadOnly &&
    generalNotes.length === 0 &&
    answeredQuestions.length === 0 &&
    unansweredQuestions.length === 0;

  const questionNotesProps = (
    question: CallQuestionFragment
  ): CallQuestionNotesSectionProps => ({
    callId,
    clipId,
    callDuration,
    interviewerIds,
    question,
    playerTime,
    onAddNote,
    onClickTimestamp,
    currentNoteId,
    notesReadOnly,
    setClipRange,
  });

  if (error) {
    return (
      <Alert
        status="error"
        title="Error fetching notes"
        description={error.message}
      />
    );
  }

  if (loading) {
    return null;
  }

  if (showEmptyNotes) {
    return (
      <Flex alignItems="center" flexDir="column" pt="36">
        <ReadOnlyEmptyNotes />
        <Box fontSize="medium" textAlign="center" pt="6">
          This interview contains no notes
        </Box>
      </Flex>
    );
  }

  return (
    <Box fontSize="sm" px={{ base: "1px", md: "4" }} {...rest}>
      {(!notesReadOnly || generalNotes.length > 0) && (
        <Flex alignItems="center" mt="2" p="3" fontWeight="medium">
          <Text as="span">General Notes</Text>
          {headerSlot && <Box ml="auto">{headerSlot}</Box>}
        </Flex>
      )}

      {/* General notes */}
      <CallNotesSection
        callId={callId}
        clipId={clipId}
        callDuration={callDuration}
        interviewerIds={interviewerIds}
        notes={generalNotes}
        playerTime={playerTime}
        onAddNote={onAddNote}
        onClickTimestamp={onClickTimestamp}
        setClipRange={setClipRange}
        currentNoteId={currentNoteId}
        notesReadOnly={notesReadOnly}
      />

      {answeredQuestions.map((question) => (
        <CallQuestionNotesSection
          key={question.id}
          {...questionNotesProps(question)}
        />
      ))}

      {unansweredQuestions.length > 0 && (
        <>
          <Divider my="3" />

          {unansweredQuestions.map((question) => (
            <CallQuestionNotesSection
              key={question.id}
              {...questionNotesProps(question)}
            />
          ))}
        </>
      )}
    </Box>
  );
};

NotesTab.displayName = "NotesTab";

export default React.memo(NotesTab);
