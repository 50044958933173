import { ApolloError } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { Alert } from "../../../components";
import { PositionSelect } from "../../components";
import StaticSelect from "../../components/StaticSelect";
import UserSelect from "../../components/UserSelect";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";

export interface FormData {
  name: string;
  positionId?: string;
  stageIds?: string[];
  assignedUserIds?: string[];
}

interface CallGuideFormProps {
  name: string;
  positionId?: string;
  assignedUserIds?: string[];
  error: ApolloError | undefined;
  isLoading: boolean;
  onSubmit: (formData: {
    name: string;
    positionId?: string;
    assignedUserIds?: string[];
  }) => void;
  onCancel: () => void;
  title: string;
}

const GuideForm: React.FC<CallGuideFormProps> = ({
  name,
  positionId,
  assignedUserIds,
  error,
  isLoading,
  onSubmit,
  onCancel,
  title,
}) => {
  const {
    register,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name,
      positionId,
      assignedUserIds,
    },
  });

  const submit = handleSubmit(({ name, positionId, assignedUserIds }): void => {
    onSubmit({
      name: name?.trim(),
      positionId,
      assignedUserIds,
    });
    onCancel();
  });

  const currentUser = useCurrentUser();
  const canAssignGuides =
    currentUser.organization.assignGuidesToInterviewersEnabled &&
    currentUser.userRole?.canManageHiringTeam;
  const guidesDemoEnabled = useFeatureFlag("guidesDemoEnabled");

  return (
    <form onSubmit={submit}>
      <ModalContent>
        <ModalHeader pb="0">{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error?.graphQLErrors?.map(({ message }, i) => (
            <Alert
              mb={6}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              status="error"
              description={message || "Error saving guide"}
            />
          ))}
          <VStack spacing="6">
            <FormControl isRequired isInvalid={errors.name !== undefined}>
              <FormLabel color="gray.600" htmlFor="name">
                Name
              </FormLabel>
              <Input
                {...register("name")}
                aria-label="name"
                borderRadius="sm"
                placeholder="Screen, Interview, Intake, etc."
                maxLength={100}
              />
              {errors.name !== undefined && (
                <FormErrorMessage>{errors.name.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormLabel color="gray.600" htmlFor="positionId">
                Attach to a Position (optional)
              </FormLabel>
              <Controller
                name="positionId"
                control={control}
                render={({ field }) => (
                  <PositionSelect
                    {...field}
                    positionId={field.value}
                    onSelect={(position) => {
                      if (position?.id !== field.value) {
                        setValue("positionId", position?.id || "");
                      }
                    }}
                  />
                )}
              />
              {errors.positionId !== undefined && (
                <FormErrorMessage>{errors.positionId.message}</FormErrorMessage>
              )}
            </FormControl>
            {guidesDemoEnabled && (
              <FormControl id="stage-ids">
                <FormLabel color="gray.600">Stages</FormLabel>
                <Controller
                  name="stageIds"
                  control={control}
                  render={({ field }) => (
                    <StaticSelect
                      {...field}
                      staticList="stage"
                      stageIds={getValues().stageIds}
                      onSelect={(stage) => {
                        if (stage && getValues().stageIds) {
                          setValue("stageIds", [
                            ...(getValues().stageIds as string[]),
                            stage.id,
                          ]);
                        }
                      }}
                    />
                  )}
                />
                {errors.stageIds !== undefined && (
                  <FormErrorMessage>{errors.stageIds.message}</FormErrorMessage>
                )}
              </FormControl>
            )}
            {(guidesDemoEnabled || canAssignGuides) && (
              <FormControl id="interviewer-ids">
                <FormLabel color="gray.600">
                  Assign to interviewers (optional)
                </FormLabel>
                <Controller
                  name="assignedUserIds"
                  control={control}
                  render={({ field }) => (
                    <UserSelect
                      {...field}
                      userIds={field.value}
                      onSelect={(userIds) =>
                        setValue("assignedUserIds", userIds)
                      }
                    />
                  )}
                />
                {errors.assignedUserIds !== undefined && (
                  <FormErrorMessage>
                    {errors.assignedUserIds.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter borderColor="gray.100 !important" borderTop="1px solid">
          <ButtonGroup
            spacing={4}
            alignSelf="end"
            width="100%"
            justifyContent="space-between"
          >
            <Button fontWeight="500" variant="ghost" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              fontWeight="500"
              type="submit"
              isLoading={isLoading}
              borderRadius="lg"
            >
              Save
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </form>
  );
};

export default GuideForm;
