import {
  Box,
  Button,
  Flex,
  Link,
  StyleProps,
  SystemProps,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import Color from "color";
import LogRocket from "logrocket";
import React, { useRef, useState } from "react";
import { IoCut, IoLink } from "react-icons/io5";

import { useToast } from "../../../../components";
import colors from "../../../../theme/colors";
import { copy } from "../../../../utils/clipboard";
import { formatDuration } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  CallNoteFragment,
  CallSpeakerFragment,
  CallSpeakerOption,
  TranscriptSegmentFragment,
  useUpdateCallSpeakerMutation,
} from "../../../graphql";
import Timestamp from "../../CallNotes/Timestamp";
import { noteTypeColorFromTheme } from "../../CallNotes/utils";
import { ClipRange } from "../../Interview/Clip/types";
import MonospacedText from "../../MonospacedText";
import SpeakerMenu from "../SpeakerMenu";
import { Speaker } from "./Speaker";
import TranscriptWordBeta from "./TranscriptWordBeta";
import { MatchPosition, SegmentPosition } from "./useTranscriptSearchBeta";

const PUNCTUATION_REGEX = /[.?!,]/g;

type TranscriptSpeakerMenuProps = {
  callId?: string;
  speaker?: Pick<CallSpeakerFragment, "label" | "speakerTag" | "identified">;
  canChangeSpeaker: boolean;
  color: string;
  hoverColor: string;
  speakerOptions?: Pick<CallSpeakerOption, "id" | "label">[];
};

const TranscriptSpeakerMenu: React.FC<TranscriptSpeakerMenuProps> = ({
  callId,
  speaker,
  canChangeSpeaker,
  color,
  hoverColor,
  speakerOptions,
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const [updateCallSpeakerMutation] = useUpdateCallSpeakerMutation({
    onError: () => {
      toast({ status: "error", title: "Error changing speaker" });
    },
  });

  const updateCallSpeaker = (speakerOptionId: string): void => {
    if (callId && speaker) {
      sendGAEvent("update_speaker", "call_review");
      LogRocket.track("speaker-label-updated");
      updateCallSpeakerMutation({
        variables: {
          callId,
          speakerTag: speaker.speakerTag,
          speakerOptionId,
        },
      });
    }
  };

  const commonStyleProps: SystemProps = {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "300px",
    color,
    fontWeight: "500",
    _hover: {
      cursor: "pointer",
      color: hoverColor,
    },
  };

  let speakerLabel = speaker?.label || "";
  if (!speaker?.identified) {
    speakerLabel = `Set ${speakerLabel}`;
  }

  return (
    <SpeakerMenu
      disabled={!canChangeSpeaker}
      trigger={Speaker}
      triggerProps={{ speakerLabel, ...commonStyleProps }}
      speakerOptions={speakerOptions}
      onSelect={(option) => updateCallSpeaker(option.id)}
    />
  );
};

const SegmentWrapper: React.FC<{
  hasRedactions: boolean;
  children: JSX.Element;
}> = ({ hasRedactions, children }) => {
  if (!hasRedactions) {
    return children;
  }
  return (
    <Tooltip
      label="This section is redacted based on your
organization’s compliance settings."
    >
      <Box className="segment">{children}</Box>
    </Tooltip>
  );
};

type TranscriptSegmentBetaProps = {
  speaker?: Pick<
    CallSpeakerFragment,
    "label" | "speakerTag" | "identified" | "isCandidate"
  >;
  playerTime: number;
  segment: TranscriptSegmentFragment;
  segmentIndex: number;
  matchPositions?: MatchPosition[];
  segmentPosition?: SegmentPosition;
  notes?: CallNoteFragment[];
  onSeek: (time: number, segmentIndex: number) => void;
  onCreateClipClick?: (range: ClipRange) => void;
  callId?: string;
  speakerOptions?: Pick<CallSpeakerOption, "id" | "label">[];
  canChangeSpeaker?: boolean;
  isCopyEnabled?: boolean;
} & Pick<StyleProps, "px">;

const TranscriptSegmentBeta: React.FC<TranscriptSegmentBetaProps> = ({
  speaker,
  playerTime,
  segment,
  segmentIndex,
  matchPositions,
  segmentPosition,
  notes,
  onSeek,
  onCreateClipClick,
  speakerOptions,
  callId,
  canChangeSpeaker = false,
  isCopyEnabled = true,
  px,
}) => {
  const segmentWordStart = segment.words[0].startTime;
  const sendGAEvent = useSendGAEvent();
  const ref = useRef<HTMLDivElement>(null);
  const {
    isOpen: isHovering,
    onOpen: onMouseEnter,
    onClose: onMouseLeave,
  } = useDisclosure();

  const [showRawWords, setShowRawWords] = useState(false);

  const currentWord = segment.words.find((word, index) => {
    // It would be more accurate to use the start time of the next segment in
    // the case that this is the last word in the segment
    const nextWord =
      index < segment.words.length - 1
        ? segment.words[index + 1]
        : { startTime: segment.endTime };
    return nextWord.startTime >= playerTime && word.startTime <= playerTime;
  });

  const hasRedactions = !!segment.words.find((word) => word.isRedacted);

  let color = "gray.400";
  let hoverColor = "blue.600";
  if (speaker?.isCandidate) {
    color = "purple.600";
    hoverColor = "purple.800";
  } else if (speaker?.identified) {
    color = "blue.600";
    hoverColor = "link.hover";
  }

  let loopIndex = segmentPosition?.startIndex ?? 0;
  return (
    <Box
      position="relative"
      py="3"
      px={px}
      fontSize="md"
      role="group"
      color="gray.700"
      lineHeight="20px"
      ref={ref}
      data-testid={`clip-segment-${segmentIndex}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Flex
        mb="1"
        userSelect="none"
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Flex direction="row" fontSize="sm">
          <TranscriptSpeakerMenu
            callId={callId}
            speaker={speaker}
            canChangeSpeaker={canChangeSpeaker}
            color={color}
            hoverColor={hoverColor}
            speakerOptions={speakerOptions}
          />
          <Timestamp ml="4" mt="-1px" minW="40px">
            <Link
              onClick={() => onSeek(segmentWordStart, segmentIndex)}
              color={color}
              _hover={{ color: hoverColor }}
              fontWeight="500"
            >
              <MonospacedText
                text={formatDuration(Math.round(segmentWordStart))}
              />
            </Link>
          </Timestamp>
        </Flex>
        <Flex direction="row" mr="5">
          {onCreateClipClick && (
            <Tooltip label="Create a clip">
              <Button
                aria-label="Create a clip"
                leftIcon={<IoCut />}
                variant="link"
                fontWeight="500"
                size="sm"
                onClick={() => {
                  const { words } = segment;
                  onCreateClipClick({
                    start: words[0].startTime,
                    end: words[words.length - 1].endTime,
                  });
                  sendGAEvent(
                    "clip_creation_start",
                    "call_review",
                    "clip_from_transcript"
                  );
                }}
                hidden={!isHovering}
              >
                Create clip
              </Button>
            </Tooltip>
          )}
          {isCopyEnabled && (
            <Tooltip label="Link to this Moment">
              <Button
                aria-label="Copy Link"
                leftIcon={<IoLink size="17" />}
                variant="link"
                ml="8"
                fontWeight="500"
                size="sm"
                onClick={() => {
                  copy(
                    `${window.location.protocol}//${window.location.host}${
                      window.location.pathname
                    }?t=${getSegmentPreciseStartTime(segment)}`
                  );
                }}
                hidden={!isHovering}
              >
                Copy link
              </Button>
            </Tooltip>
          )}
        </Flex>
      </Flex>
      <SegmentWrapper hasRedactions={hasRedactions}>
        <>
          {segment.words.map((word, index) => {
            const nextWord = segment.words[index + 1] ?? undefined;
            const previousWord = segment.words[index - 1] ?? undefined;
            const hasEndingPunctuation =
              word.word[word.word.length - 1].match(PUNCTUATION_REGEX);
            const wordStart = loopIndex;
            const wordEnd =
              loopIndex + (word.word.length - (hasEndingPunctuation ? 2 : 1));
            //  Is there a match inside this word?
            const partialMatch = matchPositions?.find(
              (match) =>
                wordStart <= match.startIndex && match.endIndex <= wordEnd
            );
            //  Is this word inside a larger match?
            const multiWordMatch = matchPositions?.find(
              (match) =>
                match.startIndex <= wordStart && wordEnd <= match.endIndex
            );
            const noteMatch = notes?.find(
              (note) =>
                note.highlightStartTime &&
                word.startTime >= note.highlightStartTime &&
                note.highlightEndTime &&
                word.endTime <= note.highlightEndTime
            );
            const isCurrentWord =
              word.startTime === currentWord?.startTime &&
              word.endTime === currentWord?.endTime;
            let backgroundColor = "transparent";
            let color = "primary";
            if (noteMatch) {
              backgroundColor = Color(
                noteTypeColorFromTheme(noteMatch.type, colors)
              )
                .alpha(0.4)
                .string();
            }
            const searchMatch = partialMatch ?? multiWordMatch;
            if (searchMatch) {
              backgroundColor = colors.searchBackgroundBeta;
            }
            if (searchMatch?.isActiveMatch) {
              backgroundColor = colors.currentSearchBackgroundBeta;
              color = "white";
            }
            if (isCurrentWord) {
              backgroundColor = "blue.400";
              color = "white";
            }
            if (word.isRedacted) {
              backgroundColor = "gray.100";
              color =
                previousWord?.isRedacted && !showRawWords
                  ? "gray.100"
                  : "gray.800";
            }
            const isFinalPartOfMatch =
              (wordEnd === searchMatch?.endIndex && !noteMatch) ||
              noteMatch?.highlightEndTime === word.endTime ||
              (word.isRedacted && !nextWord?.isRedacted);
            // Length + 1 to compensate for the space that comes after the word.
            // Don't change this! Search highlighting will completely break if you do. :(
            loopIndex += word.word.length + 1;
            return (
              <TranscriptWordBeta
                // eslint-disable-next-line react/no-array-index-key
                key={`${segmentIndex}-${index}`}
                word={
                  showRawWords && word.isRedacted && word.rawWord
                    ? { ...word, word: word.rawWord }
                    : word
                }
                onClick={(time: number) => {
                  if (word.isRedacted && word.rawWord)
                    setShowRawWords(!showRawWords);
                  if (!word.isRedacted) onSeek(time, segmentIndex);
                }}
                color={color}
                backgroundColor={backgroundColor}
                isMultiHighlight={
                  !!(
                    noteMatch ||
                    word.isRedacted ||
                    (!partialMatch && multiWordMatch)
                  )
                }
                isFinalPartOfMatch={!!isFinalPartOfMatch}
              />
            );
          })}
        </>
      </SegmentWrapper>
    </Box>
  );
};

const getSegmentPreciseStartTime = (
  segment: TranscriptSegmentFragment
): number => {
  if (segment.words.length === 0) {
    return segment.startTime;
  }
  return segment.words[0].startTime;
};

export default React.memo(TranscriptSegmentBeta);
