import { Reference } from "@apollo/client";
import {
  Box,
  Flex,
  Grid,
  GridProps,
  Icon,
  Link,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import invariant from "invariant";
import React, { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { v4 as uuidv4 } from "uuid";

import {
  Avatar,
  ContentEditable,
  ContentEditableProps,
  Linkify,
  useToast,
} from "../../../components";
import colorVars from "../../../theme/css-color-variables";
import { formatDuration, formatRelativeDate } from "../../../utils/datetime";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CallNoteFragment,
  CallNoteReplyFragment,
  CallNoteReplyFragmentDoc,
  CallNoteType,
  useAddCallNoteReplyMutation,
  useUpdateCallNoteMutation,
} from "../../graphql";
import useDeleteCallNote from "../../graphql/hooks/useDeleteCallNote";
import useCurrentUser from "../../hooks/useCurrentUser";
import { TimestampLink } from "../../interview-assistant/pages/call-in-progress/TimestampLink";
import { ClipRange } from "../Interview/Clip/types";
import MentionMenu from "../MentionMenu";
import MonospacedText from "../MonospacedText";
import CallNoteHoverMenu from "./CallNoteHoverMenu";
import Reactions from "./Reactions";
import ReplyForm from "./ReplyForm";
import Timestamp from "./Timestamp";
import { getNoteStyleProps, noteTypeColor, noteTypeIcon } from "./utils";

type CallNoteProps = {
  note: CallNoteFragment;
  onDelete: (id: string) => void;
  noteIsDeleting?: boolean;
  onClickTimestamp?: (time: number) => void;
  showAvatar?: boolean;
  interviewerIds?: Array<string>;
  notesReadOnly?: boolean;
  limitedNoteControls?: boolean;
  setClipRange?(clipRange: ClipRange | null, adjustByWordTime: boolean): void;
  duration?: number | null | undefined;
  timestampLeft?: boolean;
} & GridProps;

const CallNote = React.forwardRef<HTMLDivElement, CallNoteProps>(
  (
    {
      note,
      onDelete,
      noteIsDeleting,
      onClickTimestamp,
      showAvatar,
      interviewerIds,
      notesReadOnly,
      limitedNoteControls,
      setClipRange,
      duration,
      timestampLeft,
      ...rest
    },
    ref
  ) => {
    const toast = useToast();
    const {
      isOpen: isHovering,
      onOpen: onMouseEnter,
      onClose: onMouseLeave,
    } = useDisclosure({
      isOpen: isMobile ? false : undefined,
    });
    const {
      isOpen: isReplying,
      onOpen: onReplyOpen,
      onClose: onReplyClose,
    } = useDisclosure();
    const [updateNote] = useUpdateCallNoteMutation({
      onError: (error) =>
        toast({
          title: "Error updating note.",
          description: error.message,
          status: "error",
        }),
    });
    const [addReply, { loading: addReplyLoading }] =
      useAddCallNoteReplyMutation({
        update(cache, { data: addReplyData }) {
          cache.modify({
            id: cache.identify(note),
            fields: {
              replies(existingReplies: Array<Reference> = [], { readField }) {
                if (addReplyData) {
                  const reply = cache.writeFragment({
                    data: addReplyData.addCallNoteReply?.callNote,
                    fragment: CallNoteReplyFragmentDoc,
                    fragmentName: "CallNoteReply",
                  });
                  // check if the reply has already been added to the cache
                  const found = existingReplies.some(
                    (n) => readField("id", n) === readField("id", reply)
                  );
                  if (!found) {
                    return [...existingReplies, reply];
                  }
                }
                return existingReplies;
              },
            },
          });
        },
        onError: (error) =>
          toast({
            title: "Error adding reply.",
            description: error.message,
            status: "error",
          }),
        onCompleted: ({ addCallNoteReply }) => {
          if (addCallNoteReply?.callNote) {
            onReplyClose();
          }
        },
      });
    const [deleteNote] = useDeleteCallNote({
      onError: (error) =>
        toast({
          title: "Error updating note.",
          description: error.message,
          status: "error",
        }),
    });

    const currentUser = useCurrentUser();
    const sendGAEvent = useSendGAEvent();
    const readOnly = note.user?.id !== currentUser.id;
    const user = note.user ?? {
      id: undefined,
      firstName: "BrightHire",
      profilePicUrl: "/static/images/maskable-512-v2.png",
    };
    const isInterviewerOwned = interviewerIds?.some(
      (interviewerId) => interviewerId === note.user?.id
    );
    const updateNoteReaction = (
      newNoteType: CallNoteType,
      previousNoteType?: CallNoteType
    ): void => {
      if (previousNoteType === newNoteType) {
        if (!note.text.trim()) {
          deleteNote({ variables: { id: note.id } });
        } else {
          updateNote({
            variables: { id: note.id, type: CallNoteType.Note },
          });
        }
      } else {
        updateNote({
          variables: { id: note.id, type: newNoteType },
        });
      }
    };

    const templateAreas = timestampLeft
      ? '"timestamp reactions text menu" "replies replies replies replies"'
      : '"reactions text menu timestamp" "replies replies replies replies"';

    // don't display cues, highlights, or questions in notes list
    if (
      [
        CallNoteType.HighlightV2,
        CallNoteType.Cue,
        CallNoteType.Question,
      ].includes(note.type)
    ) {
      return null;
    }

    return (
      <Grid
        key={note.id}
        ref={ref}
        templateRows="minmax(24px, auto) auto"
        templateAreas={templateAreas}
        position="relative"
        alignItems="flex-start"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        bg={isHovering ? "blue.50" : undefined}
        {...rest}
      >
        <Flex gridArea="reactions">
          <Reactions
            noteType={note.type}
            updateNoteReaction={updateNoteReaction}
            showAvatar={showAvatar}
            user={user}
            isInterviewerOwned={isInterviewerOwned}
            readOnly={readOnly || notesReadOnly}
          />
        </Flex>
        <Flex gridArea="text" overflow="hidden" textOverflow="ellipsis">
          {[CallNoteType.Note, CallNoteType.Comment].includes(note.type) ? (
            <EditableNote
              note={note}
              isDisabled={readOnly || notesReadOnly}
              onBlur={(newValue) => {
                const text = newValue?.trim();
                if (text) {
                  sendGAEvent("edit_note", "call_review");
                  updateNote({
                    variables: { id: note.id, text },
                    optimisticResponse: {
                      updateCallNote: {
                        __typename: "UpdateCallNote",
                        callNote: {
                          ...note,
                          text,
                        },
                      },
                    },
                  });
                } else {
                  sendGAEvent("delete_note", "call_review");
                  onDelete(note.id);
                }
              }}
              {...getNoteStyleProps(timestampLeft).contentEditable}
            />
          ) : (
            <EditableNote
              note={note}
              placeholder={
                // TODO: Adjust for backwards compatibility
                note.description
                  ? `''${note.description.slice(0, 100)}${
                      note.description.length > 100 ? "..." : ""
                    }''`
                  : note.type === CallNoteType.Flag
                  ? `Potential red flag${
                      !(readOnly || notesReadOnly)
                        ? " (click to add a reason)"
                        : ""
                    }`
                  : note.type === CallNoteType.Star
                  ? `Noteworthy moment${
                      !(readOnly || notesReadOnly)
                        ? " (click to add a reason)"
                        : ""
                    }`
                  : `${note.user?.firstName ?? ""} left a ${note.type
                      .toLowerCase()
                      .replace("_", " ")}`
              }
              isDisabled={readOnly || notesReadOnly}
              onBlur={(newValue) => {
                const text = newValue?.trim();
                if (text != null) {
                  sendGAEvent("edit_note", "call_review");
                  updateNote({
                    variables: { id: note.id, text },
                    optimisticResponse: {
                      updateCallNote: {
                        __typename: "UpdateCallNote",
                        callNote: {
                          ...note,
                          text,
                        },
                      },
                    },
                  });
                }
              }}
              {...getNoteStyleProps(timestampLeft).contentEditable}
            />
          )}
        </Flex>
        {isHovering && !notesReadOnly && (
          <CallNoteHoverMenu
            note={note}
            onDelete={onDelete}
            onReplyOpen={onReplyOpen}
            readOnly={readOnly}
            setClipRange={setClipRange}
            duration={duration}
            noteIsDeleting={noteIsDeleting}
            onClickTimestamp={onClickTimestamp}
            limitedNoteControls={limitedNoteControls}
          />
        )}
        <Box gridArea="replies" ml="76px" overflowX="hidden">
          {note.replies.map((reply, index) => (
            <Box
              key={reply.id}
              mt={index === 0 ? "4" : "0"}
              mb={index + 1 === note.replies.length ? "2" : "0"}
            >
              <CallNoteReply
                reply={reply}
                isInterviewerOwned={interviewerIds?.some(
                  (interviewerId) => interviewerId === reply.user?.id
                )}
                notesReadOnly={notesReadOnly}
                onUpdate={(id, text) => {
                  sendGAEvent("edit_reply", "call_review");
                  updateNote({
                    variables: { id, text },
                    optimisticResponse: {
                      updateCallNote: {
                        __typename: "UpdateCallNote",
                        callNote: {
                          ...reply,
                          text,
                          replies: [],
                          time: note.time,
                          type: CallNoteType.Note,
                        },
                      },
                    },
                  });
                }}
                onDelete={onDelete}
              />
            </Box>
          ))}
          {isReplying && (
            <Box my="2">
              <ReplyForm
                callId={note.callId}
                isLoading={addReplyLoading}
                onSubmit={({ text: value, visibility }) => {
                  const text = value?.trim();
                  if (text) {
                    sendGAEvent("note_reply", "call_review");
                    addReply({
                      variables: {
                        parentCallNoteId: note.id,
                        text,
                        visibility,
                      },
                      optimisticResponse: {
                        addCallNoteReply: {
                          __typename: "AddCallNoteReply",
                          callNote: {
                            __typename: "CallNote",
                            id: uuidv4(),
                            createdAt: null,
                            text,
                            visibility,
                            callId: note.callId,
                            isEdited: false,
                            user: { ...currentUser },
                          },
                        },
                      },
                    });
                  }
                }}
                onCancel={() => {
                  onReplyClose();
                  onMouseLeave();
                }}
              />
            </Box>
          )}
        </Box>
        {note.type === CallNoteType.Comment ? (
          <Box textAlign="center">
            <CallNoteIcon note={note} />
          </Box>
        ) : (
          <Timestamp
            gridArea="timestamp"
            {...getNoteStyleProps(timestampLeft).timestamp}
          >
            {onClickTimestamp ? (
              <TimestampLink note={note} onClickTimestamp={onClickTimestamp} />
            ) : (
              <MonospacedText text={formatDuration(note.time)} />
            )}
          </Timestamp>
        )}
      </Grid>
    );
  }
);

interface CallNoteIconProps {
  note: CallNoteFragment;
}

const CallNoteIcon: React.FC<CallNoteIconProps> = ({ note }) => (
  <Icon
    mx="2"
    my="2px"
    boxSize="18px"
    color={noteTypeColor(note.type)}
    as={noteTypeIcon(note.type)}
  />
);

interface CallNoteReplyProps {
  reply: CallNoteReplyFragment;
  isInterviewerOwned?: boolean;
  notesReadOnly?: boolean;
  onUpdate: (id: string, text: string) => void;
  onDelete: (id: string) => void;
}

const CallNoteReply: React.FC<CallNoteReplyProps> = ({
  reply,
  isInterviewerOwned,
  notesReadOnly,
  onUpdate,
  onDelete,
}) => {
  const {
    isOpen: isHovering,
    onOpen: onMouseEnter,
    onClose: onMouseLeave,
  } = useDisclosure();
  const sendGAEvent = useSendGAEvent();
  const currentUser = useCurrentUser();
  const { colors } = useTheme();
  invariant(reply.user, "Missing user for reply");
  return (
    <Box
      pl="3"
      pb="2"
      boxShadow={`inset 2px 0px 0px 0px ${colors.gray[200]}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Flex alignItems="center">
        <Avatar
          user={reply.user}
          showTooltip
          isInterviewer={isInterviewerOwned}
          mr="3"
        />
        <Box fontSize="10px">
          {formatRelativeDate(reply.createdAt)}{" "}
          {reply.isEdited && <> &#183; edited </>}
          {isHovering && !notesReadOnly && reply.user.id === currentUser.id && (
            <span>
              &#183;
              <Link onClick={() => onDelete(reply.id)}> Delete </Link>
            </span>
          )}
        </Box>
      </Flex>
      <EditableNote
        note={reply}
        isDisabled={reply.user.id !== currentUser.id || notesReadOnly}
        onBlur={(newValue) => {
          const text = newValue?.trim();
          if (text) {
            sendGAEvent("edit_reply", "call_review");
            onUpdate(reply.id, text);
          } else {
            sendGAEvent("delete_reply", "call_review");
            onDelete(reply.id);
          }
        }}
      />
    </Box>
  );
};

const StyledBox = styled(Box)`
  &:empty::before {
    content: "${(props: any) => props.placeholder}";
    color: ${colorVars.gray[500]};
  }
`;

type EditableNoteProps = {
  note: { text: string; callId: string; clipId?: string | null };
  onBlur: ((text?: string | null | undefined) => void) | undefined;
} & ContentEditableProps;

const EditableNote: React.FC<EditableNoteProps> = ({
  note,
  isDisabled,
  onBlur,
  onChange,
  onSubmit,
  ...rest
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  return isEditing ? (
    <>
      <MentionMenu
        inputRef={inputRef}
        callId={note.callId}
        clipId={note.clipId}
      />
      <ContentEditable
        ref={inputRef}
        defaultValue={note.text}
        isDisabled={isDisabled}
        onBlur={(text) => {
          if (text !== note.text) {
            onBlur?.(text);
          }
          setIsEditing(false);
        }}
        {...rest}
      />
    </>
  ) : (
    <Linkify>
      <StyledBox
        onClick={isDisabled ? undefined : () => setIsEditing(true)}
        cursor={isDisabled ? undefined : "pointer"}
        whiteSpace="pre-wrap"
        overflowX="hidden"
        textOverflow="ellipsis"
        {...rest}
      >
        {note.text.trim() || null}
      </StyledBox>
    </Linkify>
  );
};

export default React.memo(CallNote);
