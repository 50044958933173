import { Box, Heading } from "@chakra-ui/react";
import React from "react";

import { useToast } from "../../../components";
import {
  CurrentUserFragment,
  useUpdateOrganizationZoomNotetakerMutation,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import ZoomBotRecordingAuth from "./ZoomBotRecordingAuth";
import ZoomNotetakerSettingsForm from "./ZoomNotetakerSettingsForm";

interface ZoomNotetakerSettingsProps {
  currentUser: CurrentUserFragment;
}

const ZoomNotetakerSettings: React.FC<ZoomNotetakerSettingsProps> = ({
  currentUser,
}) => {
  const toast = useToast();
  const zoomBotAuthEnabled = useFeatureFlag("recall:zoom-bot-auth");

  const [updateZoomNotetakerSettings, { loading, error }] =
    useUpdateOrganizationZoomNotetakerMutation({
      onCompleted: (data) => {
        if (data?.updateOrganizationZoomNotetaker?.organization) {
          toast({
            status: "success",
            title: "Zoom Notetaker Settings",
            description: "Saved!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Zoom Notetaker Settings",
          description:
            error?.message ?? "There was a problem - please try again",
        });
      },
    });

  const { organization } = currentUser;

  if (!currentUser.userRole?.canManageIntegrationSettings) {
    return (
      <Box w="350px">
        <Heading as="h1" size="md" pb={6}>
          Access Denied
        </Heading>
      </Box>
    );
  }

  return (
    <Box w="500px">
      <Heading as="h2" size="md" mb="10">
        Zoom Notetaker Settings
      </Heading>
      <ZoomNotetakerSettingsForm
        zoomNotetakerEnabled={organization.zoomNotetakerEnabled}
        zoomNotetakerDisclaimer={organization.zoomNotetakerDisclaimer}
        zoomNotetakerDisclaimerType={organization.zoomNotetakerDisclaimerType}
        defaultDisclaimer={organization.notetakerRecorderDisclaimerDefault}
        showVirtualOnsiteSetting={organization.showVirtualOnsiteSettingZoom}
        virtualOnsiteEnabled={organization.virtualOnsiteEnabledZoom}
        isLoading={loading}
        error={error}
        onSubmit={(formData) => {
          updateZoomNotetakerSettings({ variables: formData });
        }}
      />
      {zoomBotAuthEnabled && (
        <>
          <Heading as="h2" size="md" my="10">
            Zoom Recording Authorization
          </Heading>
          <ZoomBotRecordingAuth currentUser={currentUser} />
        </>
      )}
    </Box>
  );
};

export default ZoomNotetakerSettings;
