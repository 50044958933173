import { Box, BoxProps, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import React from "react";
import { ImRedo, ImUndo } from "react-icons/im";
import { MdPause, MdPlayArrow } from "react-icons/md";

import { PlaybackRateMenu } from "../../../components";
import { AudioControls } from "../Video/AudioControls";
import { VideoStateControl } from "../Video/useVideoState";
import VideoFullscreenControls from "../Video/VideoFullscreenControls";
import { MediaPlayerInterface } from "./useMediaPlayer";
import VideoTime from "./VideoTime";

export type VideoControlsProps = BoxProps & {
  player: MediaPlayerInterface;
  isVideoVisible: boolean;
  fullScreenControls?: VideoStateControl;
  pipControls?: VideoStateControl;
  hideVideo?(enablePIP?: boolean): void;
};

const playbackRateMenuProps = {
  variant: "blackAlpha",
};

const playbackRateMenuButtonProps = {
  borderRadius: "full",
  background: "transparent",
};

const VideoControls: React.FC<VideoControlsProps> = ({
  player,
  isVideoVisible,
  fullScreenControls,
  pipControls,
  hideVideo,
  ...styles
}) => {
  const {
    muted,
    playbackRate,
    volume,
    playing,
    skipBack,
    skipForward,
    time,
    duration,
    pause,
    play,
    mute,
    setVolume,
    setPlaybackRate,
  } = player;

  return (
    <Flex
      width="100%"
      pt="1"
      alignItems="center"
      justifyContent="space-between"
      lineHeight={1}
      height={12}
      {...styles}
    >
      <Flex alignItems="center">
        {playing ? (
          <Tooltip label="Pause">
            <IconButton
              icon={<MdPause size="24" />}
              isRound
              aria-label="Pause"
              onClick={pause}
              background="transparent"
            />
          </Tooltip>
        ) : (
          <Tooltip label="Play">
            <IconButton
              icon={<MdPlayArrow size="24" />}
              isRound
              aria-label="Play"
              onClick={play}
              background="transparent"
              onFocus={(e) => e.preventDefault()}
            />
          </Tooltip>
        )}
        <PlaybackRateMenu
          playbackRate={playbackRate}
          setPlaybackRate={setPlaybackRate}
          menuProps={playbackRateMenuProps}
          menuButtonProps={playbackRateMenuButtonProps}
        />
        <Tooltip label="Skip Backward">
          <IconButton
            background="transparent"
            aria-label="Skip Backward"
            borderRadius="full"
            size="sm"
            onClick={() => skipBack(15)}
            icon={
              <>
                <ImUndo />
                <Box
                  as="span"
                  color="white"
                  position="absolute"
                  bottom={0.5}
                  left={1.5}
                  fontSize={10}
                  zIndex={9999}
                >
                  15
                </Box>
              </>
            }
          />
        </Tooltip>
        <Tooltip label="Skip Forward">
          <IconButton
            background="transparent"
            aria-label="Skip Forward"
            size="sm"
            borderRadius="full"
            onClick={() => skipForward(15)}
            icon={
              <>
                <ImRedo />
                <Box
                  as="span"
                  color="white"
                  position="absolute"
                  bottom={0.5}
                  right={1.5}
                  fontSize={10}
                  zIndex={9999}
                >
                  15
                </Box>
              </>
            }
          />
        </Tooltip>

        <AudioControls
          setVolume={setVolume}
          mute={mute}
          volume={volume}
          muted={muted}
          isReversed={!isVideoVisible}
        />
        <VideoTime time={time} duration={duration} />
      </Flex>

      {isVideoVisible && (
        <Flex alignItems="center">
          <VideoFullscreenControls
            pipControls={pipControls}
            fullScreenControls={fullScreenControls}
          />
        </Flex>
      )}
    </Flex>
  );
};
VideoControls.displayName = "VideoControls";
export default React.memo(VideoControls);
