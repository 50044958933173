import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  Alert,
  LoadingIndicator,
  StatusWithText,
  useToast,
} from "../../../components";
import config from "../../../config";
import {
  CurrentUserFragment,
  useAuthorizeZoomBotMutation,
} from "../../graphql";
import StyledZoomImg from "./StyleZoomImg";

interface ZoomBotRecordingAuthProps {
  currentUser: CurrentUserFragment;
}

const ZoomBotRecordingAuth: React.FC<ZoomBotRecordingAuthProps> = ({
  currentUser,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get("code");
  const toast = useToast();

  const [authorizeZoomBotMutation, { loading: loadingAuthorize }] =
    useAuthorizeZoomBotMutation({
      onError: () => {
        toast({
          status: "error",
          title: "Authorizing Zoom",
          description: "There was a problem - please try again",
        });
      },
    });

  const { organization } = currentUser;

  useEffect(() => {
    if (currentUser.userRole?.canManageIntegrationSettings) {
      if (authCode) {
        authorizeZoomBotMutation({ variables: { authCode } });
        navigate(location.pathname, { replace: true });
      }
    }
  }, []);

  if (loadingAuthorize) {
    return <LoadingIndicator />;
  }

  if (organization.isZoomBotAuthorized) {
    return <StatusWithText passed label="Recording Authorized" />;
  }

  if (config.zoomBotClientId) {
    return (
      <Box>
        <Alert
          status="info"
          mb={4}
          description="This one-time setup saves coordinators and interviewers time by allowing BrightHire to record interviews without interruption.  Zoom's recording disclaimer will always be shown to participants when joining."
        />
        <a
          href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${config.zoomBotClientId}&redirect_uri=${config.urlPrefix}/settings/zoom-notetaker`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledZoomImg
            src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png"
            alt="Add to ZOOM"
          />
        </a>
      </Box>
    );
  }

  return null;
};

export default ZoomBotRecordingAuth;
