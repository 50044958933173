import { useEffect, useState } from "react";

import {
  AiNotesProcessingStatus,
  useCallAiNotesLazyQuery,
} from "../../../graphql";

export type AiNotesReturn = {
  checkAiNotesStatus: (callId: string) => void;
};

const useAiNotesStatus = (): AiNotesReturn => {
  const [canStopPolling, setCanStopPolling] = useState(false);
  const [query, { data, stopPolling }] = useCallAiNotesLazyQuery();

  const checkAiNotesStatus: (callId: string) => void = (callId) => {
    query({
      variables: { callId },
      pollInterval: 1000,
    });
  };

  // stop polling if task succeeds or fails
  useEffect(() => {
    const aiNotesStatus = data?.callAiNotes?.aiNotesProcessingStatus;
    if (aiNotesStatus) {
      if (
        canStopPolling &&
        [
          AiNotesProcessingStatus.Failed,
          AiNotesProcessingStatus.Completed,
        ].includes(aiNotesStatus)
      ) {
        stopPolling();
      }
      if (aiNotesStatus === AiNotesProcessingStatus.InProgress) {
        setCanStopPolling(true);
      }
    }
  }, [stopPolling, data]);

  return { checkAiNotesStatus };
};

export default useAiNotesStatus;
