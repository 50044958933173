import { Box, Flex, Heading, Icon, Link, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { MdCheckCircle } from "react-icons/md";

import { LoadingIndicator, useToast, ZoomSettings } from "../../../components";
import {
  CurrentUserFragment,
  useUserZoomSettingsLazyQuery,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import StyledZoomImg from "./StyleZoomImg";

interface ZoomSettingsProps {
  currentUser: CurrentUserFragment;
}

const ZoomUserSettings: React.FC<ZoomSettingsProps> = ({ currentUser }) => {
  const toast = useToast();
  const zoomAppEnabled = useFeatureFlag("zoom_app");
  const [getUserZoomSettings, { data, loading }] = useUserZoomSettingsLazyQuery(
    {
      onError: () => {
        toast({
          status: "error",
          title: "Zoom Account Settings",
          description:
            "There was a problem fetching your Zoom account settings",
        });
      },
    }
  );
  const settingsJson = data?.currentUser?.zoomSettings;
  const { organization } = currentUser;

  useEffect(() => {
    if (organization.isZoomAuthorized) {
      getUserZoomSettings();
    }
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Box>
      {zoomAppEnabled && (
        <>
          <Heading as="h2" size="md" mb={2}>
            Zoom App
          </Heading>
          <Text mb={6} fontSize="sm" color="placeholder">
            Use the Interview Assistant in Zoom Meetings.{" "}
            <Link
              href="https://help.brighthire.ai/en/collections/3357021-brighthire-for-zoom-meetings"
              target="_blank"
            >
              {" Learn More"}
            </Link>
          </Text>
          {currentUser.isZoomAppInstalled ? (
            <>
              <Flex alignItems="center">
                <Icon as={MdCheckCircle} color="green.600" />
                <Box ml={2}>Installed</Box>
              </Flex>
              <Text fontSize="sm" color="placeholder">
                To manage the application please visit the
                <Link
                  href="https://marketplace.zoom.us/apps/TUyIp7DkQM29tYD4qg0s9g"
                  target="_blank"
                >
                  {" Zoom Marketplace"}
                </Link>
              </Text>
            </>
          ) : (
            <a
              href="/zoomapp/install"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledZoomImg
                src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png"
                alt="Add to ZOOM"
              />
            </a>
          )}
        </>
      )}
      {currentUser.organization.isZoomAuthorized && (
        <>
          <Heading as="h2" size="md" mb={2} mt={zoomAppEnabled ? 8 : 0}>
            Zoom User Settings
          </Heading>
          {settingsJson ? (
            <>
              <Text mb={8} fontSize="sm" color="placeholder">
                This page shows whether your Zoom settings are configured
                correctly to work with BrightHire.
              </Text>
              <ZoomSettings
                zoomUserType={data?.currentUser?.zoomUserType}
                settingsJson={settingsJson}
              />
            </>
          ) : (
            <Text>
              We couldn&apos;t find a Zoom user that matches your email address.
            </Text>
          )}
        </>
      )}
    </Box>
  );
};

export default ZoomUserSettings;
