import { Alert, AlertDescription, Box, Text } from "@chakra-ui/react";
import React from "react";

import { SearchInput } from "../../../components";
import useOffsetPagination from "../../../hooks/useOffsetPagination";
import HiringTeamList from "../../components/HiringTeamList";
import {
  PositionListItemFragment,
  useOrgHiringTeamsLazyQuery,
} from "../../graphql";
import { useFeatureFlagForCurrentOrImpersonatedUser } from "../../graphql/hooks/useFeatureFlag";

const TeamsPage: React.FC = () => {
  const isSidebarNavEnabled =
    useFeatureFlagForCurrentOrImpersonatedUser("sidebar-nav");
  const [getPositions, { loading, error }] = useOrgHiringTeamsLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: ({ currentUser }) => {
      if (currentUser?.organization?.positions?.results) {
        const positionsList = currentUser?.organization?.positions?.results;
        setListItems(positionsList);
        setNumberOfPages(
          currentUser?.organization?.positions.pageInfo?.totalPages || 0
        );
      }
    },
  });

  const {
    listItems,
    setListItems,
    setNumberOfPages,
    pageOptions,
    searchTerm,
    setSearchTerm,
    didFetchComplete,
  } = useOffsetPagination<PositionListItemFragment>(getPositions, {
    initialSortBy: { id: "createdAt", desc: true },
  });

  if (error)
    return (
      <Alert status="error" title="Error loading teams">
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );

  return (
    <Box w="fit-content" minW="900px">
      {!isSidebarNavEnabled && (
        <Text as="h1" color="gray.900" fontWeight="500" fontSize="xl" mb="8">
          Hiring Teams
        </Text>
      )}
      <Box mb={2}>
        <SearchInput
          onSearch={setSearchTerm}
          defaultValue={searchTerm}
          placeholder="Search positions"
        />
      </Box>
      {didFetchComplete ? (
        <HiringTeamList
          positions={listItems}
          loading={loading}
          pageOptions={pageOptions}
          sortBy={pageOptions.sortBy}
        />
      ) : null}
    </Box>
  );
};

export default TeamsPage;
