import {
  Editable,
  EditablePreview,
  EditableProps,
  EditableTextarea,
  Flex,
} from "@chakra-ui/react";
// import autosize from "@github/textarea-autosize";
// import React, { useEffect, useRef } from "react";
import React from "react";
import ResizeTextarea from "react-textarea-autosize";

export type EditableTextProps = {
  defaultValue?: string | null | undefined;
  placeholder?: string;
  isDisabled?: boolean;
  onSubmit: (newValue: string) => void;
} & Omit<EditableProps, "onSubmit" | "children" | "defaultValue">;

export const EditableArea = React.forwardRef<HTMLDivElement, EditableTextProps>(
  (
    { defaultValue, placeholder = "", isDisabled, onSubmit, value, ...rest },
    ref
  ) => {
    return (
      <Editable
        selectAllOnFocus={false}
        defaultValue={value ?? defaultValue ?? ""}
        value={value}
        placeholder={placeholder}
        isDisabled={isDisabled}
        onSubmit={(newValue: any) => {
          if (typeof newValue === "string") {
            onSubmit(newValue);
          }
        }}
        {...rest}
      >
        {({
          isEditing,
          onEdit,
        }: {
          isEditing: boolean;
          onEdit: () => void;
        }) => {
          const valueIsNotEmpty =
            defaultValue !== placeholder && defaultValue !== "";
          return (
            <Flex ref={ref} h="100%" flexDirection="row" alignItems="center">
              <EditablePreview
                flex="1"
                fontSize="sm"
                overflowX="hidden"
                textOverflow="ellipsis"
                px={3}
                py={2}
                border="gray.200"
                borderRadius="4px"
                color={valueIsNotEmpty ? "gray.900" : "gray.400"}
                whiteSpace="pre-wrap"
              />
              <EditableTextarea
                as={ResizeTextarea}
                fontSize="sm"
                flex="1"
                my="1px"
                ml="1px"
                px={3}
                py={2}
                resize="none"
                color="gray.900"
                overflow="hidden"
              />
            </Flex>
          );
        }}
      </Editable>
    );
  }
);
