import { Box, Checkbox, StyleProps, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Control, Controller, useForm } from "react-hook-form";

import { errorToast, successToast, useToast } from "../../../../components";
import {
  useOrganizationDataRedactionQuery,
  useUpdateOrganizationDataRedactionMutation,
} from "../../../graphql";

type FieldValues = {
  redactEeocFamilyEnabled: boolean;
  redactEeocRaceEnabled: boolean;
  redactEeocSexEnabled: boolean;
  redactEeocHighRiskQuestionsEnabled: boolean;
};

type DataRedactionSettingsFormProps = {
  formId: string;
} & StyleProps;

const ControlledCheckbox: React.FC<{
  name: keyof FieldValues;
  control: Control<FieldValues>;
  children: React.ReactNode;
}> = ({ name, control, children }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, ...field } }) => (
      <Checkbox
        {...field}
        isChecked={value}
        lineHeight="5"
        spacing="3.5"
        variant="blue.600"
        size="lg"
      >
        <Text as="span" fontSize="sm">
          {children}
        </Text>
      </Checkbox>
    )}
  />
);

const DataRedactionSettingsForm: React.FC<DataRedactionSettingsFormProps> = ({
  formId,
  ...styles
}) => {
  const toast = useToast();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<FieldValues>();

  useOrganizationDataRedactionQuery({
    onError: () =>
      errorToast(toast, "There was a problem loading your settings"),
    onCompleted(data) {
      const organization = data?.currentUser?.organization;
      if (organization !== undefined) {
        reset({
          redactEeocFamilyEnabled: organization.redactEeocFamilyEnabled,
          redactEeocRaceEnabled: organization.redactEeocRaceEnabled,
          redactEeocSexEnabled: organization.redactEeocSexEnabled,
          redactEeocHighRiskQuestionsEnabled:
            organization.redactEeocHighRiskQuestionsEnabled,
        });
      }
    },
  });

  const [updateSettings] = useUpdateOrganizationDataRedactionMutation({
    onCompleted: () => successToast(toast, "Settings updated"),
    onError: () =>
      errorToast(toast, "There was a problem updating your settings"),
  });

  const onSubmit = async (values: FieldValues): Promise<void> => {
    await updateSettings({ variables: values });
  };

  return (
    <Box {...styles}>
      <Text mb="7" fontWeight="semibold">
        Data Redaction topics
      </Text>

      <form
        id={formId}
        onSubmit={
          isSubmitting ? (e) => e.preventDefault() : handleSubmit(onSubmit)
        }
      >
        <VStack align="baseline" spacing="7" pl="3.5">
          <ControlledCheckbox name="redactEeocFamilyEnabled" control={control}>
            Family status and pregnancy
          </ControlledCheckbox>

          <ControlledCheckbox name="redactEeocSexEnabled" control={control}>
            Sexual orientation and gender
          </ControlledCheckbox>

          <ControlledCheckbox name="redactEeocRaceEnabled" control={control}>
            Race
          </ControlledCheckbox>

          <ControlledCheckbox
            name="redactEeocHighRiskQuestionsEnabled"
            control={control}
          >
            High-risk questions
          </ControlledCheckbox>
        </VStack>
      </form>
    </Box>
  );
};

export default DataRedactionSettingsForm;
