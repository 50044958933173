import { Box, ButtonGroup, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { MdCheckCircle } from "react-icons/md";

import { RouterLink, SortableTable } from "../../../components";
import theme from "../../../theme";
import { formatRelativeDate } from "../../../utils/datetime";
import {
  Maybe,
  PlanUserRole,
  Position,
  PositionRole,
  User,
  UserFragment,
  UserRole,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useLink from "../../hooks/useLink";
import {
  AddMembershipButton,
  DisableUsersModal,
  PlanUserRoleSelect,
  ResendInvitationButton,
  UserRoleSelect,
} from "..";

type ListUser = {
  userRole?: Maybe<{ __typename?: "UserRole" } & Pick<UserRole, "id" | "name">>;
  planUserRole?: Maybe<
    { __typename?: "PlanUserRole" } & Pick<PlanUserRole, "id" | "name">
  >;
  memberships: Array<
    { __typename?: "Membership" } & {
      role: { __typename?: "PositionRole" } & Pick<
        PositionRole,
        "id" | "name" | "formattedName"
      >;
      position?: Maybe<
        { __typename?: "Position" } & Pick<Position, "id" | "title">
      >;
    }
  >;
} & UserFragment;

interface UserListProps {
  users: ListUser[];
  canDisableUsers?: boolean;
  planSkuEnabled?: boolean;
}

const UserList: React.FC<UserListProps> = ({
  users,
  canDisableUsers = false,
  planSkuEnabled = false,
}) => {
  const planFlagEnabled = useFeatureFlag("plan-poc");

  return (
    <SortableTable<User>
      width="auto"
      renderSelectedRowActions={(rows) => {
        const userIds = rows.map((r: any) => r.original.id);
        return (
          <Flex alignItems="center" justifyContent="center">
            {rows.length > 0 && (
              <Box mr={4}>({rows.length} users selected)</Box>
            )}
            <ButtonGroup my="2">
              <AddMembershipButton userIds={userIds} />
              {canDisableUsers && <DisableUsersModal userIds={userIds} />}
            </ButtonGroup>
          </Flex>
        );
      }}
      columns={[
        {
          Header: "User",
          id: "user",
          accessor: (user: any) => (user.fullName ? user.fullName : user.email),
          Cell: ({
            row: { original: user },
          }: {
            row: { original: ListUser };
          }) => {
            const userLink = useLink({ type: "userInfo", userId: user.id });

            return user.signUpCompletedAt ? (
              <Box>
                <RouterLink to={userLink}>{user.fullName}</RouterLink>
                <Text color="placeholder">{user.email}</Text>
              </Box>
            ) : (
              <Box>
                <RouterLink to={userLink}>{user.email}</RouterLink>
                <Text color="placeholder">Pending signup</Text>
              </Box>
            );
          },
        },
        {
          Header: "Signed up",
          accessor: "signUpCompletedAt",
          sortType: "basic",
          Cell: ({
            row: { original: user },
          }: {
            row: { original: ListUser };
          }) => {
            if (user.signUpCompletedAt) {
              return (
                <MdCheckCircle color={theme.colors.green[400]} size={20} />
              );
            }
            return <ResendInvitationButton user={user} />;
          },
        },
        {
          Header: "Role",
          accessor: (user) => user.userRole?.name,
          id: "userRole.name",
          Cell: ({
            row: { original: user },
          }: {
            row: { original: ListUser };
          }) => (
            <UserRoleSelect
              userId={user.id}
              defaultValue={user?.userRole?.id}
            />
          ),
        },
        {
          Header: "Plan Role",
          accessor: (user) => user.planUserRole?.name,
          id: "planUserRole.name",
          Cell: ({
            row: { original: user },
          }: {
            row: { original: ListUser };
          }) => {
            if (planSkuEnabled) {
              return (
                <PlanUserRoleSelect
                  userId={user.id}
                  defaultValue={user.planUserRole?.id || ""}
                />
              );
            }
            return (
              <Tooltip label="Contact your CSM to learn more about BrightHire Plan">
                <Text whiteSpace="nowrap">Not Enabled</Text>
              </Tooltip>
            );
          },
          show: planFlagEnabled,
        },
        {
          Header: "Teams",
          accessor: (user) => user.memberships.length,
          id: "memberships.length",
          Cell: ({
            row: { original: user },
          }: {
            row: { original: ListUser };
          }) => <UserPermissionsCell user={user} />,
        },
        {
          Header: "Invited By",
          accessor: "invitedBy",
          Cell: ({
            row: { original: user },
          }: {
            row: { original: UserFragment };
          }) => {
            if (user.invitedBy && user.signUpMethod === "INVITATION") {
              return (
                <>{`${user.invitedBy.fullName} <${user.invitedBy.email}>`}</>
              );
            }
            return <>{user.signUpMethodLabel}</>;
          },
        },
        {
          Header: "Date Invited",
          accessor: "createdAt",
          Cell: ({
            row: { original: user },
          }: {
            row: { original: ListUser };
          }) => <>{formatRelativeDate(user.createdAt)}</>,
        },
      ]}
      data={users as User[]}
      initialSort={{ id: "user", desc: false }}
    />
  );
};

interface UserPermissionsCellProps {
  user: ListUser;
}

const UserPermissionsCell: React.FC<UserPermissionsCellProps> = ({ user }) => {
  const roles = Object.entries(
    user.memberships.reduce((prev, next) => {
      if (!next.role.formattedName) return prev;
      const value = prev[next.role.formattedName] ?? 0;
      // eslint-disable-next-line no-param-reassign
      prev[next.role.formattedName] = value + 1;
      return prev;
    }, {} as { [key: string]: number })
  ).sort((a, b) => a[1] - b[1]);
  return (
    <>
      {roles.map((r) => (
        <Text key={r[0]}>
          {r[0]} {`(${r[1]} positions)`}
        </Text>
      ))}
    </>
  );
};

export default UserList;
