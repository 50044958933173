import {
  ApolloError,
  MutationTuple,
  Reference,
  StoreObject,
} from "@apollo/client";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";
import { v4 as uuidv4 } from "uuid";

import { useToast } from "../../../components";
import { getOffsetDateAsISOString } from "../../../utils/datetime";
import useCurrentUser from "../../hooks/useCurrentUser";
import {
  AddNewCallNoteMutation,
  AddNewCallNoteMutationVariables,
  CallNoteFragmentDoc,
  CallNoteType,
  useAddNewCallNoteMutation,
} from "..";

const getSortedNotes = (
  notes: Array<Reference>,
  readField: ReadFieldFunction
): Array<Reference> => {
  return notes.sort((a, b) => {
    const aTime = readField("time", a);
    const bTime = readField("time", b);
    if (aTime != null && bTime != null) {
      if (aTime < bTime) return -1;
      if (aTime > bTime) return 1;
      const aCreatedAt = readField("createdAt", a);
      const bCreatedAt = readField("createdAt", b);
      if (aCreatedAt != null && bCreatedAt != null) {
        return aCreatedAt < bCreatedAt ? -1 : 1;
      }
    }
    return 0;
  });
};

export default function useAddCallNote({
  call,
  onCompleted,
  onError,
  clipId,
}: {
  call: StoreObject;
  onCompleted?: (data: AddNewCallNoteMutation) => void;
  onError?: (error: ApolloError) => void;
  clipId?: string;
}): MutationTuple<AddNewCallNoteMutation, AddNewCallNoteMutationVariables> {
  const toast = useToast();
  const currentUser = useCurrentUser();
  const defaultOnError = (error: ApolloError): void => {
    toast({
      title: "Error adding note.",
      description: error.message,
      status: "error",
    });
  };
  const handleError = onError ?? defaultOnError;
  const [addNote, result] = useAddNewCallNoteMutation({
    update(cache, { data: addCallNoteData }, { variables }) {
      cache.modify({
        id: clipId
          ? cache.identify({
              __typename: "Clip",
              id: clipId,
            })
          : cache.identify(call),
        fields: {
          notes(existingNotes: Array<Reference> = [], { readField }) {
            if (addCallNoteData?.addNewCallNote) {
              const note = cache.writeFragment({
                data: addCallNoteData.addNewCallNote.callNote,
                fragment: CallNoteFragmentDoc,
                fragmentName: "CallNote",
              });
              if (!note) {
                return existingNotes;
              }
              // check if the new note has already been added to the cache
              const found = existingNotes.some(
                (n) => readField("id", n) === readField("id", note)
              );
              if (!found) {
                const notes = [note, ...existingNotes];
                if (readField("type", note) === CallNoteType.Comment) {
                  return notes;
                }
                return getSortedNotes(notes, readField);
              }
            }
            return existingNotes;
          },
          generalNotes(existingNotes: Array<Reference> = [], { readField }) {
            if (addCallNoteData?.addNewCallNote) {
              const note = cache.writeFragment({
                data: addCallNoteData.addNewCallNote.callNote,
                fragment: CallNoteFragmentDoc,
                fragmentName: "CallNote",
              });
              if (!note) {
                return existingNotes;
              }
              const questionId = readField("questionId", note);
              if (questionId) {
                return existingNotes;
              }
              // check if the new note has already been added to the cache
              const found = existingNotes.some(
                (n) => readField("id", n) === readField("id", note)
              );
              if (!found) {
                const notes = [note, ...existingNotes];
                if (readField("type", note) === CallNoteType.Comment) {
                  return notes;
                }
                return getSortedNotes(notes, readField);
              }
            }
            return existingNotes;
          },
        },
      });

      const newNoteRef = cache.writeFragment({
        data: addCallNoteData?.addNewCallNote?.callNote,
        fragment: CallNoteFragmentDoc,
        fragmentName: "CallNote",
      });
      if (newNoteRef && variables?.questionId) {
        cache.modify({
          id: cache.identify({
            __typename: "CallNote",
            id: variables.questionId,
          }),
          fields: {
            questionNotes: (existing: Reference[] = [], { readField }) =>
              getSortedNotes([...existing, newNoteRef], readField),
          },
        });
      }
    },
    onCompleted,
    onError: handleError,
    optimisticResponse: ({
      callId,
      text,
      time,
      type,
      visibility,
      clipId = null,
      scorecardQuestionId = null,
      questionId = null,
      guideItemId = null,
      parentGuideItemId = null,
      description = null,
    }) => ({
      addNewCallNote: {
        __typename: "AddNewCallNote",
        callNote: {
          __typename: "CallNote",
          id: uuidv4(),
          user: {
            __typename: "User",
            id: currentUser.id,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            fullName: currentUser.fullName,
            profilePicUrl: currentUser.profilePicUrl,
          },
          callId,
          text,
          time,
          type,
          visibility,
          clipId,
          scorecardQuestionId,
          questionId,
          guideItemId,
          parentGuideItemId,
          description,
          score: null,
          highlightEndTime: null,
          highlightStartTime: null,
          isEdited: false,
          replies: [],
          speakerTag: null,
          createdAt: getOffsetDateAsISOString(),
        },
      },
    }),
  });

  return [addNote, result];
}
