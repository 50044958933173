import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React, { FC } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";

interface GuideItemLabelProps {
  text: string;
  tooltipText: string;
}

const GuideItemLabel: FC<GuideItemLabelProps> = ({ text, tooltipText }) => (
  <Box display="inline-block" mb={1}>
    <Tooltip label={tooltipText}>
      <Flex direction="row" alignItems="center" color="gray.500">
        <Text color="gray.600" fontSize="sm" fontWeight="400" mr={1}>
          {text}
        </Text>
        <HiOutlineInformationCircle />
      </Flex>
    </Tooltip>
  </Box>
);

export default GuideItemLabel;
