import { Grid, useTheme } from "@chakra-ui/react";
import React from "react";
import { Navigate, Route, Routes, useMatch } from "react-router-dom";

import { Card } from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import useCurrentUser from "../../hooks/useCurrentUser";
import DefaultLayout from "../../layouts/DefaultLayout";
import UsersPageBeta from "../users/UsersPageBeta";
import RestApiSettings from "./APISettings";
import AutoJoinByPositionSettings from "./AutoJoinByPositionSettings";
import BullhornSettings from "./BullhornSettings";
import CalendarSettings from "./CalendarSettings";
import DataRetentionSettings from "./data-retention/DataRetentionSettings";
import DataRedactionSettings from "./DataRedactionSettings";
import ExcludeStageSettings from "./ExcludeStageSettings";
import GoogleMeetSettings from "./GoogleMeetSettings";
import GreenhouseSettings from "./GreenhouseSettings";
import IcimsSettings from "./IcimsSettings";
import LeverSettings from "./LeverSettings";
import MergeSettings from "./MergeSettings";
import MsftTeamsSettings from "./MsftTeamsSettings";
import NotificationsSettings from "./NotificationsSettings";
import OptInLandingPageSettings from "./OptInLandingPageSettings";
import OrganizationSettings from "./OrganizationSettings";
import ScoringSettings from "./ScoringSettings";
import SettingsNav from "./SettingsNav";
import SharedCalendarSettings from "./SharedCalendarSettings";
import SingleSignOnSettings from "./SingleSignOnSettings";
import SlackSettings from "./SlackSettings";
import SmartrecruitersSettings from "./SmartrecruitersSettings";
import TemplateSettings from "./TemplateSettings";
import UserSettings from "./UserSettings";
import ZoomNotetakerSettings from "./ZoomNotetakerSettings";
import ZoomOrgSettings from "./ZoomOrgSettings";
import ZoomUserSettings from "./ZoomUserSettings";

const SettingsPage: React.FC = () => {
  usePageTracker("user_settings");
  const theme = useTheme();
  const { pagePadding } = theme.sizes;
  const currentUser = useCurrentUser();
  const isUsersListPage = !!useMatch("/settings/users/*");

  return (
    <DefaultLayout>
      <Grid
        p={pagePadding}
        gap="5"
        overflowX="auto"
        templateColumns={`280px minmax(auto, ${
          isUsersListPage ? 1600 : 1200
        }px)`}
      >
        <SettingsNav currentUser={currentUser} />
        <Card p="8">
          <Routes>
            <Route
              path="/user"
              element={<UserSettings currentUser={currentUser} />}
            />
            <Route path="/templates" element={<TemplateSettings />} />
            <Route path="/calendar" element={<CalendarSettings />} />
            <Route
              path="/user/zoom"
              element={<ZoomUserSettings currentUser={currentUser} />}
            />
            <Route
              path="/user/notifications"
              element={<NotificationsSettings currentUser={currentUser} />}
            />
            <Route
              path="/organization"
              element={<OrganizationSettings currentUser={currentUser} />}
            />
            <Route path="/users/*" element={<UsersPageBeta />} />
            <Route
              path="/shared-calendars"
              element={<SharedCalendarSettings currentUser={currentUser} />}
            />
            <Route
              path="/auto-join-by-position"
              element={<AutoJoinByPositionSettings currentUser={currentUser} />}
            />
            <Route
              path="/zoom"
              element={<ZoomOrgSettings currentUser={currentUser} />}
            />
            <Route
              path="/zoom-notetaker"
              element={<ZoomNotetakerSettings currentUser={currentUser} />}
            />
            <Route
              path="/bullhorn"
              element={<BullhornSettings currentUser={currentUser} />}
            />
            <Route
              path="/greenhouse"
              element={<GreenhouseSettings currentUser={currentUser} />}
            />
            <Route
              path="/lever"
              element={<LeverSettings currentUser={currentUser} />}
            />
            {currentUser.enabledFeatures.includes(
              "smartrecruiters_enabled"
            ) && (
              <Route
                path="/smartrecruiters"
                element={<SmartrecruitersSettings />}
              />
            )}
            {currentUser.enabledFeatures.includes("icims_enabled") && (
              <Route path="/icims" element={<IcimsSettings />} />
            )}
            {currentUser.enabledFeatures.includes("merge_enabled") && (
              <Route path="/other-ats" element={<MergeSettings />} />
            )}
            <Route
              path="/sso"
              element={<SingleSignOnSettings currentUser={currentUser} />}
            />
            <Route
              path="/rest"
              element={<RestApiSettings currentUser={currentUser} />}
            />
            <Route
              path="/google-meet"
              element={
                <GoogleMeetSettings organization={currentUser.organization} />
              }
            />
            {currentUser.enabledFeatures.includes("msft_teams_settings") && (
              <Route
                path="/msft-teams"
                element={
                  <MsftTeamsSettings organization={currentUser.organization} />
                }
              />
            )}
            <Route path="/exclude-stage" element={<ExcludeStageSettings />} />
            <Route
              path="/slack"
              element={<SlackSettings currentUser={currentUser} />}
            />
            {[
              "/data-retention",
              "/data-retention/candidates",
              "/data-retention/interviews",
            ].map((path) => (
              <Route
                key={path}
                path={path}
                element={<DataRetentionSettings />}
              />
            ))}
            {currentUser.enabledFeatures.includes(
              "data_redaction:settings-page"
            ) && (
              <Route
                path="/data-redaction"
                element={<DataRedactionSettings />}
              />
            )}
            {currentUser.enabledFeatures.includes("opt_in_settings") && (
              <Route path="/opt-in" element={<OptInLandingPageSettings />} />
            )}
            {currentUser.enabledFeatures.includes("scoring:v1") && (
              <Route path="/scoring" element={<ScoringSettings />} />
            )}
            <Route path="*" element={<Navigate to="/not-found" />} />
          </Routes>
        </Card>
      </Grid>
    </DefaultLayout>
  );
};

export default SettingsPage;
