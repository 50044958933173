import { BreakpointStyles } from "../../../theme/breakpoints";
import { InterviewViewSection, VideoDisplayMode } from "./types";
import { MediaPlayerInterface } from "./useMediaPlayer";

/**
 * Get values for template columns, rows, and areas for a the interview grid
 * @param gridContents The sections to show in a given grid area
 * @param useSidebar Whether to force a one-column layout
 */
export const getTemplateValues = (
  gridContents: {
    [k: string]: InterviewViewSection[];
  },
  useSidebar: boolean
): {
  templateColumns: BreakpointStyles | string;
  templateRows: BreakpointStyles | string;
  templateAreas: BreakpointStyles | string;
} => {
  let templateColumns: BreakpointStyles | string = {
    base: "1fr",
    lg: "minmax(600px, auto) minmax(400px, 550px)",
  };
  let templateRows: BreakpointStyles | string = {
    base: "min-content",
    lg: "min-content 1fr",
  };
  let templateAreas: BreakpointStyles | string = {
    base: `${gridContents.header.length ? '"header"' : ""} ${
      gridContents.main.length ? '"main"' : ""
    } ${gridContents.sidebar.length ? '"sidebar"' : ""}`,
    lg: `${gridContents.header.length ? '"header header"' : ""} "main sidebar"`,
  };

  if (!useSidebar) {
    templateColumns = "1fr";
    templateRows = "min-content";
    templateAreas = `${gridContents.header.length ? '"header"' : ""} ${
      gridContents.main.length ? '"main"' : ""
    } ${gridContents.sidebar.length ? '"sidebar"' : ""}`;
  }

  return {
    templateColumns,
    templateRows,
    templateAreas,
  };
};

/**
 * Used for delaying rendering of anything that will rerender once the video
 * element is ready.
 */
export const videoElementComplete = (
  player: MediaPlayerInterface,
  videoDisplayMode: VideoDisplayMode
): boolean => {
  const elementWillNotBeCreated = [
    VideoDisplayMode.PROCESSING,
    VideoDisplayMode.NO_RECORDING,
  ].includes(videoDisplayMode);
  return elementWillNotBeCreated || player.elementReady;
};

export const sidebarTabFilter = (
  { name, area }: InterviewViewSection,
  context: any
): boolean => {
  if (area !== "sidebar") {
    return false;
  }

  switch (name) {
    case "notes":
    case "trackers":
      return true;

    case "transcript":
      return !context.useSidebar;

    case "controls":
      return context.showNotesControls && !context.showQuestionNotes;

    case "clips":
      return context.application === "app" && !context.clipId;

    case "assessment":
      return context.callTraining && context.trainingProgramId;

    default:
      return false;
  }
};
