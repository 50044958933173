import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemProps,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import {
  IoChatbubbleEllipsesSharp,
  IoClipboardSharp,
  IoCogSharp,
  IoHelpBuoySharp,
  IoLogOutOutline,
  IoPeopleSharp,
} from "react-icons/io5";
import {
  MdEmail,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { useSendGAEvent } from "../../utils/googleAnalytics";
import { showIntercom } from "../../utils/intercom";
import { Avatar } from "..";

export interface CurrentUser {
  firstName?: string | null;
  lastName?: string | null;
  profilePicUrl?: string | null;
}

const StyledMenuItem: React.FC<MenuItemProps> = ({ children, ...rest }) => (
  <MenuItem py="5px" fontWeight="inherit" {...rest}>
    {children}
  </MenuItem>
);

interface PageHeaderUserMenuProps {
  currentUser: CurrentUser | null | undefined;
  useReactRouter?: boolean;
  renderSearch?: () => React.ReactNode;
  signOutText?: string;
  onSignOut: () => void;
  order?: number;
}

export const PageHeaderUserMenu: React.FC<PageHeaderUserMenuProps> = ({
  currentUser,
  onSignOut,
  renderSearch,
  useReactRouter = true,
  signOutText = "Sign Out",
  order = 3,
}) => {
  const navigate = useNavigate();
  const sendGAEvent = useSendGAEvent();

  if (!currentUser) {
    return null;
  }

  const nav = (path: string): void => {
    if (useReactRouter) {
      navigate(path);
    } else {
      window.location.href = path;
    }
  };

  return (
    <Box order={order}>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              pl={4}
              py={2}
              flexShrink={0}
              ml={renderSearch ? undefined : "auto"}
              data-testid="pageheader-menu"
            >
              <Flex alignItems="center">
                <Avatar user={currentUser} />
                <Box
                  ml={2}
                  display={{ base: "none", lg: "block" }}
                  data-testid="firstname-display"
                  fontWeight="semibold"
                >
                  {currentUser.firstName}
                </Box>
                <Icon
                  as={isOpen ? MdKeyboardArrowUp : MdKeyboardArrowDown}
                  boxSize="6"
                  ml={1}
                  pt="2px"
                  display={{ base: "none", lg: "block" }}
                />
              </Flex>
            </MenuButton>
            <MenuList fontSize="sm" fontWeight="500" color="gray.900">
              <StyledMenuItem
                icon={<IoClipboardSharp size={16} />}
                onClick={() => nav("/guides")}
                data-testid="pageheader-menu-interview-guides"
              >
                Interview Guides
              </StyledMenuItem>
              <MenuDivider />
              <StyledMenuItem
                icon={<IoCogSharp size={16} />}
                onClick={() => nav("/settings/user")}
              >
                Settings
              </StyledMenuItem>
              <StyledMenuItem
                data-testid="pageheader-menu-users"
                icon={<IoPeopleSharp size={16} />}
                onClick={() => nav("/users")}
              >
                Users
              </StyledMenuItem>
              <MenuDivider />
              <StyledMenuItem
                onClick={() => showIntercom()}
                icon={<IoChatbubbleEllipsesSharp size={16} />}
              >
                Live Help
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  window.location.href = "https://help.brighthire.ai";
                }}
                icon={<IoHelpBuoySharp size={16} />}
              >
                Help
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => nav("/support")}
                icon={<MdEmail size={16} />}
              >
                Contact Us
              </StyledMenuItem>
              <MenuDivider />
              <StyledMenuItem
                icon={<IoLogOutOutline strokeWidth="2" size={16} />}
                onClick={() => {
                  sendGAEvent("sign_out", "auth");
                  onSignOut();
                }}
              >
                {signOutText}
              </StyledMenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};
