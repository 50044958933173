import { QueryLazyOptions } from "@apollo/client/react/types/types";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SortingRule } from "react-table";

import { Maybe, OffsetPaginationDetails } from "../main/graphql";
import useDebounce from "./useDebounce";

export type PageOptions = {
  currentPage: number;
  totalPages: number;
  handlePageNumberChange(p: number): void;
  sortBy: SortingRule<string>;
  onChangeSort(s: SortingRule<string>[]): void;
};

type OffsetPaginationReturn<T> = {
  listItems: T[];
  setListItems(items: T[]): void;
  setNumberOfPages(n: number): void;
  pageOptions: PageOptions;
  setSearchTerm(q: string): void;
  searchTerm: string;
  didFetchComplete: boolean;
};

function useOffsetPagination<T>(
  getData: (
    options?: QueryLazyOptions<{ pagination?: Maybe<OffsetPaginationDetails> }>
  ) => any,
  options: {
    initialSortBy: SortingRule<string>;
    ignoreURLParams?: boolean;
    pageLimit?: number;
  }
): OffsetPaginationReturn<T> {
  const { initialSortBy, ignoreURLParams, pageLimit } = {
    ignoreURLParams: false,
    pageLimit: 40,
    ...options,
  };

  const [listItems, setListItems] = useState<T[]>([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [didFetchComplete, setDidFetchComplete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState<SortingRule<string>>(initialSortBy);
  const [searchParams, setSearchParams] = useSearchParams();
  const debouncedSearch = useDebounce(searchTerm, 350);

  useEffect(() => {
    if (!ignoreURLParams) {
      const page = searchParams.get("page");
      const sortBy = searchParams.get("sortBy");
      const desc = searchParams.get("desc");
      const searchTerm = searchParams.get("searchTerm");
      if (page) {
        setCurrentPage(Number(page));
      }
      if (sortBy) {
        setSortBy({
          id: sortBy,
          desc: desc !== "false",
        });
      }
      if (searchTerm) {
        setSearchTerm(searchTerm);
      }
    }
  }, [ignoreURLParams]);

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearch]);

  useEffect(() => {
    if (!ignoreURLParams) {
      const firstNavigation = !searchParams.has("page");
      searchParams.set("page", currentPage.toString());
      searchParams.set("sortBy", sortBy.id);
      searchParams.set("searchTerm", searchTerm);
      searchParams.set("desc", sortBy.desc ? "true" : "false");
      setSearchParams(searchParams, { replace: firstNavigation });
    }
  }, [currentPage, sortBy, debouncedSearch, ignoreURLParams]);

  useEffect(() => {
    const sortedBy = sortBy.desc ? `${sortBy.id} desc` : `${sortBy.id} asc`;
    const limit = pageLimit || 40;
    getData({
      variables: {
        pagination: {
          limit,
          page: currentPage,
          orderBy: sortedBy,
          searchTerm,
        },
      },
    });
  }, [sortBy, currentPage, debouncedSearch, pageLimit]);

  const onChangeSort = (sortBy: SortingRule<string>[]): void => {
    if (sortBy.length > 0) {
      setSortBy(sortBy[0]);
    }
  };

  const handlePageNumberChange = (pageNumber: number): void => {
    if (pageNumber) {
      setCurrentPage(pageNumber);
    }
  };

  const setListItemsAndScrollToTop = (listItems: T[]): void => {
    setDidFetchComplete(true);
    setListItems(listItems);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const pageOptions = {
    currentPage,
    totalPages: numberOfPages,
    handlePageNumberChange,
    onChangeSort,
    sortBy,
  };
  return {
    listItems,
    setListItems: setListItemsAndScrollToTop,
    setNumberOfPages,
    pageOptions,
    setSearchTerm,
    searchTerm,
    didFetchComplete,
  };
}

export default useOffsetPagination;
