import { Box, Heading } from "@chakra-ui/react";
import React from "react";

import { useToast } from "../../../components";
import {
  OrganizationFragment,
  useUpdateOrganizationGoogleMeetMutation,
} from "../../graphql";
import GoogleMeetSettingsForm from "./GoogleMeetSettingsForm";

interface GoogleMeetSettingsProps {
  organization: OrganizationFragment;
}

const GoogleMeetSettings: React.FC<GoogleMeetSettingsProps> = ({
  organization,
}) => {
  const toast = useToast();
  const [updateGoogleMeetSettings, { loading, error }] =
    useUpdateOrganizationGoogleMeetMutation({
      onCompleted: (data) => {
        if (data?.updateOrganizationGoogleMeet?.organization) {
          toast({
            status: "success",
            title: "Google Meet Settings",
            description: "Saved!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Google Meet Settings",
          description:
            error?.message ?? "There was a problem - please try again",
        });
      },
    });

  return (
    <Box w="500px">
      <Heading as="h2" size="md" mb="10">
        Google Meet Settings
      </Heading>
      <GoogleMeetSettingsForm
        googleMeetEnabled={organization.googleMeetEnabled}
        googleMeetRecordingDisclaimer={
          organization.googleMeetRecordingDisclaimer
        }
        googleMeetRecordingDisclaimerType={
          organization.googleMeetRecordingDisclaimerType
        }
        defaultDisclaimer={organization.notetakerRecorderDisclaimerDefault}
        showVirtualOnsiteSetting={
          organization.showVirtualOnsiteSettingGoogleMeet
        }
        virtualOnsiteEnabled={organization.virtualOnsiteEnabledGoogleMeet}
        isLoading={loading}
        error={error}
        onSubmit={(formData) =>
          updateGoogleMeetSettings({ variables: formData })
        }
      />
    </Box>
  );
};

export default GoogleMeetSettings;
