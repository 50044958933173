import { Icon } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";

const BHPlaylistIcon: IconType = () => (
  <Icon
    stroke="currentColor"
    fill="transparent"
    strokeWidth="1"
    viewBox="0 0 24 24"
    boxSize="20px"
  >
    <rect
      x="3.75"
      y="10.25"
      width="16.5"
      height="11"
      rx="1.25"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="transparent"
    />
    <path
      d="M5 7.75C5 7.19772 5.44772 6.75 6 6.75H18C18.5523 6.75 19 7.19772 19 7.75V8.5H5V7.75Z"
      fill="currentColor"
      stroke="none"
    />
    <path
      d="M6.5 5C6.5 4.44772 6.94772 4 7.5 4H16.5C17.0523 4 17.5 4.44772 17.5 5V5.75H6.5V5Z"
      fill="currentColor"
      stroke="none"
    />
    <path
      d="M14.3843 15.3353C14.6786 15.5335 14.6786 15.9665 14.3843 16.1647L11.2793 18.2562C10.9472 18.4799 10.5 18.2419 10.5 17.8415L10.5 13.6585C10.5 13.2581 10.9472 13.0201 11.2793 13.2438L14.3843 15.3353Z"
      fill="currentColor"
      stroke="none"
    />
  </Icon>
);

export default BHPlaylistIcon;
