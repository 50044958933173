import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";

import { ExpandableText, LoadingIndicator } from "../../../components";
import Avatar from "../../../components/Avatar";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { formatDate, formatDuration } from "../../../utils/datetime";
import useCurrentUser from "../../hooks/useCurrentUser";
import DefaultLayout from "../../layouts/DefaultLayout";
import MainContent from "./MainContent";
import { TrainingProgram } from "./types";
import { EmptyName } from "./utils";
import ViewTrainingListItem from "./ViewTrainingListItem";
import ViewTrainingSidebar, {
  ViewTrainingProgramSidebarItem,
} from "./ViewTrainingSidebar";

type ViewTrainingPageViewProps = {
  inProgress: ViewTrainingProgramSidebarItem[];
  completed: ViewTrainingProgramSidebarItem[];
  currentTrainingProgram?: TrainingProgram | null;
  onClickProgram: (id: string) => void;
};

const ViewTrainingPageView: React.FC<ViewTrainingPageViewProps> = ({
  inProgress,
  completed,
  currentTrainingProgram,
  onClickProgram,
}) => {
  const currentUser = useCurrentUser();
  const currentTrainee = currentTrainingProgram?.trainingProgramTrainees.find(
    (t) => t.user.id === currentUser.id
  );

  if (!currentTrainingProgram || !currentTrainee) {
    return (
      <ViewTrainingLayout
        inProgress={inProgress}
        completed={completed}
        onClickProgram={onClickProgram}
      >
        <LoadingIndicator />
      </ViewTrainingLayout>
    );
  }
  const programName = !currentTrainingProgram?.name
    ? EmptyName
    : currentTrainingProgram.name;

  const traineeStartedAt =
    currentTrainingProgram.launchedAt &&
    currentTrainee.createdAt < currentTrainingProgram.launchedAt
      ? currentTrainingProgram.launchedAt
      : currentTrainee.createdAt;

  return (
    <ViewTrainingLayout
      inProgress={inProgress}
      completed={completed}
      onClickProgram={onClickProgram}
      activeProgramId={currentTrainingProgram.id}
    >
      <Box width="100%" maxWidth="1012px" ml="auto" mr="auto">
        <Text ml="10" fontSize="20" fontWeight="medium" maxW="650px">
          {programName}
        </Text>
        <Box
          ml="10"
          mr="10"
          mt="2"
          fontSize="sm"
          maxW="650px"
          whiteSpace="pre-wrap"
        >
          {currentTrainingProgram?.description && (
            <ExpandableText
              title={currentTrainingProgram.description}
              maskBg="white"
            />
          )}
        </Box>
        <Flex alignItems="center" ml="10" mt="8">
          <Text color="gray.600" fontSize="xs">
            {formatDate(currentTrainingProgram.launchedAt, {
              day: "numeric",
              month: "short",
            })}{" "}
            by
          </Text>
          <Avatar
            mx="5px"
            height="5"
            width="5"
            user={currentTrainingProgram.owner}
            borderRadius="100px"
          />
          <Text color="gray.600" fontSize="xs">
            {currentTrainingProgram.owner.fullName ||
              currentTrainingProgram.owner.email}
          </Text>
        </Flex>
      </Box>
      <Divider mt="3" />
      <Box width="100%" maxWidth="1012px" ml="auto" mr="auto">
        <Text ml="10" mt="5" mb="5" fontSize="sm" color="gray.600" maxW="650px">
          {currentTrainingProgram.trainingProgramItems.length} Video
          {currentTrainingProgram.trainingProgramItems.length === 1
            ? ""
            : "s"}{" "}
          - Total Length:{" "}
          {!!currentTrainingProgram.totalDuration &&
            formatDuration(currentTrainingProgram.totalDuration)}
        </Text>
      </Box>
      <Divider mt="3" />
      <Box width="100%" maxWidth="1012px" ml="auto" mr="auto">
        {currentTrainingProgram.trainingProgramItems.map((trainingItem, i) => (
          <ViewTrainingListItem
            key={trainingItem.id}
            trainingItem={trainingItem}
            assessmentEnabled={currentTrainingProgram.assessmentEnabled}
            listLength={currentTrainingProgram.trainingProgramItems.length}
            listPosition={i}
            addedAfterTraineeStart={trainingItem.createdAt > traineeStartedAt}
          />
        ))}
      </Box>
    </ViewTrainingLayout>
  );
};

type ViewTrainingLayoutProps = {
  activeProgramId?: string;
  children: React.ReactNode;
  completed: ViewTrainingProgramSidebarItem[];
  inProgress: ViewTrainingProgramSidebarItem[];
  onClickProgram: (id: string) => void;
};

const ViewTrainingLayout: React.FC<ViewTrainingLayoutProps> = ({
  inProgress,
  completed,
  onClickProgram,
  activeProgramId,
  children,
}) => {
  const { layoutHeight } = useWindowDimensions();
  return (
    <DefaultLayout>
      <Flex height={layoutHeight} overflowX="auto">
        <ViewTrainingSidebar
          inProgress={inProgress}
          completed={completed}
          onClickProgram={onClickProgram}
          activeProgramId={activeProgramId}
        />
        <MainContent minW="650px">{children}</MainContent>
      </Flex>
    </DefaultLayout>
  );
};

export default ViewTrainingPageView;
