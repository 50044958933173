import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LoadingIndicator, useToast } from "../../../components";
import {
  useCreateSmartrecruitersOauthRequestUrlMutation,
  useDeauthorizeSmartrecruitersMutation,
  useSmartrecruitersAuthOnRedirectMutation,
  useSmartrecruitersSetupQuery,
} from "../../graphql";

const SmartrecruitersSettings: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const oauthCode = searchParams.get("code");
  const oauthState = searchParams.get("state");
  const oauthError = searchParams.get("error");
  const oauthErrorDescription = searchParams.get("error_description");

  const { data, loading } = useSmartrecruitersSetupQuery({
    onError: () => {
      toast({
        status: "error",
        title: "Error",
        description:
          "Unable to check SmartRecruiters integration status: please contact support",
      });
    },
  });
  const [createOauthUrl, { loading: createOauthUrlLoading }] =
    useCreateSmartrecruitersOauthRequestUrlMutation();
  const [authOnRedirect, { loading: authOnRedirectLoading }] =
    useSmartrecruitersAuthOnRedirectMutation();

  const [deauthorize, { loading: deauthorizeLoading }] =
    useDeauthorizeSmartrecruitersMutation();

  const uninstall = (): void => {
    deauthorize({
      onCompleted: (data) => {
        navigate(0);
      },
      onError: () => {
        toast({
          status: "error",
          title: "Error",
          description:
            "Error uninstalling SmartRecruiters: please contact support",
        });
      },
    });
  };

  const install = (): void => {
    createOauthUrl({
      onCompleted: (data) => {
        if (!data?.createSmartrecruitersOauthRequestUrl?.oauthUrl) return;
        window.location.href =
          data?.createSmartrecruitersOauthRequestUrl?.oauthUrl;
      },
      onError: () => {
        toast({
          status: "error",
          title: "Error",
          description:
            "Error setting up SmartRecruiters: please contact support",
        });
      },
    });
  };

  useEffect(() => {
    if (!(oauthCode && oauthState)) return;

    authOnRedirect({
      variables: {
        code: oauthCode,
        state: oauthState,
        error: oauthError,
        errorDescription: oauthErrorDescription,
      },
      onCompleted: () => {
        setSearchParams({}, { replace: true });
      },
      onError: () => {
        setSearchParams({}, { replace: true });
        toast({
          status: "error",
          title: "Error",
          description:
            "Error installing SmartRecruiters: please contact support",
        });
      },
    });
  }, [oauthCode, oauthError, oauthErrorDescription, oauthState]);

  const isLoading = loading;

  if (authOnRedirectLoading)
    return (
      <Box w="500px">
        <Heading as="h1" size="md">
          SmartRecruiters
        </Heading>
        <Flex dir="column" my={8}>
          <LoadingIndicator />
        </Flex>
      </Box>
    );

  return (
    <Box w="500px">
      <Heading as="h1" size="md">
        SmartRecruiters
      </Heading>
      <Flex dir="column" my={8}>
        <Text fontWeight="bold" mr={2}>
          Integration Status:{" "}
        </Text>
        {(isLoading && <Spinner />) ||
          (data?.smartrecruitersSetup?.valid && (
            <Icon
              mt="2px"
              as={IoMdCheckmarkCircleOutline}
              color="green.400"
              height="5"
              width="5"
            />
          )) || (
            <Icon
              mt="2px"
              as={IoMdCloseCircleOutline}
              color="red.600"
              height="5"
              width="5"
            />
          )}
      </Flex>
      {data?.smartrecruitersSetup?.valid ? (
        <Button isLoading={deauthorizeLoading || isLoading} onClick={uninstall}>
          Uninstall
        </Button>
      ) : (
        <Button
          isLoading={createOauthUrlLoading || isLoading}
          onClick={install}
        >
          Install
        </Button>
      )}
    </Box>
  );
};

export default SmartrecruitersSettings;
