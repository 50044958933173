import { Conditional } from "./types";

export function isString(s: any): s is string {
  return typeof s === "string";
}

export const formatUSPhoneNumber = (
  phoneNumber: string,
  extension?: string | null
): string => {
  let phoneDigits = phoneNumber.replace(/[\D]/g, "");
  if (phoneDigits.length === 10) {
    phoneDigits = `1${phoneDigits}`;
  }
  if (phoneDigits.length !== 11 || !phoneDigits.startsWith("1")) {
    throw new Error(`Invalid phone number format: ${phoneNumber}`);
  }
  const formattedNumber = `(${phoneDigits.substring(
    1,
    4
  )}) ${phoneDigits.substring(4, 7)}-${phoneDigits.substring(7)}`;
  if (!extension) {
    return formattedNumber;
  }
  return `${formattedNumber} ext. ${extension}`;
};

export const formatKeywordList = (
  keywordList: string[],
  conditional?: Conditional
): string => {
  let joinStr = "and";
  if (conditional === Conditional.OR) {
    joinStr = "or";
  }
  return keywordList.reduce((previous, current, idx) => {
    if (idx === 0) {
      return `"${current}"`;
    }
    if (idx === keywordList.length - 1 && keywordList.length > 2) {
      return `${previous}, ${joinStr} "${current}"`;
    }
    if (keywordList.length === 2) {
      return `${previous} ${joinStr} "${current}"`;
    }
    return `${previous}, "${current}"`;
  }, "");
};

export const formatUserRolePermissions = (
  permissions?: readonly string[]
): string => {
  let formatted = permissions
    ?.map((p) => {
      if (p === "MANAGE_HIRING_TEAM") {
        return "manage hiring teams";
      }
      return `${p.toLowerCase().replace(/_/g, " ")}`;
    })
    .join(", ");
  if (permissions?.length || 0 > 1) {
    formatted += ",";
  }

  if (formatted) {
    return `Can ${formatted} and access their own interviews`;
  }

  return `Can access their own interviews`;
};

export const formatPositionRolePermissions = (
  permissions?: readonly string[]
): string => {
  let formatted = permissions
    ?.map((p) => {
      return `${p?.toLowerCase().replace(/_/g, " ")}`;
    })
    .join(", ");
  if (permissions?.length || 0 > 1) {
    formatted += ",";
  }

  if (formatted) {
    return `Can ${formatted} and access their own interviews`;
  }

  return `Can access their own interviews`;
};

export const formatPlanUserRolePermissions = (
  permissions?: readonly string[]
): string => {
  let formatted = permissions
    ?.map((p) => {
      return `${p.toLowerCase().replace(/_/g, " ")}`;
    })
    .join(", ");
  if (permissions?.length || 0 > 1) {
    formatted += ",";
  }

  if (formatted) {
    return `Can ${formatted}`;
  }

  return `Cannot access BrightHire Plan`;
};

export const snakeCaseToTitleCase = (string: string): string => {
  return string
    .split("_")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    })
    .join(" ");
};

/**
 * Convert a string to Title Case
 */
export const stringToTitleCase = (str: string): string => {
  return str
    .replace(/[-_/]/g, " ")
    .replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
    .trim();
};

/**
 * Convert a string to Sentence case
 */
export const sentenceCase = (s: string): string =>
  s.replace(/^([a-z])/i, (c) => c.toUpperCase());

export const splitName = (name: string): [string, string] => {
  const splitString = name.split(/\s+/);
  let firstName;
  let lastName = "";
  if (splitString.length < 3) {
    [firstName, lastName] = splitString;
    if (lastName === undefined) {
      lastName = "";
    }
  } else {
    firstName = splitString.slice(0, splitString.length - 1).join(" ");
    lastName = splitString[splitString.length - 1];
  }
  return [firstName, lastName];
};

export const escapeRegExp = (text: string): string => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

/**
 * Uppercases the first letter and lowercases the rest.
 */
export const uppercaseFirstLetterLowercaseRest = (text: string): string => {
  const lower = text.toLowerCase();
  return `${lower[0].toUpperCase()}${lower.slice(1)}`;
};
