import { Box } from "@chakra-ui/react";
import React from "react";

import { useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { CurrentUserFragment } from "../../graphql";
import useUpdateCurrentUser from "../../graphql/hooks/useUpdateCurrentUser";
import UserSettingsForm, { FormData } from "./UserSettingsForm";

interface UserSettingsProps {
  currentUser: CurrentUserFragment;
}

const UserSettings: React.FC<UserSettingsProps> = ({ currentUser }) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const [updateCurrentUser, { loading: updateLoading, error }] =
    useUpdateCurrentUser({
      onCompleted: (user) => {
        if (user) {
          sendGAEvent("updated", "user_settings");
          toast({
            title: "Settings saved",
            status: "success",
          });
        }
      },
      onError: () => {
        toast({
          title: "Error updating user",
          status: "error",
        });
      },
    });

  const onSubmit = (formData: FormData): void => {
    updateCurrentUser({ variables: formData });
  };

  return (
    <Box w="500px">
      <UserSettingsForm
        firstName={currentUser.firstName ?? ""}
        lastName={currentUser.lastName ?? ""}
        phoneNumber={currentUser.phoneNumber ?? ""}
        phoneNumberExtension={currentUser.phoneNumberExtension ?? ""}
        maskPersonalPhoneNumber={currentUser.maskPersonalPhoneNumber ?? ""}
        timezoneName={currentUser.timezoneName}
        emailAliases={currentUser.emailAliases}
        persona={currentUser.persona}
        error={error}
        isLoading={updateLoading}
        onSubmit={onSubmit}
      />
    </Box>
  );
};

export default UserSettings;
