import { Box, Flex, Link } from "@chakra-ui/react";
import React from "react";
import { MdExitToApp } from "react-icons/md";

import { useTheme } from "..";
import { PageHeaderNavMenu } from "./PageHeaderNavMenu";
import { CurrentUser, PageHeaderUserMenu } from "./PageHeaderUserMenu";
import { NavLinkItem } from "./types";

interface PageHeaderBaseProps {
  currentUser: CurrentUser | null | undefined;
  titleSuffix?: string;
  rootPath: string;
  navLinks?: NavLinkItem[];
  renderSearch?: () => React.ReactNode;
  useReactRouter?: boolean;
  onSignOut: () => void;
  signOutText?: string;
  backgroundColor?: string;
  showSignOutDuringSignup?: boolean;
}

/**
 * Page header layout for use in the web app (both main & admin portals)
 */
const PageHeaderBase: React.FC<PageHeaderBaseProps> = ({
  currentUser,
  titleSuffix,
  rootPath,
  navLinks,
  renderSearch,
  useReactRouter = true,
  onSignOut,
  signOutText = "Sign Out",
  backgroundColor = "white",
  showSignOutDuringSignup = false,
}) => {
  const theme = useTheme();
  const { pagePadding } = theme.sizes;

  return (
    <Flex
      as="header"
      height="12"
      backgroundColor={backgroundColor}
      width="100%"
      borderColor="border"
      borderBottomWidth="1px"
      pl={{
        base: currentUser ? "0" : "pagePaddingMobile",
        md: currentUser ? "0" : "pagePadding",
        lg: "pagePadding",
      }}
      pr={pagePadding}
      alignItems="center"
      justifyContent="space-between"
      fontSize="md"
      id="header"
      position="relative"
    >
      <Link href={rootPath} style={{ flex: "none" }} order={{ base: 2, lg: 1 }}>
        <Flex pr={{ base: 0, lg: 4 }} h="12" alignItems="center">
          <img src="/static/images/logo_wordmark_blue.svg" width="130" />
          {titleSuffix && (
            <Box fontSize="sm" ml="2" color="red.600">
              {titleSuffix}
            </Box>
          )}
        </Flex>
      </Link>
      {currentUser ? (
        <PageHeaderNavMenu navLinks={navLinks} renderSearch={renderSearch} />
      ) : (
        <Box width={6} height={6} order={{ base: 1, lg: 2 }} />
      )}
      {currentUser && (
        <PageHeaderUserMenu
          currentUser={currentUser}
          onSignOut={onSignOut}
          renderSearch={renderSearch}
          useReactRouter={useReactRouter}
          signOutText={signOutText}
          order={3}
        />
      )}
      {!currentUser && showSignOutDuringSignup && (
        <Box
          width="90px"
          height={6}
          order={3}
          onClick={() => {
            onSignOut();
          }}
          flexDir="row"
          display="flex"
          cursor="pointer"
        >
          <Box mt="4px" mr="4px">
            <MdExitToApp />
          </Box>{" "}
          Sign Out
        </Box>
      )}
      {!currentUser && !showSignOutDuringSignup && (
        <Box width={6} height={6} order={3} />
      )}
    </Flex>
  );
};

export default PageHeaderBase;
