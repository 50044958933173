import { ChakraProvider, useTheme as useThemeChakra } from "@chakra-ui/react";
import React from "react";

import theme, { Theme } from "../../theme";

interface ThemedChakraProviderProps {
  children: React.ReactNode;
}

export const ThemedChakraProvider: React.FC<ThemedChakraProviderProps> = ({
  children,
}) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};

export const StorybookThemedChakraProvider: React.FC<
  ThemedChakraProviderProps
> = ({ children }) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};

export const useTheme = (): Theme => {
  return useThemeChakra<Theme>();
};
