import {
  Box,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import {} from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";

import { LoadingIndicator } from "../../../../components";
import { useOrganizationDataRetentionQuery } from "../../../graphql";
import DataRetentionCandidatesPage from "./DataRetentionCandidatesPage";
import DataRetentionInputsPage from "./DataRetentionInputsPage";
import DataRetentionInterviewsPage from "./DataRetentionInterviewsPage";

const DataRetentionSettings: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, loading } = useOrganizationDataRetentionQuery();
  const path: { [id: string]: number } = {};
  path["/settings/data-retention"] = 0;
  path["/settings/data-retention/candidates"] = 1;
  path["/settings/data-retention/interviews"] = 2;

  const handleChangeTab = (index: number): void => {
    if (index === 0) {
      navigate({ pathname: "/settings/data-retention" }, { replace: true });
    } else if (index === 1) {
      navigate(
        { pathname: "/settings/data-retention/candidates" },
        { replace: true }
      );
    } else {
      navigate(
        { pathname: "/settings/data-retention/interviews" },
        { replace: true }
      );
    }
  };

  if (loading || !data?.currentUser?.organization) return <LoadingIndicator />;
  const organization = data?.currentUser?.organization;

  return (
    <>
      <Heading as="h3" size="lg">
        Data Retention
      </Heading>
      <br />
      <Box w="100%" p={1}>
        <Tabs index={path[location.pathname]} onChange={handleChangeTab} isLazy>
          <Flex flexDirection="column">
            <TabList mx={0} w="100%">
              <Tab>Settings</Tab>
              <Tab>Candidates</Tab>
              <Tab>Interviews</Tab>
            </TabList>
            <TabPanels
              height="100%"
              display="flex"
              flexFlow="column nowrap"
              flex={1}
            >
              <TabPanel
                display="flex"
                flexFlow="column nowrap"
                py={0}
                order={0}
                height="100%"
                flex={1}
                key="interview-sidebar"
                justifyContent="space-between"
              >
                <DataRetentionInputsPage organization={organization} />
              </TabPanel>
              <TabPanel>
                <DataRetentionCandidatesPage organization={organization} />
              </TabPanel>
              <TabPanel>
                <DataRetentionInterviewsPage organization={organization} />
              </TabPanel>
            </TabPanels>
          </Flex>
        </Tabs>
      </Box>
    </>
  );
};

export default DataRetentionSettings;
