import React from "react";
import { createRoot } from "react-dom/client";

import { initDatadog } from "../utils/datadog";
// import { initElastic } from "../utils/elastic";
import { initIntercom } from "../utils/intercom";
import { initLogrocket } from "../utils/logrocket";
import { registerServiceWorker } from "../utils/registerServiceWorker";
import { initSentry } from "../utils/sentry";
import Main from "./Main";

// initElastic();
initDatadog();
initSentry();
initLogrocket();
initIntercom();
registerServiceWorker();

const container = document.getElementById("root") as Element;

const root = createRoot(container);

root.render(<Main />);
