import { Box, Flex, Heading, Link } from "@chakra-ui/react";
import React from "react";

import { CenteredMobileLayout } from "../../../components/CenteredMobileLayout";
import { PageHeader } from "../../components";

const SupportPageLegacy: React.FC = () => (
  <CenteredMobileLayout pageHeader={<PageHeader />}>
    <Flex
      p={6}
      flexDirection="column"
      alignItems="center"
      width={["100%", "500px"]}
    >
      <Heading as="h1" mb={8} fontSize="32px" fontWeight="normal">
        Contact Us
      </Heading>
      <Box as="p" mb={4}>
        We built BrightHire to help you hire better, faster and more
        inclusively. If you need any help at all just reach out. We’re available
        8am - 6pm ET and will get back to you as soon as possible.
      </Box>
      <Box as="p">
        You can get in touch by{" "}
        <Link href="mailto:support@brighthire.ai">emailing us</Link> or sending
        us a message using the chat icon in the lower right of the app. You can
        also check out our{" "}
        <Link href="http://help.brighthire.ai">Help Center</Link> for articles
        and how-to’s.
      </Box>
    </Flex>
  </CenteredMobileLayout>
);

export default SupportPageLegacy;
