import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { useToast } from "../../../components";
import {
  useCreateExcludeStageMutation,
  useDeleteExcludeStageMutation,
  useExcludeStagesQuery,
} from "../../graphql";

const ExcludeStageSettings: React.FC = () => {
  const toast = useToast();

  const [excludeText, setExcludeText] = useState<string>("");

  const { data: excludeStages, refetch } = useExcludeStagesQuery();
  const [createExcludeStage, { loading: createLoading }] =
    useCreateExcludeStageMutation({
      onCompleted: () => {
        toast({
          title: "Success",
          description: "Updating previously scheduled interviews",
          status: "success",
        });
        refetch();
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
    });
  const [deleteExcludeStage, { loading: deleteLoading }] =
    useDeleteExcludeStageMutation({
      onCompleted: () => {
        toast({
          title: "Success",
          description: "Updating previously scheduled interviews",
          status: "success",
        });
        refetch();
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
    });

  const handleSubmit = (): void => {
    createExcludeStage({
      variables: { excludeText },
    });
    setExcludeText("");
  };

  return (
    <Box maxW={720}>
      <Heading as="h1" size="md" mb="6">
        Exclude Stages from BrightHire
      </Heading>
      <Text mb="4">
        BrightHire will not join any Zoom or Google Meet interviews where the
        stage name contains the text added below. Stage names added below are
        not case sensitive, it will apply across all positions.
      </Text>
      <Text mb="8" textColor="gray.500">
        Note: adding the term &#34;culture final round&#34; below would exclude
        BrightHire from recording interviews where the stage name is
        &#34;Engineering - Culture Final Round&#34;. It would not exclude
        BrightHire from the interview &#34;Product Final Round - Culture&#34;. A
        BrightHire interview scheduled by inviting recorder@brighthire.ai may
        override the exclusion list and record automatically.
      </Text>
      <Box />
      <Box maxW={520}>
        <InputGroup mt="8">
          <Input
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            autoFocus
            fontSize="sm"
            mr="2"
            placeholder="Please enter a stage you want to exclude. Example: culture"
            disabled={createLoading || deleteLoading}
            value={excludeText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setExcludeText(event.currentTarget.value);
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                handleSubmit();
              }
            }}
          />
          <Button
            type="submit"
            px="6"
            onClick={handleSubmit}
            isLoading={createLoading || deleteLoading}
          >
            Submit
          </Button>
        </InputGroup>
        <Flex direction="column" wrap="wrap" mb="4" mt="8">
          {excludeStages?.excludeStages.map((excludeStage) => (
            <Tag
              key={excludeStage.id}
              size="lg"
              mb="3"
              py="4"
              px="6"
              backgroundColor="gray.50"
              borderRadius="lg"
            >
              <TagLabel>
                <Text fontSize="sm" overflowX="hidden" textOverflow="ellipsis">
                  {excludeStage.excludeText}
                </Text>
              </TagLabel>
              <TagCloseButton
                ml="auto"
                onClick={() => {
                  deleteExcludeStage({
                    variables: { id: excludeStage.id },
                  });
                }}
              />
            </Tag>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default ExcludeStageSettings;
