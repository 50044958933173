import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Link,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { useToast } from "../../../components";
import { useUpdateOrganizationScoringMutation } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

const ScoringSettings: React.FC = () => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const {
    scoringEnabled,
    guideOverallScoringEnabledByDefault,
    guideCompetencyScoringEnabledByDefault,
  } = currentUser.organization;
  const [isEnabled, setIsEnabled] = useState(scoringEnabled);
  const [
    isGuideOverallScoringEnabledByDefault,
    setIsGuideOverallScoringEnabledByDefault,
  ] = useState(guideOverallScoringEnabledByDefault);
  const [
    isGuideCompetencyScoringEnabledByDefault,
    setIsGuideCompetencyScoringEnabledByDefault,
  ] = useState(guideCompetencyScoringEnabledByDefault);

  const [updateOrganizationScoring, { loading, error }] =
    useUpdateOrganizationScoringMutation({
      onCompleted: (data) => {
        if (data?.updateOrganizationScoring?.organization) {
          toast({
            status: "success",
            title: "Scoring Settings",
            description: "Saved!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Scoring Settings",
          description:
            error?.message ?? "There was a problem - please try again",
        });
      },
    });

  return (
    <Flex direction="column" alignItems="baseline">
      <Heading as="h3" size="lg" fontWeight="600" color="gray.900">
        Scoring
      </Heading>
      <Text
        mb="9"
        mt="4"
        maxW="xl"
        fontWeight="500"
        fontSize="sm"
        color="gray.800"
      >
        Enabling scoring gives you the option to create scored competencies
        within interview guides. This
        {/* TODO: update article link */}
        <Link href="#" target="_blank">
          {" help article "}
        </Link>
        gives an overview of how scoring works in BrightHire.
      </Text>
      <Flex justifyContent="center" mb={5}>
        <Switch
          alignSelf="center"
          defaultChecked={scoringEnabled}
          onChange={() => setIsEnabled(!isEnabled)}
        />
        <Flex ml={3} fontWeight="400" color="gray.900" fontSize="md">
          Enable Scoring
        </Flex>
      </Flex>
      <Divider />
      <Heading mt="5" mb="3" as="h3" size="sm" fontWeight="600">
        Scoring preferences
      </Heading>
      <VStack align="flex-start">
        <Checkbox
          isChecked={isGuideCompetencyScoringEnabledByDefault}
          disabled={!isEnabled}
          onChange={() =>
            setIsGuideCompetencyScoringEnabledByDefault(
              !isGuideCompetencyScoringEnabledByDefault
            )
          }
        >
          <Text fontWeight="400" fontSize="sm" color="gray.900">
            All new guides enable overall scoring by default
          </Text>
        </Checkbox>
        <Checkbox
          isChecked={isGuideOverallScoringEnabledByDefault}
          disabled={!isEnabled}
          onChange={() =>
            setIsGuideOverallScoringEnabledByDefault(
              !isGuideOverallScoringEnabledByDefault
            )
          }
        >
          <Text fontWeight="400" fontSize="sm" color="gray.900">
            All new competencies are scored by default
          </Text>
        </Checkbox>
      </VStack>
      <Button
        type="submit"
        mt={5}
        fontWeight="500"
        isLoading={loading}
        onClick={() =>
          updateOrganizationScoring({
            variables: {
              isEnabled,
              guideOverallScoringEnabledByDefault:
                isGuideOverallScoringEnabledByDefault,
              guideCompetencyScoringEnabledByDefault:
                isGuideCompetencyScoringEnabledByDefault,
            },
          })
        }
      >
        Save
      </Button>
    </Flex>
  );
};

export default ScoringSettings;
