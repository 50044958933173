import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { ScheduledInterviewListItemFragment } from "../../graphql";
import InterviewListItem from "./InterviewListItem";
import { CandidateCall } from "./types";

type InterviewTabProps = {
  calls: Array<CandidateCall>;
  scheduledInterviews: Array<ScheduledInterviewListItemFragment>;
  currentUserNotesOnly: boolean;
  showHighlightsForInterview(callId: string): void;
};

const InterviewTab: React.FC<InterviewTabProps> = ({
  calls,
  scheduledInterviews,
  currentUserNotesOnly,
  showHighlightsForInterview,
}) => {
  return (
    <Flex flex="1 1 auto" direction="column">
      {scheduledInterviews.length > 0 &&
        scheduledInterviews.map((scheduledInterview, i) => {
          return (
            <Box
              data-intercom="completed-interview"
              key={scheduledInterview.id}
              position="relative"
            >
              <Box
                position="absolute"
                top="calc(var(--chakra-space-12) * -1)"
              />
              <InterviewListItem
                scheduledInterview={scheduledInterview}
                listPosition={i + 1}
                currentUserNotesOnly={currentUserNotesOnly}
              />
            </Box>
          );
        })}
      {calls.length > 0 &&
        calls.map((call, i) => {
          return (
            <Box
              data-intercom="completed-interview"
              key={call.id}
              position="relative"
              m={0}
            >
              <Box
                position="absolute"
                top="calc(var(--chakra-space-12) * -1)"
              />
              <InterviewListItem
                call={call}
                listPosition={i + 1}
                currentUserNotesOnly={currentUserNotesOnly}
                onShowHighlights={() => showHighlightsForInterview(call.id)}
              />
            </Box>
          );
        })}
    </Flex>
  );
};

export default InterviewTab;
