import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  HiDotsVertical,
  HiExternalLink,
  HiOutlineHome,
  HiOutlineMail,
  HiOutlinePencil,
  HiOutlinePhone,
  HiOutlineTrash,
} from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";

import {
  GreenhouseIcon,
  LeverIcon,
  RouterLink,
  SmartrecruitersIcon,
  useToast,
} from "../../../../components";
import BhLinkedIn from "../../../../components/Icons/BhLinkedIn";
import VideoCameraDisabled from "../../../../components/Icons/VideoCameraDisabled";
import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { buildLeverLink } from "../../../../utils/lever";
import { getSmartrecruitersLink } from "../../../../utils/smartRecruiters";
import {
  CandidateFragment,
  CandidateLinkType,
  CandidateRatingBreakdown,
  useUpdateCandidateOptOutMutation,
} from "../../../graphql";
import { useIsExtension } from "../../../hooks/useAppEnvironmentContext";
import useCurrentUser from "../../../hooks/useCurrentUser";
import CandidateHeaderLinks, { LinkInputType } from "./CandidateHeaderLinks";
import CandidatePositionSelect from "./CandidatePositionSelect";
import CandidateStarRating from "./CandidateStarRating";
import DeleteCandidateModal from "./DeleteCandidateModal";
import EditCandidateModal from "./EditCandidateModal";
import ToggleCandidateDeletionModal from "./ToggleCandidateDeletionModal";
import { useCandidatePositionOptions } from "./useCandidatePositionOptions";

type CandidateHeaderProps = {
  candidate: CandidateFragment;
  positionId?: string;
  refetchCandidate: () => void;
};

const CandidateHeader: React.FC<CandidateHeaderProps> = ({
  candidate,
  positionId,
  refetchCandidate,
}) => {
  const navigate = useNavigate();
  const sendGAEvent = useSendGAEvent();
  const location = useLocation();
  const toast = useToast();
  const currentUser = useCurrentUser();
  const isExtension = useIsExtension();

  const isSmallScreen = useIsSmallScreen();
  const isDesktop = !isSmallScreen;

  // The 'Edit Candidate' modal
  const {
    isOpen: isEditCandidateOpen,
    onOpen: onOpenEditCandidate,
    onClose: onCloseEditCandidate,
  } = useDisclosure();

  // The 'Delete Candidate' modal
  const {
    isOpen: isDeleteCandidateOpen,
    onOpen: onOpenDeleteCandidate,
    onClose: onCloseDeleteCandidate,
  } = useDisclosure();

  // The 'Enable/Disable Candidate Deletion' modal
  const {
    isOpen: isToggleCandidateDeletionOpen,
    onOpen: onOpenToggleCandidateDeletion,
    onClose: onCloseToggleCandidateDeletion,
  } = useDisclosure();

  // TODO: pull these various mutations into a standalone hook, something like useCandidateMutations
  const [updateCandidateOptOut] = useUpdateCandidateOptOutMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
    onCompleted(data) {
      const { candidate } = data.updateCandidateOptOut;
      if (candidate) {
        const description = candidate.recordingDisabled
          ? "Future interviews will not be recorded"
          : "Future interviews will be recorded";
        toast({
          title: "Success",
          status: "success",
          description,
        });
      }
    },
  });

  const onPositionChange = (newPositionId: string, replace: boolean): void => {
    navigate(`/candidate/${candidate.id}/position/${newPositionId}`, {
      replace,
    });
  };

  // If the URL ends with /position/none, the user manually selected the "Interviews with no position" option.
  const isNoneCase = location.pathname.endsWith("/none");
  const candidatePositionOptions = useCandidatePositionOptions(candidate);

  useEffect(() => {
    if (isNoneCase) {
      return;
    }
    const validOptions = candidatePositionOptions.filter(
      (opt) => (opt?.count || 0) > 0
    );
    if (!positionId && validOptions.length) {
      onPositionChange(validOptions[0].key, true);
    }
  }, [isNoneCase, candidatePositionOptions, positionId]);

  const canDeleteCandidates = currentUser.userRole?.canDeleteCandidates;
  const smartRecruitersLink = getSmartrecruitersLink(candidate, positionId);
  const atsOptInEnabled = currentUser.organization?.atsOptInEnabled;

  const onEditModalClose = (): void => {
    onCloseEditCandidate();
    refetchCandidate();
  };

  const linkedInLinks = candidate.candidateLinks.filter(
    (link) => link.type === CandidateLinkType.Linkedin
  );
  let atsName = "ATS";
  if (candidate.lastLeverOpportunityId) atsName = "Lever";
  if (candidate.greenhouseId) atsName = "Greenhouse";

  const candidateLinks = (
    <Flex alignItems="center">
      <HStack spacing="1">
        {candidate?.phoneNumbers.length && (
          <CandidateHeaderLinks
            label="Call Candidate"
            linkType="phone_number"
            links={candidate?.phoneNumbers as LinkInputType[]}
            icon={<HiOutlinePhone strokeWidth="1.5px" size={20} />}
            dataTestId="candidate-phone-numbers-popover"
          />
        )}
        {candidate?.emails.length && (
          <CandidateHeaderLinks
            label="Email Candidate"
            linkType="email"
            links={candidate?.emails as LinkInputType[]}
            icon={<HiOutlineMail strokeWidth="1.5px" size={20} />}
            dataTestId="candidate-emails-popover"
          />
        )}
        {!!candidate?.greenhouseLink && (
          <Tooltip label="View candidate in Greenhouse">
            <Link href={candidate?.greenhouseLink} isExternal variant="icon">
              <GreenhouseIcon
                fill="currentColor"
                marginRight="0"
                width={10}
                height={20}
              />
            </Link>
          </Tooltip>
        )}
        {!!candidate.lastLeverOpportunityId && (
          <Tooltip label="View candidate in Lever">
            <Link
              href={buildLeverLink(undefined, candidate.lastLeverOpportunityId)}
              isExternal
              variant="icon"
            >
              <LeverIcon fill="currentColor" marginRight="0" height={14} />
            </Link>
          </Tooltip>
        )}
        {!!smartRecruitersLink && (
          <Tooltip label="View candidate in SmartRecruiters">
            <Link href={smartRecruitersLink} isExternal variant="icon">
              <SmartrecruitersIcon
                fill="currentColor"
                marginRight="0"
                height={16}
              />
            </Link>
          </Tooltip>
        )}
        {!!candidate.customAtsUrl && (
          <Tooltip label="View candidate in your ATS">
            <Link href={candidate.customAtsUrl} isExternal variant="icon">
              {/* TODO: replace with a custom icon/update react-icons */}
              <HiExternalLink fill="currentColor" size={19} />
            </Link>
          </Tooltip>
        )}
        {linkedInLinks.length === 1 && (
          <Tooltip label="View candidate's LinkedIn profile">
            <Link
              href={linkedInLinks[0].link}
              isExternal
              variant="icon"
              aria-label="LinkedIn"
            >
              <BhLinkedIn width={4} height={4} />
            </Link>
          </Tooltip>
        )}
        {linkedInLinks.length > 1 && (
          <CandidateHeaderLinks
            label="View Candidate LinkedIn Profile"
            links={linkedInLinks as LinkInputType[]}
            linkType="linked_in"
            icon={<BhLinkedIn />}
          />
        )}
      </HStack>
      <HStack ml={4} spacing="4">
        {candidate?.recordingDisabled && (
          <Flex
            alignItems="center"
            color="yellow.800"
            backgroundColor="yellow.200"
            px="1.5"
            py="0.5"
            borderRadius="2px"
          >
            <VideoCameraDisabled />
            <Text
              fontWeight="400"
              ml="1.5"
              fontSize="sm"
              data-testid="candidate-recording-disabled-text"
            >
              Recording disabled
            </Text>
          </Flex>
        )}
      </HStack>
    </Flex>
  );

  return (
    <Box>
      <EditCandidateModal
        candidate={candidate}
        isOpen={isEditCandidateOpen}
        onClose={onEditModalClose}
      />
      <DeleteCandidateModal
        candidate={candidate}
        isOpen={isDeleteCandidateOpen}
        onClose={onCloseDeleteCandidate}
      />
      <ToggleCandidateDeletionModal
        candidate={candidate}
        isOpen={isToggleCandidateDeletionOpen}
        onClose={onCloseToggleCandidateDeletion}
      />
      {!isExtension && (
        <Breadcrumb
          fontSize="sm"
          mb="3"
          color="gray.500"
          separator={<Text color="gray.300">/</Text>}
          spacing="2"
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/">
              <HiOutlineHome size={16} />
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} fontWeight="400" to="/candidates">
              Candidates
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink
              as={RouterLink}
              to="#"
              color="gray.900"
              fontWeight="500"
            >
              Candidate details
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      )}
      <Flex justifyContent="space-between" alignItems={["baseline", null]}>
        <Flex alignItems="baseline" px={[2, 0]}>
          <Text
            data-intercom="candidate-name-heading"
            data-testid="candidate-name-heading"
            as="h2"
            fontWeight="600"
            color="gray.800"
            fontSize={{ base: "3xl", lg: "34px" }}
            mr="4"
          >
            {candidate?.fullName}
          </Text>
          {isDesktop && candidateLinks}
        </Flex>
        <HStack spacing="2">
          {isDesktop && (
            <>
              <Tooltip
                label={
                  candidate.canEdit
                    ? "Edit Candidate"
                    : `Candidate info is editable only in ${atsName}`
                }
              >
                <IconButton
                  variant="icon"
                  aria-label="Edit Candidate"
                  icon={<HiOutlinePencil strokeWidth="1.5px" size={20} />}
                  onClick={onOpenEditCandidate}
                  data-testid="candidate-edit-button"
                  disabled={!candidate.canEdit}
                />
              </Tooltip>
              {canDeleteCandidates && (
                <Tooltip label="Delete Candidate">
                  <IconButton
                    isDisabled={candidate.skipRedaction}
                    variant="icon"
                    aria-label="Delete Candidate"
                    icon={<HiOutlineTrash strokeWidth="1.5px" size={20} />}
                    onClick={onOpenDeleteCandidate}
                  />
                </Tooltip>
              )}
            </>
          )}
          <Menu variant="new" autoSelect={false}>
            <MenuButton
              as={IconButton}
              variant="icon"
              icon={<HiDotsVertical size={20} />}
              onClick={(e) => e.stopPropagation()}
              data-testid="candidate-more-menu-button"
            />
            <MenuList minWidth="220px">
              {isSmallScreen && (
                <MenuItem onClick={onOpenEditCandidate}>
                  Edit candidate
                </MenuItem>
              )}
              {atsOptInEnabled ? (
                <MenuItem
                  isDisabled
                  data-testid="candidate-menu-option-opt-out"
                >
                  <Tooltip label="This candidate's recording settings are managed in your ats">
                    <span>
                      {candidate.recordingDisabled
                        ? "Record interviews"
                        : "Do not record interviews"}
                    </span>
                  </Tooltip>
                </MenuItem>
              ) : (
                <MenuItem
                  data-testid="candidate-menu-option-opt-out"
                  onClick={() => {
                    updateCandidateOptOut({
                      variables: {
                        id: candidate.id,
                        recordingDisabled: !candidate.recordingDisabled,
                      },
                    });
                  }}
                >
                  {candidate.recordingDisabled
                    ? "Record interviews"
                    : "Do not record interviews"}
                </MenuItem>
              )}
              {canDeleteCandidates && (
                <MenuItem onClick={onOpenToggleCandidateDeletion}>
                  {candidate.skipRedaction ? "Allow " : "Prevent "}
                  deletion
                </MenuItem>
              )}
              {isSmallScreen && canDeleteCandidates && (
                <MenuItem onClick={onOpenDeleteCandidate}>
                  Delete candidate
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
      {isSmallScreen && candidateLinks}
      <Flex
        direction={["column", "row"]}
        alignItems={["flex-start", "center"]}
        gap={[2, 5]}
        px={[2, 0]}
        mt={[2, 2]}
      >
        <CandidatePositionSelect
          options={candidatePositionOptions.filter((option) => option.count)}
          positionId={positionId}
          onSelect={(position) => {
            sendGAEvent("position_change", "candidate");
            onPositionChange(position.key, false);
          }}
        />
        {candidate.rating && candidate.ratingBreakdown && (
          <CandidateStarRating
            rating={Math.floor(candidate.rating)}
            ratingBreakdown={
              candidate.ratingBreakdown as CandidateRatingBreakdown[]
            }
          />
        )}
      </Flex>
    </Box>
  );
};

export default CandidateHeader;
