import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Link,
  Spacer,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import {
  HiOutlineBriefcase,
  HiOutlineHome,
  HiOutlineInformationCircle,
} from "react-icons/hi";
import { MdLock } from "react-icons/md";

import { GreenhouseIcon, LeverIcon, TooltipIcon } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { CallGuideMenu } from "../../components";
import { CallGuideQuery, CallGuideVisibility } from "../../graphql";
import useUpdateCallGuide from "../../graphql/hooks/CallGuide/useUpdateCallGuide";

type CallGuide = NonNullable<CallGuideQuery["callGuide"]>;

interface GuideHeaderProps {
  callGuide: CallGuide;
  readOnly: boolean;
  onEdit(): void;
}

const GuideHeader: React.FC<GuideHeaderProps> = ({
  callGuide,
  readOnly,
  onEdit,
}) => {
  const [updateCallGuide] = useUpdateCallGuide({});
  const sendGAEvent = useSendGAEvent();
  const canEdit = !readOnly && !callGuide.atsId;
  return (
    <>
      <Flex>
        <Box flex={1}>
          <Breadcrumb
            fontSize="sm"
            mb="3"
            mt="6"
            color="gray.500"
            separator={<Text color="gray.300">/</Text>}
            spacing="2"
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/">
                <HiOutlineHome size={16} />
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink data-testid="guides-breadcrumb" href="/guides">
                Interview Guides
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="#" color="gray.900" fontWeight="500">
                {callGuide.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex fontSize="xl" alignItems="center" fontWeight="medium">
            {callGuide.name}
            {callGuide.atsId && (
              <Tooltip
                label="This Interview Guide is managed by your ATS."
                placement="top"
                hasArrow
              >
                <Flex alignItems="center" ml={2}>
                  {callGuide.greenhouseId && (
                    <GreenhouseIcon height="24" width="12" />
                  )}
                  {callGuide.leverFeedbackTemplateId && <LeverIcon />}
                  {callGuide.customAtsId && (
                    <Icon as={MdLock} color="gray.500" />
                  )}
                </Flex>
              </Tooltip>
            )}
            <Spacer />
            <Flex flexDirection="column" justifyContent="flex-end">
              <Flex justifyContent="flex-end">
                <FormControl
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  mr={4}
                >
                  <FormLabel
                    htmlFor="public-switch"
                    fontWeight="bold"
                    fontSize="xs"
                    mx="2"
                    mb="0"
                  >
                    Public
                  </FormLabel>
                  <Switch
                    id="public-switch"
                    alignSelf="center"
                    size="sm"
                    colorScheme="green"
                    isDisabled={readOnly}
                    isChecked={
                      callGuide.visibility === CallGuideVisibility.Public
                    }
                    onChange={() => {
                      sendGAEvent("update", "call_guides", "visibility");
                      updateCallGuide({
                        variables: {
                          id: callGuide.id,
                          visibility:
                            callGuide.visibility === CallGuideVisibility.Public
                              ? CallGuideVisibility.Private
                              : CallGuideVisibility.Public,
                        },
                      });
                    }}
                  />
                  <TooltipIcon
                    hasArrow
                    placement="top"
                    color="gray.400"
                    icon={HiOutlineInformationCircle}
                    label="Public guides can be used by everyone in the Position's Hiring Team."
                    ml={2}
                  />
                </FormControl>
                <FormControl
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <FormLabel
                    htmlFor="template-switch"
                    fontWeight="bold"
                    fontSize="xs"
                    mx="2"
                    mb="0"
                    cursor="pointer"
                  >
                    Template
                  </FormLabel>
                  <Switch
                    id="template-switch"
                    alignSelf="center"
                    size="sm"
                    colorScheme="green"
                    isDisabled={readOnly}
                    isChecked={callGuide.isTemplate}
                    onChange={() => {
                      sendGAEvent("update", "call_guides", "template");
                      updateCallGuide({
                        variables: {
                          id: callGuide.id,
                          isTemplate: !callGuide.isTemplate,
                        },
                      });
                    }}
                  />
                  <TooltipIcon
                    hasArrow
                    placement="top"
                    color="gray.400"
                    icon={HiOutlineInformationCircle}
                    label="Template guides can be used by everyone in your organization."
                    ml={2}
                  />
                </FormControl>
                <Box ml={2} />
                <CallGuideMenu callGuide={callGuide} redirectOnDelete />
              </Flex>
            </Flex>
          </Flex>
          {callGuide.position && (
            <Text
              fontSize="17px"
              mt="8px"
              fontWeight="medium"
              cursor="pointer"
              _hover={{ color: "blue.500" }}
              display="flex"
              alignItems="center"
            >
              <Link
                display="flex"
                alignItems="center"
                href={`/position/${callGuide.position?.id}`}
              >
                <Icon as={HiOutlineBriefcase} h="20px" w="20px" mr="1" />
                {callGuide.position?.displayTitle}
              </Link>
            </Text>
          )}
          {!callGuide.position && canEdit && (
            <Text
              fontSize="17px"
              mt="8px"
              color="gray.400"
              fontWeight="medium"
              onClick={onEdit}
              cursor="pointer"
              _hover={{ color: "blue.500" }}
              display="flex"
              alignItems="center"
            >
              <Icon as={HiOutlineBriefcase} h="20px" w="20px" mr="1.5" />
              Set position
            </Text>
          )}
        </Box>
      </Flex>
      <Flex ml="auto" mb={6}>
        {callGuide.atsId && (
          <Box>
            <Text
              fontWeight={400}
              fontSize="xs"
              color="gray.500"
              display="flex"
              alignItems="center"
              mt={1}
            >
              <Icon
                as={HiOutlineInformationCircle}
                color="gray.500"
                h="16px"
                w="16px"
                mr={1}
              />
              This guide is managed by your ATS, edits may be made there
            </Text>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default GuideHeader;
