import { Box, Button, Flex, Heading, Icon } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { MdCheckCircle } from "react-icons/md";
import { useSearchParams } from "react-router-dom";

import { RouterLink, useToast } from "../../../components";
import { CurrentUserFragment } from "../../graphql";

interface SlackSettingsProps {
  currentUser: CurrentUserFragment;
}

const SlackSettings: React.FC<SlackSettingsProps> = ({ currentUser }) => {
  const toast = useToast();
  const isSlackAppInstalled = currentUser?.organization.slackAppInstalled;

  const [searchParams, setSearchParams] = useSearchParams();
  const slackInstalled = searchParams.get("slackInstalled");
  const clientId = "709853757287.3061148597697";
  useEffect(() => {
    if (slackInstalled) {
      if (slackInstalled === "success") {
        toast({
          status: "success",
          title: "Success",
          description:
            "The BrightHire Slack app has been successfully installed.",
        });
      } else if (slackInstalled === "failure") {
        toast({
          status: "error",
          title: "Something Went Wrong",
          description:
            "There was a problem installing the Slack app. Please try again later.",
        });
      }
      searchParams.delete("slackInstalled");
      setSearchParams(searchParams, { replace: true });
    }
  });

  return (
    <Box>
      <Heading as="h1" size="md" mb={4}>
        Enable Slack Notifications
      </Heading>
      <Box>
        Add BrightHire to Slack to receive timely notifications about your
        interviews, recordings, and more.
      </Box>
      {!isSlackAppInstalled && (
        <>
          <Box mb={4} color="orange.400">
            Your organization has not enabled Slack Notifications yet.
          </Box>
          <Box mt={8}>
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                window.location.href = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=users:read,users:read.email,chat:write,im:write&user_scope=`;
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: "18px", height: "18px", marginRight: "8px" }}
                viewBox="0 0 122.8 122.8"
              >
                <path
                  d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                  fill="#e01e5a"
                />
                <path
                  d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                  fill="#36c5f0"
                />
                <path
                  d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                  fill="#2eb67d"
                />
                <path
                  d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                  fill="#ecb22e"
                />
              </svg>
              Add to Slack
            </Button>
          </Box>
        </>
      )}
      {isSlackAppInstalled && (
        <>
          <Box mt={8}>
            <Flex alignItems="center">
              <Icon
                boxSize="18px"
                mr={4}
                as={MdCheckCircle}
                color="green.600"
              />
              <Box fontSize="14">
                Slack has been enabled for your organization.
              </Box>
            </Flex>
          </Box>
          <Box mt={7}>
            <RouterLink fontSize="14" to="/settings/user/notifications">
              Edit your notification preferences
            </RouterLink>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SlackSettings;
