import { NetworkStatus } from "@apollo/client";
import {
  Box,
  Button,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useMergeLink } from "@mergeapi/react-merge-link";
import React, { useCallback, useEffect } from "react";

import { LoadingIndicator, useToast } from "../../../components";
import {
  useCreateMergeLinkTokenMutation,
  useDeauthorizeMergeMutation,
  useInstallMergeIntegrationMutation,
  useMergeSetupQuery,
} from "../../graphql";

const formatCategory = (category: string | null | undefined): string => {
  if (category) {
    return category.toLocaleUpperCase();
  }
  return "";
};

const formatAccountSlug = (slug: string | null | undefined): string => {
  if (slug) {
    return slug.charAt(0).toUpperCase() + slug.slice(1);
  }
  return "";
};

const MergeSettings: React.FC = () => {
  const toast = useToast();
  const {
    data: mergeSetupData,
    loading: mergeSetupLoading,
    refetch: mergeSetupRefetch,
    networkStatus: mergeSetupNetworkStatus,
  } = useMergeSetupQuery({
    notifyOnNetworkStatusChange: true,
  });

  const [
    createMergeLinkToken,
    { data: createMergeLinkTokenData, loading: createMergeLinkTokenLoading },
  ] = useCreateMergeLinkTokenMutation();

  const [installMergeIntegration, { loading: installMergeIntegrationLoading }] =
    useInstallMergeIntegrationMutation({
      onCompleted: (data) => {
        mergeSetupRefetch();
      },
      onError: (err) => {
        toast({
          title: "Merge Integration Error",
          description: err.message,
          status: "error",
          duration: 18000,
          isClosable: true,
        });
      },
    });

  const [deauthorizeMerge, { loading: deauthorizeMergeLoading }] =
    useDeauthorizeMergeMutation({
      onCompleted: (data) => {
        mergeSetupRefetch();
      },
    });

  const onSuccess = useCallback((publicToken: string) => {
    installMergeIntegration({
      variables: { publicToken },
    });
  }, []);

  const { open, isReady } = useMergeLink({
    linkToken: createMergeLinkTokenData?.createMergeLinkToken?.linkToken,
    onSuccess,
  });

  useEffect(() => {
    open();
  }, [isReady, createMergeLinkTokenData]);

  const isLoading =
    mergeSetupLoading ||
    deauthorizeMergeLoading ||
    mergeSetupNetworkStatus === NetworkStatus.refetch;

  return (
    <Box w="500px">
      <Heading as="h1" size="md">
        Other ATS Integrations
      </Heading>
      <Flex flexDir="column">
        {mergeSetupLoading && <LoadingIndicator />}
        <Box mt={4}>
          <Heading as="h3" size="sm" mb={4}>
            Currently Installed Integrations
          </Heading>
          <UnorderedList>
            {mergeSetupData?.mergeSetup?.map((account) => {
              return (
                <ListItem key={account.id} mb={4}>
                  <Flex alignItems="center">
                    <Text>
                      {formatCategory(account.category)} -{" "}
                      {formatAccountSlug(account.slug)}
                    </Text>
                    <Button
                      ml={4}
                      isLoading={isLoading}
                      variant="dangerOutline"
                      size="xs"
                      onClick={() => {
                        deauthorizeMerge({
                          variables: {
                            accountId: account.id,
                          },
                        });
                      }}
                    >
                      Remove
                    </Button>
                  </Flex>
                </ListItem>
              );
            })}
          </UnorderedList>
        </Box>
        <Box mt={8}>
          <Button
            size="sm"
            variant="outline"
            isLoading={
              mergeSetupLoading ||
              createMergeLinkTokenLoading ||
              installMergeIntegrationLoading
            }
            onClick={() => {
              createMergeLinkToken();
            }}
          >
            Add new integration
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default MergeSettings;
