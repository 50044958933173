import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { MdMoreVert } from "react-icons/md";

import { useToast } from "../../../components";
import {
  PositionListItemFragment,
  useUpdatePositionsAutoJoinMutation,
} from "../../graphql";

export interface PositionGroupItem {
  id: string;
  name: string;
  autoJoinAllNewPositions: boolean;
  positions: Array<PositionListItemFragment>;
}

interface AutoJoinPositionGroupListItemProps extends PositionGroupItem {
  handleUpdateAutoJoinAllNewPositions: () => void;
}

export const AutoJoinPositionGroupListItem: React.FC<
  AutoJoinPositionGroupListItemProps
> = ({
  id,
  name,
  autoJoinAllNewPositions,
  positions,
  handleUpdateAutoJoinAllNewPositions,
}) => {
  const toast = useToast();

  const [updatePositionsAutoJoin, { loading }] =
    useUpdatePositionsAutoJoinMutation({
      onError: (err) => {
        toast({
          status: "error",
          title: "Error",
          description: `Failed to update Auto Join Video Interview setting: ${err.message}`,
        });
      },
      onCompleted: (data) => {
        if (
          data?.updatePositionsAutoJoin &&
          data?.updatePositionsAutoJoin.positions.length > 0
        ) {
          toast({
            title: "Success",
            description: `Updating current scheduled interviews. BrightHire will ${
              data?.updatePositionsAutoJoin.positions[0].autoJoinInterview
                ? "automatically join."
                : "NOT automatically join."
            }`,
            status: "success",
          });
        }
      },
    });

  const handleUpdatePositionsAutoJoin = (
    positions: Array<PositionListItemFragment>,
    autoJoinInterview: boolean
  ): void => {
    const positionIds = positions.map((position) => position.id);
    updatePositionsAutoJoin({
      variables: { positionIds, autoJoinInterview },
    });
  };

  const countEnabledPositions = (
    positions: Array<PositionListItemFragment>
  ): number => {
    return positions.reduce((prevCount, position) => {
      if (position.autoJoinInterview === true) {
        return prevCount + 1;
      }
      return prevCount;
    }, 0);
  };
  const enabledPositionsCount = useMemo(
    () => countEnabledPositions(positions),
    [positions]
  );

  return (
    <Box mb="5">
      {name !== "" && (
        <Heading as="h3" size="xs" pb={3}>
          {name}
        </Heading>
      )}
      <HStack spacing={2}>
        <Tooltip
          label={`${enabledPositionsCount} positions currently enabled`}
          placement="right"
        >
          <Box>
            <Button
              size="sm"
              variant="outline"
              colorScheme="blue"
              fontSize="14px"
              textTransform="none"
              width="200px"
              isLoading={loading}
              onClick={() => handleUpdatePositionsAutoJoin(positions, true)}
              disabled={positions.length === enabledPositionsCount}
            >
              {positions.length === 1
                ? `Turn on 1 position`
                : `Turn on all ${positions.length} positions`}
            </Button>
          </Box>
        </Tooltip>
        <Box pr="20px">
          <Menu placement="bottom-start" autoSelect={false}>
            <MenuButton
              as={IconButton}
              variant="ghost"
              colorScheme="gray"
              borderRadius="base"
              height="32px"
              width="20px"
              size=""
              icon={<Icon as={MdMoreVert} boxSize="5" />}
            />
            <MenuList>
              <MenuItem
                onClick={() => handleUpdatePositionsAutoJoin(positions, false)}
              >
                {positions.length === 1
                  ? `Turn off 1 position`
                  : `Turn off all ${positions.length} positions`}
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Switch
          id={`auto-join-all-new-positions-${id}`}
          alignSelf="center"
          colorScheme="blue"
          isChecked={autoJoinAllNewPositions}
          onChange={handleUpdateAutoJoinAllNewPositions}
        />
        <Text fontSize="sm" noOfLines={1}>
          Default &quot;on&quot; for new {name} positions
        </Text>
      </HStack>
    </Box>
  );
};
