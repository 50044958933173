import { Box, Flex, HStack, Switch, Text } from "@chakra-ui/react";
import React, { FC } from "react";

import StarWithCircle from "../../../components/Icons/StarWithCircle";
import { CallGuide } from "../../graphql";
import { CallGuide as CallGuideType } from "./types";

interface OverallScoringToggleProps {
  callGuide: Pick<CallGuide, "id" | "overallScoringEnabled">;
  isEditing: boolean;
  setGuide: React.Dispatch<React.SetStateAction<CallGuideType | null>>;
}

const OverallScoringToggle: FC<OverallScoringToggleProps> = ({
  callGuide,
  isEditing,
  setGuide,
}) => {
  return (
    <Flex
      px={6}
      py={3.5}
      bg="white"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="12px"
      border="1px solid"
      borderColor="gray.200"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.05)"
    >
      <HStack spacing="3">
        <StarWithCircle />
        <Box>
          <Text color="gray.900" fontWeight="700" fontSize="sm">
            Overall Scoring
          </Text>
          <Text color="gray.700" fontWeight="400" fontSize="sm" mt="0.5">
            Assess and rate the entire interview performance, distinct from
            individual competency scoring.
          </Text>
        </Box>
      </HStack>
      {isEditing ? (
        <Switch
          size="md"
          isChecked={callGuide.overallScoringEnabled}
          onChange={() => {
            setGuide((guide) => {
              return {
                ...guide,
                overallScoringEnabled: !guide?.overallScoringEnabled,
              } as CallGuide;
            });
          }}
        />
      ) : (
        <Flex
          px="1.5"
          color={callGuide.overallScoringEnabled ? "green.700" : "gray.700"}
          bg={callGuide.overallScoringEnabled ? "green.100" : "gray.100"}
          borderRadius="2"
          fontSize="xs"
          fontWeight="600"
        >
          {callGuide.overallScoringEnabled ? "Enabled" : "Disabled"}
        </Flex>
      )}
    </Flex>
  );
};

export default OverallScoringToggle;
