import {
  Box,
  Flex,
  Icon,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { IoClose } from "react-icons/io5";

import { useTheme } from "../../../components";
import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CallNoteFragment,
  CallNoteType,
  CallSpeakerFragment,
  CallSpeakerOption,
  ShareUserFragment,
  TranscriptSegmentFragment,
} from "../../graphql";
import ActionBar from "../Recording/ActionBar";
import ActionBarNoteSection from "../Recording/ActionBar/ActionBarNoteSection";
import SpeakerBars from "../Recording/SpeakerBars";
import { VideoCallbackRef, VideoStateControl } from "../Video/useVideoState";
import { VideoDisplayMode } from "./types";
import {
  CallbackRef,
  MediaListeners,
  MediaPlayerInterface,
} from "./useMediaPlayer";
import { videoElementComplete } from "./utils";
import VideoControls from "./VideoControls";
import VideoControlsMobile from "./VideoControlsMobile";
import VideoModuleBeta from "./VideoModuleBeta";
import VideoPlayerProgressBar, {
  VideoPlayerProgressBarProps,
} from "./VideoPlayerProgressBar";

export interface VideoBetaProps {
  callId?: string;
  speakers: CallSpeakerFragment[];
  speakerOptions?: Pick<CallSpeakerOption, "id" | "label">[];
  canChangeSpeaker?: boolean;
  duration?: number | null;
  listeners: MediaListeners;
  mediaPlayerRef: CallbackRef;
  mediaSrc?: string;
  notes: CallNoteFragment[];
  onSeek(t: number): void;
  onCreateClipClick?(): void;
  onAddNote?(note: {
    text: string;
    type: CallNoteType;
    time: number;
  }): Promise<void>;
  player: MediaPlayerInterface;
  videoDisplayMode: VideoDisplayMode;
  segments: TranscriptSegmentFragment[];
  shareableUsers?: ShareUserFragment[] | undefined;
  shareableUsersLoading?: boolean;
  isVideoVisible: boolean;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  videoRef: VideoCallbackRef;
  hideVideo(enablePIP?: boolean): void;
  fullScreenControls: VideoStateControl;
  pipControls: VideoStateControl;
  isActionBarEnabled?: boolean;
}

const VideoBeta: React.FC<VideoBetaProps> = ({
  callId,
  speakers,
  speakerOptions,
  canChangeSpeaker = false,
  duration,
  listeners,
  mediaPlayerRef,
  mediaSrc,
  notes,
  onSeek,
  onAddNote,
  onCreateClipClick,
  player,
  videoDisplayMode,
  segments,
  shareableUsers,
  shareableUsersLoading,
  isVideoVisible,
  containerRef,
  videoRef,
  hideVideo,
  fullScreenControls,
  pipControls,
  isActionBarEnabled = true,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const sendGAEvent = useSendGAEvent();
  const { colors } = useTheme();

  const { isActive: fullscreenActive } = fullScreenControls;
  const { isActive: pipActive } = pipControls;

  const [height, setHeight] = useState<string | number>(
    0.24 * Math.floor(window.innerWidth)
  );

  useLayoutEffect(() => {
    if (fullscreenActive) {
      setHeight(`calc(100% - 32px)`);
    } else if (containerRef.current?.clientWidth) {
      setHeight(0.4 * (containerRef.current?.clientWidth || 0));
    }
  }, [containerRef.current, fullscreenActive]);

  useLayoutEffect(() => {
    const updateSize = (): void => {
      if (!fullscreenActive) {
        setHeight(0.4 * (containerRef.current?.clientWidth || 0));
      }
    };
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [fullscreenActive]);

  const { time, duration: playerDuration } = player;
  const [isNoteSectionOpen, setIsNoteSectionOpen] = useState(false);
  const [noteType, setNoteType] = useState(CallNoteType.Note);
  const openNote =
    (type: CallNoteType): (() => void) =>
    () => {
      sendGAEvent("action_bar", "call_review", type);
      /**
       * Allow one-click star and flag notes, but for
       * a general note open the text input
       */
      if (!onAddNote) {
        return;
      }
      if ([CallNoteType.Star, CallNoteType.Flag].includes(type)) {
        onAddNote({ text: "", type, time });
      } else {
        if (isNoteSectionOpen) {
          setIsNoteSectionOpen(false);
          return;
        }
        setNoteType(type);
        setIsNoteSectionOpen(true);
      }
    };

  const {
    isOpen: isHovering,
    onOpen: onMouseEnter,
    onClose: onMouseLeave,
  } = useDisclosure({
    // Disable hover tracking for mobile devices
    isOpen: isMobile ? false : undefined,
  });

  const progressBarProps: VideoPlayerProgressBarProps = {
    duration: playerDuration ?? 0,
    value: time,
    speakers,
    seek: onSeek,
    disabled: false,
    notes,
  };

  const videoReady = mediaSrc && videoElementComplete(player, videoDisplayMode);
  const shouldUseMobileControls = isSmallScreen && isVideoVisible && videoReady;
  const shouldUseDesktopControls = !shouldUseMobileControls && videoReady;

  const [shouldShowScreenControls, setShouldShowScreenControls] =
    useState(true);
  const [hideScreenControlsTimeout, setHideScreenControlsTimeout] =
    useState<NodeJS.Timeout>();

  const onVideoPlayerClick = useCallback(() => {
    if (isMobile) {
      if (player.playing && !pipActive) {
        setShouldShowScreenControls(true);
        clearTimeout(hideScreenControlsTimeout);
        setHideScreenControlsTimeout(
          setTimeout(() => {
            setShouldShowScreenControls(false);
          }, 3000)
        );
      }
    } else if (player.playing) {
      player.pause();
    } else {
      player.play();
    }
  }, [player.playing, hideScreenControlsTimeout, pipActive, player]);

  /**
   * Screen control logic
   *  - show if no video or if paused
   *  - show on hover if isDesktop and video is playing
   *  - show on tap if isMobile and video is playing
   *     - This is handled above; the isMobile case in
   *       this effect hides the controls on play
   */
  useEffect(() => {
    if (!isVideoVisible || !player.playing) {
      setShouldShowScreenControls(true);
      clearTimeout(hideScreenControlsTimeout);
    } else if (isDesktop) {
      setShouldShowScreenControls(isHovering);
    } else if (isMobile) {
      setShouldShowScreenControls(false);
    }
  }, [isVideoVisible, player.playing, isHovering]);

  // On mobile only, when PIP is active, hide the video controls
  useEffect(() => {
    if (isMobile && pipActive) {
      setShouldShowScreenControls(false);
    }
  }, [pipActive]);

  return (
    <Box
      ref={containerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      bg={
        isVideoVisible
          ? "transparent"
          : "linear-gradient(180deg, #010D17 0%, #012A4F 100%);"
      }
      overflow={videoReady ? undefined : "hidden"}
    >
      <Flex
        position="relative"
        height={isVideoVisible ? height : "auto"}
        minH={isVideoVisible ? "220px" : undefined}
      >
        <VideoModuleBeta
          mediaPlayerRef={mediaPlayerRef}
          videoRef={videoRef}
          clipInProgress={false}
          clipTimedOut={false}
          listeners={listeners}
          videoDisplayMode={videoDisplayMode}
          mediaSrc={mediaSrc}
          height="100%"
          isVideoVisible={isVideoVisible}
          onClick={onVideoPlayerClick}
        />
        {shouldUseDesktopControls && (
          <Flex>
            {!fullscreenActive && isVideoVisible && (
              <Box position="absolute" top="2" right="2" opacity={0.8}>
                <Tooltip label="Hide video">
                  <IconButton
                    bg="transparent"
                    color="white"
                    aria-label="Hide video"
                    display="flex"
                    pointerEvents="all"
                    _hover={{ transform: "scale(1.1)" }}
                    icon={<Icon as={IoClose} color="white" boxSize="6" />}
                    onClick={() => hideVideo(false)}
                  />
                </Tooltip>
              </Box>
            )}
          </Flex>
        )}

        {isActionBarEnabled && shouldUseDesktopControls && (
          <ActionBar
            onCreateClipClick={onCreateClipClick}
            openNote={openNote}
            isVideoVisible={isVideoVisible}
          />
        )}
        {shouldUseDesktopControls ? (
          <Flex
            alignItems="flex-start"
            pos={isVideoVisible ? "absolute" : "static"}
            left="0"
            right="0"
            bottom="0"
            flexWrap="wrap-reverse"
            justifyContent="space-between"
            minWidth="0"
          >
            <Flex
              direction="column"
              h={isVideoVisible ? "120px" : "auto"}
              justifyContent="flex-end"
              px="4"
              py="1"
              width="100%"
              bg={
                shouldShowScreenControls
                  ? `linear-gradient(transparent, ${colors.blackAlpha[800]})`
                  : "transparent"
              }
            >
              {shouldShowScreenControls ? (
                <VideoControls
                  player={player}
                  isVideoVisible={isVideoVisible}
                  hideVideo={hideVideo}
                  pipControls={pipControls}
                  fullScreenControls={fullScreenControls}
                  zIndex="3"
                />
              ) : (
                <Flex />
              )}
            </Flex>
          </Flex>
        ) : (
          <Box />
        )}

        {shouldUseMobileControls && shouldShowScreenControls && (
          <VideoControlsMobile
            player={player}
            fullScreenControls={fullScreenControls}
            pipControls={pipControls}
            progressBarProps={progressBarProps}
            onClick={onVideoPlayerClick}
          />
        )}
      </Flex>

      <Flex
        flexDir="column"
        position="relative"
        bg={
          isVideoVisible
            ? "linear-gradient(180deg, #010D17 0%, #012A4F 100%);"
            : "transparent"
        }
      >
        {isActionBarEnabled && onAddNote && (
          <ActionBarNoteSection
            isOpen={isNoteSectionOpen}
            isFullscreen={fullscreenActive}
            noteType={noteType}
            onAddNote={onAddNote}
            setIsOpen={setIsNoteSectionOpen}
            time={player.time}
            shareableUsers={shareableUsers}
            shareableUsersLoading={shareableUsersLoading}
          />
        )}
        <Box
          px={5}
          py={0}
          borderTopColor="whiteAlpha.300"
          borderTopWidth="1px"
          display={shouldUseDesktopControls ? undefined : "none"}
        >
          <VideoPlayerProgressBar {...progressBarProps} />
        </Box>

        <Box
          px={5}
          py={3}
          borderTopColor="whiteAlpha.300"
          borderTopWidth="1px"
          display={{
            base: "none",
            lg: shouldUseDesktopControls ? "block" : "none",
          }}
        >
          <SpeakerBars
            callId={callId}
            callDuration={duration ?? 0}
            speakers={speakers}
            speakerOptions={speakerOptions}
            canChangeSpeaker={canChangeSpeaker}
            player={player}
            segments={segments}
            onClickSegment={onSeek}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default VideoBeta;
