import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { MdInfo } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Alert,
  LoadingIndicator,
  PageContent,
  PageTitle,
  TabButton,
} from "../../../components";
import useCurrentBreakpoint from "../../../hooks/useCurrentBreakpoint";
import useOffsetPagination from "../../../hooks/useOffsetPagination";
import { usePageTracker } from "../../../utils/googleAnalytics";
import { AddPositionModal } from "../../components";
import {
  PositionListItemFragment,
  useCurrentUserPositionsLazyQuery,
  useOrgPositionsLazyQuery,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import DefaultLayout from "../../layouts/DefaultLayout";
import MyPositions from "./MyPositions";
import TeamPositions from "./TeamPositions";

const mobileListBreakpoints = ["base", "sm"];

const PositionsPage: React.FC = () => {
  usePageTracker("positions");
  const { search, pathname } = useLocation();
  const lastLocation = useRef(pathname);
  const navigate = useNavigate();
  const canCreatePosition =
    useCurrentUser().userRole?.canCreatePosition ?? false;
  const addPositionModal = useDisclosure({
    isOpen: canCreatePosition ? undefined : false,
    onOpen: () => {
      lastLocation.current = pathname;
      navigate({ pathname: "/positions/new", search }, { replace: true });
    },
    onClose: () =>
      navigate({ pathname: lastLocation.current, search }, { replace: true }),
  });
  const pathToCheck = addPositionModal.isOpen ? lastLocation.current : pathname;
  const mineSelected = pathToCheck === "/positions/mine";
  const allSelected = pathToCheck === "/positions";
  const [totalUserPositionsCount, setTotalUserPositionsCount] = useState("");
  const [totalOrgPositionsCount, setTotalOrgPositionsCount] = useState("");
  const currentBreakpoint = useCurrentBreakpoint();
  const enableMobileView = mobileListBreakpoints.includes(currentBreakpoint);

  const [
    getOrgPositions,
    { loading: orgPositionsLoading, error: orgPositionsError },
  ] = useOrgPositionsLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: ({ currentUser }) => {
      if (currentUser?.organization?.positions?.results) {
        setOrgPositions(currentUser.organization.positions.results);
        setNumberOfOrgPositions(
          currentUser?.organization?.positions.pageInfo?.totalPages || 0
        );
        setTotalOrgPositionsCount(
          currentUser?.organization?.positions.pageInfo?.totalRows?.toString() ||
            ""
        );
      }
    },
  });

  const [
    getUserPositions,
    { loading: userPositionsLoading, error: userPositionsError },
  ] = useCurrentUserPositionsLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: ({ currentUser }) => {
      if (currentUser?.positions?.results) {
        setUserPositions(currentUser.positions.results);
        setNumberOfUserPositions(
          currentUser?.positions.pageInfo?.totalPages || 0
        );
        setTotalUserPositionsCount(
          currentUser?.positions.pageInfo?.totalRows?.toString() || ""
        );
      }
    },
  });

  const {
    listItems: orgPositions,
    setListItems: setOrgPositions,
    setNumberOfPages: setNumberOfOrgPositions,
    pageOptions: orgPositionPageOptions,
    setSearchTerm: setOrgPositionSearchTerm,
    searchTerm: orgPositionSearchTerm,
    didFetchComplete: didOrgPositionsFetchComplete,
  } = useOffsetPagination<PositionListItemFragment>(getOrgPositions, {
    initialSortBy: { id: "lastCallAt", desc: true },
  });

  const {
    listItems: userPositions,
    setListItems: setUserPositions,
    setNumberOfPages: setNumberOfUserPositions,
    pageOptions: userPositionPageOptions,
    setSearchTerm: setUserPositionSearchTerm,
    searchTerm: userPositionSearchTerm,
    didFetchComplete: didUserPositionsFetchComplete,
  } = useOffsetPagination<PositionListItemFragment>(getUserPositions, {
    initialSortBy: { id: "lastCallAt", desc: true },
  });

  const handleChangeTab = (index: number): void => {
    setOrgPositionSearchTerm("");
    setUserPositionSearchTerm("");
    if (index === 0) {
      navigate({ pathname: "/positions/mine", search }, { replace: true });
    } else {
      navigate({ pathname: "/positions", search }, { replace: true });
    }
  };

  const renderTabsList = (): React.ReactNode => {
    return (
      <TabList mt={enableMobileView ? 4 : 0} mb="3">
        <Tab
          _focus={{ boxShadow: "none" }}
          _hover={{ background: "none" }}
          _active={{ background: "none" }}
          paddingLeft="0"
          paddingRight="2"
          paddingBottom="0"
        >
          <TabButton
            label="My Positions"
            count={totalUserPositionsCount}
            isSelected={mineSelected}
          />
        </Tab>
        <Tab
          _focus={{ boxShadow: "none" }}
          _hover={{ background: "none" }}
          _active={{ background: "none" }}
          paddingLeft="2"
          paddingRight="2"
          paddingBottom="0"
        >
          <TabButton
            label="All Positions"
            count={totalOrgPositionsCount}
            isSelected={allSelected}
          />
        </Tab>
      </TabList>
    );
  };

  const renderSearchAndCreateButton = (): React.ReactNode => {
    return (
      <Flex direction={["column", "row"]}>
        <InputGroup h="8">
          <InputLeftElement h="8" px="0" color="placeholder">
            <FiSearch />
          </InputLeftElement>
          <Input
            value={
              mineSelected ? userPositionSearchTerm : orgPositionSearchTerm
            }
            fontSize="sm"
            type="search"
            h="8"
            placeholder="Search Positions"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (mineSelected) {
                setUserPositionSearchTerm(event.currentTarget.value);
              } else {
                setOrgPositionSearchTerm(event.currentTarget.value);
              }
            }}
          />
          <InputRightElement h="8" px="0">
            {didUserPositionsFetchComplete && userPositionsLoading ? (
              <LoadingIndicator delay={0} size="sm" />
            ) : null}
            {didOrgPositionsFetchComplete && orgPositionsLoading ? (
              <LoadingIndicator delay={0} size="sm" />
            ) : null}
          </InputRightElement>
        </InputGroup>
        {canCreatePosition && (
          <Button
            size="sm"
            flexShrink={0}
            mt={[3, 0]}
            ml={[0, 3]}
            onClick={addPositionModal.onOpen}
          >
            New position
          </Button>
        )}
      </Flex>
    );
  };

  return (
    <>
      <AddPositionModal
        {...addPositionModal}
        onSave={(position) => navigate(`/position/${position.id}`)}
      />
      <DefaultLayout>
        <PageContent>
          <PageTitle mb="5" titleText="Positions" />
          <Tabs
            index={mineSelected ? 0 : 1}
            onChange={handleChangeTab}
            variant="unstyled"
          >
            <Flex flexDirection="column">
              {enableMobileView ? (
                <>
                  {renderSearchAndCreateButton()}
                  {renderTabsList()}
                </>
              ) : (
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {renderTabsList()}
                  {renderSearchAndCreateButton()}
                </Flex>
              )}
              {mineSelected && (
                <Flex ml={2} mt={4} mb={4} alignItems="center">
                  <Icon as={MdInfo} color="gray.400" />
                  <Box ml={2} color="gray.700" fontSize="sm">
                    You have been an interviewer for these positions, or have
                    been added to the Hiring Team in BrightHire
                  </Box>
                </Flex>
              )}
              <TabPanels>
                <TabPanel>
                  {userPositionsError && (
                    <Alert
                      status="error"
                      title="Error loading positions"
                      description={userPositionsError.message}
                    />
                  )}
                  {!userPositionsError && !didUserPositionsFetchComplete && (
                    <LoadingIndicator />
                  )}
                  {!userPositionsError && didUserPositionsFetchComplete ? (
                    <MyPositions
                      pageOptions={userPositionPageOptions}
                      positions={userPositions}
                      loading={userPositionsLoading}
                      error={userPositionsError}
                      sortBy={userPositionPageOptions.sortBy}
                    />
                  ) : null}
                </TabPanel>
                <TabPanel>
                  {orgPositionsError && (
                    <Alert
                      status="error"
                      title="Error loading positions"
                      description={orgPositionsError.message}
                    />
                  )}
                  {!orgPositionsError && !didOrgPositionsFetchComplete && (
                    <LoadingIndicator />
                  )}
                  {!orgPositionsError && didOrgPositionsFetchComplete ? (
                    <TeamPositions
                      pageOptions={orgPositionPageOptions}
                      positions={orgPositions}
                      loading={orgPositionsLoading}
                      error={orgPositionsError}
                      sortBy={orgPositionPageOptions.sortBy}
                    />
                  ) : null}
                </TabPanel>
              </TabPanels>
            </Flex>
          </Tabs>
        </PageContent>
      </DefaultLayout>
    </>
  );
};

export default PositionsPage;
