import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import invariant from "invariant";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { Alert, LoadingIndicator, useToast } from "../../../components";
import { CheckmarkIcon } from "../../../components/Icons/CheckmarkIcon";
import {
  CallVisibility,
  RolePolicies,
  useOrganizationRolesQuery,
  useRolePoliciesQuery,
  useUpdateRolePoliciesMutation,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useLink from "../../hooks/useLink";
import {
  PolicyGridBorder,
  PolicyGridHeader,
  PolicySectionGridHeader,
  PolicySectionGridRow,
} from "./RolePoliciesGrid";

const hoverStyles = {
  _hover: {
    bg: "transparent",
    color: "gray.800",
    borderBottomColor: "gray.100",
  },
  _active: {},
  _focus: {},
};

type FormValues = Pick<
  RolePolicies,
  | "recruiterCanSeePublicCalls"
  | "recruiterCanSeeRestrictedCalls"
  | "recruiterCanManageHiringTeams"
  | "recruiterCanCreatePositions"
  | "recruiterCanCreateTrainings"
  | "recruiterCanManageAllTrainings"
  | "recruiterCanShareRecordingsExternally"
  | "hiringManagerCanSeePrivateCalls"
  | "hiringManagerCanShareRecordingsExternally"
  | "memberCanSeePublicCalls"
  | "defaultSelfSignUpRole"
  | "defaultScheduledInterviewerInviteRole"
  | "defaultHiringTeamRole"
  | "defaultPositionCreateRole"
  | "defaultRecruiterCallVisibility"
  | "basicCanSeeOthersNotes"
  | "recruiterCanSeeOthersNotes"
  | "hiringManagerCanSeeOthersNotes"
  | "hiringManagerCanCreateTrainings"
  | "memberCanSeeOthersNotes"
  | "interviewerCanSeeOthersNotes"
>;

const RolePoliciesPage: React.FC = () => {
  const toast = useToast();
  const { data, loading } = useRolePoliciesQuery({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });
  const { data: orgRolesData, loading: orgRolesLoading } =
    useOrganizationRolesQuery();

  const paths = ["user", "team", "policies"];
  const pathLinks = [
    useLink({ type: "userRoles", page: "user" }),
    useLink({ type: "userRoles", page: "team" }),
    useLink({ type: "userRoles", page: "policies" }),
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);
  const { tabName } = useParams<"tabName">();
  const navigate = useNavigate();
  useEffect(() => {
    const index = paths.indexOf(tabName ?? "");
    if (index === -1) {
      navigate(pathLinks[0], {
        replace: true,
      });
      return;
    }
    if (selectedIndex !== index) {
      setSelectedIndex(index);
    }
  }, [tabName, selectedIndex]);

  if (loading || orgRolesLoading) {
    return <LoadingIndicator />;
  }

  const userRoles = orgRolesData?.currentUser?.organization.userRoles;
  const positionRoles = orgRolesData?.currentUser?.organization.positionRoles;

  invariant(data?.rolePolicies, "missing rolePolicies");
  invariant(userRoles, "missing userRoles");
  invariant(positionRoles, "missing positionRoles");

  return (
    <>
      <Text as="h1" color="gray.900" fontWeight="500" fontSize="xl" mb="8">
        User Roles &amp; Policies
      </Text>
      <Tabs
        index={selectedIndex}
        onChange={(index: number) => {
          const link = pathLinks[index];
          setSelectedIndex(index);
          navigate(link, { replace: true });
        }}
      >
        <TabList borderBottomWidth="1px" borderBottomColor="gray.100">
          <Tab px="0" pt="0" mr="5" fontWeight="500" {...hoverStyles}>
            User Permissions
          </Tab>
          <Tab px="0" pt="0" mr="5" fontWeight="500" {...hoverStyles}>
            Hiring Team Permissions
          </Tab>
          <Tab px="0" pt="0" fontWeight="500" {...hoverStyles}>
            Policies
          </Tab>
        </TabList>
        <Box mt={10}>
          <RolePoliciesForm
            defaultValues={data.rolePolicies}
            userRoles={userRoles}
            positionRoles={positionRoles}
          />
        </Box>
      </Tabs>
    </>
  );
};

interface RolePoliciesFormProps {
  defaultValues: FormValues;
  userRoles: { id: string; name: string; formattedName?: string | null }[];
  positionRoles: { id: string; name: string; formattedName?: string | null }[];
}

const RolePoliciesForm: React.FC<RolePoliciesFormProps> = ({
  defaultValues,
  userRoles,
  positionRoles,
}) => {
  const toast = useToast();
  const externalSharingFeatureEnabled = useFeatureFlag("external_sharing");
  const autoAssignGuidesEnabled = useFeatureFlag(
    "assign-guides-interviewers:v1"
  );
  const { register, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues,
  });
  const watchValues = watch();

  const [updateRolePolicies] = useUpdateRolePoliciesMutation({
    onCompleted: (err) => {
      toast({
        title: "Success",
        description: "Role policies updated.",
        status: "success",
      });
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });
  const onSubmit = handleSubmit((values): void => {
    const finalValues = { ...defaultValues, ...values };
    updateRolePolicies({ variables: finalValues });
  });

  const renderCheckbox = (policy: keyof FormValues): React.ReactNode => (
    <Checkbox
      {...register(policy)}
      isChecked={watchValues[policy] as boolean}
      onChange={(e) => {
        setValue(policy, e.target.checked);
        onSubmit();
      }}
      data-testid={policy}
      variant="ghostOutline"
      spacing="4"
      icon={<CheckmarkIcon width="9px" height="6px" />}
    />
  );

  return (
    <form onSubmit={onSubmit}>
      <TabPanels>
        <TabPanel>
          <Alert
            status="info"
            description="Each User is assigned a role in BrightHire: Basic, Recruiter or
            Admin. Use the settings below to configure permissions for each of
            the roles."
            mb={6}
            display="none"
          />

          <Grid
            templateColumns="repeat(6, 1fr)"
            templateRows="auto auto"
            alignItems="center"
            mb={10}
          >
            <PolicyGridHeader
              columnCount={6}
              columnTitles={["Basic", "Recruiter", "Admin"]}
            />
            <PolicyGridBorder noBottomMargin />
            <PolicySectionGridHeader text="Visibility" />
            <PolicySectionGridRow
              text="Can view their own interviews and Candidates and Positions related to those interviews"
              basicCell={<CheckmarkIcon isChecked />}
              recruiterCell={<CheckmarkIcon isChecked />}
              adminCell={<CheckmarkIcon isChecked />}
            />
            <PolicySectionGridRow
              text="Can view notes and reactions of interviews shared with them"
              basicCell={renderCheckbox("basicCanSeeOthersNotes")}
              recruiterCell={<CheckmarkIcon isChecked />}
              adminCell={<CheckmarkIcon isChecked />}
              noBottomMargin
            />
            <PolicySectionGridHeader text="Hiring Teams" />
            <PolicySectionGridRow
              text="Can create Positions and Hiring Teams"
              basicCell={null}
              recruiterCell={renderCheckbox("recruiterCanCreatePositions")}
              adminCell={<CheckmarkIcon isChecked />}
            />
            <PolicySectionGridRow
              text={
                autoAssignGuidesEnabled
                  ? "Can act as Hiring Team Admin for any Hiring Team, share interviews they have access to with any user, and can assign interview guides to interviewers"
                  : "Can act as Hiring Team Admin for any Hiring Team, and share interviews they have access to with any user"
              }
              basicCell={null}
              recruiterCell={renderCheckbox("recruiterCanManageHiringTeams")}
              adminCell={<CheckmarkIcon isChecked />}
            />
            <PolicySectionGridRow
              text={`Can view all interviews with "Hiring Team" visibility, all candidate information, and all scheduled interviews`}
              basicCell={null}
              recruiterCell={renderCheckbox("recruiterCanSeePublicCalls")}
              adminCell={<CheckmarkIcon isChecked />}
            />
            <PolicySectionGridRow
              text="Can view all restricted interviews on any Hiring Team"
              basicCell={null}
              recruiterCell={renderCheckbox("recruiterCanSeeRestrictedCalls")}
              adminCell={<CheckmarkIcon isChecked />}
            />
            <PolicySectionGridRow
              text="Can view all private interviews on any Hiring Team"
              basicCell={null}
              recruiterCell={null}
              adminCell={<CheckmarkIcon isChecked />}
              noBottomMargin
            />
            <PolicySectionGridHeader text="Training" />
            <PolicySectionGridRow
              text="Can set up and manage training programs"
              basicCell={null}
              recruiterCell={renderCheckbox("recruiterCanCreateTrainings")}
              adminCell={<CheckmarkIcon isChecked />}
            />
            <PolicySectionGridRow
              text="Can view and edit all training programs across the company"
              basicCell={null}
              recruiterCell={renderCheckbox("recruiterCanManageAllTrainings")}
              adminCell={<CheckmarkIcon isChecked />}
              noBottomMargin
            />
            <PolicySectionGridHeader text="Sharing &amp; Management" />
            {externalSharingFeatureEnabled && (
              <PolicySectionGridRow
                text="Can share interviews and clips with external users"
                basicCell={null}
                recruiterCell={renderCheckbox(
                  "recruiterCanShareRecordingsExternally"
                )}
                adminCell={<CheckmarkIcon isChecked />}
              />
            )}
            <PolicySectionGridRow
              text="Can manage Organization settings"
              basicCell={null}
              recruiterCell={null}
              adminCell={<CheckmarkIcon isChecked />}
            />
            <PolicySectionGridRow
              text="Can manage Integration settings"
              basicCell={null}
              recruiterCell={null}
              adminCell={<CheckmarkIcon isChecked />}
            />
            <PolicySectionGridRow
              text="Can manage User Roles"
              basicCell={null}
              recruiterCell={null}
              adminCell={<CheckmarkIcon isChecked />}
              noBottomMargin
            />
          </Grid>
        </TabPanel>

        <TabPanel>
          <Alert
            status="info"
            description="Users can belong to one or more Hiring Teams. When added to a
            Hiring Team, the User is assigned a role: Interviewer,
            Team Member or Hiring Team Admin. Use the settings below to configure 
            permissions for each of the roles."
            mb={6}
            display="none"
          />

          <Grid templateColumns="repeat(6, 1fr)" templateRows="auto auto">
            <PolicyGridHeader
              columnCount={6}
              columnTitles={["Interviewer", "Team Member", "Hiring Team Admin"]}
            />
            <PolicyGridBorder noBottomMargin />
            <PolicySectionGridHeader text="Visibility" />
            <PolicySectionGridRow
              text="Can view notes and reactions of interviews shared with them"
              basicCell={renderCheckbox("interviewerCanSeeOthersNotes")}
              recruiterCell={renderCheckbox("memberCanSeeOthersNotes")}
              adminCell={<CheckmarkIcon isChecked />}
            />
            <PolicySectionGridRow
              text={`Can view interviews with "Hiring Team" visibility for Hiring Teams they are on`}
              basicCell={null}
              recruiterCell={renderCheckbox("memberCanSeePublicCalls")}
              adminCell={<CheckmarkIcon isChecked />}
            />
            <PolicySectionGridRow
              text="Can view restricted interviews for Hiring Teams they are on"
              basicCell={null}
              recruiterCell={null}
              adminCell={<CheckmarkIcon isChecked />}
            />
            <PolicySectionGridRow
              text="Can view private interviews for Hiring Teams they are on"
              basicCell={null}
              recruiterCell={null}
              adminCell={renderCheckbox("hiringManagerCanSeePrivateCalls")}
            />
            <PolicySectionGridRow
              text="Can view Position information for Hiring Teams they are on"
              basicCell={<CheckmarkIcon isChecked />}
              recruiterCell={<CheckmarkIcon isChecked />}
              adminCell={<CheckmarkIcon isChecked />}
            />
            <PolicySectionGridRow
              text="Can view Candidate information for Hiring Teams they are on"
              basicCell={null}
              recruiterCell={null}
              adminCell={<CheckmarkIcon isChecked />}
              noBottomMargin
            />

            <PolicySectionGridHeader text="Training" />
            <PolicySectionGridRow
              text="Can set up and manage training programs"
              basicCell={null}
              recruiterCell={null}
              adminCell={<CheckmarkIcon isChecked />}
              noBottomMargin
            />

            <PolicySectionGridHeader text="Sharing" />
            <PolicySectionGridRow
              text="Can share interviews belonging to Hiring Teams they are on with any user in the organization"
              basicCell={null}
              recruiterCell={null}
              adminCell={<CheckmarkIcon isChecked />}
            />
            {externalSharingFeatureEnabled && (
              <PolicySectionGridRow
                text="Can share interviews and clips with external users"
                basicCell={null}
                recruiterCell={null}
                adminCell={renderCheckbox(
                  "hiringManagerCanShareRecordingsExternally"
                )}
              />
            )}
          </Grid>
        </TabPanel>

        <TabPanel mb={10}>
          <Alert
            status="info"
            description="Use the settings below to configure organization wide role policies."
            mb={6}
          />
          <FormControl id="defaultSelfSignUpRole" mb={8}>
            <FormLabel>
              Default User Role assigned to a User during Self Sign-Up
            </FormLabel>
            <FormHelperText mb={4}>
              If your organization has Self-Signup, anyone at your organization
              can join BrightHire, even without an invitation. This helps
              streamline onboarding for Hiring Teams. Use the setting below to
              configure the User Role assigned to Self-Signup Users. We
              recommend Basic.
            </FormHelperText>
            <Select
              w="max-content"
              {...register("defaultSelfSignUpRole")}
              value={watchValues.defaultSelfSignUpRole}
              onChange={(e) => {
                setValue("defaultSelfSignUpRole", e.target.value);
              }}
              data-testid="defaultSelfSignUpRole"
            >
              {userRoles.map((pr) => {
                return (
                  <option value={pr.id} key={pr.id}>
                    {pr.formattedName}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl id="defaultScheduledInterviewerInviteRole" mb={8}>
            <FormLabel>
              Default User Role assigned to a User with Auto-Invite Scheduled
              Interviewers
            </FormLabel>
            <FormHelperText mb={4}>
              This is the User Role assigned to the interviewers that are not
              already BrightHire users.
            </FormHelperText>
            <Select
              w="max-content"
              {...register("defaultScheduledInterviewerInviteRole")}
              value={watchValues.defaultScheduledInterviewerInviteRole}
              onChange={(e) => {
                setValue(
                  "defaultScheduledInterviewerInviteRole",
                  e.target.value
                );
              }}
            >
              {userRoles.map((pr) => {
                return (
                  <option value={pr.id} key={pr.id}>
                    {pr.formattedName}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl id="defaultHiringTeamRole" mb={8}>
            <FormLabel>
              Default Hiring Team Role assigned to a User when automatically
              added to a Hiring Team
            </FormLabel>
            <FormHelperText mb={4}>
              When a position is created via an integration (i.e., click-to-call
              from Greenhouse), the User making the call will automatically be
              added to the Position&apos;s Hiring Team. Use the setting below to
              configure the default Hiring Team role assigned to the User. We
              recommend Interviewer.
            </FormHelperText>
            <Select
              w="max-content"
              {...register("defaultHiringTeamRole")}
              value={watchValues.defaultHiringTeamRole}
              onChange={(e) => {
                setValue("defaultHiringTeamRole", e.target.value);
              }}
            >
              {positionRoles.map((pr) => {
                return (
                  <option value={pr.id} key={pr.id}>
                    {pr.formattedName}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl id="defaultPositionCreateRole" mb={8}>
            <FormLabel>
              Default Hiring Team Role assigned to a User when creating a
              position
            </FormLabel>
            <FormHelperText mb={4}>
              When a User creates a Position they are automatically added to
              it&apos;s Hiring Team. Use the setting below to configure the
              default Hiring Team role assigned to the User. We recommend Hiring
              Team Admin.
            </FormHelperText>
            <Select
              w="max-content"
              {...register("defaultPositionCreateRole")}
              value={watchValues.defaultPositionCreateRole}
              onChange={(e) => {
                setValue("defaultPositionCreateRole", e.target.value);
              }}
            >
              {positionRoles.map((pr) => {
                return (
                  <option value={pr.id} key={pr.id}>
                    {pr.formattedName}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl id="defaultPositionCreateRole" mb={8}>
            <FormLabel>Default Recruiter Interview Visibility</FormLabel>
            <FormHelperText mb={4}>
              Use the setting below to configure the default Interview
              Visibility set on Interviews run by recruiters. We recommend
              Hiring Team.
            </FormHelperText>
            <Select
              w="max-content"
              {...register("defaultRecruiterCallVisibility")}
              value={watchValues.defaultRecruiterCallVisibility}
              onChange={(e) => {
                setValue(
                  "defaultRecruiterCallVisibility",
                  e.target.value as CallVisibility
                );
              }}
            >
              <option value={CallVisibility.Public}>Hiring Team</option>
              <option value={CallVisibility.Restricted}>Restricted</option>
              <option value={CallVisibility.Private}>Private</option>
            </Select>
          </FormControl>
          <Button
            type="submit"
            size="md"
            mt={6}
            data-testid="policies-save-button"
          >
            Save
          </Button>
        </TabPanel>
      </TabPanels>
    </form>
  );
};

export default RolePoliciesPage;
