import React from "react";

import BaseLayout from "./BaseLayout";

interface DefaultLayoutProps {
  children: React.ReactNode;
  showSearchBox?: boolean;
  allowEmptyQuery?: boolean;
  updateUrlWithSearchParams?: boolean;
}

/**
 * Renders navigation and places children in remaining space.
 *
 * No layout decisions are made for children——they must take full control
 * of their styling
 */
const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  showSearchBox = true,
  allowEmptyQuery = false,
  updateUrlWithSearchParams = false,
  children,
}) => (
  <BaseLayout
    showSearchBox={showSearchBox}
    allowEmptyQuery={allowEmptyQuery}
    updateUrlWithSearchParams={updateUrlWithSearchParams}
  >
    {children}
  </BaseLayout>
);

export default DefaultLayout;
