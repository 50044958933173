import { Box, Heading, Link } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { LoadingIndicator, useToast, ZoomSettings } from "../../../components";
import config from "../../../config";
import {
  CurrentUserFragment,
  useAuthorizeZoomMutation,
  useOrgZoomSettingsLazyQuery,
} from "../../graphql";
import StyledZoomImg from "./StyleZoomImg";

interface ZoomSettingsProps {
  currentUser: CurrentUserFragment;
}

const ZoomOrgSettings: React.FC<ZoomSettingsProps> = ({ currentUser }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get("code");
  const toast = useToast();

  const [getOrgZoomSettings, { data, loading: loadingAccountSettings }] =
    useOrgZoomSettingsLazyQuery({
      onError: () => {
        toast({
          status: "error",
          title: "Zoom Account Settings",
          description:
            "There was a problem fetching your Zoom account settings",
        });
      },
    });
  const settingsJson = data?.currentUser?.organization.zoomSettings;

  const [authorizeZoomMutation, { loading: loadingAuthorize }] =
    useAuthorizeZoomMutation({
      onError: () => {
        toast({
          status: "error",
          title: "Authorizing Zoom",
          description: "There was a problem - please try again",
        });
      },
    });

  const { organization } = currentUser;

  useEffect(() => {
    if (currentUser.userRole?.canManageIntegrationSettings) {
      if (authCode) {
        authorizeZoomMutation({ variables: { authCode } });
        navigate(location.pathname, { replace: true });
      } else if (organization.isZoomAuthorized) {
        getOrgZoomSettings();
      }
    }
  }, []);

  if (!currentUser.userRole?.canManageIntegrationSettings) {
    return (
      <Box w="350px">
        <Heading as="h1" size="md" pb={6}>
          Access Denied
        </Heading>
      </Box>
    );
  }

  if (loadingAccountSettings || loadingAuthorize) {
    return <LoadingIndicator />;
  }

  if (organization.isZoomAuthorized) {
    if (!settingsJson) {
      return null;
    }

    return (
      <Box>
        <Heading as="h2" size="md" mb={2}>
          Zoom Organization Settings
        </Heading>
        <Box mb={8}>
          {
            "This page shows whether your organization's Zoom account settings are configured correctly to work with BrightHire. See the "
          }
          <Link href="https://help.brighthire.ai/en/articles/5491706-brighthire-x-zoom-integration-setup">
            Zoom Setup Instructions
          </Link>{" "}
          for more information.
        </Box>
        <ZoomSettings showLicense={false} settingsJson={settingsJson} />
      </Box>
    );
  }

  if (config.zoomClientId) {
    return (
      <Box>
        <Heading as="h2" size="md" mb={4}>
          Zoom Organization Settings
        </Heading>
        <Box mb={4}>
          Please read the{" "}
          <Link href="https://help.brighthire.ai/en/articles/5491706-brighthire-x-zoom-integration-setup">
            Zoom Setup Instructions
          </Link>{" "}
          before clicking the button below.
        </Box>
        <a
          href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${config.zoomClientId}&redirect_uri=${config.urlPrefix}/settings/zoom`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledZoomImg
            src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png"
            alt="Add to ZOOM"
          />
        </a>
      </Box>
    );
  }

  return null;
};

export default ZoomOrgSettings;
