import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import {
  RequireUUIDParams as RequireUUIDParamsBase,
  RequireUUIDParamsProps,
} from "../../../components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { usePageTracker } from "../../../utils/googleAnalytics";
import DefaultLayout from "../../layouts/DefaultLayout";
import ActivityPage from "./ActivityPage";
import ExcludedUsers from "./ExcludedUsers";
import RolePoliciesPage from "./RolePoliciesPage";
import TeamInfoPage from "./TeamInfoPage";
import TeamsPage from "./TeamsPage";
import UserInfoPage from "./UserInfoPage";
import UsersListPage from "./UsersListPage";
import UsersNav from "./UsersNav";

const RequireUUIDParams: React.FC<Omit<RequireUUIDParamsProps, "element">> = (
  props
) => (
  <RequireUUIDParamsBase element={<Navigate to="/not-found" />} {...props} />
);

const UsersPage: React.FC = () => {
  usePageTracker("user_settings");
  const { layoutHeight } = useWindowDimensions();
  return (
    <DefaultLayout>
      <Flex height={layoutHeight} overflowX="auto">
        <UsersNav />
        <Box
          bg="white"
          flex="1 1 auto"
          borderLeftWidth="1px"
          borderLeftColor="gray.100"
          minWidth="400px"
          pt="7"
          px="10"
          overflow="auto"
        >
          <Routes>
            <Route path="/list" element={<UsersListPage />} />
            <Route
              path="/info/:id"
              element={
                <RequireUUIDParams paramNames={["id"]}>
                  <UserInfoPage />
                </RequireUUIDParams>
              }
            />
            <Route path="/teams" element={<TeamsPage />} />
            <Route
              path="/teams/info/:id"
              element={
                <RequireUUIDParams paramNames={["id"]}>
                  <TeamInfoPage />
                </RequireUUIDParams>
              }
            />
            <Route path="/roles" element={<RolePoliciesPage />} />
            <Route path="/roles/:tabName" element={<RolePoliciesPage />} />
            <Route path="/activity" element={<ActivityPage />} />
            <Route path="/excluded" element={<ExcludedUsers />} />
            <Route path="*" element={<Navigate to="/users/list" />} />
          </Routes>
        </Box>
      </Flex>
    </DefaultLayout>
  );
};

export default UsersPage;
