import { useMemo } from "react";

import { CallAiSummaryFormat } from "../graphql";
import useFeatureFlag from "../graphql/hooks/useFeatureFlag";

export const useAvailableAiSummaryFormats = (): CallAiSummaryFormat[] => {
  const allFormatsEnabled = useFeatureFlag("ai-summary-other-formats");
  const tldrEnabled = useFeatureFlag(
    `ai-summary:${CallAiSummaryFormat.Tldr.toLowerCase()}`
  );
  const intakeEnabled = useFeatureFlag(
    `ai-summary:${CallAiSummaryFormat.Intake.toLowerCase()}`
  );
  const debriefEnabled = useFeatureFlag(
    `ai-summary:${CallAiSummaryFormat.Debrief.toLowerCase()}`
  );
  const salesEnabled = useFeatureFlag(
    `ai-summary:${CallAiSummaryFormat.Sales.toLowerCase()}`
  );

  return useMemo(() => {
    if (allFormatsEnabled) {
      return [
        CallAiSummaryFormat.Tldr,
        CallAiSummaryFormat.Intake,
        CallAiSummaryFormat.Debrief,
        CallAiSummaryFormat.Sales,
      ];
    }
    const partialFormats: CallAiSummaryFormat[] = [];
    if (tldrEnabled) {
      partialFormats.push(CallAiSummaryFormat.Tldr);
    }
    if (intakeEnabled) {
      partialFormats.push(CallAiSummaryFormat.Intake);
    }
    if (debriefEnabled) {
      partialFormats.push(CallAiSummaryFormat.Debrief);
    }
    if (salesEnabled) {
      partialFormats.push(CallAiSummaryFormat.Sales);
    }
    return partialFormats;
  }, [
    allFormatsEnabled,
    tldrEnabled,
    intakeEnabled,
    debriefEnabled,
    salesEnabled,
  ]);
};
