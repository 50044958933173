import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  RequireUUIDParams as RequireUUIDParamsBase,
  RequireUUIDParamsProps,
} from "../components";
import { RequireAuth } from "./components";
import FeatureGate from "./components/FeatureGate/FeatureGate";
import { useFeatureFlagForCurrentOrImpersonatedUser } from "./graphql/hooks/useFeatureFlag";
import AnalyticsMetrics from "./pages/analytics/AnalyticsMetrics";
import AnalyticsMyAnalytics from "./pages/analytics/AnalyticsMyAnalytics";
import AnalyticsOverviewWrapper from "./pages/analytics/AnalyticsOverviewWrapper";
import AnalyticsPage from "./pages/analytics/AnalyticsPage";
import CallPage from "./pages/call/CallPage";
import CandidatePage from "./pages/candidate/CandidatePage";
import CandidateEmailOptOutPage from "./pages/candidate-opt-out/CandidateEmailOptOutPage";
import VerifyEmailPage from "./pages/candidate-opt-out/VerifyEmailPage";
import CandidatesPage from "./pages/candidates/Candidates";
import ClipPage from "./pages/clip/ClipPage";
import MyClipsPage from "./pages/clip/MyClips";
import ExternalSharePage from "./pages/external-share/ExternalSharePage";
import Forbidden from "./pages/forbidden/Forbidden";
import GuidePage from "./pages/guide/GuidePage";
import GuidePageBeta from "./pages/guide-beta/GuidePage";
import GuidesPage from "./pages/guides/GuidesPage";
import HomePage from "./pages/home/HomePage";
import InterviewOptInPage from "./pages/interview-opt-in/InterviewOptInPage";
import InterviewerOptOutPage from "./pages/interviewer-opt-out/InterviewerOptOutPage";
import NoAccessPage from "./pages/no-access/NoAccessPage";
import NotFound from "./pages/not-found/NotFoundPage";
import MyPlaylistsPage from "./pages/playlists/MyPlaylistsPage";
import PositionPage from "./pages/position/PositionPage";
import PositionsPage from "./pages/positions/PositionsPage";
import RegisterInfoPage from "./pages/register-info/RegisterInfoPage";
import VerifyPhonePage from "./pages/register-phone/RegisterPhonePage";
import SearchPage from "./pages/search/SearchPage";
import SelectPersonaPage from "./pages/select-persona/SelectPersonaPage";
import DataRedactionLog from "./pages/settings/DataRedactionSettings/DataRedactionLog";
import SettingsPage from "./pages/settings/SettingsPage";
import SignInPage from "./pages/sign-in/SignInPage";
import SignUpPage from "./pages/sign-up/SignUpPage";
import SupportPage from "./pages/support/SupportPage";
import SupportPageLegacy from "./pages/support/SupportPageLegacy";
import SyncCalendarPage from "./pages/sync-calendar/SyncCalendarPage";
import AllTraineesPage from "./pages/training/AllTraineesPage";
import ManageTrainingPage from "./pages/training/ManageTrainingPage";
import ManageTrainingRedirectPage from "./pages/training/ManageTrainingRedirectPage";
import ViewTrainingPage from "./pages/training/ViewTrainingPage";
import ViewTrainingRedirectPage from "./pages/training/ViewTrainingRedirectPage";
import UpgradePage from "./pages/upgrade/UpgradePage";
import UsersPage from "./pages/users/UsersPage";

const RequireUUIDParams: React.FC<Omit<RequireUUIDParamsProps, "element">> = (
  props
) => <RequireUUIDParamsBase element={<NotFound />} {...props} />;

export const AppRoutes = (): JSX.Element => {
  const isScoringEnabled =
    useFeatureFlagForCurrentOrImpersonatedUser("scoring:v1");
  const isSidebarNavEnabled =
    useFeatureFlagForCurrentOrImpersonatedUser("sidebar-nav");

  return (
    <Routes>
      {["/", "/schedule"].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <HomePage />
            </RequireAuth>
          }
        />
      ))}
      {[
        `/interview/:callId`,
        `/interview/:callId/add-to-training-program`,
        `/interview/:callId/share`,
        `/interview/:callId/edit`,
      ].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth hideIntercom>
              <RequireUUIDParams paramNames={["callId"]}>
                <CallPage />
              </RequireUUIDParams>
            </RequireAuth>
          }
        />
      ))}
      {[
        `/interview/:callId/clip/:clipId`,
        `/interview/:callId/clip/:clipId/share`,
      ].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth hideIntercom>
              <RequireUUIDParams paramNames={["callId", "clipId"]}>
                <ClipPage />
              </RequireUUIDParams>
            </RequireAuth>
          }
        />
      ))}
      <Route
        path="/clips"
        element={
          <RequireAuth>
            <MyClipsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/playlists"
        element={
          <RequireAuth>
            <MyPlaylistsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/playlist/:playlistId"
        element={
          <RequireAuth>
            <RequireUUIDParams paramNames={["playlistId"]}>
              <MyPlaylistsPage />
            </RequireUUIDParams>
          </RequireAuth>
        }
      />
      {["/guides", "/guides/shared", "/guides/new"].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <GuidesPage />
            </RequireAuth>
          }
        />
      ))}
      <Route
        path="guides/assigned"
        element={
          <RequireAuth>
            <FeatureGate flag="assign-guides-interviewers:v1">
              <GuidesPage />
            </FeatureGate>
          </RequireAuth>
        }
      />
      <Route
        path="/guide/:callGuideId"
        element={
          <RequireAuth>
            <RequireUUIDParams paramNames={["callGuideId"]}>
              {isScoringEnabled ? <GuidePageBeta /> : <GuidePage />}
            </RequireUUIDParams>
          </RequireAuth>
        }
      />
      {["/positions", "/positions/mine", "/positions/new"].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <PositionsPage />
            </RequireAuth>
          }
        />
      ))}
      {[
        "/position/:positionId",
        "/position/:positionId/candidates",
        "/position/:positionId/guides",
        "/position/:positionId/faqs",
      ].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <RequireUUIDParams paramNames={["positionId"]}>
                <PositionPage />
              </RequireUUIDParams>
            </RequireAuth>
          }
        />
      ))}
      <Route
        path="/candidates"
        element={
          <RequireAuth>
            <CandidatesPage />
          </RequireAuth>
        }
      />
      {[
        "/candidate/:candidateId",
        "/candidate/:candidateId/:positionId",
        "/candidate/:candidateId/position/none",
        "/candidate/:candidateId/position/:positionId",
      ].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <RequireUUIDParams paramNames={["candidateId", "positionId"]}>
                <CandidatePage />
              </RequireUUIDParams>
            </RequireAuth>
          }
        />
      ))}
      <Route
        path="/training/manage"
        element={
          <RequireAuth>
            <ManageTrainingRedirectPage />
          </RequireAuth>
        }
      />
      <Route
        path="/training/manage/all-trainees"
        element={
          <RequireAuth>
            <AllTraineesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/training/view"
        element={
          <RequireAuth>
            <ViewTrainingRedirectPage />
          </RequireAuth>
        }
      />
      <Route
        path="/training/manage/:trainingProgramId"
        element={
          <RequireAuth>
            <RequireUUIDParams paramNames={["trainingProgramId"]}>
              <ManageTrainingPage />
            </RequireUUIDParams>
          </RequireAuth>
        }
      />
      <Route
        path="/training/view/:trainingProgramId"
        element={
          <RequireAuth>
            <RequireUUIDParams paramNames={["trainingProgramId"]}>
              <ViewTrainingPage />
            </RequireUUIDParams>
          </RequireAuth>
        }
      />
      <Route path="/analytics/:metric" element={<InsightsPageRedirect />} />
      <Route path="/analytics" element={<InsightsPageRedirect />} />
      <Route
        path="/insights"
        element={
          <RequireAuth>
            <AnalyticsPage />
          </RequireAuth>
        }
      >
        <Route index element={<Navigate to="overview" replace />} />
        <Route path="overview" element={<AnalyticsOverviewWrapper />} />
        <Route path="my-insights" element={<AnalyticsMyAnalytics />} />
        <Route path=":metric" element={<AnalyticsMetrics />} />
      </Route>
      <Route path="/users/*" element={<UsersPageRedirect />} />
      <Route
        path="/interviewer-opt-out/:interviewerId"
        element={
          <RequireAuth>
            <RequireUUIDParams paramNames={["interviewerId"]}>
              <InterviewerOptOutPage />
            </RequireUUIDParams>
          </RequireAuth>
        }
      />
      <Route path="/candidate-opt-out/:orgId" element={<VerifyEmailPage />} />
      <Route
        path="/candidate-opt-out/:orgId/:verificationCode"
        element={<CandidateEmailOptOutPage />}
      />
      <Route path="/launch/interview" element={<InterviewOptInPage />} />
      <Route path="/launch/interview/*" element={<InterviewOptInPage />} />
      <Route
        path="/external-share/:shareType/:shareId"
        element={<ExternalSharePage />}
      />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route
        path="/register-info"
        element={
          <RequireAuth>
            <RegisterInfoPage />
          </RequireAuth>
        }
      />
      <Route
        path="/select-persona"
        element={
          <RequireAuth>
            <SelectPersonaPage />
          </RequireAuth>
        }
      />
      <Route
        path="/sync-calendar"
        element={
          <RequireAuth>
            <SyncCalendarPage />
          </RequireAuth>
        }
      />
      <Route
        path="/register-phone"
        element={
          <RequireAuth>
            <VerifyPhonePage />
          </RequireAuth>
        }
      />
      <Route
        path="/search"
        element={
          <RequireAuth>
            <SearchPage />
          </RequireAuth>
        }
      />
      <Route
        path="/settings/*"
        element={
          <RequireAuth>
            <SettingsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/data-redaction/log"
        element={
          <RequireAuth>
            <DataRedactionLog />
          </RequireAuth>
        }
      />
      <Route
        path="/support"
        element={
          <RequireAuth>
            {isSidebarNavEnabled ? <SupportPage /> : <SupportPageLegacy />}
          </RequireAuth>
        }
      />
      <Route
        path="/not-found"
        element={
          <RequireAuth>
            <NotFound />
          </RequireAuth>
        }
      />
      <Route
        path="/upgrade"
        element={
          <RequireAuth>
            <UpgradePage />
          </RequireAuth>
        }
      />
      <Route
        path="/unauthorized"
        element={
          <RequireAuth>
            <NoAccessPage />
          </RequireAuth>
        }
      />
      <Route
        path="/forbidden"
        element={
          <RequireAuth>
            <Forbidden />
          </RequireAuth>
        }
      />
      <Route
        path="*"
        element={
          <RequireAuth>
            <NotFound />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

/**
 * If using the new sidebar nav, redirect all `/users/*` pages
 * to `/settings/users/*`
 */
const UsersPageRedirect = (): JSX.Element => {
  const { pathname } = useLocation();
  const isSidebarNavEnabled =
    useFeatureFlagForCurrentOrImpersonatedUser("sidebar-nav");

  return (
    <RequireAuth>
      {isSidebarNavEnabled ? (
        <Navigate to={`/settings${pathname}`} replace />
      ) : (
        <UsersPage />
      )}
    </RequireAuth>
  );
};

const InsightsPageRedirect = (): JSX.Element => {
  const { metric = "" } = useParams();
  const [searchParams] = useSearchParams();
  return (
    <RequireAuth>
      <Navigate
        replace
        to={`/insights/${metric}${
          searchParams ? `?${searchParams as unknown as string}` : ""
        }`}
      />
    </RequireAuth>
  );
};
