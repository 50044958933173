import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { GoDotFill } from "react-icons/go";

import CopyOutlineIcon from "../../../../../components/Icons/CopyOutlineIcon";
import { EmptyAIIcon } from "../../../../../components/Images";
import { copy } from "../../../../../utils/clipboard";
import { formatDuration } from "../../../../../utils/datetime";
import { useSendGAEvent } from "../../../../../utils/googleAnalytics";
import {
  CallAiSummaryProcessingStatus,
  CallAiSummaryQuery,
  useUpdateCallAiSummaryFormatMutation,
} from "../../../../graphql";
import { noteTimestampedLink } from "../../../CallNotes/utils";
import AiNotesLoading from "../../../Interview/beta/AiNotesLoading";
import AiNotesMessage from "../../../Interview/beta/AiNotesMessage";
import MonospacedText from "../../../MonospacedText";
import { AiSummaryLabels } from "./ai_summary_ui";
import { AiTldr } from "./AiTldr";

type SummaryHeader = NonNullable<
  NonNullable<CallAiSummaryQuery["callAiSummary"]>["headers"]
>[0];

const AISummary: React.FC<{
  data: CallAiSummaryQuery;
  callId: string;
  onClickTimestamp(t: number): void;
  loading: boolean;
  onRetry(): void;
}> = ({ data, callId, onClickTimestamp, loading, onRetry }) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const [update, updateSummaryData] = useUpdateCallAiSummaryFormatMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error generating summary",
        status: "error",
        position: "top",
      });
    },
  });

  if (
    [
      "3ca685ab-6334-4010-b396-0e34936ac796",
      "34276b99-67ed-46dc-b585-91d9a60c2640",
      "95d82c8b-f7d7-458e-9260-767573772f29",
    ].includes(callId)
  ) {
    return <AiTldr callId={callId} />;
  }

  if (
    loading ||
    (data.callAiSummary?.status !== CallAiSummaryProcessingStatus.Completed &&
      data.callAiSummary?.status !== CallAiSummaryProcessingStatus.Failed)
  ) {
    return <AiNotesLoading loadingText="Generating summary" />;
  }
  if (data.callAiSummary?.status === CallAiSummaryProcessingStatus.Failed) {
    return (
      <AiNotesMessage>
        <EmptyAIIcon marginBottom="64px" />
        <Text align="center" maxW="360px">
          Something went wrong generating your summary.
        </Text>
        {data?.callAiSummary?.format && data?.callAiSummary?.targetSpeakerTags && (
          <Button
            variant="solid"
            isLoading={updateSummaryData.loading}
            onClick={() => {
              if (
                data?.callAiSummary?.format &&
                data?.callAiSummary?.targetSpeakerTags
              ) {
                update({
                  variables: {
                    callId,
                    format: data.callAiSummary.format,
                    targetSpeakerTags: data.callAiSummary.targetSpeakerTags,
                  },
                }).then(() => {
                  onRetry();
                });
              }
            }}
            my={4}
          >
            Retry?
          </Button>
        )}
      </AiNotesMessage>
    );
  }
  const totalNotes = data.callAiSummary?.headers?.reduce(
    (acc, header) => acc + (header.notes?.length || 0),
    0
  );
  if (totalNotes === 0) {
    return (
      <AiNotesMessage>
        <EmptyAIIcon marginBottom="64px" />
        <Text align="center" maxW="360px">
          No relevant notes were found for this AI Notes format
        </Text>
      </AiNotesMessage>
    );
  }

  // Create a time-ordered mapping to footnote numbers
  const footnoteMap: { [key: number]: number } = {};
  const uniqueTimestamps: Set<number> = new Set();
  data.callAiSummary?.headers?.forEach((header) => {
    header.notes?.forEach((note) => {
      note.startTimes.forEach((t) => {
        uniqueTimestamps.add(t as number);
      });
    });
  });
  const orderedTimestamps = Array.from<number>(uniqueTimestamps).sort(
    (a, b) => a - b
  );
  orderedTimestamps.forEach((t: number, idx) => {
    footnoteMap[t] = idx + 1;
  });

  const formatHeader = (
    header: SummaryHeader
  ): { plain: string; html: string } | null => {
    const notes = header.notes || [];
    if (notes.length === 0) {
      return null;
    }

    let plain = `${header.text}\n\n`;
    let html = `<b>${header.text}</b><br><ul>`;

    notes.forEach((note) => {
      plain += `- ${note.text ?? ""}\n`;
      html += "<li>";
      html += `${note.text} `;
      html += note.startTimes
        .map(
          (startTime) =>
            `[<a href="${noteTimestampedLink({
              callId,
              time: note.startTimes[0] ?? 0,
            })}">${footnoteMap[startTime ?? 0]}</a>]`
        )
        .join(" ");
      html += "</li>";
    });
    html += "</ul><br>";
    return { plain, html };
  };

  const copyNote = (header: SummaryHeader): void => {
    const result = formatHeader(header);
    if (!result) {
      return;
    }
    copy({ plain: result.plain, html: result.html });
    toast({
      title: "Section copied to clipboard",
      status: "success",
    });
    sendGAEvent("ai_summary_copy_section", "call_review");
  };

  const copyNotes = (): void => {
    const headers = data.callAiSummary?.headers || [];
    if (headers.length === 0) {
      return;
    }
    let plain = "";
    let html = "";

    headers.forEach((header) => {
      const result = formatHeader(header);
      if (!result) {
        return;
      }
      plain += result.plain;
      html += result.html;
    });
    copy({ plain, html });
    toast({
      title: "Notes copied to clipboard",
      status: "success",
    });
    sendGAEvent("ai_summary_copy_all", "call_review");
  };

  const headers = data.callAiSummary?.headers?.map((header) => {
    return (
      <Box mb={4} fontSize="sm" key={header.id} px="4">
        <Flex
          py="10px"
          pl={4}
          mb={4}
          bg="gray.50"
          borderRadius="sm"
          flexDir="row"
          alignItems="center"
          position="relative"
          role="group"
        >
          <Text as="span" fontWeight="medium">
            {header.text}
          </Text>
          <Tooltip label="Copy section notes" placement="bottom-start">
            <Button
              size="xs"
              height={4}
              fontSize="sm"
              fontWeight="medium"
              variant="ghost"
              colorScheme="blue"
              ml="auto"
              leftIcon={<CopyOutlineIcon width="16px" height="16px" />}
              onClick={() => copyNote(header)}
              display="none"
              _hover={{ bg: "transparent" }}
              _groupHover={{ display: "inline-block" }}
            />
          </Tooltip>
        </Flex>
        <Box color="gray.700">
          <UnorderedList listStyleType="none" ml={0}>
            {header.notes?.map((note) => {
              return (
                <SummaryNote
                  id={note.id}
                  callId={callId}
                  onClickTimestamp={onClickTimestamp}
                  text={note.text}
                  startTimes={
                    note.startTimes.filter((t) => t !== null) as number[]
                  }
                  key={note.id}
                  footnoteMap={footnoteMap}
                />
              );
            })}
          </UnorderedList>
        </Box>
      </Box>
    );
  });
  return (
    <Box fontSize="sm" px={{ base: "1px" }} pt={1}>
      <Flex mb={6} mt={4} ml={4} alignItems="center">
        <Text fontWeight="medium" ml={2}>
          {AiSummaryLabels[data.callAiSummary.format]}
        </Text>
        <Button
          py={4}
          size="xs"
          fontSize="sm"
          fontWeight="medium"
          variant="ghost"
          colorScheme="blue"
          ml="auto"
          mr={4}
          leftIcon={<CopyOutlineIcon width="16px" height="16px" />}
          onClick={copyNotes}
        >
          Copy all notes
        </Button>
      </Flex>
      {headers}
    </Box>
  );
};

type NoteProps = {
  id: string;
  callId: string;
  onClickTimestamp(t: number): void;
  text: string;
  startTimes: number[];
  footnoteMap: { [key: number]: number };
};

const SummaryNote: React.FC<NoteProps> = ({
  id,
  callId,
  onClickTimestamp,
  text,
  startTimes,
  footnoteMap,
}) => {
  return (
    <Box
      as={ListItem}
      key={id}
      _hover={{
        bg: "blue.50",
      }}
    >
      <Flex direction="row" align="baseline" cursor="pointer">
        <Icon as={GoDotFill} boxSize={2} ml={4} mr="6px" flexGrow="0" />
        <Text as="span" py={2}>
          <Text as="span" pr={1}>
            {text}
          </Text>
          <Text as="span" verticalAlign="top" fontSize="xs">
            {startTimes.map((startTime, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={idx}>
                {idx > 0 && " "}
                <Tooltip label={formatDuration(Math.round(startTime))}>
                  <Link
                    fontWeight="normal"
                    href={`/interview/${callId}?t=${startTime}`}
                    onClick={(e) => {
                      e.preventDefault();
                      onClickTimestamp(startTime);
                    }}
                    pt={2}
                  >
                    <MonospacedText
                      text={`[${footnoteMap[startTime].toString()}]`}
                    />
                  </Link>
                </Tooltip>
              </React.Fragment>
            ))}
          </Text>
        </Text>
      </Flex>
    </Box>
  );
};

export default AISummary;
