export const escapeHtml = (unsafe: string): string => {
  return unsafe
    .replaceAll("&", "&amp;")
    .replaceAll("<", "&lt;")
    .replaceAll(">", "&gt;")
    .replaceAll('"', "&quot;")
    .replaceAll("'", "&#039;");
};

// Escapes algolia search results used in dangerouslySetInnerHtml
export const escapeSearchResults = (hit: string): string => {
  const reg = /<[^>]*>/g;
  const matches = hit.match(reg);
  let output = hit;
  matches?.forEach((match) => {
    if (match === "<mark>" || match === "</mark>") return;
    output = output.replace(match, escapeHtml(match));
  });
  return output;
};
