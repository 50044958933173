import { Button, Flex, Tooltip } from "@chakra-ui/react";
import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";

import {
  GreenhouseIcon,
  LeverIcon,
  SmartrecruitersIcon,
  useTheme,
} from "../../../../components";

type DisplayLink = {
  href: string;
  tooltipLabel: string;
};

export type ATSLinks = {
  greenhouse?: DisplayLink;
  lever?: DisplayLink;
  linkedIn?: DisplayLink;
  smartRecruiters?: DisplayLink;
  custom?: DisplayLink;
};

const LinkButton: React.FC<{
  link: DisplayLink;
  label: string;
  testId?: string;
  icon: React.ReactElement;
}> = ({ link: { href, tooltipLabel }, label, testId, icon }) => (
  <Tooltip label={tooltipLabel} openDelay={300}>
    <Button
      as="a"
      href={href}
      target="_blank"
      data-testid={testId}
      aria-label={label}
      size="xs"
      variant="chalk"
      color="blue.600"
      leftIcon={icon}
      iconSpacing="0"
      p="0"
      mr="1"
    />
  </Tooltip>
);

const CandidateLinks: React.FC<ATSLinks> = ({
  greenhouse,
  lever,
  linkedIn,
  smartRecruiters,
  custom,
}) => {
  const { colors } = useTheme();
  const iconProps = {
    marginRight: "0",
    fill: colors.blue[600],
  };

  return (
    <Flex color="blue.600">
      {greenhouse && (
        <LinkButton
          link={greenhouse}
          label="Greenhouse"
          testId=""
          icon={<GreenhouseIcon {...iconProps} width="10" height="20" />}
        />
      )}
      {lever && (
        <LinkButton
          link={lever}
          label="Lever"
          testId=""
          icon={<LeverIcon {...iconProps} />}
        />
      )}
      {linkedIn && (
        <LinkButton
          link={linkedIn}
          label="LinkedIn"
          testId=""
          icon={<FaLinkedin size="20" />}
        />
      )}
      {smartRecruiters && (
        <LinkButton
          link={smartRecruiters}
          label="SmartRecruiters"
          testId=""
          icon={<SmartrecruitersIcon {...iconProps} width="16" height="20" />}
        />
      )}
      {custom && (
        <LinkButton
          link={custom}
          label="ATS"
          testId=""
          icon={<FiExternalLink size="19" />}
        />
      )}
    </Flex>
  );
};

export default CandidateLinks;
