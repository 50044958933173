import { Checkbox, HStack, Link, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

import { SortableTable } from "../../../components";
import { formatRelativeDate } from "../../../utils/datetime";
import { buildLeverLink } from "../../../utils/lever";
import { Position, PositionListItemFragment } from "../../graphql";

interface AutoJoinByPositionListProps {
  positions: Array<PositionListItemFragment>;
  updatePositionsAutoJoin: (
    positionIds: Array<PositionListItemFragment>,
    onOff: boolean
  ) => void;
}

const AutoJoinByPositionList: React.FC<AutoJoinByPositionListProps> = ({
  positions,
  updatePositionsAutoJoin,
}) => {
  return (
    <SortableTable<Position>
      columns={[
        {
          Header: "Title",
          accessor: "title",
          id: "title",
          Cell: ({
            row: { original: position },
          }: {
            row: { original: PositionListItemFragment };
          }) => {
            let positionLink;
            if (position.leverPostingId) {
              positionLink = buildLeverLink(position.leverPostingId);
            }
            if (position.greenhouseLink) {
              positionLink = position.greenhouseLink;
            }
            return (
              <Link
                href={positionLink}
                display="flex"
                alignItems="center"
                target="_blank"
              >
                {position.title}
              </Link>
            );
          },
        },
        {
          Header: "Position Group",
          accessor: (position) => position.client.name,
          id: "client.name",
        },
        {
          Header: "Status",
          accessor: "atsStatus",
        },
        {
          Header: "Date Added",
          accessor: "createdAt",
          Cell: ({
            row: { original: position },
          }: {
            row: { original: PositionListItemFragment };
          }) => <>{formatRelativeDate(position.createdAt)}</>,
        },
        {
          Header: "Positions to Auto Join",
          accessor: "autoJoinInterview",
          Cell: ({
            row: { original: position },
          }: {
            row: { original: PositionListItemFragment };
          }) => (
            <HStack align="baseline" spacing="3">
              <Checkbox
                id={`auto-join-check-${position.id}`}
                alignSelf="center"
                isChecked={position.autoJoinInterview}
                onChange={() => {
                  updatePositionsAutoJoin(
                    [position],
                    !position.autoJoinInterview
                  );
                }}
              />
              {position.autoJoinInterview && (
                <Tooltip label="Except for users exluded from Auto Join">
                  <Text fontSize="sm">Join all video interviews*</Text>
                </Tooltip>
              )}
            </HStack>
          ),
        },
      ]}
      data={positions as Position[]}
      initialSort={{ id: "title", desc: false }}
    />
  );
};

export default AutoJoinByPositionList;
