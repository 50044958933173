import { Box, Center } from "@chakra-ui/react";
import React from "react";

import {
  LoadingIndicator,
  RouterLink,
  SortableTable,
  SortableTableColumn,
} from "../../../components";
import useCurrentBreakpoint from "../../../hooks/useCurrentBreakpoint";
import { PageOptions } from "../../../hooks/useOffsetPagination";
import { formatRelativeDate } from "../../../utils/datetime";
import { CandidatePageListItemFragment } from "../../graphql";

const largeScreenBreakpoints = ["lg", "xl", "2xl"];
const smallScreenBreakpoints = ["base", "sm"];

export type CandidateListItem = CandidatePageListItemFragment;
type TableRow = { row: { original: CandidateListItem } }; // TS Fix for react-table

interface CandidatesListProps {
  candidates: CandidateListItem[];
  pageOptions: PageOptions;
  loading?: boolean;
}

const CandidatesList: React.FC<CandidatesListProps> = ({
  candidates,
  pageOptions,
  loading,
}) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const shouldShowCompactView =
    smallScreenBreakpoints.includes(currentBreakpoint);
  const { sortBy } = pageOptions;

  if (candidates.length === 0 && !loading) {
    return (
      <Box bgColor="gray.50" mt={4} borderRadius={4}>
        <Center fontSize="sm" color="gray.600" p={10}>
          There are no candidates to show here.
        </Center>
      </Box>
    );
  }

  const columns: SortableTableColumn<CandidateListItem>[] = [
    {
      Header: "Candidate Name",
      accessor: "fullName",
      id: "fullName",
      Cell: ({ row: { original: candidate } }) => (
        <RouterLink to={`/candidate/${candidate.id}`}>
          {candidate.fullName || (
            <Box whiteSpace="nowrap">
              {candidate.defaultPhoneNumber || "Candidate"}
            </Box>
          )}
        </RouterLink>
      ),
    },
    {
      Header: "Interviews",
      accessor: "callCount",
      show: !shouldShowCompactView,
      id: "callCount",
    },
    {
      Header: "Position (Last Interview)",
      accessor: (candidate) => candidate.lastCall?.position?.displayTitle,
      showEmpty: false,
      id: "lastCall.position.displayTitle",
    },
    {
      Header: "Last Interview",
      accessor: (candidate) => candidate.lastCall?.name,
      show: !shouldShowCompactView,
      id: "lastCallName",
    },
    {
      Header: "Last Interview Date",
      accessor: (candidate) => candidate.lastCall?.createdAt,
      id: "lastCall.createdAt",
      Cell: ({ row: { original: candidate } }: TableRow) => (
        <>
          {(shouldShowCompactView ? "Last Interview " : "") +
            formatRelativeDate(candidate.lastCall?.createdAt)}
        </>
      ),
    },
  ];

  const showLoading = shouldShowCompactView && loading;
  return (
    <Box mt={{ base: 4, md: 0 }}>
      {showLoading && <LoadingIndicator mt="16" />}
      {!showLoading && (
        <SortableTable
          columns={columns}
          loading={false}
          data={candidates}
          initialSort={sortBy}
          width="100%"
          manualPagination
          pageOptions={pageOptions}
          includeSpacerColumn={largeScreenBreakpoints.includes(
            currentBreakpoint
          )}
          shouldShowCompactView={shouldShowCompactView}
        />
      )}
    </Box>
  );
};

CandidatesList.displayName = "CandidatesList";
export default CandidatesList;
