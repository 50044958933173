import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";

import CopyOutlineIcon from "../../../../../components/Icons/CopyOutlineIcon";
import { copy } from "../../../../../utils/clipboard";

export const AiTldr: React.FC<{
  callId: string;
}> = ({ callId }) => {
  const toast = useToast();

  const [selectedSections, setSelectedSections] = useState<Set<string>>(
    new Set(["Background", "Action Items"])
  );

  const data = fakeData[callId];
  if (!data) {
    return "Call is not configured for TLDR prototype";
  }

  const sections = Object.keys(data).filter((s) => selectedSections.has(s));

  const fullText = sections.map((section) => data[section]).join(" ");

  const copyNotes = (): void => {
    copy({ plain: fullText, html: fullText });
    toast({
      title: "Notes copied to clipboard",
      status: "success",
    });
  };

  return (
    <Box fontSize="sm" px={7} py={7}>
      <Flex alignItems="center">
        <Text fontWeight="medium" ml={2}>
          Short interview summary
        </Text>
        <Button
          py={4}
          size="xs"
          fontSize="sm"
          fontWeight="medium"
          variant="ghost"
          colorScheme="blue"
          ml="auto"
          mr={4}
          leftIcon={<CopyOutlineIcon width="16px" height="16px" />}
          onClick={copyNotes}
        >
          Copy
        </Button>
      </Flex>
      <Box mb={4} mt={4}>
        {Object.keys(data).map((section) => (
          <Box
            display="inline-block"
            bg={selectedSections.has(section) ? "gray.50" : "white"}
            border="1px solid"
            borderColor="gray.200"
            _hover={{
              bg: !selectedSections.has(section) ? "blue.100" : "",
            }}
            borderRadius="md"
            cursor="pointer"
            fontSize="sm"
            px={2}
            py={1}
            mr={2}
            my={1}
            onClick={() => {
              const newSet = new Set(selectedSections);
              if (selectedSections.has(section)) {
                newSet.delete(section);
              } else {
                newSet.add(section);
              }
              setSelectedSections(newSet);
            }}
          >
            {section}
          </Box>
        ))}
      </Box>
      <Box>
        <Text display="inline">{fullText}</Text>
      </Box>
    </Box>
  );
};

const fakeData = {
  "3ca685ab-6334-4010-b396-0e34936ac796": {
    Accomplishments:
      "With a strong track record in building and expanding customer success teams at companies like HireVue, Pluralsight, and BetterUp, the candidate has successfully implemented customer success planning, set up key milestones, and empowered champions in their roles. They have demonstrated their ability to manage commercial conversations and renewals as a Customer Success Manager (CSM) and have experience in account management.",
    "Action Items":
      "Currently, the candidate is involved in hiring and onboarding his replacement at BetterUp. The recruiter is tasked with providing more information about the company's go-to-market group and setting up another call to delve deeper into the candidate's experience.",
    Background:
      "With a diverse background that includes a degree in musical theater, an MBA, and over a decade of experience in customer success roles in SaaS and HR tech startups, the candidate has worked at companies like HireVue, Pluralsight, BetterUp, and LinkedIn. Their experience includes building customer support and onboarding teams, customer success planning, onboarding, and adoption.",
    "Candidate Concerns":
      "Moving back to Salt Lake City, Utah due to his wife's preference, the candidate has expressed a preference for higher touch, person-to-person engagement roles over a support center or call center environment.",
    Compensation: "Compensation details were not discussed during the call.",
    Motivations:
      "Open to external discussions due to limited management openings at BetterUp, the candidate is motivated by building the pillars of practice, strategizing with customers, ensuring customer adoption, and post-sales revenue ownership. They also expressed interest in the company's use of AI for workflow efficiency and process improvement.",
    "Candidate Questions":
      "The candidate inquired about the company's preferred stage or environment, the current state and size of the company's go-to-market group, the company's business model and growth prospects, and the company's use of AI.",
    "Candidate Job Search Status":
      "Exploring both internal options at BetterUp and external opportunities, the candidate is interested in roles in companies that are between Series B to D, where they can help make processes repeatable, and roles that involve post-sales revenue ownership.",
    "Work Location":
      "Currently based in the UK but will be relocating to Salt Lake City, Utah, the candidate prefers to have calls at 9:00 PM UK time, which is 4:00 PM Eastern Time.",
  },
  "95d82c8b-f7d7-458e-9260-767573772f29": {
    Accomplishments:
      "With a strong background in customer service and technical troubleshooting, the candidate has experience in high-pressure situations, different communication mediums, and software integrations. They have showcased skills in account management, problem-solving, and time management at companies like Zillow and Dandelion.",
    "Action Items":
      "Although no specific action items were discussed during the call, the recruiter plans to be in touch by Friday with next steps.",
    Background:
      "Having a diverse background in customer service roles, the candidate has worked in fast-paced environments like major events and startups. Their technical experience includes Salesforce, HubSpot, JIRA, and Slack, and they have held account management and customer support roles at Zillow and Dandelion.",
    "Candidate Concerns":
      "Concerns were expressed by the candidate about understanding the team's work style, maintaining work-life boundaries in a role with flexible work hours, and handling troubleshooting issues related to software integrations.",
    Compensation: "During the call, compensation was not discussed.",
    Motivations:
      "Change, learning new systems, being part of a fast-paced, growing company, and contributing to innovative solutions motivate the candidate. They value team introductions, a supportive and lighthearted work culture, and are seeking longevity and brand loyalty in their next role.",
    "Candidate Questions":
      "Questions were asked by the candidate about the software used for chat and ticketing, the company's process of documenting issues and creating tickets, the approach to flexible work hours, and the process for handling troubleshooting issues related to software integrations. They also inquired about the workflow from the time a company signs on to use BrightHire and the recruiter's favorite parts about working for BrightHire.",
    "Candidate Job Search Status":
      "After being laid off from their previous role at Dandelion, the candidate is currently job searching and is in the interview process with BrightHire.",
    "Work Location":
      "The candidate, currently located in Cincinnati, Ohio, operating on Eastern Time, has experience working in both physical offices and fully remote settings. No specific work location preferences were discussed.",
  },
  "34276b99-67ed-46dc-b585-91d9a60c2640": {
    Accomplishments:
      "Having built processes at Prelude and Bright Wheel, the candidate boasts a strong background in Customer Success. They have a proven ability to turn neutral or detractor customers into champions, and have demonstrated creative problem-solving skills and the ability to scale customers and products effectively.",
    "Action Items":
      "Next steps in the hiring process will be followed up by the recruiter with the candidate.",
    Background:
      "Starting in office management, transitioning into finance, and then moving into SaaS, the candidate has a diverse background. They have worked at various companies including Animation Mentor, Bright Wheel, and Greenhouse, and have been consulting for early-stage seed companies.",
    "Candidate Concerns":
      "Curiosity was expressed by the candidate about the size and structure of the Customer Success team at BrightHire, the nature of cross-functional relationships, and the company's approach to sharing information about new releases.",
    Compensation: "During the call, compensation was not discussed.",
    Motivations:
      "The opportunity to work in HR tech, the agility of a startup environment, and problem-solving and building strong customer relationships are what motivate the candidate. They are also attracted to companies that are efficient and do not overwork their employees.",
    "Candidate Questions":
      "Questions were asked by the candidate about the size and structure of the Customer Success team, the size of the book of business for the role, the distribution of accounts, the company's growth, and how information about new releases is shared.",
    "Candidate Job Search Status":
      "After a sabbatical and part-time consulting work, the candidate is actively job searching. However, they expressed that the Implementation Manager role at BrightHire seemed more junior than what they were looking for.",
    "Work Location":
      "No preferences regarding work location were expressed by the candidate.",
  },
};
