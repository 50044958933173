import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useState } from "react";

import {
  Avatar,
  RouterLink,
  SortableTable,
  useToast,
} from "../../../components";
import { formatDate } from "../../../utils/datetime";
import UserSelect from "../../components/UserSelect";
import { useUpdateCallGuideAssignedUsersMutation } from "../../graphql";
import useLink from "../../hooks/useLink";
import { CallGuide, CallGuideUser } from "./types";

interface AssignedUsersProps {
  callGuide: CallGuide;
}

const AssignedUsers: React.FC<AssignedUsersProps> = ({ callGuide }) => {
  const toast = useToast();
  const [updateCallGuideAssignedUsers] =
    useUpdateCallGuideAssignedUsersMutation({
      onCompleted: () => {
        toast({
          title: "Success",
          status: "success",
          description: `Assigned interviewers updated successfully`,
        });
      },
    });
  const [userIds, setUserIds] = useState<string[]>([]);
  const onAdd = (): void => {
    updateCallGuideAssignedUsers({
      variables: {
        id: callGuide.id,
        assignedUserIds: [
          ...callGuide.assignedUsers.map((cgu) => cgu.userId),
          ...userIds,
        ],
      },
      onCompleted() {
        setUserIds([]);
      },
    });
  };

  return (
    <Flex mx={8} direction="column">
      <Flex direction="column">
        <Heading fontSize="xl" fontWeight="500" mb="4" mt="3">
          Add user
        </Heading>
        <Flex width="100%" alignItems="center">
          <UserSelect
            userIds={userIds}
            onSelect={setUserIds}
            customStyles={{
              container: (provided: Record<string, any>) => {
                return { ...provided, flex: 1 };
              },
              control: (provided: Record<string, any>) => {
                return {
                  ...provided,
                  borderRadius: "6px",
                  borderColor: "gray.200",
                };
              },
            }}
            idsToExclude={callGuide.assignedUsers.map((cgu) => cgu.userId)}
            placeholder="Find interviewer"
          />
          <Button
            variant="solid"
            size="sm"
            onClick={onAdd}
            ml="4"
            fontWeight="500"
            height="38px"
          >
            Assign
          </Button>
        </Flex>
      </Flex>
      <Flex mt="6" borderBottom="1px" borderBottomColor="gray.200" />
      <Heading fontSize="xl" fontWeight="500" mt="6" mb="4">
        Assigned interviewers
      </Heading>
      {!callGuide.assignedUsers.length && (
        <Text fontSize="sm" fontWeight="400" color="gray.600">
          No interviewers assigned yet. Assign this guide to interviewers. They
          will automatically see this guide in the interview assistant when
          interviewing with BrightHire.
        </Text>
      )}
      {callGuide.assignedUsers.length > 0 && (
        <Flex mb="8">
          <SortableTable
            data={callGuide.assignedUsers}
            initialSort={{ id: "Name", desc: false }}
            columns={[
              {
                Header: "Name",
                accessor: (cgu: CallGuideUser) => cgu.user?.lastName,
                Cell: ({
                  row: { original: cgu },
                }: {
                  row: { original: CallGuideUser };
                }) => (
                  <Flex color="gray.800" alignItems="center">
                    <Avatar user={cgu?.user} showTooltip mr="3" />
                    <Text>{cgu?.user?.fullName}</Text>
                  </Flex>
                ),
              },
              {
                Header: "Email",
                Cell: ({
                  row: { original: cgu },
                }: {
                  row: { original: CallGuideUser };
                }) => (
                  <RouterLink
                    color="blue.600"
                    fontWeight="400"
                    to={useLink({
                      type: "userInfo",
                      userId: cgu?.user?.id,
                    })}
                  >
                    {cgu?.user?.email}
                  </RouterLink>
                ),
              },
              {
                Header: "Date Assigned",
                Cell: ({
                  row: { original: cgu },
                }: {
                  row: { original: CallGuideUser };
                }) => {
                  return (
                    <Flex alignItems="center" justifyContent="space-between">
                      <Box color="gray.800">{formatDate(cgu.createdAt)}</Box>
                      <RouterLink
                        color="blue.600"
                        fontWeight="400"
                        to="#"
                        mr="2"
                        onClick={() => {
                          updateCallGuideAssignedUsers({
                            variables: {
                              id: callGuide.id,
                              assignedUserIds: callGuide.assignedUsers
                                .map((cgu) => cgu.userId)
                                .filter((id) => id !== cgu.userId),
                            },
                          });
                        }}
                      >
                        Unassign
                      </RouterLink>
                    </Flex>
                  );
                },
              },
            ]}
            width="100%"
          />
        </Flex>
      )}
    </Flex>
  );
};

export default AssignedUsers;
