import { Box } from "@chakra-ui/react";
import React from "react";
import { HiOutlineBookOpen, HiOutlineCog } from "react-icons/hi";
import { IoCut, IoVideocam } from "react-icons/io5";
import { PiListMagnifyingGlass } from "react-icons/pi";
import { Location, useNavigate } from "react-router-dom";

import { BHPlaylistIcon, PageHeaderBase } from "../../../components";
import { NavLinkItem } from "../../../components/PageHeader/types";
import { SearchBox } from "../../../components/Search";
import theme from "../../../theme";
import {
  canManageTraining,
  canViewAllMetrics,
} from "../../../utils/permissions";
import {
  CurrentUserFragment,
  useSignOutMutation,
  useUnimpersonateMutation,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import CallInProgressBanner from "../CallInProgressBanner";
import { DrawerState } from "../SearchDrawer";

interface PageHeaderProps {
  currentUser?: CurrentUserFragment;
  showSearchBox?: boolean;
  drawerState?: DrawerState;
  showSignOutDuringSignup?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  currentUser,
  showSearchBox = false,
  drawerState = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onOpen: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClose: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onToggle: () => {},
    isOpen: false,
  },
  showSignOutDuringSignup = false,
}) => {
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = drawerState;
  const useCandidateRedesign = useFeatureFlag("candidates:redesign");
  const planPOCEndabled = useFeatureFlag("plan-poc");
  const [signOut] = useSignOutMutation({
    onCompleted: () => {
      window.location.href = "/sign-in";
    },
  });
  const [unimpersonate] = useUnimpersonateMutation({
    onCompleted: (data) => {
      window.location.href = data.unimpersonate?.redirectTo ?? "/admin/users";
    },
    onError: () => {
      window.location.href = "/";
    },
  });
  const onSignOut = (): void => {
    if (currentUser?.isImpersonated) {
      unimpersonate();
      return;
    }
    signOut();
  };

  const navLinks: NavLinkItem[] = [];
  const interviewLinks = [
    {
      label: "Full Interviews",
      mobileLabel: "Full Interviews",
      path: "/search",
      icon: IoVideocam,
    },
    {
      label: "My Clips",
      mobileLabel: "My Clips",
      path: "/clips",
      icon: IoCut,
    },
    {
      label: "My Playlists",
      mobileLabel: "My Playlists",
      path: "/playlists",
      icon: BHPlaylistIcon,
    },
  ];

  if (currentUser) {
    navLinks.push({
      type: "menu",
      label: "Interviews",
      items: interviewLinks,
      isActive: (location: Location) =>
        location.pathname.startsWith("/search") ||
        location.pathname.startsWith("/clips") ||
        location.pathname.startsWith("/playlist") ||
        location.pathname.startsWith("/interview"),
    });
    navLinks.push({
      type: "link",
      path: "/candidates",
      label: "Candidates",
      isActive: (location: Location) =>
        location.pathname.startsWith("/candidate"),
      badge: useCandidateRedesign ? "New" : undefined,
      tourId: "candidates-nav-item",
    });
    navLinks.push({
      type: "link",
      path: "/positions/mine",
      label: "Positions",
      isActive: (location: Location) =>
        location.pathname.startsWith("/positions"),
    });
    const enabledOptions = [];
    if (canManageTraining(currentUser)) {
      const label = currentUser.userRole?.canManageAllTrainings
        ? "Manage All Programs"
        : "Manage My Programs";
      const mobileLabel = currentUser.userRole?.canManageAllTrainings
        ? "Manage All Training Programs"
        : "Manage My Training Programs";
      enabledOptions.push({
        label,
        mobileLabel,
        path: "/training/manage",
        icon: HiOutlineCog,
      });
    }
    enabledOptions.push({
      label: "My Training",
      mobileLabel: "My Training Programs",
      path: "/training/view",
      icon: HiOutlineBookOpen,
    });
    if (enabledOptions.length > 1) {
      navLinks.push({
        type: "menu",
        label: "Training",
        isActive: (location: Location) =>
          location.pathname.startsWith("/training"),
        items: enabledOptions,
      });
    } else {
      navLinks.push({
        type: "link",
        label: "Training",
        isActive: (location: Location) =>
          location.pathname.startsWith("/training"),
        path: "/training/view",
      });
    }
    // only show insights to users who can view metrics
    if (canViewAllMetrics(currentUser)) {
      navLinks.push({
        type: "link",
        path: `/insights/overview`,
        label: "Insights",
        isActive: (location: Location) =>
          location.pathname.startsWith("/insights"),
      });
    }

    // only show plan link to users in the feature flag
    if (planPOCEndabled) {
      navLinks.push({
        type: "menu",
        label: "Plan",
        isActive: (location: Location) => location.pathname.startsWith("/plan"),
        items: [
          {
            label: "Job Descriptions",
            mobileLabel: "Job Descriptions",
            path: "/plan/job-descriptions",
            icon: PiListMagnifyingGlass,
            isCrossAppLink: true,
          },
        ],
      });
    }
  }

  return (
    <Box position="sticky" top="0" zIndex="5">
      {currentUser?.isImpersonated && (
        <Box
          width="100%"
          py={0.5}
          backgroundColor={theme.colors.red[600]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          textColor="white"
        >
          Impersonating User
        </Box>
      )}
      <PageHeaderBase
        currentUser={currentUser}
        rootPath="/"
        navLinks={navLinks}
        onSignOut={onSignOut}
        showSignOutDuringSignup={showSignOutDuringSignup}
        signOutText={
          currentUser?.isImpersonated ? "Un-impersonate" : "Sign Out"
        }
        renderSearch={
          showSearchBox
            ? () => (
                <SearchBox
                  searchOnFocus
                  onFocus={() => {
                    if (!isOpen) {
                      onOpen();
                    }
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const query = e.currentTarget.value;
                    if (query && !isOpen) {
                      onOpen();
                    } else if (!query && isOpen) {
                      onClose();
                    }
                  }}
                  onEnter={(value) => {
                    navigate(`/search?q=${encodeURIComponent(value)}`);
                  }}
                />
              )
            : undefined
        }
      />
      {currentUser && <CallInProgressBanner />}
    </Box>
  );
};

export default React.memo(PageHeader);
