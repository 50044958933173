import {
  Box,
  Flex,
  IconButton,
  IconButtonProps,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import {
  HiOutlineThumbDown,
  HiOutlineThumbUp,
  HiThumbDown,
  HiThumbUp,
} from "react-icons/hi";

import { useSendGAEvent } from "../../../../utils/googleAnalytics";

const AiFeedback: React.FC<{
  feature: string;
}> = ({ feature }) => {
  const [submitted, setSubmitted] = useState<string | null>(null);
  const sendGAEvent = useSendGAEvent();
  const toast = useToast();

  const rateNotes = useCallback(
    (valence: "positive" | "negative"): void => {
      if (!submitted) {
        sendGAEvent(`rate_ai_${feature}`, "user_feedback", valence);
        setSubmitted(valence);
        toast({
          description: "Thank you for your feedback!",
          status: "success",
        });
      }
    },
    [submitted]
  );

  const iconProps = {
    size: "24px",
  };

  const parent = document.getElementById("ai-notes-feedback");
  if (!parent || !parent.parentElement) {
    return null;
  }
  parent.style.position = "fixed";
  parent.style.bottom = "0";
  parent.style.width = `${parent.parentElement.clientWidth}px`;
  parent.style.right = "0";
  const textToDisplay = (): string => {
    let featureArray = feature.split("-");
    if (featureArray[0] === "topic") {
      featureArray = featureArray.splice(1).map((str) => str.replace("_", " "));
    } else if (featureArray[0] === "notes") {
      return "";
    }
    return featureArray.join(" ");
  };

  return createPortal(
    <Box w="100%" bg="white" borderTop="1px solid" borderColor="gray.200">
      <Flex
        align="center"
        justify="space-between"
        maxW="380px"
        py="2"
        mx="auto"
      >
        <Text color="gray.600" fontWeight="600" fontSize="sm">
          {`Are these AI-generated ${textToDisplay()} notes helpful?`}
        </Text>
        <Flex direction="row">
          <RatingButton
            aria-label="Thumbs up"
            icon={
              submitted === "positive" ? (
                <HiThumbUp {...iconProps} />
              ) : (
                <HiOutlineThumbUp {...iconProps} />
              )
            }
            onClick={() => {
              rateNotes("positive");
            }}
            isDisabled={!!submitted}
            data-tour-id={`ai-notes-thumbsup-${feature.replace("_", "-")}`}
          />
          <RatingButton
            aria-label="Thumbs down"
            icon={
              submitted === "negative" ? (
                <HiThumbDown {...iconProps} />
              ) : (
                <HiOutlineThumbDown {...iconProps} />
              )
            }
            onClick={() => {
              rateNotes("negative");
            }}
            isDisabled={!!submitted}
            data-tour-id={`ai-notes-thumbsdown-${feature.replace("_", "-")}`}
          />
        </Flex>
      </Flex>
    </Box>,
    parent
  );
};

const RatingButton: React.FC<IconButtonProps> = (props) => (
  <IconButton
    variant="unstyled"
    color="blue.600"
    align="center"
    justify="center"
    display="flex"
    size="lg"
    _hover={{ bg: "transparent" }}
    _active={{ bg: "transparent" }}
    _disabled={{ color: "blue.600" }}
    {...props}
  />
);

export default AiFeedback;
