import React, { PropsWithChildren } from "react";
import { useSearchParams } from "react-router-dom";

import PageHeader from "../components/PageHeader";
import { InstantSearch } from "../components/Search";
import { DrawerState, SearchDrawer } from "../components/SearchDrawer";
import { SidebarNav } from "../components/SidebarNav";
import { SpotlightSearch } from "../components/SpotlightSearch";
import { useFeatureFlagForCurrentOrImpersonatedUser } from "../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../hooks/useCurrentUser";

interface BaseLayoutProps {
  showSearchBox?: boolean;
  allowEmptyQuery?: boolean;
  updateUrlWithSearchParams?: boolean;
}

/**
 * Prepares page navigation components and places `children` in
 * the appropriate spot to clear the navigation UI
 */
const BaseLayout: React.FC<PropsWithChildren<BaseLayoutProps>> = ({
  showSearchBox = true,
  allowEmptyQuery = false,
  updateUrlWithSearchParams = false,
  children,
}) => {
  const currentUser = useCurrentUser();
  const [s] = useSearchParams();
  const sidebarNavEnabled =
    useFeatureFlagForCurrentOrImpersonatedUser("sidebar-nav") &&
    // Temporary: for testing purposes, allow opting out of the new design
    !s.has("orig");

  if (!currentUser) {
    if (sidebarNavEnabled) {
      return <SidebarNav>{children}</SidebarNav>;
    }

    return (
      <>
        <PageHeader />
        {children}
      </>
    );
  }

  return (
    <InstantSearch
      allowEmptyQuery={allowEmptyQuery}
      updateUrl={updateUrlWithSearchParams}
    >
      {sidebarNavEnabled && (
        <SpotlightSearch>
          {(searchState) => (
            <SidebarNav currentUser={currentUser} searchState={searchState}>
              {children}
            </SidebarNav>
          )}
        </SpotlightSearch>
      )}
      {!sidebarNavEnabled && (
        <SearchDrawer>
          {(drawerState: DrawerState) => (
            <>
              <PageHeader
                currentUser={currentUser}
                showSearchBox={showSearchBox}
                drawerState={drawerState}
              />
              {children}
            </>
          )}
        </SearchDrawer>
      )}
    </InstantSearch>
  );
};

export default BaseLayout;
