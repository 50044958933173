import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";

import {
  Alert,
  AnalyticsDimension,
  CurrentUserFragment,
  MetricName,
  UserRoleName,
} from "../../graphql";
import { calculateAverage } from "./averages";
import { ValueFormat } from "./MetricConfig";
import { DataPoint, TrendType } from "./types";
import { AnalyticsConfig } from "./useAnalyticsConfig";

export const hcaMetrics = [
  MetricName.WorkLocationCoverage,
  MetricName.CompensationCoverage,
  MetricName.CareLikeFamilyCoverage,
];

export const ratingMetrics = [
  MetricName.Rating,
  MetricName.StrongRatingPercent,
];

export const scorecardMetrics = [
  MetricName.PassRate,
  MetricName.ScorecardCompletionRate,
  MetricName.ScorecardCompletionTime,
];

export const isScorecardMetric = (metric: MetricName): boolean => {
  return scorecardMetrics.includes(metric);
};

const unitsCondensed = {
  seconds: "s",
  minutes: "m",
  hours: "h",
  days: "d",
};

const getUnitsVerbose = (plural: boolean): { [key: string]: string } => {
  return {
    seconds: ` second${plural ? "s" : ""}`,
    minutes: ` minute${plural ? "s" : ""}`,
    hours: ` hour${plural ? "s" : ""}`,
    days: ` day${plural ? "s" : ""}`,
  };
};

type FormatOptions = {
  verbose?: boolean;
};

type StructuredFormattedValue = {
  value: number | string;
  unitSymbol: string;
};

export const getStructuredFormatFromValue = (
  value: number | null | undefined,
  format: ValueFormat,
  options?: FormatOptions
): StructuredFormattedValue[] => {
  const verbose = options?.verbose;

  let obj: StructuredFormattedValue = {
    value: "",
    unitSymbol: "",
  };

  // null case - lint-friendly check
  if (!value && value !== 0) {
    return [obj];
  }
  // default case
  obj = {
    value: value.toFixed(2),
    unitSymbol: "",
  };
  // formats
  if (format === "percent") {
    obj = {
      value: (value * 100).toFixed(0),
      unitSymbol: "%",
    };
  }
  if (format === "seconds") {
    const roundedVal = Math.round(value);
    const units = verbose ? getUnitsVerbose(roundedVal !== 1) : unitsCondensed;
    obj = {
      value: roundedVal,
      unitSymbol: units.seconds,
    };
  }
  if (format === "minutes") {
    const roundedVal = Math.round((value || 0) / 60.0);
    const units = verbose ? getUnitsVerbose(roundedVal !== 1) : unitsCondensed;
    obj = {
      value: roundedVal,
      unitSymbol: units.minutes,
    };
  }
  if (format === "hours") {
    const roundedVal = Math.round((value || 0) / 3600.0);
    const units = verbose ? getUnitsVerbose(roundedVal !== 1) : unitsCondensed;
    obj = {
      value: roundedVal,
      unitSymbol: units.hours,
    };
  }
  if (format === "days-hours") {
    const roundedHours = Math.round((value || 0) / 3600.0); // hours
    const days = roundedHours / 24;
    const roundedDays = Math.floor(days);
    const roundedRemainderHours = Math.round((days - roundedDays) * 24 || 0);
    const dayUnits = verbose
      ? getUnitsVerbose(roundedDays !== 1)
      : unitsCondensed;
    const hourUnits = verbose
      ? getUnitsVerbose(roundedRemainderHours !== 1)
      : unitsCondensed;
    const result = [
      {
        value: roundedDays,
        unitSymbol: dayUnits.days,
      },
    ];
    if (roundedRemainderHours > 0) {
      result.push({
        value: roundedRemainderHours,
        unitSymbol: hourUnits.hours,
      });
    }
    return result;
  }
  if (format === "integer") {
    obj = {
      value: Math.round(value),
      unitSymbol: "",
    };
  }

  return [obj];
};

export const formatValue = (
  value: number | null | undefined,
  format: ValueFormat,
  options?: FormatOptions
): string => {
  const structuredValue = getStructuredFormatFromValue(value, format, options);
  let result = "";
  structuredValue.forEach((v, i) => {
    result += `${v.value}${v.unitSymbol}`;
    if (i + 1 < structuredValue.length) {
      result += " ";
    }
  });
  return result;
};

export const canViewAllMetrics = (user: CurrentUserFragment): boolean => {
  const isSiteAdmin = user.userRole?.name === UserRoleName.SiteAdmin;
  const isHiringAdmin = !!user.memberships.find(
    (m) => m.role.canManageHiringTeam
  );

  return isSiteAdmin || isHiringAdmin;
};

type FilterMap = {
  position: string[];
  client: string[];
  interviewer: string[];
  stage: string[];
};

export const mapFiltersToAlgoliaURL = (
  analyticsConfig: AnalyticsConfig,
  entry: { label?: string | null },
  metric: MetricName
): string => {
  const {
    primaryDimension: { value: primaryDimension },
    dateRange,
    filters,
  } = analyticsConfig;

  const dimension =
    primaryDimension === AnalyticsDimension.Department
      ? "client"
      : primaryDimension === AnalyticsDimension.JobStage
      ? "stage"
      : primaryDimension.toLocaleLowerCase();
  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.getAll("source");
  source.push("insights");
  const sourceParams = `source=${[...new Set(source)].join("&source=")}`;

  let url = `/search?${sourceParams}&metric=${metric}&${dimension}=${encodeURIComponent(
    entry.label || ""
  )}&date%5Bmin%5D=${Number.parseInt(
    format(dateRange.value.start, "t")
  )}&date%5Bmax%5D=${Number.parseInt(format(dateRange.value.end, "t"))}`;

  // add filters to url conditionally
  // in some cases, bigquery and algolia work with different params
  // i.e. id instead of name
  // in those cases, raw label will return the label without count
  const filtersMap: FilterMap = {
    position: filters.positions,
    client: filters.departments,
    interviewer: analyticsConfig.interviewers.labels || [],
    stage: filters.stages,
  };

  const filterKeys = Object.keys(filtersMap);
  filterKeys.forEach((filter: string) => {
    if (dimension !== filter) {
      filtersMap[filter as keyof typeof filtersMap].forEach((value: string) => {
        url += `&${filter}=${encodeURIComponent(value)}`;
      });
    }
  });
  return url;
};

export const groupBySegment = (
  dataPoints: DataPoint[] = []
): { [key: string]: DataPoint[] } => {
  const segments: { [key: string]: DataPoint[] } = {};
  dataPoints.forEach((p) => {
    const segment = p.segment || "all";
    if (segments[segment]) {
      segments[segment].push(p);
    } else {
      segments[segment] = [p];
    }
  });
  return segments;
};

export type MetricStats = {
  totalInterviews: number;
  numXValues: number;
  metricAverageValue: number;
  metricTotalValue: number;
};
// TODO: Write tests for this util
export const calculateMetricStats = (
  dataPoints: DataPoint[] = [],
  metric: MetricName
): MetricStats => {
  const totalInterviews = dataPoints.reduce(
    (prev, cur) => prev + (cur.countDataPoints || 0),
    0
  );

  const metricDefinedValues: number[] = dataPoints
    .map((d) => d.value)
    .filter((v) => v !== undefined && v !== null) as number[];
  const metricTotalValue = metricDefinedValues.reduce(
    (prev, cur) => prev + cur,
    0
  );
  const metricAverageValue = calculateAverage(dataPoints, metric);
  const numXValues = new Set(dataPoints?.map((d) => d.dataId)).size;

  return {
    totalInterviews,
    numXValues,
    metricAverageValue,
    metricTotalValue,
  };
};

type GetDashboardCardValuesForMetricArgs = {
  metric: MetricName;
  dataPoints: DataPoint[];
  deltaDataPoints?: DataPoint[];
};

type DashboardCardValues = {
  numXValues: number;
  metricAverageValue: number;
  delta?: number;
};

export const getDashboardCardValuesForMetric = (
  args: GetDashboardCardValuesForMetricArgs
): DashboardCardValues => {
  const { metric, dataPoints = [], deltaDataPoints = [] } = args;

  const { metricAverageValue, numXValues } = calculateMetricStats(
    dataPoints,
    metric
  );

  const {
    metricAverageValue: deltaMetricAverageValue,
    numXValues: deltaNumXValues,
  } = calculateMetricStats(deltaDataPoints, metric);

  const calculatedDelta =
    numXValues > 0 && deltaNumXValues > 0 && metricAverageValue !== 0
      ? metricAverageValue / deltaMetricAverageValue - 1
      : undefined;

  return {
    metricAverageValue,
    numXValues,
    delta: calculatedDelta,
  };
};

export const areFiltersOutdated = (search: string): boolean => {
  // we switched from uuid to email based interviewer filters
  // check for presence of "@" to determine if filters are outdated
  const interviewersFilterValues = new URLSearchParams(search).get(
    "interviewers"
  );
  return (
    !!interviewersFilterValues && interviewersFilterValues.indexOf("@") < 0
  );
};

export const getMockAlerts = (): Alert[] => [
  {
    id: uuidv4(),
    organizationId: "35bb5f6b-2733-464b-a561-35b4c941e824",
    message:
      "Candidates received < 30% talk time in interviews for 3 positions (40% of 99 interviews).",
    alertWeight: 40,
    alertType: "candidate_talk_time",
    alertValence: "negative",
    category: "candidate_experience",
    segment: "all",
    aggregation: "POSITION",
    aggregationIds: [
      "Account Executive (Demo) - No Department",
      "Content Marketing Manager - Marketing",
      "Head of Pawnee Parks - Customer Success Demo",
    ],
    alertOrder: 1,
    isNew: false,
  },
  {
    id: uuidv4(),
    organizationId: "35bb5f6b-2733-464b-a561-35b4c941e824",
    message:
      "Male candidates were asked 32% fewer questions than females in 2 departments (275 interviews).",
    alertWeight: 275,
    alertType: "count_interviewer_questions",
    alertValence: "negative",
    category: "dei",
    segment: "GENDER",
    aggregation: "DEPARTMENT",
    aggregationIds: ["Product", "Data Science"],
    alertOrder: 1,
    isNew: false,
  },
  {
    id: uuidv4(),
    organizationId: "35bb5f6b-2733-464b-a561-35b4c941e824",
    message:
      "A female candidate had 4+ interviews with no same-gender interviewers for the Senior Product Designer - Product  position.",
    alertWeight: 40,
    alertType: "gender_skewed_panel",
    alertValence: "negative",
    category: "dei",
    segment: "GENDER",
    aggregation: "CANDIDATE",
    aggregationIds: ["Yuki Wu"],
    alertOrder: 1,
    isNew: false,
  },
  {
    id: uuidv4(),
    organizationId: "35bb5f6b-2733-464b-a561-35b4c941e824",
    message:
      "Feedback was sent more than 24 hours after the interview in 2 departments (32% of 90 interviews).",
    alertWeight: 29,
    alertType: "submission_delay",
    alertValence: "negative",
    category: "talent_operations",
    segment: "all",
    aggregation: "DEPARTMENT",
    aggregationIds: ["Marketing", "Sales"],
    alertOrder: 1,
    isNew: false,
  },
  {
    id: uuidv4(),
    organizationId: "35bb5f6b-2733-464b-a561-35b4c941e824",
    message:
      'Female candidates were given a "hire" rating 35% more frequently than males in the Engineering department (29 interviews).',
    alertWeight: 29,
    alertType: "submission_is_positive",
    alertValence: "negative",
    category: "dei",
    segment: "GENDER",
    aggregation: "DEPARTMENT",
    aggregationIds: ["Engineering"],
    alertOrder: 1,
    isNew: false,
  },
  {
    id: uuidv4(),

    organizationId: "35bb5f6b-2733-464b-a561-35b4c941e824",
    message:
      "Feedback was not submitted in 9 departments (63% of 490 interviews).",
    alertWeight: 308,
    alertType: "submission_rate",
    alertValence: "negative",
    category: "talent_operations",
    segment: "all",
    aggregation: "DEPARTMENT",
    aggregationIds: [
      "Test Engineering",
      "Marketing",
      "Sales",
      "Data Science",
      "Demo",
      "Engineering",
      "Product",
      "Operations & People",
      "Customer Success Demo",
    ],
    alertOrder: 1,
    isNew: false,
  },
];

export const getTrendType = (metric: MetricName, value: number): TrendType => {
  const roundedValue = Math.round(value * 100);
  if (roundedValue === 0) {
    return "neutral";
  }
  if (metric === MetricName.ScorecardCompletionTime) {
    return roundedValue < 0 ? "positive" : "negative";
  }
  return roundedValue > 0 ? "positive" : "negative";
};

export const drildownToMetricURL = (
  alert: Alert,
  dateParam: string
): string => {
  if (alert.alertType === "gender_skewed_panel") {
    const filters = alert.aggregationIds
      .map((id) => id.replaceAll(" ", "+"))
      .join("&candidate=");
    return `/search?candidate=${filters}&source=gender_skew_alert`;
  }

  const metric = {
    submission_rate: MetricName.ScorecardCompletionRate, // eslint-disable-line camelcase
    submission_delay: MetricName.ScorecardCompletionTime, // eslint-disable-line camelcase
    submission_is_positive: MetricName.PassRate, // eslint-disable-line camelcase
    is_late_start: MetricName.OnTimeInterviews, // eslint-disable-line camelcase
    candidate_talk_time: MetricName.CandidateTalkRatio, // eslint-disable-line camelcase
    count_interviewer_questions: MetricName.QuestionsAsked, // eslint-disable-line camelcase
  }[alert.alertType];

  if (!metric) {
    return "/insights";
  }

  let url = `/insights/${metric.toLowerCase()}?source=alert&${dateParam}`;
  const filters = alert.aggregationIds
    .map((id) => id.replaceAll(" ", "+"))
    .map((id) => id.replaceAll("&", "%26"))
    .join("%2C");
  const alertAggregation = alert.aggregation.toLocaleUpperCase();

  if (
    alert.segment.toLocaleLowerCase() ===
    AnalyticsDimension.Gender.toLocaleLowerCase()
  ) {
    url = `${url}&segment=${AnalyticsDimension.Gender}`;
  }

  if (alert.aggregationIds.length > 1) {
    url = `${url}&dimension=${alertAggregation}`;
    switch (alertAggregation) {
      case AnalyticsDimension.Department:
        url = `${url}&departments=`;
        break;
      case AnalyticsDimension.Interviewer:
        url = `${url}&interviewers=`;
        break;
      case AnalyticsDimension.Position:
        url = `${url}&positions=`;
        break;
      case AnalyticsDimension.JobStage:
        url = `${url}&stages=`;
        break;
      default:
        break;
    }
    return `${url}${filters}`;
  }
  if (alert.aggregationIds.length === 1) {
    const aggregationString = alert.aggregationIds[0]
      .replaceAll(" ", "+")
      .replaceAll("&", "%26");
    if (alertAggregation === AnalyticsDimension.Department) {
      return `${url}&dimension=${AnalyticsDimension.Position}&departments=${aggregationString}`;
    }
    if (alertAggregation === AnalyticsDimension.Position) {
      return `${url}&dimension=${AnalyticsDimension.Interviewer}&positions=${aggregationString}`;
    }
    return `${url}&dimension=${AnalyticsDimension.Interviewer}&interviewers=${aggregationString}`;
  }
  return `${url}&dimension=${alert.aggregation.toLocaleUpperCase()}`;
};

// PERSONAL INSIGHTS DEMO DATA
export type PersonalInsightsDataPoint = {
  name: string;
  primary: number;
  secondary: number;
};
export const makeData = (values: number[][]): PersonalInsightsDataPoint[] => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthOffset = 7;
  const data = [];
  for (let i = 0; i < values.length; i++) {
    const valuePair = values[i];
    data.push({
      name: months[(i + monthOffset) % 12],
      primary: valuePair[0],
      secondary: valuePair.length > 1 ? valuePair[1] : 0,
    });
  }
  return data;
};
