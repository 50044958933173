/* eslint-disable camelcase */

import { useEffect, useMemo } from "react";

import config from "../config";
import { UserSignUpMethod } from "../main/graphql";
import { checkCookieExistence } from "./cookie";
import { dateInSeconds } from "./datetime";

interface CurrentUser {
  id: string;
  email: string;
  phoneNumber?: string | null;
  fullName: string;
  intercomUserHash: string;
  createdAt: string;
  signUpCompletedAt?: string | null;
  organization: {
    id: string;
    name: string;
    ats?: string | null;
    googleMeetEnabled: boolean;
    isZoomAuthorized: boolean;
  };
  userRole?: {
    formattedName?: string | null;
  } | null;
  browserExtensionVersion?: string | null;
  callCount: number;
  lastCalledAt?: string | null;
  signUpMethod: UserSignUpMethod;
  persona?: string | null;
  isZoomAppInstalled: boolean;
  trainingProgramsCreatedCount: number;
  trainingProgramsCompletedCount: number;
}

export const initIntercom = (): void => {
  const isImpersonating = checkCookieExistence("is_impersonating");
  if (!config.intercomEnabled || isImpersonating) {
    return;
  }
  const settings: Intercom_.IntercomSettings = {
    app_id: "wzl4gpu9",
  };
  if (window.Intercom.booted) {
    window.Intercom("update", settings);
  } else {
    window.Intercom("boot", settings);
  }
};

export const showIntercom = (): void => {
  const isImpersonating = checkCookieExistence("is_impersonating");
  if (!config.intercomEnabled || isImpersonating) {
    return;
  }
  window.Intercom("show");
};

export const startTour = (tourId: number): void => {
  const isImpersonating = checkCookieExistence("is_impersonating");
  if (!config.intercomEnabled || isImpersonating) {
    return;
  }
  window.Intercom("startTour", tourId);
};

export const useIntercom = (
  hideDefaultLauncher = false,
  currentUser?: CurrentUser | null
): void => {
  const isImpersonating = checkCookieExistence("is_impersonating");
  const settings = useMemo<Intercom_.IntercomSettings>(() => {
    return {
      ...(currentUser && !isImpersonating
        ? {
            user_id: currentUser.id,
            user_hash: currentUser.intercomUserHash,
            name: currentUser.fullName,
            email: currentUser.email,
            phone: currentUser.phoneNumber ?? undefined,
            ...(currentUser.signUpCompletedAt && {
              created_at: dateInSeconds(currentUser.signUpCompletedAt),
            }),
            logrocketURL: `https://app.logrocket.com/otjbrk/brighthire-production/sessions?u=${currentUser.id}`,
            company: {
              id: currentUser.organization.id,
              name: currentUser.organization.name,
              ats: currentUser.organization.ats,
              googleMeetEnabled:
                currentUser.organization.googleMeetEnabled ?? false,
              zoomEnabled: currentUser.organization.isZoomAuthorized ?? false,
            },
            persona: currentUser.persona,
            user_role: currentUser.userRole?.formattedName,
            sign_up_method: currentUser.signUpMethod,
            browser_extension_version: currentUser.browserExtensionVersion,
            interview_count: currentUser.callCount,
            last_interview_at: currentUser.lastCalledAt
              ? dateInSeconds(currentUser.lastCalledAt)
              : undefined,
            isZoomAppInstalled: currentUser.isZoomAppInstalled,
            trainingProgramsCreatedCount:
              currentUser.trainingProgramsCreatedCount,
            trainingProgramsCompletedCount:
              currentUser.trainingProgramsCompletedCount,
          }
        : undefined),
      hide_default_launcher: hideDefaultLauncher,
    };
  }, [hideDefaultLauncher, currentUser]);

  useEffect(() => {
    if (!config.intercomEnabled || isImpersonating) {
      return;
    }
    if (window.Intercom.booted) {
      window.Intercom("update", settings);
    } else {
      window.Intercom("boot", settings);
    }
  }, [settings, isImpersonating]);
};
