import { useEffect } from "react";

import {
  CallBetaFragment,
  CallNotesBetaQuery,
  useCallClipsLazyQuery,
} from "../../../graphql";
import { ClipsTabProps } from "../../Recording/SidebarTabs/ClipsTab";
import useCallNotes from "./useCallNotes";
import useCallTrackers from "./useCallTrackers";
import useCallTraining from "./useCallTraining";
import useCallTranscript from "./useCallTranscript";

export enum CallTab {
  NOTES = "notes",
  POIS = "trackers",
  CLIPS = "clips",
  TRANSCRIPT = "transcript",
  TRAINING = "training",
  AI_NOTES = "ai_notes",
}

type CallSidebarParams = {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "greenhouseScorecardLinkWithId"
    | "leverInterviewLinkWithId"
    | "interviewerIds"
  >;
  currentTab: CallTab | undefined;
  onCallNotesQueryCompleted?: (data: CallNotesBetaQuery) => void;
  disableAutofill?: boolean;
  notesForCurrentUserOnly: boolean;
};

/**
 * The types listed here are a bit esoteric in an effort to be DRY. See
 * the definitions of the various helper hooks for more useful types
 */
type CallSidebarReturn = {
  trackers: ReturnType<typeof useCallTrackers>;
  clips: Pick<ClipsTabProps, "clips" | "loading" | "error">;
  training: ReturnType<typeof useCallTraining>;
  notes: Omit<ReturnType<typeof useCallNotes>, "getCallNotes">;
  transcript: ReturnType<typeof useCallTranscript>;
};

export function useCallSidebar({
  call,
  currentTab,
  onCallNotesQueryCompleted,
  disableAutofill,
  notesForCurrentUserOnly,
}: CallSidebarParams): CallSidebarReturn {
  const callId = call.id;
  const trackers = useCallTrackers({ callId });
  const training = useCallTraining({ callId });
  const transcript = useCallTranscript({ callId, notesForCurrentUserOnly });
  const { getCallNotes, ...notes } = useCallNotes({
    call,
    onCallNotesQueryCompleted,
    disableAutofill,
    notesForCurrentUserOnly,
  });

  const [getCallClips, callClipsQuery] = useCallClipsLazyQuery({
    variables: { callId },
  });

  useEffect(() => {
    if (currentTab === CallTab.CLIPS) {
      getCallClips();
    } else if (currentTab === CallTab.NOTES) {
      getCallNotes();
    }
  }, [currentTab]);

  return {
    trackers,
    training,
    transcript,
    notes,
    clips: {
      loading: callClipsQuery.loading,
      error: callClipsQuery.error?.message,
      clips:
        callClipsQuery.data?.call?.callClips ??
        callClipsQuery.previousData?.call?.callClips ??
        [],
    },
  };
}
