import { Flex, Icon } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiOutlineSparkles } from "react-icons/hi";

import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { CallAiSummaryFormat, CallBetaFragment } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { useAvailableAiSummaryFormats } from "../../hooks/useAvailableAiSummaryFormats";
import useCurrentUser from "../../hooks/useCurrentUser";
import {
  ClipsTab,
  NotesTab,
  SidebarTab,
  SidebarTabs,
  TrackersTab,
  TrainingTab,
  Transcript,
} from "../Recording";
import AiFormatSelector from "../Recording/SidebarTabs/AiNotes/AiFormatSelector";
import AiTopicsTab from "../Recording/SidebarTabs/AiNotes/AiTopicsTab";
import { CallTab, useCallSidebar } from "./beta/useCallSidebar";
import { ClipRange } from "./Clip/types";
import { MediaPlayerInterface } from "./useMediaPlayer";

type CallSidebarProps = {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "greenhouseScorecardLinkWithId"
    | "leverInterviewLinkWithId"
    | "duration"
    | "interviewerIds"
    | "canEdit"
    | "canShare"
    | "canShareExternal"
    | "clipsCount"
    | "position"
    | "speakers"
    | "transcriptLanguageCode"
    | "aiNotesFormat"
  >;
  currentTab?: CallTab;
  player: MediaPlayerInterface;
  onTabChange?(t: CallTab): void;
  onSeek(t: number): void;
  onCreateClipClick(): void;
  setClipRange(c: ClipRange | null): void;
  autoScrollEnabled: boolean;
  setAutoScrollEnabled(enabled: boolean): void;
  isVideoVisible?: boolean;
  disableAutofill?: boolean;
  notesForCurrentUserOnly: boolean;
  hideVideo(): void;
  focusVideo(): void;
  isVideoCall?: boolean;
};

const CallSidebar: React.FC<CallSidebarProps> = ({
  call,
  currentTab,
  player,
  onTabChange,
  onSeek,
  onCreateClipClick,
  setClipRange,
  autoScrollEnabled,
  setAutoScrollEnabled,
  isVideoCall,
  isVideoVisible,
  disableAutofill,
  notesForCurrentUserOnly,
  hideVideo,
  focusVideo,
}) => {
  const aiNotesEnabled = useFeatureFlag("ai_notes:v1");
  const currentUser = useCurrentUser();
  const { allowAiNotes } = currentUser.organization;
  const isSmallScreen = useIsSmallScreen();
  const [userHasChangedTab, setUserHasChangedTab] = useState(false);
  const sendGAEvent = useSendGAEvent();

  const hasLabeledCandidate = !!call.speakers.find((s) => s.isCandidate);
  const canShowAiNotesFeature = aiNotesEnabled && allowAiNotes;

  const [format, setFormat] = useState<CallAiSummaryFormat | "AI_NOTES">(
    call.aiNotesFormat ?? "AI_NOTES"
  );
  const availableFormats = useAvailableAiSummaryFormats();
  const canUseOtherAISummaryFormats = availableFormats.length > 0;

  /**
   * Assembles props for various page components
   */
  const { trackers, clips, training, notes, transcript } = useCallSidebar({
    call,
    currentTab,
    disableAutofill,
    notesForCurrentUserOnly,
    onCallNotesQueryCompleted: (data) => {
      if (
        !userHasChangedTab &&
        !data.call?.generalNotes.length &&
        !data.call?.questions.some((q) => q.questionNotes.length > 0)
      ) {
        if (canShowAiNotesFeature) {
          onTabChange?.(CallTab.AI_NOTES);
        } else {
          onTabChange?.(CallTab.POIS);
        }
      }
    },
  });

  /**
   * Navigate to training tab if training is present
   */
  useEffect(() => {
    if (training.callTraining) {
      onTabChange?.(CallTab.TRAINING);
    }
  }, [training.callTraining]);

  /**
   * Go to transcript tab on small screens, fallback to notes
   * tab if going from small screen to large screen
   */
  useEffect(() => {
    if (isSmallScreen) {
      onTabChange?.(CallTab.NOTES);
    } else if (currentTab === CallTab.TRANSCRIPT) {
      onTabChange?.(CallTab.NOTES);
    }
  }, [isSmallScreen]);

  // Tab will be auto-selected to something else, wait a moment
  // before rendering the tab to avoid a flash of the wrong tab
  if (
    !userHasChangedTab &&
    CallTab.NOTES === currentTab &&
    notes.generalNotes.length === 0 &&
    !notes.questions.some((q) => q.questionNotes.length > 0)
  ) {
    return null;
  }

  return (
    <SidebarTabs
      currentTab={currentTab}
      onTabChange={(newTab) => {
        if (newTab !== currentTab) {
          setUserHasChangedTab(true);
          sendGAEvent("sidebar_tab_change", "call_review", currentTab);
        }
        onTabChange?.(newTab);
      }}
      menuButton={
        canUseOtherAISummaryFormats ? (
          <AiFormatSelector format={format} setFormat={setFormat} call={call} />
        ) : undefined
      }
    >
      <SidebarTab id={CallTab.NOTES} displayName="Notes">
        <NotesTab
          {...notes}
          callId={call.id}
          callDuration={call.duration as number}
          interviewerIds={call.interviewerIds}
          notesReadOnly={!call.canEdit}
          onClickTimestamp={onSeek}
          setClipRange={isSmallScreen ? undefined : setClipRange}
          playerTime={player.time}
          overflow="hidden"
        />
      </SidebarTab>

      {canShowAiNotesFeature && (
        <SidebarTab
          id={CallTab.AI_NOTES}
          displayName={({ isSelected }) => {
            if (isSelected && canUseOtherAISummaryFormats) {
              return (
                <Flex alignItems="center" data-tour-id="sidebar-tab-ai-notes">
                  <AiFormatSelector
                    format={format}
                    setFormat={setFormat}
                    call={call}
                  />
                </Flex>
              );
            }
            return (
              <Flex alignItems="center" data-tour-id="sidebar-tab-ai-notes">
                <Icon
                  as={HiOutlineSparkles}
                  color="yellow.500"
                  h="16px"
                  mr="3px"
                  w="16px"
                />
                AI Notes
              </Flex>
            );
          }}
        >
          <AiTopicsTab
            call={call}
            hasLabeledCandidate={hasLabeledCandidate}
            onClickTimestamp={onSeek}
            format={format}
          />
        </SidebarTab>
      )}

      <SidebarTab id={CallTab.POIS} displayName="POIs">
        <TrackersTab
          onSeek={onSeek}
          {...trackers}
          transcriptLanguageCode={call.transcriptLanguageCode}
          doesCallHaveTranscript={transcript.data.transcript.length > 0}
        />
      </SidebarTab>

      {isSmallScreen && (
        <SidebarTab id={CallTab.TRANSCRIPT} displayName="Transcript">
          <Transcript
            h="100%"
            callId={call.id}
            speakers={call.speakers}
            player={player}
            autoScrollEnabled={autoScrollEnabled}
            setAutoScrollEnabled={setAutoScrollEnabled}
            isVideoCall={isVideoCall}
            isVideoVisible={isVideoVisible}
            hideVideo={hideVideo}
            focusVideo={focusVideo}
            {...transcript.data}
          />
        </SidebarTab>
      )}

      {!isSmallScreen && (
        <SidebarTab
          id={CallTab.CLIPS}
          displayName={`Clips${
            call.clipsCount > 0 ? ` (${call.clipsCount})` : ""
          }`}
        >
          <ClipsTab
            callId={call.id}
            positionId={call.position?.id}
            readOnly={!call.canEdit}
            onCreateClipClick={onCreateClipClick}
            canShare={call.canShare || call.canShareExternal}
            {...clips}
          />
        </SidebarTab>
      )}

      {training.callTraining && !isSmallScreen && (
        <SidebarTab id={CallTab.TRAINING} displayName="Training">
          <TrainingTab
            {...training}
            callTraining={training.callTraining}
            pt="6"
            h="100%"
          />
        </SidebarTab>
      )}
    </SidebarTabs>
  );
};

export default CallSidebar;
