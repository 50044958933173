import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Heading,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";

import { CallType, NotetakerRecorderDisclaimerType } from "../../main/graphql";

export type DisclaimerFormData = {
  disclaimer: string;
  disclaimerType: NotetakerRecorderDisclaimerType;
  defaultDisclaimer: string;
};

interface DisclaimerInputsProps {
  callType: CallType;
}

const DisclaimerInputs: React.FC<DisclaimerInputsProps> = ({ callType }) => {
  const { register, watch } = useFormContext<DisclaimerFormData>();

  const callText = (callType: CallType): string => {
    switch (callType) {
      case CallType.GoogleMeet:
        return "Google Meet";
      case CallType.ZoomNotetaker:
        return "Zoom Notetaker";
      case CallType.TeamsNotetaker:
        return "Teams Notetaker";
      default:
        return "the meeting";
    }
  };

  const [
    notetakerRecorderDisclaimer,
    notetakerRecorderDisclaimerType,
    defaultDisclaimer,
  ] = watch(["disclaimer", "disclaimerType", "defaultDisclaimer"]);

  return (
    <div>
      <Divider mb={6} />
      <Heading as="h4" fontSize="md" mb={6}>
        Disclaimer
      </Heading>
      <FormControl id="notetakerRecorderDisclaimerType" mb={8}>
        <Select
          size="sm"
          width="auto"
          data-testid="notetaker-recorder-disclaimer-type"
          {...register("disclaimerType")}
        >
          <option key="DEFAULT" value={NotetakerRecorderDisclaimerType.Default}>
            Use BrightHire&apos;s default disclaimer
          </option>
          <option
            key="ORGANIZATION"
            value={NotetakerRecorderDisclaimerType.Organization}
          >
            Create custom disclaimer text
          </option>
          <option key="NONE" value={NotetakerRecorderDisclaimerType.None}>
            Don&apos;t show any disclaimer
          </option>
        </Select>
        <FormHelperText>
          Select the disclaimer text all participants should see while
          BrightHire is recording. This will appear as a notification in{" "}
          {callText(callType)} and each time a user joins the meeting.
        </FormHelperText>
      </FormControl>
      <FormControl
        id="googleMeetRecordingDisclaimer"
        mb={8}
        hidden={
          notetakerRecorderDisclaimerType !==
          NotetakerRecorderDisclaimerType.Organization
        }
      >
        <Input
          {...register("disclaimer")}
          placeholder="Add a custom BrightHire disclaimer"
          data-testid="notetaker-recorder-disclaimer"
        />
        <FormHelperText>
          Please note that only the first 100 characters will be shown in the
          notification, but the full text will be available in the chat panel.
        </FormHelperText>
      </FormControl>

      {notetakerRecorderDisclaimerType !==
        NotetakerRecorderDisclaimerType.None && (
        <Box p={6} mb={6} bg="gray.100">
          <Text fontSize="xs">Your interviewers and candidates will see:</Text>
          {notetakerRecorderDisclaimerType ===
          NotetakerRecorderDisclaimerType.Default
            ? defaultDisclaimer
            : notetakerRecorderDisclaimer}
        </Box>
      )}
    </div>
  );
};

export default DisclaimerInputs;
