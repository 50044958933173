import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { Card, CardTitle, SettingsNavLink } from "../../../components";
import { CurrentUserFragment } from "../../graphql";

interface SettingsNavProps {
  currentUser: CurrentUserFragment;
}

const SettingsNav: React.FC<SettingsNavProps> = ({ currentUser }) => {
  return (
    <Box gridRow="1 / 3">
      <Card>
        <CardTitle position="relative">User Settings</CardTitle>
        <Flex flexDirection="column">
          <SettingsNavLink end to="/settings/user">
            General
          </SettingsNavLink>
          <SettingsNavLink to="/settings/templates">Templates</SettingsNavLink>
          <SettingsNavLink to="/settings/calendar">Calendar</SettingsNavLink>
          {(currentUser.organization.isZoomAuthorized ||
            currentUser.isZoomAppInstalled) && (
            <SettingsNavLink to="/settings/user/zoom">Zoom</SettingsNavLink>
          )}
          <SettingsNavLink to="/settings/user/notifications">
            Notification Preferences
          </SettingsNavLink>
        </Flex>
      </Card>
      {currentUser.userRole?.canManageOrganizationSettings && (
        <Card mt={8}>
          <CardTitle position="relative">Organization Settings</CardTitle>
          <Flex flexDirection="column">
            <SettingsNavLink to="/settings/organization">
              General
            </SettingsNavLink>
            <SettingsNavLink to="/settings/users" data-tour-id="settings-users">
              Users
            </SettingsNavLink>
            {currentUser.enabledFeatures.includes("scoring:v1") && (
              <SettingsNavLink
                to="/settings/scoring"
                onClick={() => window.scrollTo(0, 0)}
              >
                Scoring
              </SettingsNavLink>
            )}
            <SettingsNavLink to="/settings/shared-calendars">
              Shared Calendars
            </SettingsNavLink>
            <SettingsNavLink to="/settings/slack">Slack</SettingsNavLink>
            {/* Still need access to the old Zoom settings page until Notetaker
            is fully relased to production for all organizations */}
            {currentUser.organization.zoomUseZoomNative && (
              <SettingsNavLink to="/settings/zoom">Zoom</SettingsNavLink>
            )}
            {!currentUser.organization.zoomUseZoomNative && (
              <SettingsNavLink to="/settings/zoom-notetaker">
                Zoom Notetaker
              </SettingsNavLink>
            )}
            <SettingsNavLink to="/settings/auto-join-by-position">
              Auto Join by Position
            </SettingsNavLink>
            <SettingsNavLink to="/settings/bullhorn">Bullhorn</SettingsNavLink>
            <SettingsNavLink to="/settings/greenhouse">
              Greenhouse
            </SettingsNavLink>
            <SettingsNavLink to="/settings/lever">Lever</SettingsNavLink>
            {currentUser.enabledFeatures.includes(
              "smartrecruiters_enabled"
            ) && (
              <SettingsNavLink to="/settings/smartrecruiters">
                SmartRecruiters
              </SettingsNavLink>
            )}
            {currentUser.enabledFeatures.includes("icims_enabled") && (
              <SettingsNavLink to="/settings/icims">iCIMS</SettingsNavLink>
            )}
            {currentUser.enabledFeatures.includes("merge_enabled") && (
              <SettingsNavLink to="/settings/other-ats">
                Other ATS Integrations
              </SettingsNavLink>
            )}
            <SettingsNavLink to="/settings/sso">SSO Setup</SettingsNavLink>
            <SettingsNavLink to="/settings/google-meet">
              Google Meet
            </SettingsNavLink>
            {currentUser.enabledFeatures.includes("msft_teams_settings") && (
              <SettingsNavLink to="/settings/msft-teams">
                Microsoft Teams
              </SettingsNavLink>
            )}
            {currentUser.enabledFeatures.includes("data_retention") && (
              <SettingsNavLink to="/settings/data-retention">
                Data Retention
              </SettingsNavLink>
            )}
            {currentUser.enabledFeatures.includes(
              "data_redaction:settings-page"
            ) && (
              <SettingsNavLink to="/settings/data-redaction">
                Data Redaction
              </SettingsNavLink>
            )}
            {currentUser.enabledFeatures.includes("rest_api") && (
              <SettingsNavLink to="/settings/rest">Rest API</SettingsNavLink>
            )}
            <SettingsNavLink to="/settings/exclude-stage">
              Exclude Stage
            </SettingsNavLink>
            {currentUser.enabledFeatures.includes("opt_in_settings") && (
              <SettingsNavLink
                to="/settings/opt-in"
                onClick={() => window.scrollTo(0, 0)}
              >
                Opt In Landing Page
              </SettingsNavLink>
            )}
          </Flex>
        </Card>
      )}
    </Box>
  );
};

export default SettingsNav;
