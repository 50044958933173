import { Box, Heading } from "@chakra-ui/react";
import React from "react";

import { useToast } from "../../../components";
import {
  CurrentUserFragment,
  useUpdateOrganizationMutation,
} from "../../graphql";
import OrganizationSettingsForm from "./OrganizationSettingsForm";

interface OrganizationSettingsProps {
  currentUser: CurrentUserFragment;
}

const OrganizationSettings: React.FC<OrganizationSettingsProps> = ({
  currentUser,
}) => {
  const toast = useToast();
  const [
    updateOrganizationSettings,
    { loading: orgSettingsLoading, error: orgSettingsError },
  ] = useUpdateOrganizationMutation({
    onCompleted: (data) => {
      if (data?.updateOrganization?.currentUser) {
        toast({
          status: "success",
          title: "Organization Settings",
          description: "Success!",
        });
      }
    },
    onError: () => {
      toast({
        status: "error",
        title: "Organization Settings",
        description: "There was a problem - please try again",
      });
    },
  });

  return (
    <Box w="500px">
      <Heading as="h2" size="md" mb="10">
        General Settings
      </Heading>
      <OrganizationSettingsForm
        allowSelfSignUp={currentUser.organization.allowSelfSignUp}
        allowPhoneSignIn={currentUser.organization.allowPhoneSignIn}
        postNotesToAts={currentUser.organization.postNotesToAts}
        emailDomain={currentUser.organization.emailDomain}
        allowCandidateOptOut={currentUser.organization.allowCandidateOptOut}
        candidateOptOutUrl={currentUser.organization.candidateOptOutUrl}
        allowInterviewerOptOut={currentUser.organization.allowInterviewerOptOut}
        automaticallyDeleteZoomRecordings={
          currentUser.organization.automaticallyDeleteZoomRecordings
        }
        playCallDisclaimer={currentUser.organization.playCallDisclaimer}
        showStarRatings={!!currentUser.organization.showStarRatings}
        ratingDescriptions={currentUser.organization.ratingDescriptions}
        inviteScheduledInterviewers={
          currentUser.organization.inviteScheduledInterviewers
        }
        assignGuidesToInterviewersEnabled={
          currentUser.organization.assignGuidesToInterviewersEnabled
        }
        requireActiveUserToImport={
          currentUser.organization.requireActiveUserToImport
        }
        hideOtherInterviewerNotesInExtension={
          currentUser.organization.hideOtherInterviewerNotesInExtension
        }
        allowManualScheduledInterviews={
          currentUser.organization.allowManualScheduledInterviews
        }
        externalShareDefaultDurationDays={
          currentUser.organization.externalShareDefaultDurationDays
        }
        zoomNotetakerEnabled={currentUser.organization.zoomNotetakerEnabled}
        maximumCallDurationForNotetakerToRejoin={
          currentUser.organization.maximumCallDurationForNotetakerToRejoin
        }
        allowInPersonMeetings={currentUser.organization.allowInPersonMeetings}
        playInPersonRecordingDisclaimer={
          currentUser.organization.playInPersonRecordingDisclaimer
        }
        audioOnlyViewing={currentUser.organization.audioOnlyViewing}
        isLoading={orgSettingsLoading}
        error={orgSettingsError}
        onSubmit={(formData) =>
          updateOrganizationSettings({ variables: formData })
        }
      />
    </Box>
  );
};

export default OrganizationSettings;
