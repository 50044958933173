import { Tab, TabList, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  matchPath,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import {
  RequireUUIDParams as RequireUUIDParamsBase,
  RequireUUIDParamsProps,
} from "../../../components";
import useCurrentUser from "../../hooks/useCurrentUser";
import ActivityPage from "./ActivityPage";
import ExcludedUsers from "./ExcludedUsers";
import RolePoliciesPage from "./RolePoliciesPage";
import TeamInfoPage from "./TeamInfoPage";
import TeamsPage from "./TeamsPage";
import UserInfoPage from "./UserInfoPage";
import UsersListPage from "./UsersListPage";

const RequireUUIDParams: React.FC<Omit<RequireUUIDParamsProps, "element">> = (
  props
) => (
  <RequireUUIDParamsBase element={<Navigate to="/not-found" />} {...props} />
);

const UsersPageBeta: React.FC = () => {
  const currentUser = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();

  const baseRoute = "/settings/users";
  const routeName = (s: string): string => baseRoute.concat(s);

  const tabs = [
    {
      displayName: "Users",
      route: "/list",
      subRoutes: ["/info/:id", "/activity"],
    },
    {
      displayName: "Hiring teams",
      route: "/teams",
      subRoutes: ["/teams/info/:id"],
    },
  ];

  if (currentUser.userRole?.canManageUserRoles) {
    tabs.push(
      {
        displayName: "Roles & policies",
        route: "/roles",
        subRoutes: ["/roles/:tabName"],
      },
      {
        displayName: "Excluded users",
        route: "/excluded",
        subRoutes: [],
      }
    );
  }

  const initialTabIdx = tabs.findIndex((t) =>
    [t.route, ...t.subRoutes]
      .map(routeName)
      .some((path) => matchPath(path, location.pathname))
  );

  useEffect(() => {
    if (initialTabIdx === -1) {
      navigate(routeName("/list"), { replace: true });
    }
  }, []);

  return (
    <Tabs
      defaultIndex={initialTabIdx !== -1 ? initialTabIdx : undefined}
      isLazy
    >
      <TabList>
        {tabs.map(({ displayName, route }) => (
          <Tab
            key={displayName}
            fontWeight="semibold"
            onClick={() => navigate(routeName(route), { replace: true })}
          >
            {displayName}
          </Tab>
        ))}
      </TabList>
      <TabPanels mt="4">
        <Routes>
          <Route path="/list" element={<UsersListPage w="auto" />} />
          <Route
            path="/info/:id"
            element={
              <RequireUUIDParams paramNames={["id"]}>
                <UserInfoPage />
              </RequireUUIDParams>
            }
          />
          <Route path="/activity" element={<ActivityPage />} />

          <Route path="/teams" element={<TeamsPage />} />
          <Route
            path="/teams/info/:id"
            element={
              <RequireUUIDParams paramNames={["id"]}>
                <TeamInfoPage />
              </RequireUUIDParams>
            }
          />

          {currentUser.userRole?.canManageUserRoles && (
            <>
              <Route path="/roles" element={<RolePoliciesPage />} />
              <Route path="/roles/:tabName" element={<RolePoliciesPage />} />

              <Route path="/excluded" element={<ExcludedUsers />} />
            </>
          )}

          <Route
            path="*"
            element={<Navigate to={routeName("/list")} replace />}
          />
        </Routes>
      </TabPanels>
    </Tabs>
  );
};

export default UsersPageBeta;
