import { Theme } from "react-select";

import { useTheme } from "../components";

// react-select types are complex and difficult to use in a wrapper.
// useSelectTheme returns custom theme and style props.
// see https://react-select.com/styles
const useSelectTheme = (
  styles?: Record<string, any>
): [(theme: Theme) => Theme, Record<string, any>] => {
  const theme = useTheme();
  return [
    (provided: Theme) => ({
      ...provided,
      colors: {
        ...provided.colors,
        text: theme.primary,
        neutral: theme.primary,
        neutral20: theme.colors.gray[300],
        neutral50: theme.colors.gray[400],
        neutral75: theme.colors.gray[500],
        primary25: theme.colors.blue[50],
        primary50: theme.colors.blue[300],
        primary75: theme.colors.blue[400],
        primary: theme.colors.blue[500],
      },
    }),
    {
      menu: (provided: Record<string, any>) => ({
        ...provided,
        zIndex: 3,
      }),
      menuPortal: (styles: Record<string, any>) => ({
        ...styles,
        zIndex: 1500,
      }),
      control: (styles: Record<string, any>) => ({
        ...styles,
        minHeight: "40px",
        borderRadius: theme.radii.sm,
      }),
      multiValue: (styles: Record<string, any>) => ({
        ...styles,
        backgroundColor: theme.colors.blue[50],
      }),
      multiValueLabel: (styles: Record<string, any>) => ({
        ...styles,
        color: theme.colors.blue[700],
      }),
      multiValueRemove: (styles: Record<string, any>) => ({
        ...styles,
        color: theme.colors.blue[300],
        ":hover": {
          backgroundColor: theme.colors.blue[300],
          color: "white",
        },
      }),
      ...styles,
    },
  ];
};

export default useSelectTheme;
