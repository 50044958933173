import { Box, Heading, HStack, Text } from "@chakra-ui/react";
import React from "react";

import { LoadingIndicator, useToast } from "../../../components";
import { AutoJoinPositionGroupPopover } from "../../components";
import {
  CurrentUserFragment,
  PositionListItemFragment,
  useOrgPositionsQuery,
  useUpdatePositionsAutoJoinMutation,
} from "../../graphql";
import AutoJoinByPositionList from "./AutoJoinByPositionList";

interface AutoJoinByPositionProps {
  currentUser: CurrentUserFragment;
}

const AutoJoinByPositionSettings: React.FC<AutoJoinByPositionProps> = ({
  currentUser,
}) => {
  const toast = useToast();

  const { data: positionsData, loading: positionsLoading } =
    useOrgPositionsQuery({ variables: { atsOnly: true } });
  const positions =
    positionsData?.currentUser?.organization?.positions.results ?? [];
  const orgAutoJoinAllNewPositions =
    positionsData?.currentUser?.organization?.autoJoinAllNewPositions ?? false;

  const [updatePositionsAutoJoin] = useUpdatePositionsAutoJoinMutation({
    onError: (err) => {
      toast({
        status: "error",
        title: "Error",
        description: `Failed to turn on Auto Join Video Interview: ${err.message}`,
      });
    },
    onCompleted: (data) => {
      if (
        data?.updatePositionsAutoJoin &&
        data?.updatePositionsAutoJoin.positions.length > 0
      ) {
        toast({
          title: "Success",
          description: `Updating current scheduled interviews. BrightHire will ${
            data?.updatePositionsAutoJoin.positions[0].autoJoinInterview
              ? "automatically join."
              : "NOT automatically join."
          }`,
          status: "success",
        });
      }
    },
  });

  const handleUpdatePositionsAutoJoin = (
    positions: Array<PositionListItemFragment>,
    autoJoinInterview: boolean
  ): void => {
    const positionIds = positions.map((position) => position.id);
    updatePositionsAutoJoin({
      variables: { positionIds, autoJoinInterview },
    });
  };

  return (
    <Box w="fit-content">
      <Heading as="h1" size="md" mb="4">
        Auto Join By Position
      </Heading>
      <Text mb="8">
        BrightHire will automatically join all Zoom interviews for the positions
        selected below.
      </Text>
      <Heading as="h2" size="sm" mb="4">
        Positions
      </Heading>
      <HStack mb="24px">
        <AutoJoinPositionGroupPopover
          positions={positions}
          orgAutoJoinAllNewPositions={orgAutoJoinAllNewPositions}
        />
      </HStack>
      {positionsLoading ? (
        <LoadingIndicator />
      ) : (
        <AutoJoinByPositionList
          positions={positions}
          updatePositionsAutoJoin={handleUpdatePositionsAutoJoin}
        />
      )}
    </Box>
  );
};

export default AutoJoinByPositionSettings;
