import { Box, Flex, IconButton, Text, Textarea } from "@chakra-ui/react";
import React, { FC } from "react";
import { HiOutlineTrash } from "react-icons/hi";

import { CommonGuideItemProps } from "../types";
import GuideItemLabel from "./GuideItemLabel";

const GuideItemText: FC<CommonGuideItemProps> = ({
  itemId,
  text,
  isEditing,
  onDelete,
  onUpdate,
}) => {
  if (isEditing) {
    return (
      <Flex direction="row" alignItems="flex-start">
        <Box flex={1}>
          <GuideItemLabel text="Text block" tooltipText="TBD" />
          <Textarea
            autoFocus={isEditing}
            value={text}
            onChange={(e) => onUpdate(itemId, { description: e.target.value })}
          />
        </Box>

        <IconButton
          variant="icon"
          aria-label="Delete Text block"
          color="red.400"
          icon={<HiOutlineTrash size={20} />}
          ml={6}
          onClick={onDelete}
        />
      </Flex>
    );
  }
  return <Text color="gray.800">{text}</Text>;
};

export default GuideItemText;
