import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";

import { Alert, LoadingIndicator, useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  useTextMessageTemplateQuery,
  useUpdateTextMessageTemplateMutation,
} from "../../graphql";
import TemplateSettingsForm, { FormData } from "./TemplateSettingsForm";

const TemplateSettings: React.FC = () => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const { data, loading, error } = useTextMessageTemplateQuery({
    variables: { templateType: "CALL_BACK" },
  });
  const [
    updateTextMessageTemplate,
    { loading: updateLoading, error: updateError },
  ] = useUpdateTextMessageTemplateMutation({
    onCompleted: (user) => {
      if (user) {
        toast({
          title: "Settings saved",
          status: "success",
        });
      }
    },
    onError: () => {
      toast({
        title: "Error updating template",
        status: "error",
      });
    },
  });

  const onSubmit = (formData: FormData): void => {
    sendGAEvent("updated", "template_settings");
    updateTextMessageTemplate({
      variables: {
        templateType: "CALL_BACK",
        ...formData,
      },
    });
  };

  return (
    <Box w="600px">
      <Heading as="h1" size="md" mb={4}>
        Text Message Templates
      </Heading>
      <Text fontSize="sm" mb={4} color="placeholder">
        Customize the templates below to quickly send follow up texts from
        Interview Assistant.
      </Text>
      {error && <Alert status="error" description="Error fetching template" />}
      {loading ? (
        <LoadingIndicator />
      ) : (
        <TemplateSettingsForm
          title="Call Back Text"
          instructions={[
            "Use this template to send a text message to a recipient directly from the Interview Assistant if they don’t answer your call. The text gives the recipient a BrightHire call back number that will route to your registered phone and be captured by BrightHire.",
            "You’ll then be able to use the Interview Assistant during the call from the app. This template can always be edited before sending a text from the pop-up window.",
            "Note: the text will be sent from a BrightHire number dedicated to your organization, not your registered phone number.",
          ]}
          content={data?.textMessageTemplate?.content || ""}
          error={updateError}
          isLoading={updateLoading}
          onSubmit={onSubmit}
        />
      )}
    </Box>
  );
};

export default TemplateSettings;
