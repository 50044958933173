import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useEffect } from "react";

import { EmptyAIIcon } from "../../../../../components/Images";
import { useSendGAEvent } from "../../../../../utils/googleAnalytics";
import {
  CallAiSummaryFormat,
  CallAiSummaryProcessingStatus,
  CallBetaFragment,
  TranscriptionStatus,
  useCallAiSummaryLazyQuery,
} from "../../../../graphql";
import { useAvailableAiSummaryFormats } from "../../../../hooks/useAvailableAiSummaryFormats";
import useMouseHasMoved from "../../../../hooks/useMouseHasMoved";
import AiNotesMessage from "../../../Interview/beta/AiNotesMessage";
import AISummary from "./AISummary";
import AiTopics from "./AiTopics";

type AiTopicsTabProps = {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "canEdit"
    | "candidate"
    | "position"
    | "startTime"
    | "speakers"
    | "transcriptionStatus"
    | "aiNotesFormat"
  >;
  hasLabeledCandidate: boolean;
  onClickTimestamp(t: number): void;
  format: CallAiSummaryFormat | "AI_NOTES";
};

const AiTopicsTab: React.FC<AiTopicsTabProps> = ({
  call,
  hasLabeledCandidate,
  onClickTimestamp,
  format,
}) => {
  const toast = useToast();
  const availableFormats = useAvailableAiSummaryFormats();
  const canUseOtherAISummaryFormats = availableFormats.length > 0;
  const mouseHasMoved = useMouseHasMoved();
  useEffect(() => {
    LogRocket.track("ai-notes-tab-view");
    sendGAEvent("ai_notes_tab_view", "call_review");
  }, [mouseHasMoved]);
  const sendGAEvent = useSendGAEvent();
  const transcriptAvailable =
    call.transcriptionStatus === TranscriptionStatus.Completed;

  const [fetchSummary, aiSummary] = useCallAiSummaryLazyQuery({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error generating summary",
        status: "error",
        position: "top",
      });
    },
  });

  const aiSummaryStatus = aiSummary.data?.callAiSummary?.status;
  const aiSummaryFormat = aiSummary.data?.callAiSummary?.format;
  const aiSummaryProcessingStartedAt =
    aiSummary.data?.callAiSummary?.processingStartedAt;

  useEffect(() => {
    if (!canUseOtherAISummaryFormats) {
      aiSummary.stopPolling();
      return;
    }
    if (format === "AI_NOTES") {
      aiSummary.stopPolling();
      return;
    }

    if (!availableFormats.includes(format)) {
      aiSummary.stopPolling();
      return;
    }

    if (aiSummaryFormat === format) {
      if (
        aiSummaryStatus &&
        [
          CallAiSummaryProcessingStatus.Completed,
          CallAiSummaryProcessingStatus.Failed,
        ].includes(aiSummaryStatus)
      ) {
        aiSummary.stopPolling();
        return;
      }
    }

    fetchSummary({
      variables: { callId: call.id, format },
      pollInterval: 5000,
      fetchPolicy: "network-only",
    });
  }, [
    canUseOtherAISummaryFormats,
    format,
    fetchSummary,
    aiSummary.stopPolling,
    aiSummaryStatus,
    aiSummaryFormat,
    aiSummaryProcessingStartedAt,
    availableFormats,
    call.id,
  ]);

  if (!transcriptAvailable) {
    return (
      <AiTopicsTabWrapper>
        <AiNotesMessage>
          <EmptyAIIcon marginBottom="64px" />
          <Text align="center" maxW="360px">
            Hold tight! AI Notes are generated once the transcript for the
            interview is ready.
          </Text>
        </AiNotesMessage>
      </AiTopicsTabWrapper>
    );
  }

  if (format !== "AI_NOTES" && availableFormats.includes(format)) {
    if (aiSummary.data != null) {
      return (
        <AiTopicsTabWrapper>
          <AISummary
            data={aiSummary.data}
            callId={call.id}
            onClickTimestamp={onClickTimestamp}
            loading={aiSummary.loading}
            onRetry={() => {
              fetchSummary({
                variables: { callId: call.id, format },
                pollInterval: 5000,
                fetchPolicy: "network-only",
              });
            }}
          />
        </AiTopicsTabWrapper>
      );
    }
    return <AiTopicsTabWrapper />;
  }

  if (!hasLabeledCandidate) {
    return (
      <AiTopicsTabWrapper>
        <AiNotesMessage>
          <EmptyAIIcon marginBottom="64px" />
          <Text align="center" maxW="360px">
            AI Notes are not available for this interview because we were unable
            to identify a candidate.
          </Text>
          {canUseOtherAISummaryFormats && (
            <Text align="center" mt={3} mb={3} maxW="360px">
              If this was not an interview, try selecting a different AI Notes
              format.
            </Text>
          )}
        </AiNotesMessage>
      </AiTopicsTabWrapper>
    );
  }

  return (
    <AiTopicsTabWrapper>
      <AiTopics call={call} onClickTimestamp={onClickTimestamp} />
    </AiTopicsTabWrapper>
  );
};

const AiTopicsTabWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Flex
      data-tour-id="ai-notes-tab-content"
      flexDir="column"
      h="100%"
      overflow="hidden"
      position="relative"
    >
      <Box flex="1" overflow="auto">
        {children}
        <Box
          id="ai-notes-feedback"
          height="65px" // 65px is the height of the feedback bar
        />
      </Box>
    </Flex>
  );
};

export default AiTopicsTab;
