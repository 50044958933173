import { Box, IconButton, Image, Tooltip } from "@chakra-ui/react";
import { randomColor } from "@chakra-ui/theme-tools";
import React from "react";
import { HiOutlineClipboardList } from "react-icons/hi";
import {
  HiOutlineArrowRightOnRectangle,
  HiOutlineChatBubbleLeftRight,
  HiOutlineCog8Tooth,
  HiOutlineMagnifyingGlass,
  HiOutlineQuestionMarkCircle,
} from "react-icons/hi2";
import { matchPath, useLocation } from "react-router-dom";

import { CurrentUserFragment } from "../../main/graphql";
import { CurrentPlanUserFragment } from "../../plan/graphql";
import { asArray } from "../../utils/array";
import { canManageTraining, canViewAllMetrics } from "../../utils/permissions";
import { GradCapIcon, InsightsIcon } from "../Icons";
import { NavItem, SubNavItem } from "./SidebarNavItem";

type NavItemsParams = {
  app: "main" | "plan";
  enabledProducts: Array<"interview" | "plan">;
  availableProducts: Array<"interview" | "plan">;
  currentUser?: CurrentUserFragment | CurrentPlanUserFragment;
  onClick?(navItem: NavItem | SubNavItem): void;
  onSignOut?(): void;
};

const useIsActive = (): ((paths: string | string[]) => boolean) => {
  const location = useLocation();

  return (paths) => {
    return asArray(paths).some((path) => matchPath(path, location.pathname));
  };
};

/**
 * Prepares the items that will go into page navigation for the current user
 */
export const useNavItems = ({
  app,
  enabledProducts,
  availableProducts,
  currentUser,
  onClick,
  onSignOut,
}: NavItemsParams): [NavItem[], NavItem[]] => {
  const isActive = useIsActive();
  if (!currentUser) return [[], []];

  // Search
  const search: NavItem = {
    icon: HiOutlineMagnifyingGlass,
    label: "Search",
    isExternal: app !== "main",
    to: app !== "main" ? "/search" : undefined,
  };

  // Plan
  const planEnabled = enabledProducts.includes("plan");
  const plan: NavItem = {
    icon: HiOutlineClipboardList,
    label: "Plan",
    isActive: isActive(["/plan", "/plan/*"]),
    to: planEnabled ? "/plan/job-descriptions" : "/plan/upgrade",
    enabled: planEnabled,
    isExternal: app !== "plan",
  };

  // Interview
  const interview: NavItem = {
    icon: HiOutlineChatBubbleLeftRight,
    label: "Interview",
    enabled: enabledProducts.includes("interview"),
  };
  if (interview.enabled) {
    interview.subItems = [
      {
        label: "Recordings",
        to: "/search",
        isExternal: app === "plan",
        isActive: isActive(["/search", "/interview/*"]),
      },
      {
        label: "Candidates",
        to: "/candidates",
        isExternal: app === "plan",
        isActive: isActive(["/candidates", "/candidate/:id/*"]),
      },
      {
        label: "Positions",
        to: "/positions",
        isExternal: app === "plan",
        isActive: isActive(["/positions/*", "/position/:id/*"]),
      },
      {
        label: "Interview Guides",
        to: "/guides",
        isExternal: app === "plan",
        isActive: isActive(["/guides/*", "/guide/:id/*"]),
      },
      {
        label: "Clips",
        to: "/clips",
        isExternal: app === "plan",
        isActive: isActive("/clips"),
      },
      {
        label: "Playlists",
        to: "/playlists",
        isExternal: app === "plan",
        isActive: isActive(["/playlists/*", "/playlist/:id"]),
      },
    ];
  } else {
    interview.to = "/upgrade";
    interview.isActive = isActive(interview.to);
    interview.isExternal = app !== "main";
  }

  // Training
  const trainingManage: NavItem = {
    icon: GradCapIcon,
    label: "Train",
    subItems: [
      {
        label: "Manage Programs",
        to: "/training/manage",
        isExternal: app === "plan",
        isActive:
          isActive(["/training/manage", "/training/manage/:id"]) &&
          !isActive("/training/manage/all-trainees"),
      },
      {
        label: "All Trainees",
        to: "/training/manage/all-trainees",
        isExternal: app === "plan",
        isActive: isActive("/training/manage/all-trainees"),
      },
      {
        label: "My Training",
        to: "/training/view",
        isExternal: app === "plan",
        isActive: isActive("/training/view/*"),
      },
    ],
  };
  const trainingView: NavItem = {
    icon: GradCapIcon,
    label: "Train",
    isActive: isActive("/training/*"),
    to: "/training/view",
    isExternal: app === "plan",
  };
  const training = canManageTraining(currentUser)
    ? trainingManage
    : trainingView;

  const mainNavItems = [search, plan, interview, training];
  if (!availableProducts.includes("plan")) mainNavItems.splice(1, 1);

  // Insights
  const insights: NavItem = {
    icon: InsightsIcon,
    label: "Insights",
    to: "/insights",
    isActive: isActive("/insights/*"),
    isExternal: app === "plan",
  };

  if (canViewAllMetrics(currentUser)) {
    mainNavItems.push(insights);
  }

  const help: NavItem = {
    icon: HiOutlineQuestionMarkCircle,
    label: "Help",
    to: "/support",
    isActive: isActive("/support"),
  };

  const settings: NavItem = {
    icon: HiOutlineCog8Tooth,
    label: "Configure",
    to: "/settings/user",
    isExternal: app === "plan",
    isActive: isActive("/settings/*"),
  };

  const user: NavItem = {
    icon: (props) => {
      const fallback = (
        <Box
          borderRadius="full"
          bg={randomColor({ string: currentUser.fullName })}
          boxSize="6"
          fontSize="xs"
          border="1px solid white"
          {...props}
        >
          <Box
            display="flex"
            h="100%"
            alignItems="center"
            justifyContent="center"
          >
            {getInitials(currentUser)}
          </Box>
        </Box>
      );

      if (!currentUser.profilePicUrl) {
        return fallback;
      }

      return (
        <Image
          src={currentUser.profilePicUrl}
          fallback={fallback}
          fallbackStrategy="onError"
          borderRadius="full"
          border="1px solid white"
          {...props}
        />
      );
    },
    label: currentUser.fullName,
    tourId: "nav-item-user",
    static: true,
    endSlot: onSignOut && (
      <Tooltip
        label={currentUser.isImpersonated ? "Un-impersonate" : "Sign out"}
        openDelay={500}
        placement="left"
      >
        <IconButton
          aria-label="Sign out"
          icon={<HiOutlineArrowRightOnRectangle size="24" />}
          bg="transparent"
          _hover={{ bg: "whiteAlpha.400" }}
          _active={{ bg: "whiteAlpha.300" }}
          minW="unset"
          boxSize="10"
          p="1"
          onClick={onSignOut}
        />
      </Tooltip>
    ),
  };

  const addClickHandler = ({ subItems, ...navItem }: NavItem): NavItem => ({
    ...navItem,
    onClick: () => onClick?.(navItem),
    subItems: subItems?.map((subItem) => ({
      ...subItem,
      onClick: () => onClick?.(subItem),
    })),
  });

  const footerNavItems = [help, settings, user];

  return [
    mainNavItems.map(addClickHandler),
    footerNavItems.map(addClickHandler),
  ];
};

const getInitials = (
  user: CurrentUserFragment | CurrentPlanUserFragment
): string => {
  const names = user.fullName.split(" ");
  if (names.length > 1) {
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  }
  return names[0].charAt(0);
};
