import React from "react";

import { useFontVariantNumericStrategy } from "../../hooks/useFontVariantNumericStrategy";

type MonospacedTextProps = {
  text: string;
};

const MonospacedText: React.FC<MonospacedTextProps> = ({ text }) => {
  useFontVariantNumericStrategy();
  const fontVariant = {
    strategy: window.fvStrategy,
    width: window.fvWidth,
  };
  // If there's no text, no care
  if (!text) {
    return <span />;
  }
  // If we should use the span strategy, put each character in its own span :(
  if (fontVariant.strategy === "span") {
    const chars = [...text];
    return (
      <span style={{ whiteSpace: "nowrap" }}>
        {chars.map((char, i) => {
          return (
            <span
              style={{
                display: "inline-block",
                width: fontVariant.width ?? "0.7em",
                textAlign: "center",
              }}
              key={`mono-char-${i + 1}`}
            >
              {char}
            </span>
          );
        })}
      </span>
    );
  }
  // Otherwise, use tabular-nums :)
  return (
    <span style={{ whiteSpace: "nowrap", fontVariantNumeric: "tabular-nums" }}>
      {text}
    </span>
  );
};

export default MonospacedText;
