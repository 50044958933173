import { AnimationProps, Variants } from "framer-motion";

export const COLLAPSED_WIDTH = 56;
export const EXPANDED_WIDTH = 240;

export const EXPAND_DELAY = 0.2;
export const COLLAPSE_DELAY = 0.5;

export const sidebarAnimation: AnimationProps = {
  initial: false,
  variants: {
    expanded: ({ skipAnimationDelay }) => ({
      width: EXPANDED_WIDTH,
      transition: {
        delay: skipAnimationDelay ? 0 : EXPAND_DELAY,
        type: "spring",
        stiffness: 1200,
        damping: 80,
      },
    }),
    collapsed: ({ skipAnimationDelay }) => ({
      width: COLLAPSED_WIDTH,
      transition: {
        delay: skipAnimationDelay ? 0 : COLLAPSE_DELAY,
        type: "spring",
        stiffness: 1200,
        damping: 60,
      },
    }),
  },
};

export const exitOnCollapse: Variants = {
  exit: ({ skipAnimationDelay } = {}) => ({
    opacity: 1,
    transition: { duration: 0, delay: skipAnimationDelay ? 0 : COLLAPSE_DELAY },
  }),
};

export const exitOnExpand: Variants = {
  exit: ({ skipAnimationDelay } = {}) => ({
    opacity: 1,
    transition: { duration: 0, delay: skipAnimationDelay ? 0 : EXPAND_DELAY },
  }),
};
