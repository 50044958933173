import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const ThreeDots = (props: IconProps): JSX.Element => (
  <Icon {...props} viewBox="0 0 8 2">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1C2 1.55228 1.55228 2 1 2C0.447715 2 0 1.55228 0 1ZM3 1C3 0.447715 3.44772 0 4 0C4.55228 0 5 0.447715 5 1C5 1.55228 4.55228 2 4 2C3.44772 2 3 1.55228 3 1ZM7 0C6.44772 0 6 0.447715 6 1C6 1.55228 6.44772 2 7 2C7.55228 2 8 1.55228 8 1C8 0.447715 7.55228 0 7 0Z"
      fill="currentColor"
    />
  </Icon>
);

export default ThreeDots;
