import { IconProps } from "@chakra-ui/react";
import { IconType } from "react-icons";
import {
  IoMdFlag,
  IoMdStar,
  IoMdThumbsDown,
  IoMdThumbsUp,
} from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { MdComment } from "react-icons/md";

import ChatOval from "../../../components/Icons/ChatOval";
import colors from "../../../theme/css-color-variables";
import { CallNoteFragment, CallNoteType } from "../../graphql";

export const noteTypeIcon = (
  type: CallNoteType
): IconType | React.FC<IconProps> => {
  switch (type) {
    case CallNoteType.Cue:
      return IoLocationSharp;
    case CallNoteType.HighlightV2:
      return IoLocationSharp;
    case CallNoteType.Star:
      return IoMdStar;
    case CallNoteType.ThumbsUp:
      return IoMdThumbsUp;
    case CallNoteType.ThumbsDown:
      return IoMdThumbsDown;
    case CallNoteType.Flag:
      return IoMdFlag;
    case CallNoteType.Comment:
      return MdComment;
    case CallNoteType.Note:
      return ChatOval;
    default:
      throw new Error("Invalid note type variant");
  }
};

/**
 * Construct the `href` for a note link
 */
export const noteTimestampedLink = (
  note: Pick<CallNoteFragment, "callId" | "time">,
  time?: number
): string => {
  const { protocol, host } = window.location;
  const url = new URL(`${protocol}//${host}`);
  url.pathname = `/interview/${note.callId}`;
  const timestamp = `${time !== undefined ? time : note.time}`;
  url.searchParams.set("t", timestamp);
  return url.href;
};

type ThemeColors = {
  gray: {
    300: string;
  };
  icons: {
    comment: string;
    cue: string;
    flag: string;
    highlightV2: string;
    note: string;
    star: string;
    thumbsDown: string;
    thumbsUp: string;
  };
};

// Get the color for a note using a given theme. This is used when you need
// to get the hex value of a color rather than the CSS variable.
export const noteTypeColorFromTheme = (
  type: CallNoteType,
  colors: ThemeColors
): string => {
  switch (type) {
    case CallNoteType.Note:
      return colors.icons.note;
    case CallNoteType.Comment:
      return colors.icons.comment;
    case CallNoteType.Cue:
      return colors.icons.cue;
    case CallNoteType.Star:
      return colors.icons.star;
    case CallNoteType.ThumbsUp:
      return colors.icons.thumbsUp;
    case CallNoteType.ThumbsDown:
      return colors.icons.thumbsDown;
    case CallNoteType.Flag:
      return colors.icons.flag;
    case CallNoteType.HighlightV2:
      return colors.icons.highlightV2;
    default:
      return colors.gray[300];
  }
};

export const noteTypeColor = (type: CallNoteType): string => {
  switch (type) {
    case CallNoteType.Note:
      return colors.icons.note;
    case CallNoteType.Comment:
      return colors.icons.comment;
    case CallNoteType.Cue:
      return colors.icons.cue;
    case CallNoteType.Star:
      return colors.icons.star;
    case CallNoteType.ThumbsUp:
      return colors.icons.thumbsUp;
    case CallNoteType.ThumbsDown:
      return colors.icons.thumbsDown;
    case CallNoteType.Flag:
      return colors.icons.flag;
    case CallNoteType.HighlightV2:
      return colors.icons.highlightV2;
    default:
      return colors.gray[300];
  }
};

// Beta IA has a different color scheme for flag and star
// this change can be made global in following work
export const noteTypeColorBeta = (
  type: CallNoteType,
  hover?: boolean
): string => {
  switch (type) {
    case CallNoteType.Star:
      return hover ? "yellow.100" : "yellow.500";
    case CallNoteType.Flag:
      return hover ? "red.100" : "red.500";
    default:
      return colors.gray[300];
  }
};

export const noteTypeBackgroundColor = (type: CallNoteType): string => {
  switch (type) {
    case CallNoteType.Star:
      return colors.yellow[100];
    case CallNoteType.ThumbsUp:
      return colors.green[100];
    case CallNoteType.ThumbsDown:
      return colors.red[100];
    case CallNoteType.Flag:
      return colors.orange[100];
    default:
      return colors.gray[300];
  }
};

export const iconForNote = (note: Pick<CallNoteFragment, "type">): string => {
  switch (note.type) {
    case CallNoteType.Star:
      return "⭐";
    case CallNoteType.ThumbsUp:
      return "👍";
    case CallNoteType.ThumbsDown:
      return "👎";
    case CallNoteType.Flag:
      return "🚩";
    default:
      return "";
  }
};

export const reactionTooltipLabel = (type: CallNoteType): string => {
  switch (type) {
    case CallNoteType.Star:
      return "Noteworthy";
    case CallNoteType.ThumbsUp:
      return "Good answer";
    case CallNoteType.ThumbsDown:
      return "Poor answer";
    case CallNoteType.Flag:
      return "Potential concern";
    default:
      return "Reaction";
  }
};

// Shared styles that should be consistent between
// <CallNoteBeta /> and <TimestampedNoteBeta />
export const getNoteStyleProps = (
  timestampLeft?: boolean,
  longDuration?: boolean
): any => ({
  contentEditable: {
    flex: "1",
    ml: "3",
    mt: "1",
  },
  timestamp: {
    mt: "1",
    ml: timestampLeft ? "3" : "0",
    mr: timestampLeft ? "2" : "0",
    textAlign: timestampLeft ? "start" : "end",
  },
  timestampCol: {
    minW: longDuration ? "68px" : "58px",
  },
});

// Note types displayed in <CallNoteList />
export const notesListNoteTypes = [
  CallNoteType.Note,
  CallNoteType.Flag,
  CallNoteType.Star,
];

// Note types used as question timestamps
export const timestampNoteTypes = [CallNoteType.Cue, CallNoteType.HighlightV2];
