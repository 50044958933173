import { addSeconds, isPast } from "date-fns";

const buildProgressMessage = (endTime: string): string => {
  const timeLeft = getTranscriptionTimeLeft(endTime);

  return isPast(timeLeft.paddedTimestamp)
    ? "This is taking longer than expected. We apologize for any inconvenience."
    : `This should take about ${timeLeft.inMinutes} ${
        timeLeft.inMinutes > 1 ? "minutes" : "minute"
      }.`;
};

type TimeLeft = {
  paddedTimestamp: Date;
  inMinutes: number;
};

const getTranscriptionTimeLeft = (endTime: string): TimeLeft => {
  const inMinutes = 15;
  const paddedTimestamp = addSeconds(new Date(endTime), inMinutes * 60);

  return { paddedTimestamp, inMinutes };
};

export { buildProgressMessage };
