import {
  Button,
  Icon,
  Link,
  LinkProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { IoChevronDown } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";

import useCurrentBreakpoint from "../../hooks/useCurrentBreakpoint";
import { useTheme } from "..";
import { LinkNavItem, NavLinkItem } from "./types";

interface PageHeaderNavLinkProps {
  navLink: NavLinkItem;
  index?: number;
  placement?: "mobile" | "desktop";
}

export const PageHeaderNavLink: React.FC<PageHeaderNavLinkProps> = ({
  navLink,
  index,
  placement,
}) => {
  const theme = useTheme();
  const currentBreakpoint = useCurrentBreakpoint();
  const location = useLocation();

  if (navLink.type === "link") {
    return <LinkView linkNavItem={navLink} />;
  }

  if (currentBreakpoint === "sm" || currentBreakpoint === "md") {
    return (
      <>
        {navLink.items.map((menuItem) => (
          <LinkView
            key={menuItem.label}
            linkNavItem={{
              type: "link",
              label: menuItem.label,
              path: menuItem.path,
              isCrossAppLink: menuItem.isCrossAppLink,
            }}
          />
        ))}
      </>
    );
  }

  return (
    <Menu placement="bottom" gutter={10}>
      <MenuButton
        data-testid={`header-nav-link-${navLink.label.toLowerCase()}`}
        as={Button}
        rightIcon={<IoChevronDown />}
        color="gray.900"
        background="transparent"
        fontWeight={theme.fontWeights.semibold}
        borderColor={
          navLink.isActive?.(location) ? theme.colors.blue[400] : "transparent"
        }
        borderBottomWidth="2px"
        minHeight="47px"
        padding={2}
        borderRadius={0}
        _hover={{
          color: "gray.900",
          background: theme.colors.gray[50],
        }}
        _active={{
          color: "gray.900",
          background: theme.colors.gray[50],
        }}
        _focus={{
          boxShadow: "var(--chakra-shadows-outline)",
        }}
      >
        {navLink.label}
      </MenuButton>
      <MenuList borderRadius="4px" borderColor="gray.100">
        {navLink.items.map((menuItem) => (
          <CrossAppLink
            key={menuItem.label}
            path={menuItem.path}
            isCrossAppLink={menuItem.isCrossAppLink}
            color="gray.900"
            fontWeight={theme.fontWeights.semibold}
            _hover={{
              color: "gray.900",
              textDecoration: "none",
            }}
          >
            <MenuItem
              _hover={{
                backgroundColor: theme.colors.gray[50],
              }}
              fontSize="sm"
              icon={
                menuItem.icon ? (
                  <Icon
                    as={menuItem.icon}
                    boxSize="5"
                    color="gray.900"
                    marginLeft="0"
                  />
                ) : undefined
              }
            >
              {menuItem.label}
            </MenuItem>
          </CrossAppLink>
        ))}
      </MenuList>
    </Menu>
  );
};

type LinkViewProps = {
  linkNavItem: LinkNavItem;
};

const LinkView: React.FC<LinkViewProps> = ({ linkNavItem }) => {
  const theme = useTheme();

  const location = useLocation();
  const isActiveOverride = linkNavItem.isActive?.(location);
  const activeStyle = {
    color: "primary",
    borderColor: theme.colors.blue[400],
  };
  const styleFunc = useCallback(({ isActive: isA }: { isActive: boolean }) => {
    if (isA || isActiveOverride) return activeStyle;
    return {};
  }, []);

  return (
    <CrossAppLink
      data-testid={`header-nav-link-${linkNavItem.label.toLowerCase()}`}
      data-tour-id={linkNavItem.tourId}
      path={linkNavItem.path}
      isCrossAppLink={linkNavItem.isCrossAppLink}
      // Since we are using as={NavLink} the style property actually calls
      // a function, but the typescript interpreter doesn't know this.
      //
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={linkNavItem.isCrossAppLink ? undefined : styleFunc}
      end={linkNavItem.exact}
      paddingTop={{ base: 2, lg: "auto" }}
      paddingRight={{ base: "pagePaddingMobile", md: "pagePadding", lg: 3 }}
      paddingBottom={{ base: 2, lg: "auto" }}
      paddingLeft={{ base: "pagePaddingMobile", md: "pagePadding", lg: 3 }}
      height={{ base: "auto", lg: "100%" }}
      width={{ base: "100%", lg: "auto" }}
      backgroundColor={{ base: theme.colors.white, lg: "transparent" }}
      alignSelf="stretch"
      display="inline-flex"
      alignItems="center"
      whiteSpace="nowrap"
      color="primary"
      borderStyle="solid"
      borderColor="transparent"
      borderTopWidth={0}
      borderRightWidth={0}
      borderBottomWidth={{ base: 0, lg: "2px" }}
      borderLeftWidth={{ base: "4px", lg: 0 }}
      fontWeight={theme.fontWeights.semibold}
      _hover={{
        backgroundColor: theme.colors.gray[50],
      }}
    >
      {linkNavItem.label}
      {linkNavItem.badge && (
        <Tag colorScheme="blue" ml={2}>
          <TagLabel>{linkNavItem.badge}</TagLabel>
        </Tag>
      )}
    </CrossAppLink>
  );
};

const CrossAppLink: React.FC<
  {
    children: any;
    isCrossAppLink?: boolean;
    path: string;
  } & LinkProps
> = ({ children, isCrossAppLink, path, ...linkProps }) => {
  if (isCrossAppLink) {
    return (
      <Link href={path} {...linkProps}>
        {children}
      </Link>
    );
  }

  return (
    <Link as={NavLink} to={path} {...linkProps}>
      {children}
    </Link>
  );
};
