import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { IoIosMenu } from "react-icons/io";

import useCurrentBreakpoint from "../../hooks/useCurrentBreakpoint";
import { mobileBreakpoints } from "../../theme/breakpoints";
import { useTheme } from "..";
import { PageHeaderNavLink } from "./PageHeaderNavLink";
import { LinkNavItem, NavLinkItem } from "./types";

interface PageHeaderNavMenuProps {
  navLinks?: NavLinkItem[];
  renderSearch?: () => React.ReactNode;
}

export const PageHeaderNavMenu: React.FC<PageHeaderNavMenuProps> = ({
  navLinks,
  renderSearch,
}) => {
  const theme = useTheme();
  const currentBreakpoint = useCurrentBreakpoint();
  const { onToggle } = useDisclosure();

  const isMobile = mobileBreakpoints.includes(currentBreakpoint);

  if (isMobile) {
    // Convert menu items to flat list of links
    const mobileNavLinks: LinkNavItem[] = (navLinks || []).flatMap(
      (navLink): LinkNavItem | LinkNavItem[] => {
        if (navLink.type === "menu") {
          return navLink.items.map((menuItem) => ({
            type: "link",
            label: menuItem.mobileLabel || menuItem.label,
            path: menuItem.path,
            isCrossAppLink: menuItem.isCrossAppLink,
          }));
        }
        return navLink;
      }
    );

    // Popper menu for desktop display so it plays nice with other menus
    return (
      <Menu
        strategy="fixed"
        placement="bottom-start"
        preventOverflow={false}
        flip={false}
      >
        {({ isOpen }) => (
          <Flex
            order={1}
            width="auto"
            height="auto"
            alignItems="center"
            flexFlow="column nowrap"
            className="pageheader-nav-menu"
          >
            <MenuButton pl={{ base: "pagePaddingMobile", md: "pagePadding" }}>
              <Icon
                as={IoIosMenu}
                h={8}
                w={8}
                display="block"
                cursor="pointer"
                onClick={onToggle}
              />
            </MenuButton>
            <Box
              display={isOpen ? "block" : "none"}
              position="fixed"
              top={12}
              bottom={0}
              left={0}
              right={0}
              backgroundColor="gray.700"
              opacity="0.5"
              onClick={onToggle}
            />
            <MenuList
              display="flex"
              order={1}
              m={0}
              borderRadius={0}
              position="fixed"
              height="auto"
              alignItems="center"
              flexFlow="column nowrap"
              border="none"
              top={0}
              left={0}
              right={0}
              paddingTop={4}
              paddingBottom={4}
              backgroundColor={theme.colors.white}
              boxShadow="elevate"
              zIndex={2147483647}
            >
              {mobileNavLinks?.map((navLink, i) => (
                <MenuItem
                  p="0"
                  w={{ base: "100%", lg: "auto" }}
                  key={`nav-desktop-${i + 1}`}
                >
                  <PageHeaderNavLink
                    navLink={navLink}
                    index={i}
                    placement="mobile"
                  />
                </MenuItem>
              ))}
            </MenuList>
          </Flex>
        )}
      </Menu>
    );
  }

  // Plain menu for desktop display
  return (
    <Flex
      display="flex"
      order={2}
      m={0}
      borderRadius={0}
      position="relative"
      width="100%"
      height="100%"
      alignItems="center"
      flexFlow="row nowrap"
      backgroundColor={theme.colors.white}
    >
      {navLinks?.map((navLink, i) => (
        <PageHeaderNavLink
          navLink={navLink}
          index={i}
          placement="desktop"
          key={`nav-mobile-${i + 1}`}
        />
      ))}
      {renderSearch && (
        <Box
          display={{ base: "none", lg: "block" }}
          maxWidth="290px"
          minWidth="110px"
          flex="1"
          ml="auto"
          order={2}
        >
          {renderSearch()}
        </Box>
      )}
    </Flex>
  );
};
