import {
  Box,
  Flex,
  Icon,
  keyframes,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { MdAutorenew, MdDone } from "react-icons/md";

import { Avatar, ExpandableText } from "../../../components";
import { formatDate } from "../../../utils/datetime";
import BasicAlertDialog from "../../components/BasicAlertDialog";
import { TrashIcon } from "./icons";
import ManageTrainingLayout from "./ManageTrainingLayout";
import ManageTrainingPageAssessments from "./ManageTrainingPageAssessments";
import ManageTrainingPageRecordings from "./ManageTrainingPageRecordings";
import ManageTrainingPageTrainees from "./ManageTrainingPageTrainees";
import { ManageTrainingProgramSidebarItem } from "./ManageTrainingSidebar";
import TrainingNameModal, {
  TrainingProgramNameModalFocus,
} from "./TrainingNameModal";
import TrainingWizard from "./TrainingWizard";
import { TrainingProgram, TrainingProgramTrainee } from "./types";
import { TrainingApi } from "./useTrainingApi";
import { EmptyName } from "./utils";

type ManageTrainingPageViewProps = {
  trainingPrograms: ManageTrainingProgramSidebarItem[];
  trainingProgram: TrainingProgram;
  trainingApi: TrainingApi;
  trainees: TrainingProgramTrainee[];
  traineeCount: number;
};

const hoverStyles = {
  _hover: {
    bg: "transparent",
    color: "gray.800",
    borderBottomColor: "gray.100",
  },
  _active: {},
  _focus: {},
};

const ManageTrainingPageView: React.FC<ManageTrainingPageViewProps> = ({
  trainingProgram,
  trainingPrograms,
  trainingApi,
  trainees,
  traineeCount,
}) => {
  const [deleting, setDeleting] = useState(false);
  const nameModal = useDisclosure({});
  const [nameModalFocus, setNameModalFocus] =
    useState<TrainingProgramNameModalFocus>("name");
  const [autoSaveInProgress, setAutoSaveInProgress] = useState(false);

  const openModal = (opts: { focus: TrainingProgramNameModalFocus }): void => {
    setNameModalFocus(opts.focus);
    nameModal.onOpen();
  };

  return (
    <ManageTrainingLayout
      trainingApi={trainingApi}
      trainingPrograms={trainingPrograms}
      activeProgramId={trainingProgram.id}
      traineeCount={traineeCount}
    >
      <Flex flexDir="column" height="100%">
        <Box mt="2" mb="0">
          <Flex
            flexDir="row"
            alignItems="center"
            width="100%"
            maxWidth="1012px"
            ml="auto"
            mr="auto"
          >
            <Text
              placeholder={EmptyName}
              fontSize="xl"
              fontWeight="medium"
              ml="10"
              mr="4"
              display="inline-block"
              cursor="pointer"
              maxW="650px"
              onClick={() => openModal({ focus: "name" })}
              _hover={{
                bgColor: "gray.50",
              }}
            >
              {trainingProgram?.name || EmptyName}
            </Text>
            <Icon
              as={FiEdit2}
              cursor="pointer"
              color="gray.400"
              boxSize="4"
              onClick={() => openModal({ focus: "name" })}
              _hover={{
                bgColor: "gray.50",
                borderRadius: "2px",
              }}
            />
            {autoSaveInProgress && (
              <AutoSaveTag setAutoSaveInProgress={setAutoSaveInProgress} />
            )}
            <TrashIcon
              role="button"
              aria-label="delete"
              boxSize="5"
              cursor="pointer"
              color="gray.700"
              mr="10"
              ml="auto"
              onClick={() => setDeleting(true)}
              _hover={{
                backgroundColor: "gray.100",
                borderRadius: "2px",
              }}
            />
          </Flex>
          <Box width="100%" maxWidth="1012px" ml="auto" mr="auto">
            <Box
              ml="10"
              mr="10"
              fontSize="sm"
              whiteSpace="pre-wrap"
              cursor="pointer"
              onClick={() => openModal({ focus: "description" })}
              display="inline-block"
              maxW="650px"
              _hover={{
                bgColor: "gray.50",
              }}
            >
              {trainingProgram.description && (
                <ExpandableText
                  title={trainingProgram.description}
                  maskBg="white"
                  mt="2"
                />
              )}
              {!trainingProgram.description && (
                <Text as="span" color="gray.600" display="inline-block" mt="2">
                  Add a description
                </Text>
              )}
            </Box>
          </Box>

          {trainingProgram.launchedAt && (
            <Box width="100%" maxWidth="1012px" ml="auto" mr="auto">
              <Flex mx="10" mt="5" alignItems="center">
                <Text color="gray.600" fontSize="xs">
                  Published on{" "}
                  {formatDate(trainingProgram.launchedAt, {
                    dateStyle: "medium",
                  })}{" "}
                  by
                </Text>
                <Avatar
                  user={trainingProgram.owner}
                  height="5"
                  width="5"
                  mx="5px"
                />
                <Text color="gray.600" fontSize="xs">
                  {trainingProgram.owner.fullName ||
                    trainingProgram.owner.email}
                </Text>
              </Flex>
            </Box>
          )}

          {trainingProgram.launchedAt && (
            <Box mt="7">
              <Tabs>
                <Box borderBottomColor="gray.100" borderBottomWidth="1px">
                  <Box width="100%" maxWidth="1012px" ml="auto" mr="auto">
                    <TabList px="10" borderBottomWidth="0px">
                      <Tab px="0" mr="4" fontWeight="500" {...hoverStyles}>
                        Trainees
                      </Tab>
                      <Tab px="0" mr="4" fontWeight="500" {...hoverStyles}>
                        Recordings
                      </Tab>
                      {trainingProgram.assessmentEnabled && (
                        <Tab px="0" fontWeight="500" {...hoverStyles}>
                          Assessments
                        </Tab>
                      )}
                    </TabList>
                  </Box>
                </Box>

                <TabPanels>
                  <TabPanel>
                    <Box width="100%" maxWidth="1012px" ml="auto" mr="auto">
                      <ManageTrainingPageTrainees
                        trainingProgram={trainingProgram}
                        trainees={trainees}
                        trainingApi={trainingApi}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box width="100%" maxWidth="1012px" ml="auto" mr="auto">
                      <ManageTrainingPageRecordings
                        trainingProgram={trainingProgram}
                        trainingListItems={trainingProgram.trainingProgramItems}
                        trainingApi={trainingApi}
                        setAutoSaveInProgress={setAutoSaveInProgress}
                      />
                    </Box>
                  </TabPanel>
                  {trainingProgram.assessmentEnabled && (
                    <TabPanel>
                      <Box width="100%" maxWidth="1012px" ml="auto" mr="auto">
                        <ManageTrainingPageAssessments
                          trainingProgramId={trainingProgram.id}
                          trainingQuestions={
                            trainingProgram.trainingProgramQuestions
                          }
                          trainingApi={trainingApi}
                          setAutoSaveInProgress={setAutoSaveInProgress}
                        />
                      </Box>
                    </TabPanel>
                  )}
                </TabPanels>
              </Tabs>
            </Box>
          )}
        </Box>

        {!trainingProgram.launchedAt && (
          <TrainingWizard
            trainingApi={trainingApi}
            trainingProgram={trainingProgram}
            setAutoSaveInProgress={setAutoSaveInProgress}
            trainingListItems={trainingProgram.trainingProgramItems}
          />
        )}

        {nameModal.isOpen && (
          <TrainingNameModal
            onClose={nameModal.onClose}
            onSave={(name, description) => {
              trainingApi.program.rename(trainingProgram.id, name, description);
              setAutoSaveInProgress(true);
            }}
            initialName={trainingProgram.name}
            initialDescription={trainingProgram.description || ""}
            mode="edit"
            initialFocus={nameModalFocus}
          />
        )}
        {deleting && (
          <BasicAlertDialog
            title="Delete this Training Program?"
            body={
              <>
                Deleting this training program will instantly delete it for all
                trainees in the program (in progress and marked complete). The
                list of completed trainees and their assessments will be deleted
                as well.
              </>
            }
            confirmLabel="Delete"
            onCancel={() => setDeleting(false)}
            onConfirm={() => {
              setDeleting(false);
              trainingApi.program.delete(trainingProgram.id);
            }}
          />
        )}
      </Flex>
    </ManageTrainingLayout>
  );
};

export default ManageTrainingPageView;

const AutoSaveTag = ({
  setAutoSaveInProgress,
}: {
  setAutoSaveInProgress: (arg0: boolean) => void;
}): JSX.Element => {
  const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  100% { transform: scale(1) rotate(360deg); }
`;

  const animation = `${animationKeyframes} 750ms linear infinite`;
  const [animationCompleted, setAnimationCompleted] = useState(false);

  return (
    <Box display="flex" flexGrow={1} justifyContent="flex-end" pr={5}>
      <Box
        ml="auto"
        display="flex"
        flexDirection="row"
        alignItems="center"
        borderRadius={4}
        bgColor="gray.50"
        pt="1"
        pb="1"
        pl="2"
        pr="2"
      >
        <Box
          as={motion.div}
          height="16px"
          width="16px"
          animation={animationCompleted ? undefined : animation}
          color="gray.500"
          onAnimationIteration={(): void => {
            setAnimationCompleted(true);
            setTimeout(() => {
              setAutoSaveInProgress(false);
            }, 500);
          }}
        >
          {animationCompleted ? <MdDone /> : <MdAutorenew />}
        </Box>
        <Text color="gray.500" fontSize="xs" ml={1}>
          {animationCompleted ? "Autosaved" : "Autosaving"}
        </Text>
      </Box>
    </Box>
  );
};
