import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ConfirmModal, useToast } from "../../../components";
import {
  CurrentUserFragment,
  RestApiSetupDocument,
  useGenerateExternalApiWebhookTokenMutation,
  useGenerateRestTokenMutation,
  useRestApiSetupQuery,
  useSetupRestApiMutation,
} from "../../graphql";

interface RestApiSettingsProps {
  currentUser: CurrentUserFragment;
}

interface FormValues {
  externalApiWebhookUrl: string;
}

const RestApiSettings: React.FC<RestApiSettingsProps> = ({ currentUser }) => {
  const toast = useToast();
  const [isRestTokenOpen, setRestTokenIsOpen] = useState(false);
  const [isWebhookTokenOpen, setWebhookTokenIsOpen] = useState(false);
  const [
    generateRestToken,
    { data: restTokenData, loading: restTokenLoading },
  ] = useGenerateRestTokenMutation();
  const [
    generateWebhookToken,
    {
      data: externalApiWebhookTokenData,
      loading: externalApiWebhookTokenLoading,
    },
  ] = useGenerateExternalApiWebhookTokenMutation();
  const { data, loading } = useRestApiSetupQuery();
  const [setupRestApi, { loading: setupRestApiLoading }] =
    useSetupRestApiMutation({
      update: (cache, { data }) => {
        const settings = data?.setupRestApi?.setup;
        if (settings) {
          cache.writeQuery({
            query: RestApiSetupDocument,
            data: {
              restApiSetup: settings,
            },
          });
          toast({
            title: "Success",
            description: `Saved REST API settings.`,
            status: "success",
          });
        }
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: `Failed to setup RestApi: ${err.message}`,
          status: "error",
        });
      },
    });
  const { register, handleSubmit, setValue } = useForm<FormValues>({});

  const onSubmit = handleSubmit((formValues) => {
    setupRestApi({
      variables: {
        externalApiWebhookUrl: formValues.externalApiWebhookUrl,
      },
    });
  });

  useEffect(() => {
    setValue(
      "externalApiWebhookUrl",
      data?.restApiSetup?.externalApiWebhookUrl || ""
    );
  }, [data]);

  const isLoading = loading || setupRestApiLoading;
  const restToken =
    restTokenData?.generateRestToken?.setup.apiToken ||
    data?.restApiSetup?.apiToken;

  const externalApiWebhookToken =
    externalApiWebhookTokenData?.generateExternalApiWebhookToken?.setup
      .externalApiWebhookToken || data?.restApiSetup?.externalApiWebhookToken;

  return (
    <Box>
      <Heading as="h1" size="md">
        Rest API Setup
      </Heading>
      <Box my={8}>
        <Heading as="h3" size="sm" mb={2}>
          Instructions
        </Heading>
        <Text maxW="500px" mb={2}>
          Please see the{" "}
          <Link target="_blank" href="/api/v1/docs/redoc.html">
            REST API documentation
          </Link>
          .
        </Text>
      </Box>
      <form onSubmit={onSubmit}>
        <VStack spacing="8" align="start">
          <FormControl id="externalApiWebhookUrl">
            <FormLabel>Webhook URL</FormLabel>
            <Input {...register("externalApiWebhookUrl")} />
          </FormControl>
          <Button type="submit" disabled={isLoading}>
            Save
          </Button>
        </VStack>
      </form>
      <Divider my={8} />
      <Heading as="h3" size="sm">
        Secrets
      </Heading>
      <FormLabel mt={4}>Webhook Token</FormLabel>
      <Box display="flex" flexDir="row">
        <Input
          name="externalApiWebhookToken"
          type="text"
          value={
            externalApiWebhookToken ||
            "Please click the button to the right to generate a new token."
          }
          isReadOnly
          minWidth="650px"
        />
        <Button
          disabled={isLoading || externalApiWebhookTokenLoading}
          variant="danger"
          ml={2}
          minWidth="200px"
          onClick={() => {
            setWebhookTokenIsOpen(true);
          }}
        >
          {externalApiWebhookToken ? "Regenerate" : "Generate"}
        </Button>
        <ConfirmModal
          modalBodyText="This will regenerate your Webhook Token and you will need to update it in your webhook integration. Are you sure?"
          isOpen={isWebhookTokenOpen}
          onCancel={() => {
            setWebhookTokenIsOpen(false);
          }}
          onConfirm={() => {
            generateWebhookToken();
            setWebhookTokenIsOpen(false);
          }}
        />
      </Box>
      <FormLabel mt={4}>REST API Token</FormLabel>
      <Box display="flex" flexDir="row">
        <Input
          name="restToken"
          type="text"
          value={
            restToken ||
            "Please click the button to the right to generate a new token."
          }
          isReadOnly
          minWidth="650px"
        />
        <Button
          disabled={isLoading || restTokenLoading}
          variant="danger"
          ml={2}
          minWidth="200px"
          onClick={() => {
            setRestTokenIsOpen(true);
          }}
        >
          {restToken ? "Regenerate" : "Generate"}
        </Button>
        <ConfirmModal
          modalBodyText="This will regenerate your REST API Token and you will need to update it in your API integration. Are you sure?"
          isOpen={isRestTokenOpen}
          onCancel={() => {
            setRestTokenIsOpen(false);
          }}
          onConfirm={() => {
            generateRestToken();
            setRestTokenIsOpen(false);
          }}
        />
      </Box>
    </Box>
  );
};

export default RestApiSettings;
