import { Button, ButtonProps, Flex, IconButton } from "@chakra-ui/react";
import React, { useMemo } from "react";

import {
  GreenhouseIcon,
  LeverIcon,
  Tooltip,
  TooltipProps,
  useTheme,
} from "../../../../components";
import CopyOutlineIcon from "../../../../components/Icons/CopyOutlineIcon";
import useBrowserExtensionFeatureEnabled from "../../../../hooks/useBrowserExtensionFeatureEnabled";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { Ats } from "../../../graphql";

export interface CopyNotesButtonsProps extends ButtonProps {
  onCopy(): void;
  onAutofill?(): void;
  autofillEnabled: boolean;
  ats?: Ats;
  /**
   * *deprecated* Whether to show a warning that autofill may not work as expected
   *
   * This prop (plus a lot of UI code in this component around this prop) can be
   * removed when `one-click-scorecard` is enabled for all
   */
  showAutofillInfo?: boolean;
}

/**
 * This component will render two buttons in the case that autofill is available,
 * and one button otherwise
 *   - When autofill is available the primary button is for
 *     autofill and there is a smaller icon button for copy notes.
 *   - When autofill is not available, copy notes is the only button
 */
const CopyNotesButtons: React.FC<CopyNotesButtonsProps> = ({
  onCopy,
  onAutofill: onAutofillProp,
  autofillEnabled,
  ats,
  showAutofillInfo: showAutofillInfoProp,
  ...rest
}) => {
  const { colors } = useTheme();
  const sendGAEvent = useSendGAEvent();
  const oneClickScorecardEnabled = useBrowserExtensionFeatureEnabled(
    "one-click-scorecard"
  );
  const showAutofillInfo = showAutofillInfoProp && !oneClickScorecardEnabled;

  const isZoomApp = typeof zoomSdk !== "undefined";
  const isGreenhouse = autofillEnabled && ats === Ats.Greenhouse;
  const isLever = autofillEnabled && ats === Ats.Lever;

  const onAutofill = autofillEnabled
    ? (): void => {
        sendGAEvent("autofill_scorecard", "call_review", "call_details");
        onAutofillProp?.();
      }
    : undefined;

  const primaryColor = colors.blue[600];
  const secondaryColor = colors.blue[50];
  const tertiaryColor = colors.blue[100];

  const autofillInfoText = useMemo(() => {
    if (showAutofillInfo && isGreenhouse) {
      return `Click to open Scorecard. One-click “Paste in Scorecard” isn't
      available because this interview guide is not a perfect match with the
      linked scorecard. You can still paste your notes into the scorecard by
      section from the scorecard page.`;
    }
    if (showAutofillInfo && isLever) {
      return `Click to open Feedback Form. One-click “Paste in Feedback Form”
      isn't available because this interview guide is not a perfect match with
      the linked feedback form. You can still paste your notes into the feedback
      form by section from the feedback form`;
    }

    return undefined;
  }, [showAutofillInfo, isGreenhouse, isLever]);

  const tooltipStyles: Partial<TooltipProps> = {
    padding: "4px 12px",
    bgColor: "gray.700",
    fontSize: "14px",
  };

  return (
    <Flex alignItems="center">
      {/* Note: this tooltip is not shown if `!showAutofillInfo`,
          but the primary button (`trigger`) will always be shown */}
      <Tooltip
        id="autofillInfo"
        placement="bottom"
        maxW="260px"
        {...tooltipStyles}
        trigger={
          <Button
            size="xs"
            fontSize="sm"
            fontWeight="medium"
            variant="ghost"
            colorScheme="blue"
            data-tour-id="one-click-complete-scorecard"
            ml="3"
            mr="2"
            height="8"
            leftIcon={
              isGreenhouse ? (
                <GreenhouseIcon fill="currentColor" height="24px" />
              ) : isLever ? (
                <LeverIcon fill="currentColor" height="16px" />
              ) : (
                <CopyOutlineIcon width="16px" height="16px" />
              )
            }
            onClick={onAutofill ?? onCopy}
            {...rest}
          >
            {oneClickScorecardEnabled &&
              (isGreenhouse
                ? "Complete scorecard"
                : isLever
                ? "Complete feedback form"
                : "Copy all notes")}
            {!oneClickScorecardEnabled &&
              (isGreenhouse
                ? isZoomApp
                  ? "Open scorecard"
                  : "Paste in scorecard"
                : isLever
                ? isZoomApp
                  ? "Open feedback form"
                  : "Paste in feedback form"
                : "Copy all notes")}
          </Button>
        }
      >
        {autofillInfoText}
      </Tooltip>

      {/* Secondary "copy notes" icon button, only shown if autofill is available */}
      {(isGreenhouse || isLever) && (
        <Tooltip
          id="copyAllNotes"
          placement="top"
          {...tooltipStyles}
          trigger={
            <IconButton
              icon={<CopyOutlineIcon width="16px" height="16px" />}
              {...rest}
              aria-label="Copy all notes"
              size="xs"
              ml="2"
              p="2"
              variant="ghost"
              height="8"
              onClick={onCopy}
              color={primaryColor}
              bg="transparent"
              _hover={{ bg: secondaryColor }}
              _active={{
                bg: tertiaryColor,
              }}
            />
          }
        >
          Copy All Notes
        </Tooltip>
      )}
    </Flex>
  );
};

export default CopyNotesButtons;
