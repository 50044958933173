import { ApolloError } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MdCheck } from "react-icons/md";

import { Alert } from "../../../components";
import { copy } from "../../../utils/clipboard";
import { RatingDescriptions } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";

export interface FormData {
  playCallDisclaimer: boolean;
  showStarRatings: boolean;
  oneStarRating: string;
  twoStarRating: string;
  threeStarRating: string;
  fourStarRating: string;
  fiveStarRating: string;
  allowSelfSignUp: boolean;
  allowPhoneSignIn: boolean;
  postNotesToAts: boolean;
  emailDomain: string;
  inviteScheduledInterviewers: boolean;
  assignGuidesToInterviewersEnabled: boolean;
  requireActiveUserToImport: boolean;
  allowCandidateOptOut: boolean;
  allowInterviewerOptOut: boolean;
  automaticallyDeleteZoomRecordings: boolean;
  hideOtherInterviewerNotesInExtension: boolean;
  allowManualScheduledInterviews: boolean;
  externalShareDefaultDurationDays: number;
  allowInPersonMeetings: boolean;
  playInPersonRecordingDisclaimer: boolean;
  maximumCallDurationForNotetakerToRejoin: number;
  audioOnlyViewing: boolean;
}

interface OrganizationSettingsFormProps {
  playCallDisclaimer: boolean;
  showStarRatings: boolean;
  ratingDescriptions: RatingDescriptions;
  allowSelfSignUp: boolean;
  allowPhoneSignIn: boolean;
  postNotesToAts: boolean;
  emailDomain?: string | null;
  inviteScheduledInterviewers: boolean;
  assignGuidesToInterviewersEnabled: boolean;
  requireActiveUserToImport: boolean;
  allowCandidateOptOut: boolean;
  allowInPersonMeetings: boolean;
  playInPersonRecordingDisclaimer: boolean;
  candidateOptOutUrl: string;
  allowInterviewerOptOut: boolean;
  automaticallyDeleteZoomRecordings: boolean;
  error: ApolloError | undefined;
  isLoading: boolean;
  hideOtherInterviewerNotesInExtension: boolean;
  allowManualScheduledInterviews: boolean;
  zoomNotetakerEnabled: boolean;
  audioOnlyViewing: boolean;
  onSubmit: (formData: FormData) => void;
  externalShareDefaultDurationDays: number;
  maximumCallDurationForNotetakerToRejoin: number | null | undefined;
}

const EXTERNAL_SHARE_DURATION_VALUES = [
  { value: 0.125, name: "3 hours" },
  { value: 0.25, name: "6 hours" },
  { value: 0.5, name: "12 hours" },
  { value: 1, name: "1 day" },
  { value: 5, name: "5 days" },
  { value: 7, name: "7 days" },
  { value: 14, name: "14 days" },
  { value: 21, name: "21 days" },
  { value: 28, name: "28 days" },
];

const OrganizationSettingsForm: React.FC<OrganizationSettingsFormProps> = ({
  playCallDisclaimer,
  showStarRatings,
  ratingDescriptions,
  allowSelfSignUp,
  allowPhoneSignIn,
  postNotesToAts,
  emailDomain,
  inviteScheduledInterviewers,
  assignGuidesToInterviewersEnabled,
  requireActiveUserToImport,
  allowCandidateOptOut,
  candidateOptOutUrl,
  allowInterviewerOptOut,
  automaticallyDeleteZoomRecordings,
  hideOtherInterviewerNotesInExtension,
  allowManualScheduledInterviews,
  allowInPersonMeetings,
  playInPersonRecordingDisclaimer,
  zoomNotetakerEnabled,
  externalShareDefaultDurationDays,
  maximumCallDurationForNotetakerToRejoin,
  audioOnlyViewing,
  error,
  isLoading,
  onSubmit,
}) => {
  const optOutEnabled = useFeatureFlag("opt_out");
  const inPersonEnabled = useFeatureFlag("in_person_interviews");

  const inviteScheduledInterviewersEnabled = useFeatureFlag(
    "invite_scheduled_interviewers"
  );
  const autoAssignGuidesEnabled = useFeatureFlag(
    "assign-guides-interviewers:v1"
  );
  const { register, handleSubmit, watch, control } = useForm<FormData>({
    defaultValues: {
      playCallDisclaimer,
      oneStarRating: ratingDescriptions.oneStar,
      twoStarRating: ratingDescriptions.twoStar,
      threeStarRating: ratingDescriptions.threeStar,
      fourStarRating: ratingDescriptions.fourStar,
      fiveStarRating: ratingDescriptions.fiveStar,
      allowSelfSignUp,
      allowPhoneSignIn,
      postNotesToAts,
      inviteScheduledInterviewers,
      assignGuidesToInterviewersEnabled,
      requireActiveUserToImport,
      emailDomain: emailDomain || "",
      allowCandidateOptOut,
      allowInterviewerOptOut,
      automaticallyDeleteZoomRecordings,
      hideOtherInterviewerNotesInExtension,
      allowManualScheduledInterviews,
      externalShareDefaultDurationDays,
      allowInPersonMeetings,
      playInPersonRecordingDisclaimer,
      audioOnlyViewing,
      maximumCallDurationForNotetakerToRejoin:
        maximumCallDurationForNotetakerToRejoin
          ? maximumCallDurationForNotetakerToRejoin / 60
          : undefined,
    },
  });
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const copyLink = (): void => {
    copy(candidateOptOutUrl);
    setIsLinkCopied(true);
  };

  return (
    <form
      autoComplete="on"
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData);
      })}
    >
      {error?.graphQLErrors?.map(({ message }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Alert key={i} status="error" description={message} />
      ))}
      <VStack spacing="8" align="start">
        <FormControl id="playCallDisclaimer">
          <Checkbox
            {...register("playCallDisclaimer")}
            defaultChecked={playCallDisclaimer}
          >
            Call Recording Disclaimer
          </Checkbox>
          <FormHelperText>
            Turning this on will play an automated recording disclaimer to when
            candidates answer calls made through BrightHire. This is
            recommended.
          </FormHelperText>
          <FormHelperText>
            {"If you're using BrightHire with Zoom, please refer to "}
            <Link
              href="https://support.zoom.us/hc/en-us/articles/360026909191-Enabling-the-recording-disclaimer"
              target="_blank"
            >
              {"Zoom's Documentation "}
            </Link>
            to configure a recording disclaimer.
          </FormHelperText>
        </FormControl>
        <FormControl id="allowPhoneSignIn">
          <Checkbox
            {...register("allowPhoneSignIn")}
            defaultChecked={allowPhoneSignIn}
          >
            Allow Phone Sign-In
          </Checkbox>
          <FormHelperText>
            Phone Sign-In allows users to log in with their cell phones via SMS.
            Turning this off will force them to sign-in with an OAuth provider
            (Google or Microsoft).
          </FormHelperText>
        </FormControl>
        <FormControl id="postNotesToAts">
          <Checkbox
            {...register("postNotesToAts")}
            defaultChecked={postNotesToAts}
          >
            Post interviewer notes and reactions to ATS Activity Feed
          </Checkbox>
          <FormHelperText>
            After each interview, BrightHire will post the interviewer name, a
            link to the recording and the notes and reactions taken during the
            interview back to the candidate profile on the ATS. Unchecking this
            will remove the notes and reactions posted to the ATS.
          </FormHelperText>
        </FormControl>
        <FormControl id="hideOtherInterviewerNotesInExtension">
          <Checkbox
            {...register("hideOtherInterviewerNotesInExtension")}
            defaultChecked={hideOtherInterviewerNotesInExtension}
          >
            Prevent bias when filling out feedback
          </Checkbox>
          <FormHelperText>
            This setting will hide other interviews notes in the BrightHire
            sidepanel to prevent bias when inteviewers are filling out their
            scorecard or feedback form.
          </FormHelperText>
        </FormControl>
        <FormControl id="allowSelfSignUp">
          <Checkbox
            {...register("allowSelfSignUp")}
            defaultChecked={allowSelfSignUp}
          >
            Allow Self Sign-Up
          </Checkbox>
          <FormHelperText>
            Self sign-up allows anybody from your organization&#39;s email
            domain to sign-up using their work email, without an invitation.
            This is recommended.
          </FormHelperText>
        </FormControl>
        <FormControl
          id="inviteScheduledInterviewers"
          hidden={!inviteScheduledInterviewersEnabled}
        >
          <Checkbox
            {...register("inviteScheduledInterviewers")}
            defaultChecked={inviteScheduledInterviewers}
          >
            Auto-Invite Scheduled Interviewers
          </Checkbox>
          <FormHelperText>
            Enabling this setting will automatically invite scheduled
            interviewers to sign up with BrightHire (this applies only to
            interviews with “Auto-Join by Position” enabled). Please note that
            this only applies to new or updated scheduled interviews.
          </FormHelperText>
        </FormControl>
        <FormControl
          id="assignGuidesToInterviewersEnabled"
          hidden={!autoAssignGuidesEnabled}
        >
          <Checkbox
            {...register("assignGuidesToInterviewersEnabled")}
            defaultChecked={assignGuidesToInterviewersEnabled}
          >
            Assign Guides to Interviewers
          </Checkbox>
          <FormHelperText>
            When enabled, guides can be assigned to specific interviewers. If a
            position is also assigned, the interviewer will automatically see
            this guide on any interviews for that position. If a position is not
            assigned, the interviewer will see this guide on all interviews. If
            multiple guides are assigned to an interviewer, the interviewer can
            select which guide to use.
          </FormHelperText>
        </FormControl>
        <FormControl id="requireActiveUserToImport">
          <Checkbox
            {...register("requireActiveUserToImport")}
            defaultChecked={requireActiveUserToImport}
            isDisabled={
              inviteScheduledInterviewers === false &&
              watch("inviteScheduledInterviewers") === false
            }
          >
            Require Sign Up to Capture Interviews
          </Checkbox>
          <FormHelperText>
            This setting requires users to have completed sign up with
            BrightHire in order to capture their interviews. Auto-Invite
            Scheduled Interviewers must be enabled, to disable require sign up.
          </FormHelperText>
        </FormControl>
        <FormControl id="emailDomain" isRequired>
          <FormLabel>Email Domain</FormLabel>
          <FormHelperText mb={2}>
            Emails from this domain can sign up without an invitation when
            &quot;Allow Self Sign-Up&quot; is enabled
          </FormHelperText>
          <Input
            {...register("emailDomain")}
            maxLength={100}
            placeholder="mycompany.com"
          />
        </FormControl>
        <FormControl id="allowCandidateOptOut" hidden={!optOutEnabled}>
          <Checkbox
            {...register("allowCandidateOptOut")}
            defaultChecked={allowCandidateOptOut}
          >
            Allow BrightHire to Send Candidate Opt-out Emails
          </Checkbox>
          <FormHelperText>
            Turning this on sends an email to candidates when their first
            interview set to captured by BrightHire is scheduled. It informs
            them about your use of BrightHire and allows them to opt-out of
            BrightHire for all of their upcoming interviews.
            <Link
              href="https://help.brighthire.ai/en/articles/5403608-candidate-opt-out-of-recording"
              target="_blank"
            >
              {" Preview Email"}
            </Link>
          </FormHelperText>
        </FormControl>
        <Flex
          alignItems="baseline"
          justifyContent="space-between"
          background="gray.50"
          px="4"
          py="5"
          borderRadius="md"
          hidden={!optOutEnabled}
          alignSelf="stretch"
        >
          <Text fontSize="sm">Want to share your opt-out link directly?</Text>
          <Button
            variant="outline"
            size="xs"
            onClick={copyLink}
            leftIcon={isLinkCopied ? <MdCheck /> : undefined}
            w="174px"
          >
            {isLinkCopied ? "Copied" : "Copy general opt-out link"}
          </Button>
        </Flex>
        <FormControl id="allowInterviewerOptOut" hidden={!optOutEnabled}>
          <Checkbox
            {...register("allowInterviewerOptOut")}
            defaultChecked={allowInterviewerOptOut}
          >
            Allow Interviewer Opt-out
          </Checkbox>
          <FormHelperText>
            Turning this on adds an ‘opt-out of recording’ link below each
            interview for interviewers in your organization. This opt-out link
            will appear in their ‘daily upcoming interviews’ email.
          </FormHelperText>
        </FormControl>
        <FormControl id="automaticallyDeleteZoomRecordings">
          <Checkbox
            {...register("automaticallyDeleteZoomRecordings")}
            defaultChecked={automaticallyDeleteZoomRecordings}
          >
            Automatically Delete Zoom Recordings
          </Checkbox>
          <FormHelperText>
            This setting will automatically delete Zoom recordings from the Zoom
            Cloud after import, which will free up your organization&apos;s Zoom
            storage. Recordings will be preserved in the Brighthire app.
          </FormHelperText>
        </FormControl>
        <FormControl id="allowManualScheduledInterviews">
          <Checkbox
            {...register("allowManualScheduledInterviews")}
            defaultChecked={allowManualScheduledInterviews}
          >
            Allow Manually Scheduled Interviews
          </Checkbox>
          <FormHelperText>
            This will reveal a &quot;Schedule Interview&quot; button on the
            homepage that allows any user to manually schedule interviews in
            BrightHire
            <Link
              href="https://help.brighthire.ai/en/articles/6170599"
              target="_blank"
            >
              {" Learn More"}
            </Link>
          </FormHelperText>
        </FormControl>
        {inPersonEnabled && (
          <>
            <FormControl id="allowInPersonMeetings">
              <Checkbox
                {...register("allowInPersonMeetings")}
                defaultChecked={allowInPersonMeetings}
              >
                Allow In-Person Interviews
              </Checkbox>
              <FormHelperText>
                This setting enables users to create and launch any upcoming
                interview as an in-person interview. In-person interviews use a
                computer or mobile microphone to record the audio of an
                in-person conversation.
              </FormHelperText>
            </FormControl>
            <FormControl id="playInPersonRecordingDisclaimer">
              <Checkbox
                {...register("playInPersonRecordingDisclaimer")}
                defaultChecked={playInPersonRecordingDisclaimer}
                isDisabled={
                  allowInPersonMeetings === false &&
                  watch("allowInPersonMeetings") === false
                }
              >
                Call Recording Disclaimer - In-Person Interviews
              </Checkbox>
              <FormHelperText>
                Turning this on will play an automated recording disclaimer to
                when an in-person interview is started in BrightHire. This is
                recommended.
              </FormHelperText>
            </FormControl>
          </>
        )}
        <Box hidden={!showStarRatings} alignSelf="stretch">
          <FormLabel id="oneStarRating" fontSize="lg">
            Star rating description
          </FormLabel>
          <FormControl mb={3} isRequired>
            <FormLabel>One Star</FormLabel>
            <Input {...register("oneStarRating")} maxLength={35} />
          </FormControl>
          <FormControl id="TwoStarRating" mb={3} isRequired>
            <FormLabel>Two Star</FormLabel>
            <Input {...register("twoStarRating")} maxLength={35} />
          </FormControl>
          <FormControl id="threeStarRating" mb={3} isRequired>
            <FormLabel>Three Star</FormLabel>
            <Input {...register("threeStarRating")} maxLength={35} />
          </FormControl>
          <FormControl id="fourStarRating" mb={3} isRequired>
            <FormLabel>Four Star</FormLabel>
            <Input {...register("fourStarRating")} maxLength={35} />
          </FormControl>
          <FormControl id="fiveStarRating" mb={3} isRequired>
            <FormLabel>Five Star</FormLabel>
            <Input {...register("fiveStarRating")} maxLength={35} />
          </FormControl>
        </Box>
        <FormControl id="externalShareDefaultDurationDays">
          <FormLabel id="oneStarRating" fontSize="lg">
            Sharing settings
          </FormLabel>
          <FormLabel>
            Default time an external recipient can access an interview or clip:
          </FormLabel>
          <Controller
            control={control}
            name="externalShareDefaultDurationDays"
            defaultValue={externalShareDefaultDurationDays}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                name="externalShareDefaultDurationDays"
                value={value}
                ref={ref}
                onChange={(v) => {
                  onChange(parseInt(v.target.value));
                }}
              >
                {EXTERNAL_SHARE_DURATION_VALUES.map((item) => (
                  <option key={item.name} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <FormControl id="maximumCallDurationForNotetakerToRejoin">
          <FormLabel fontSize="lg">
            Prevent Notetaker Rejoin - Minimum Interview Duration
          </FormLabel>
          <FormHelperText mb={2}>
            BrightHire automatically tries to rejoin Google Meet, Zoom, and
            Teams meetings if they end more than 5 minutes before their
            scheduled end time. BrightHire can also use the duration of the
            interview to determine if we should rejoin. Use this input to
            override the default behavior, and only rejoin if interviews are
            shorter than this duration. (Duration is in minutes.)
            {/* Duration converted to seconds in the backend */}
          </FormHelperText>
          <Input
            {...register("maximumCallDurationForNotetakerToRejoin", {
              valueAsNumber: true,
            })}
            type="number"
            min="0"
            max="60"
            placeholder="minutes"
          />
        </FormControl>
        <FormControl id="audioOnlyViewing">
          <Checkbox
            {...register("audioOnlyViewing")}
            defaultChecked={audioOnlyViewing}
          >
            Disable Video Viewing
          </Checkbox>
          <FormHelperText>
            Checking this will disable video throughout the BrightHire
            application so that your users can only listen to the audio of the
            interview. This setting can help reduce bias.
          </FormHelperText>
        </FormControl>
        <Button type="submit" isLoading={isLoading} data-testid="save">
          Save
        </Button>
      </VStack>
    </form>
  );
};

export default OrganizationSettingsForm;
