import { ApolloError } from "@apollo/client";
import React from "react";
import { SortingRule } from "react-table";

import { Alert } from "../../../components";
import { PageOptions } from "../../../utils/types";
import { PositionListItemFragment } from "../../graphql";
import PositionListNew from "./PositionList";

interface MyPositionsProps {
  positions: Array<PositionListItemFragment>;
  pageOptions: PageOptions;
  loading?: boolean;
  sortBy: SortingRule<string>;
  error?: ApolloError;
}

const MyPositions: React.FC<MyPositionsProps> = ({
  positions,
  pageOptions,
  loading,
  sortBy,
  error,
}) => {
  // TODO: better error handling
  if (error)
    return (
      <Alert
        status="error"
        title="Error loading positions"
        description={error.message}
      />
    );

  return (
    <PositionListNew
      positions={positions}
      pageOptions={pageOptions}
      emptyState="Positions you've made calls for will appear here."
      sortBy={sortBy}
      loading={loading}
    />
  );
};

export default MyPositions;
