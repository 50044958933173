import { Box, Button, ButtonGroup, Flex, useTheme } from "@chakra-ui/react";
import React from "react";
import { FiExternalLink } from "react-icons/fi";
import { IoPeopleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import {
  GreenhouseIcon,
  LeverIcon,
  PageTitle,
  SmartrecruitersIcon,
} from "../../../components";
import { buildLeverLink } from "../../../utils/lever";
import { Client, Position } from "../../graphql";
import useLink from "../../hooks/useLink";

interface PositionHeaderProps {
  position: Pick<
    Position,
    | "id"
    | "title"
    | "greenhouseLink"
    | "leverPostingId"
    | "customAtsUrl"
    | "smartrecruitersLink"
  > & { client: Pick<Client, "name"> };
  canEdit: boolean;
  loading: boolean;
  handleSubmitTitle: (value: string) => void;
}

const PositionHeader: React.FC<PositionHeaderProps> = ({
  position,
  canEdit,
  loading,
  handleSubmitTitle,
}) => {
  const navigate = useNavigate();
  const hiringTeamLink = useLink({
    type: "hiringTeam",
    positionId: position.id,
  });
  const theme = useTheme();
  const { colors } = theme;
  return (
    <Flex
      direction={["column", "column", "row"]}
      align="start"
      justify="space-between"
    >
      <Box flex="1">
        <PageTitle
          titleText={position.title}
          secondaryText={position.client.name}
          editable={canEdit && !loading}
          onTitleSubmit={handleSubmitTitle}
          mb={[4, 4, 10]}
        />
      </Box>
      <ButtonGroup spacing="2" size="sm" variant="ghost" mb={[10, 10, 0]}>
        {!!position.greenhouseLink && (
          <Button
            as="a"
            href={position.greenhouseLink}
            target="_blank"
            leftIcon={
              <GreenhouseIcon marginRight="0" fill={colors.blue[600]} />
            }
            colorScheme="blue"
            aria-label="Greenhouse"
            borderRadius="base"
          >
            View in Greenhouse
          </Button>
        )}
        {!!position.leverPostingId && (
          <Button
            as="a"
            href={buildLeverLink(position.leverPostingId)}
            target="_blank"
            leftIcon={
              <LeverIcon
                height="12px"
                marginRight="0"
                fill={colors.blue[600]}
              />
            }
            colorScheme="blue"
            aria-label="Lever"
            borderRadius="base"
          >
            View in Lever
          </Button>
        )}
        {!!position.customAtsUrl && (
          <Button
            as="a"
            href={position.customAtsUrl}
            target="_blank"
            leftIcon={<FiExternalLink height="12px" />}
            colorScheme="blue"
            aria-label="View in your ATS"
            borderRadius="base"
          >
            ATS
          </Button>
        )}
        {!!position.smartrecruitersLink && (
          <Button
            as="a"
            href={position.smartrecruitersLink}
            target="_blank"
            leftIcon={
              <SmartrecruitersIcon
                height="14px"
                width="11.45px"
                marginRight="0"
                fill={colors.blue[600]}
              />
            }
            colorScheme="blue"
            aria-label="Smartrecruiters"
            borderRadius="base"
          >
            View in SmartRecruiters
          </Button>
        )}
        <Button
          as="a"
          onClick={() => navigate(hiringTeamLink)}
          leftIcon={<IoPeopleSharp height="12px" />}
          colorScheme="blue"
          aria-label="Lever"
          borderRadius="base"
        >
          View hiring team
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default PositionHeader;
