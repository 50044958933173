import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Navigate, useParams } from "react-router-dom";

import { Alert, LoadingIndicator } from "../../../components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { usePageTracker } from "../../../utils/googleAnalytics";
import { CallGuide, useCallGuideQuery } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import DefaultLayout from "../../layouts/DefaultLayout";
import Forbidden from "../forbidden/Forbidden";
import AssignedUsers from "./AssignedUsers";
import EditGuideModal from "./EditGuideModal";
import GuideHeader from "./GuideHeader";
import OverallScoringToggle from "./OverallScoringToggle";
import QuestionList from "./QuestionList";

const GuidePage: React.FC = () => {
  usePageTracker("call_guide");
  const currentUser = useCurrentUser();
  const { percentHeight, layoutHeight } = useWindowDimensions();
  const autoAssignGuidesEnabled = useFeatureFlag(
    "assign-guides-interviewers:v1"
  );
  const { callGuideId } = useParams() as { callGuideId: string };
  const {
    isOpen: editGuideModalIsOpen,
    onOpen: openEditGuideModal,
    onClose: closeEditGuideModal,
  } = useDisclosure();

  const { data, loading, error } = useCallGuideQuery({
    variables: { id: callGuideId },
  });

  const callGuide = data?.callGuide;

  if (loading) {
    return (
      <DefaultLayout>
        <LoadingIndicator mt={percentHeight(25)} />
      </DefaultLayout>
    );
  }

  if (error) {
    if (error.graphQLErrors.some((e) => e.extensions?.code === "FORBIDDEN")) {
      return (
        <Forbidden forbiddenFrom="guide">
          You can request access from the Hiring Team Admin.
        </Forbidden>
      );
    }
    return <Alert status="error" description="Error loading guide" reload />;
  }

  if (!callGuide) {
    return <Navigate to="/not-found" />;
  }

  // Treat any guides created in the ATS as read-only.
  const readOnly = !callGuide?.canEdit || !!callGuide.atsId;
  const assignedCount = callGuide.assignedUsers.length;

  return (
    <DefaultLayout>
      <EditGuideModal
        callGuide={callGuide}
        isOpen={editGuideModalIsOpen}
        onClose={closeEditGuideModal}
      />
      <Box minHeight={layoutHeight} backgroundColor="gray.50">
        <Box maxWidth="930px" marginX="auto">
          <GuideHeader
            callGuide={callGuide}
            readOnly={readOnly}
            onEdit={openEditGuideModal}
          />
          <Tabs isLazy>
            <TabList>
              <Tab>Questions</Tab>
              {autoAssignGuidesEnabled && (
                <Tab>
                  Assigned Interviewers{" "}
                  {assignedCount > 0 ? `(${assignedCount})` : ""}
                </Tab>
              )}
            </TabList>

            <TabPanels pt={4}>
              <TabPanel>
                <QuestionList callGuide={callGuide} readOnly={readOnly} />
              </TabPanel>
              <TabPanel>
                <AssignedUsers
                  callGuide={callGuide as CallGuide}
                  onEdit={openEditGuideModal}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
          {currentUser.enabledFeatures.includes("scoring:v1") && (
            <Box mt={2}>
              <OverallScoringToggle callGuide={callGuide} />
            </Box>
          )}
        </Box>
      </Box>
    </DefaultLayout>
  );
};

export default GuidePage;
