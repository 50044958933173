import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";

import { RouterLink, useToast } from "../../../../components";
import { useRequestOrganizationDataRedactionAccessMutation } from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import DataRedactionSettingsForm from "./DataRedactionSettingsForm";

const DataRedactionSettings: React.FC = () => {
  const currentUser = useCurrentUser();
  const { dataRedactionEnabled } = currentUser.organization;

  const [successfullySubmittedRequest, setSuccessfullySubmittedRequest] =
    useState(false);
  const toast = useToast();
  const showErrorToast = (): void => {
    toast({
      status: "error",
      title:
        "There was an error with submitting the request, please try again in some time",
    });
  };

  const [requestOrganizationDataRedactionAccess] =
    useRequestOrganizationDataRedactionAccessMutation({
      onCompleted: (data) => {
        if (data?.requestOrganizationDataRedactionAccess?.success) {
          setSuccessfullySubmittedRequest(true);
          toast({
            status: "success",
            title:
              "Request submitted successfully. Our support team will be in touch with you with next steps shortly",
          });
        } else {
          showErrorToast();
        }
      },
      onError: () => {
        showErrorToast();
      },
    });

  const onContactSupport = (): void => {
    requestOrganizationDataRedactionAccess();
  };

  return (
    <Flex direction="column" alignItems="baseline">
      <Heading as="h3" size="lg">
        Data Redaction
      </Heading>
      <Text mb="6" mt="4" maxW="xl">
        Enabling data redaction ensures that certain protected-class information
        that candidates share is automatically detected and redacted from video,
        audio, and interview transcripts. Redacting this information helps your
        company and interviewers stay compliant with hiring laws.
      </Text>
      <Text mb="6" maxW="xl">
        <Text as="span" fontWeight="bold">
          Note:{" "}
        </Text>
        Data Redaction is only available for English-language transcripts.
      </Text>
      <Divider />

      <Box my="6">
        {dataRedactionEnabled ? (
          <Flex>
            <Icon as={HiOutlineCheckCircle} color="green.600" boxSize="6" />
            <Text ml="1">
              Data Redaction has been enabled for your organization.
            </Text>
          </Flex>
        ) : (
          <Text>Data Redaction has been enabled for your organization.</Text>
        )}

        <Flex direction="row" alignItems="center" mt="4">
          <RouterLink variant="primaryButton" to="/data-redaction/log" mr={6}>
            View redaction log
          </RouterLink>
          <Button
            variant="outline"
            fontSize="sm"
            fontWeight="medium"
            px="3"
            h="8"
            textTransform="none"
            onClick={onContactSupport}
            disabled={successfullySubmittedRequest}
          >
            Contact support to {dataRedactionEnabled ? "disable" : "enable"}
          </Button>
        </Flex>
      </Box>

      {dataRedactionEnabled && (
        <DataRedactionSettingsForm
          mt="6"
          mb="9"
          formId="data-redaction-settings"
        />
      )}

      <Text color="gray.600" fontSize="sm" maxW="xl" lineHeight="5">
        When Data Redaction is enabled, redacted portions of the conversation
        will not be visible on the interview pages, or in search results. When
        enabled, all future interviews will be redacted; this feature is not
        retroactive and will not redact interviews that occurred in the past.
        Our data redaction technology is state-of-the-art, but it&apos;s not
        perfect. When data redaction is enabled, Admins with the &quot;View
        Redacted Data&quot; permission will have visibility of any redacted
        content to ensure accuracy.
      </Text>

      {dataRedactionEnabled && (
        <Button type="submit" form="data-redaction-settings" mt="6">
          Save
        </Button>
      )}
    </Flex>
  );
};

export default DataRedactionSettings;
