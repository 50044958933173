import { useFeatureFlagForCurrentOrImpersonatedUser } from "../graphql/hooks/useFeatureFlag";

type BaseLinkType<S extends string> = { type: S };

type HiringTeamLink = BaseLinkType<"hiringTeam"> & { positionId: string };
type UserInfoLink = BaseLinkType<"userInfo"> & { userId: string };
type RolesAndPoliciesLink = BaseLinkType<"userRoles"> & {
  page: "user" | "team" | "policies";
};

type LinkType = HiringTeamLink | UserInfoLink | RolesAndPoliciesLink;

/**
 * Generates the correct link for a given object
 *
 * This was born out of a need to change certain paths based on
 * a feature flag, but this can be extended for all types of links
 * to centralize their definitions
 */
const useLink = ({ type, ...rest }: LinkType): string => {
  const isSidebarNavEnabled =
    useFeatureFlagForCurrentOrImpersonatedUser("sidebar-nav");
  const onSettingsPage = window.location.pathname.startsWith("/settings");
  const useSettingsLink = isSidebarNavEnabled || onSettingsPage;
  const usersSettingsLink = (link: string): string =>
    useSettingsLink ? "/settings".concat(link) : link;

  if (type === "hiringTeam") {
    const { positionId } = rest as HiringTeamLink;
    return usersSettingsLink(`/users/teams/info/${positionId}`);
  }

  if (type === "userInfo") {
    const { userId } = rest as UserInfoLink;
    return usersSettingsLink(`/users/info/${userId}`);
  }

  if (type === "userRoles") {
    const { page } = rest as RolesAndPoliciesLink;
    return usersSettingsLink(`/users/roles/${page}`);
  }

  return "";
};

export default useLink;
