import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import Sidebar, { SidebarNavLink } from "../../components/Sidebar";
import useCurrentUser from "../../hooks/useCurrentUser";

const UsersNav: React.FC = () => {
  const currentUser = useCurrentUser();
  return (
    <Sidebar minW="170px" flexShrink={{ base: "1", md: "0" }}>
      <Flex flexDir="column" px="2">
        <Text
          fontSize="xs"
          color="gray.400"
          fontWeight="500"
          pl="4"
          pt="5"
          pb="2"
        >
          User Settings
        </Text>
        <SidebarNavLink text="Users" to="/users/list" />
        <SidebarNavLink text="Hiring Teams" to="/users/teams" />
        {currentUser.userRole?.canManageUserRoles && (
          <SidebarNavLink text="Roles &amp; Policies" to="/users/roles" />
        )}
        {currentUser.userRole?.canManageUserRoles && (
          <SidebarNavLink text="Excluded Users" to="/users/excluded" />
        )}
      </Flex>
    </Sidebar>
  );
};

export default UsersNav;
