import {
  AvatarGroup,
  AvatarGroupProps,
  Box,
  BoxProps,
  Flex,
  Link,
  StyleProps,
} from "@chakra-ui/react";
import React from "react";

import { Avatar, useTheme } from "../../../components";
import { BreakpointStyles } from "../../../theme/breakpoints";
import { formatDate } from "../../../utils/datetime";
import { startTour } from "../../../utils/intercom";
import { UserAvatarFragment } from "../../graphql";

export enum InterviewMenuOptions {
  SHARE = "share",
  DELETE = "delete",
}

export const THUMBNAIL_ML = 7;
export const THUMBNAIL_MR = 5;
export const CALENDAR_WIDTH = "44px";
export const CALENDAR_ML = 0;
export const CALENDAR_MR = 5;
export const DATELINE_SPACING: BreakpointStyles = {
  base: "2",
  lg: "0",
};
export const DATELINE_SPACING_WITH_THUMBNAIL: BreakpointStyles = {
  base: "2",
  lg: "1",
};
export const ROLE_SPACING: BreakpointStyles = {
  base: "0",
  lg: "0",
};
export const LIST_ITEM_FLEX: BreakpointStyles = {
  base: "auto",
  lg: "0 0 50%",
  xl: "0 0 55%",
};
export const LIST_ITEM_FLEX_FULL: BreakpointStyles = {
  base: "auto",
  lg: "1 1 auto",
  xl: "1 1 auto",
};
export const LIST_ROW_FLEX_FLOW: BreakpointStyles = {
  base: "row nowrap",
};
export const LIST_ROW_PADDING: BreakpointStyles = {
  base: "4",
  xl: "4",
};
export const LIST_ROW_MARGIN: BreakpointStyles = {
  base: "0",
  xl: "0",
};
export const LIST_SPACER_DISPLAY: BreakpointStyles = {
  base: "block",
  lg: "none",
};

interface InterviewsListRowProps extends StyleProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  listLength?: number;
  listPosition?: number;
  showDivider?: boolean;
  showPadding?: boolean;
}

export const InterviewsListRow: React.FC<InterviewsListRowProps> = ({
  listLength,
  listPosition,
  showPadding = true,
  showDivider = true,
  fullWidth = false,
  children,
  ...rest
}) => (
  <Flex
    justifyContent="flex-start"
    ml={LIST_ROW_MARGIN}
    mr={LIST_ROW_MARGIN}
    pt={5}
    pb={5}
    px={showPadding ? LIST_ROW_PADDING : 0}
    flexFlow={LIST_ROW_FLEX_FLOW}
    borderBottom={showDivider ? "gray.100" : "0"}
    width={fullWidth ? "100%" : undefined}
    {...rest}
  >
    {children}
  </Flex>
);

export const InterviewsListRight: React.FC<{
  children: React.ReactNode;
  marginLeft?: string;
}> = ({ marginLeft = "68px", children }) => (
  <Flex
    direction="row"
    wrap={{ base: "wrap", lg: "nowrap" }}
    alignItems={{ base: "center", lg: "flex-start" }}
    justifyContent={{ base: "initial" }}
    ml={{ base: "auto" }}
    mt={ROLE_SPACING}
  >
    {children}
  </Flex>
);

export const InterviewsListTitle: React.FC<{ parent: string } & BoxProps> = ({
  parent,
  children,
  ...boxProps
}) => (
  <Flex
    direction="column"
    mr={{ base: "3", lg: "6" }}
    ml={{ base: parent === "completed" ? "140px" : "64px", lg: "0" }}
    {...boxProps}
  >
    {children}
  </Flex>
);
type InterviewsListInterviewersProps = Omit<AvatarGroupProps, "children"> & {
  interviewers: Array<UserAvatarFragment | null | undefined> | null;
  size?: string;
};

export const InterviewsListInterviewers: React.FC<
  InterviewsListInterviewersProps
> = ({ interviewers, size = "xs", ...rest }) => {
  if (!interviewers || interviewers.length === 0) return null;
  const interviewerUsers: Array<UserAvatarFragment> = [];
  interviewers.forEach((interviewer) => {
    if (interviewer) {
      interviewerUsers.push(interviewer);
    }
  });
  return (
    <AvatarGroup
      max={2}
      size={size}
      spacing="-2"
      marginLeft={3}
      marginTop={{ base: "2px", lg: 0 }}
      {...rest}
    >
      {interviewerUsers.map((interviewer) => (
        <Avatar key={interviewer.id} user={interviewer} showTooltip />
      ))}
    </AvatarGroup>
  );
};

export const InterviewsListGetStarted: React.FC = () => (
  <Box marginTop="small" textAlign="center">
    {"Your interviews will appear here."}
    <br />
    {"Ready to get started? "}
    <Link
      href="#"
      onClick={(e) => {
        startTour(187358);
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      Let us show you around
    </Link>
    .
  </Box>
);

interface InterviewsListSpacerProps {
  size?: string;
  ml?: string | number;
  mr?: string | number;
}

export const InterviewsListSpacer: React.FC<InterviewsListSpacerProps> = ({
  size = CALENDAR_WIDTH,
  ml = 0,
  mr = 6,
}) => (
  <Box
    display={LIST_SPACER_DISPLAY}
    width={size}
    minWidth={size}
    ml={ml}
    mr={mr}
  >
    &nbsp;
  </Box>
);

interface CalendarProps extends BoxProps {
  scheduledStart: string;
  size?: string;
}

export const Calendar: React.FC<CalendarProps> = ({
  scheduledStart,
  size = CALENDAR_WIDTH,
  ...props
}) => {
  const { colors } = useTheme();
  let color = colors.green["600"];
  if (parseInt(formatDate(scheduledStart, { month: "numeric" })) % 2 === 0) {
    color = colors.green["800"];
  }
  return (
    <Flex
      direction="column"
      w={size}
      h={size}
      minWidth={size}
      borderRadius="base"
      overflow="hidden"
      position={{ base: "absolute", lg: "relative" }}
      {...props}
    >
      <Flex
        h="18px"
        alignItems="center"
        justifyContent="center"
        bg={color}
        color={colors.white}
        fontSize="xs"
        fontWeight="bold"
      >
        {formatDate(scheduledStart, { month: "short" })}
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        fontSize="md"
        fontWeight="bold"
        roundedBottomLeft="base"
        roundedBottomRight="base"
        border="gray.200"
        borderTop="none"
      >
        {formatDate(scheduledStart, { day: "numeric" })}
      </Flex>
    </Flex>
  );
};
