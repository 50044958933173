import { Box, Tag, TagLabel } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { Row } from "react-table";

import {
  GreenhouseIcon,
  LeverIcon,
  RouterLink,
  SortableTable,
  SortableTableColumn,
  useToast,
} from "../../../components";
import { formatRelativeDate } from "../../../utils/datetime";
import {
  CallGuide,
  CallGuideFragment,
  CallGuideListItemFragment,
} from "../../graphql";
import useUpdateCallGuide from "../../graphql/hooks/CallGuide/useUpdateCallGuide";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { CallGuideMenu } from "..";
import SharingLabel from "./SharingLabel";

interface CallGuideListProps {
  callGuides: Array<CallGuideListItemFragment>;
  loading?: boolean;
  showSharing?: boolean;
  showOwner?: boolean;
  showPosition?: boolean;
  showRemove?: boolean;
}

const CallGuideList: React.FC<CallGuideListProps> = ({
  callGuides,
  loading = false,
  showSharing = false,
  showOwner = false,
  showPosition = false,
  showRemove = false,
}) => {
  const toast = useToast();
  const [updateCallGuide] = useUpdateCallGuide({
    onError: () =>
      toast({ status: "error", description: "Error updating guide" }),
  });

  const toggleIsShared = useCallback(
    (id: string, visibility: string) =>
      updateCallGuide({
        variables: {
          id,
          visibility: visibility === "PUBLIC" ? "PRIVATE" : "PUBLIC",
        },
      }),
    [updateCallGuide]
  );

  const templateSortBy = React.useCallback(
    (
      rowA: Row<CallGuideFragment>,
      rowB: Row<CallGuideFragment>,
      id: "isTemplate",
      desc: boolean
    ): number => {
      if (rowA.original[id] && !rowB.original[id]) return -1;
      if (rowB.original[id] && !rowA.original[id]) return 1;
      return 0;
    },
    []
  );

  const showAssignedCount = useFeatureFlag("assign-guides-interviewers:v1");

  if (callGuides.length < 1) {
    return (
      <Box mt={2} alignItems="center">
        {"No guides yet. "}
        <RouterLink to="/guides/new">Create your own</RouterLink>.
      </Box>
    );
  }

  const columns: Array<SortableTableColumn<CallGuide>> = [
    {
      Header: "Guide Name",
      accessor: "name",
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideFragment };
      }) => (
        <RouterLink to={`/guide/${callGuide.id}`}>
          {callGuide.name ?? "Untitled"}
        </RouterLink>
      ),
    },
    {
      Header: "Position",
      accessor: (guide) => guide.position?.displayTitle,
      id: "position.displayTitle",
      show: showPosition,
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideFragment };
      }) => {
        if (!callGuide.position) {
          return null;
        }
        return (
          <RouterLink to={`/position/${callGuide.position?.id}`}>
            {callGuide.position?.displayTitle}
          </RouterLink>
        );
      },
    },
    {
      Header: "Questions",
      accessor: "cues",
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideFragment };
      }) => <>{callGuide.cues?.length}</>,
    },
    {
      Header: "Assigned Interviewers",
      accessor: (guide) => guide.assignedUsers.length,
      show: showAssignedCount,
    },
    {
      Header: "Template",
      accessor: "isTemplate",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sortType: templateSortBy,
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideFragment };
      }) =>
        callGuide.isTemplate ? (
          <Tag
            variant="solid"
            size="sm"
            borderRadius="full"
            backgroundColor="blue.50"
            color="blue.600"
          >
            <TagLabel pl="1" pr="1">
              Template
            </TagLabel>
          </Tag>
        ) : null,
    },
    {
      Header: "Sharing",
      accessor: "visibility",
      show: showSharing,
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideFragment };
      }) => (
        <SharingLabel
          id={callGuide.id}
          visibility={callGuide.visibility}
          onClick={toggleIsShared}
        />
      ),
    },
    {
      Header: "Owner",
      accessor: (guide) => guide.creator?.fullName,
      id: "creator.fullName",
      show: showOwner,
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideFragment };
      }) => {
        if (callGuide.creator) {
          return <>{callGuide.creator.fullName}</>;
        }
        return null;
      },
    },
    {
      Header: "ATS",
      sortType: "none",
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideFragment };
      }) => {
        if (callGuide.greenhouseId) {
          return <GreenhouseIcon />;
        }
        if (callGuide.leverFeedbackTemplateId) {
          return <LeverIcon />;
        }
        return null;
      },
    },
    {
      Header: "Last Updated",
      accessor: "updatedAt",
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideFragment };
      }) => <>{formatRelativeDate(callGuide.updatedAt)}</>,
    },
    {
      Header: " ", // Empty header for the menu (must be a space)
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideFragment };
      }) => <CallGuideMenu callGuide={callGuide} showRemove={showRemove} />,
    },
  ];

  return (
    <SortableTable
      columns={columns as CallGuide[]}
      loading={loading}
      data={callGuides}
      initialSort={{ id: "updatedAt", desc: true }}
      width="100%"
    />
  );
};

CallGuideList.displayName = "CallGuideList";
export default CallGuideList;
