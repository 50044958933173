import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { EmptyAIIcon } from "../../../../components/Images";
import { AiNotesProcessingStatus, QuestionAndAnswer } from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import AiFeedback from "./AiFeedback";
import AiNotesLoading from "./AiNotesLoading";
import AiNotesMessage from "./AiNotesMessage";
import AiNotesQuestionBlock from "./AiNotesQuestionBlock";

type AiNotesQuestionBlocksProps = {
  status: AiNotesProcessingStatus;
  questionsAndAnswers: QuestionAndAnswer[];
  copyNotes: (filterId?: string) => void;
  updateAiNote: (itemId: string, newValue: string) => void;
  updateAiNoteQuestion: (noteId: string, newValue: string) => void;
  onClickTimestamp(t: number): void;
  callId: string;
  regenerate: () => void;
  canEdit: boolean;
  createAiNoteTags: () => void;
};

const AiNotesQuestionBlocks: React.FC<AiNotesQuestionBlocksProps> = ({
  status,
  questionsAndAnswers,
  copyNotes,
  updateAiNote,
  updateAiNoteQuestion,
  onClickTimestamp,
  callId,
  regenerate,
  canEdit,
  createAiNoteTags,
}) => {
  const [debug, setDebug] = useState(false);
  const candidateQuestionsEnabled = useFeatureFlag(
    "ai_notes:candidate_questions"
  );

  if (status === AiNotesProcessingStatus.InProgress) {
    return <AiNotesLoading />;
  }

  if (status === AiNotesProcessingStatus.Failed) {
    return (
      <AiNotesMessage>
        <EmptyAIIcon marginBottom="64px" />
        <Text align="center" maxW="360px">
          We are experiencing some technical issues in generating AI notes at
          this moment. Please stay tuned as we work to resolve these issues.
        </Text>
      </AiNotesMessage>
    );
  }

  const filteredQuestionsAndAnswers: QuestionAndAnswer[] =
    questionsAndAnswers?.filter((qa) => !qa.error);

  const interviewerQuestions = filteredQuestionsAndAnswers?.filter(
    (qa) => !qa.isCandidate
  );

  const candidateQuestions = filteredQuestionsAndAnswers?.filter(
    (qa) => qa.isCandidate
  );

  if (!filteredQuestionsAndAnswers.length) {
    return (
      <>
        <Debug
          debug={debug}
          setDebug={setDebug}
          regenerate={regenerate}
          createAiNoteTags={createAiNoteTags}
        />
        <AiNotesMessage>
          <EmptyAIIcon marginBottom="64px" />
          <Text align="center" maxW="360px">
            Our current AI is built to detect interview questions and answers —
            we didn’t detect any in this conversation but stay tuned for
            improvements.
          </Text>
        </AiNotesMessage>
      </>
    );
  }

  return (
    <>
      <Debug
        debug={debug}
        setDebug={setDebug}
        regenerate={regenerate}
        createAiNoteTags={createAiNoteTags}
      />
      <Box
        data-tour-id="ai-notes-notes-container"
        fontSize="sm"
        px={{ base: "1px" }}
        pb="70px" // 65px + buffer for the fixed footer
      >
        {interviewerQuestions?.map((questionAndAnswer) => (
          <AiNotesQuestionBlock
            key={questionAndAnswer.startTime}
            questionAndAnswer={questionAndAnswer}
            callId={callId}
            updateAiNote={updateAiNote}
            updateAiNoteQuestion={updateAiNoteQuestion}
            debug={debug}
            canEdit={canEdit}
            onClickTimestamp={onClickTimestamp}
            copyNotes={() => copyNotes(questionAndAnswer.id)}
          />
        ))}
        {candidateQuestionsEnabled && candidateQuestions.length > 0 && (
          <Box mb={6}>
            <Text mt={8} ml={4} mb={2} fontWeight="medium">
              Candidate Questions
            </Text>
            {candidateQuestions?.map((questionAndAnswer) => (
              <AiNotesQuestionBlock
                key={questionAndAnswer.startTime}
                questionAndAnswer={questionAndAnswer}
                callId={callId}
                updateAiNote={updateAiNote}
                updateAiNoteQuestion={updateAiNoteQuestion}
                debug={debug}
                canEdit={canEdit}
                onClickTimestamp={onClickTimestamp}
                copyNotes={() => copyNotes(questionAndAnswer.id)}
              />
            ))}
          </Box>
        )}
        {filteredQuestionsAndAnswers.length < questionsAndAnswers.length && (
          <PartialFailureAlert />
        )}
        <AiFeedback feature="notes" />
      </Box>
    </>
  );
};

const PartialFailureAlert: React.FC = () => (
  <Alert status="warning" mb="4">
    <AlertDescription>
      We experienced some technical issues with generating AI notes for this
      call and were only able to generate notes for a portion of the interview
      conversation.
    </AlertDescription>
  </Alert>
);

const Debug: React.FC<{
  debug: boolean;
  setDebug: (debug: boolean) => void;
  regenerate: () => void;
  createAiNoteTags: () => void;
}> = ({ regenerate, debug, setDebug, createAiNoteTags }) => {
  const debugEnabled = useFeatureFlag("ai_notes:debug");
  if (!debugEnabled) {
    return null;
  }
  return (
    <Flex bg="gray.50" mb="2" justify="end">
      <Button variant="ghost" size="xs" onClick={regenerate}>
        Regenerate ($0.20)
      </Button>
      <Button variant="ghost" size="xs" onClick={createAiNoteTags}>
        Create tags
      </Button>
      <Button variant="ghost" size="xs" onClick={() => setDebug(!debug)}>
        {debug ? "Hide" : "Show"} Debug
      </Button>
    </Flex>
  );
};

export default AiNotesQuestionBlocks;
