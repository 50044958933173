import { NetworkStatus } from "@apollo/client";
import { Box, useInterval } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";

import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import { ActivityType, useActivityFeedLazyQuery } from "../../../graphql";
import ActivityFeedBase from "./ActivityFeedBase";

const PAGE_LIMIT = 10;
const REFRESH_DELAY = 1000 * 60 * 5;

/**
 * Activity feed component with necessary graphQL queries
 */
const ActivityFeed: React.FC = () => {
  const sentinelRef = useRef<HTMLDivElement>(null);
  const sentinelInView = useIntersectionObserver(sentinelRef, {
    rootMargin: "20%",
  });
  const isSmallScreen = useIsSmallScreen();

  const types: ActivityType[] = [
    ActivityType.CallNoteComment,
    ActivityType.CallNoteMention,
    ActivityType.CallShare,
    ActivityType.CallView,
    ActivityType.ClipShare,
    ActivityType.ExternalCallView,
    ActivityType.ExternalClipView,
    ActivityType.PlaylistShare,
  ];
  const [getActivityFeed, { data, loading, error, fetchMore, networkStatus }] =
    useActivityFeedLazyQuery({
      notifyOnNetworkStatusChange: true,
    });
  const activities = (data?.activityFeed.results || [])
    // an activity with a null call
    // means that the call was deleted
    .filter(
      ({ activity }) =>
        activity.call != null ||
        activity.clip != null ||
        activity.playlistShare != null
    );

  const pageInfo = data?.activityFeed.pageInfo || {
    hasNextPage: false,
    hasPreviousPage: false,
  };

  /**
   * Executes at the beginning
   */
  useEffect(() => {
    getActivityFeed({
      variables: {
        types,
        pagination: {
          limit: PAGE_LIMIT,
        },
      },
    });
  }, []);

  /**
   * Executed when the user loads a new page of results
   */
  const onLoadMore = (): void => {
    fetchMore({
      variables: {
        types,
        pagination: {
          limit: activities.length + PAGE_LIMIT,
        },
      },
    });
  };

  useEffect(() => {
    if (sentinelInView && pageInfo.hasNextPage) {
      onLoadMore();
    }
  }, [sentinelInView]);

  /**
   * Executes when it's time to refresh the contents of the list
   */
  const onRefresh = (): void => {
    getActivityFeed({
      variables: {
        types,
        pagination: {
          limit: Math.max(activities.length, PAGE_LIMIT),
        },
      },
    });
  };

  // Execute onRefresh on a set timer
  useInterval(onRefresh, REFRESH_DELAY);

  return (
    <>
      <ActivityFeedBase
        activities={activities}
        onLoadMore={onLoadMore}
        loading={
          loading &&
          networkStatus !== NetworkStatus.refetch &&
          networkStatus !== NetworkStatus.ready
        }
        error={!!error}
        hasNextPage={isSmallScreen ? false : pageInfo.hasNextPage ?? false}
      />
      {isSmallScreen && <Box width="100%" h="4" ref={sentinelRef} />}
      {isSmallScreen && !loading && !error && pageInfo.hasNextPage === false && (
        <Box my={2} color="gray.500">
          No more items to show
        </Box>
      )}
    </>
  );
};

export default ActivityFeed;
