import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

import { usePageTracker } from "../../../utils/googleAnalytics";
import { DefaultPageLayout } from "../../layouts";

const NoAccessPage: React.FC = () => {
  usePageTracker("no-access");

  return (
    <DefaultPageLayout bg="yellow.100">
      <Flex px="4" h="100%" alignItems="center" justifyContent="space-around">
        <Box textAlign="center">
          <Heading
            as="h3"
            fontSize="3xl"
            size="3xl"
            color="gray.800"
            fontWeight="600"
            mb={3}
            mt={8}
          >
            Sorry
          </Heading>
          <Text color="gray.800">
            You don&apos;t have permission to view this page.
          </Text>
          <Text color="gray.800">
            You can request access from your administrator.
          </Text>
          <Button mt="4" onClick={() => window.history.back()}>
            Go back
          </Button>
        </Box>
      </Flex>
    </DefaultPageLayout>
  );
};

export default NoAccessPage;
