import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import config from "../config";
import { checkCookieExistence } from "./cookie";

export const initDatadog = (): void => {
  const isImpersonating = checkCookieExistence("is_impersonating");
  if (config.datadogEnabled && !isImpersonating) {
    const clientToken = "pubd56d797d13261529c15435d4f846a024";
    const site = "datadoghq.com";
    const service = "brighthire";

    datadogRum.init({
      applicationId: "cf1b44d6-305c-4aaa-8db2-27b2b9c3e3f6",
      clientToken,
      site,
      service,
      env: config.appEnv,
      version: config.appVersion,
      sampleRate: 100,
      tracingSampleRate: 20,
      premiumSampleRate: 0,
      trackInteractions: true,
      useSecureSessionCookie: true,
      allowedTracingOrigins: [/https:\/\/.*\.brighthire\.ai/],
    });

    datadogLogs.init({
      clientToken,
      site,
      service,
      env: config.appEnv,
      version: config.appVersion,
      // send console.log calls and exceptions as well as datadogLogs.logger calls
      forwardErrorsToLogs: true,
      useSecureSessionCookie: true,
      sampleRate: 100,
    });
  }
};

interface CurrentUser {
  id: string;
  email: string;
  fullName: string;
  organization: {
    name: string;
  };
}

export const identifyDatadog = (currentUser?: CurrentUser): void => {
  const isImpersonating = checkCookieExistence("is_impersonating");
  if (config.datadogEnabled && currentUser && !isImpersonating) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    datadogRum.setUser({
      id: currentUser.id,
      name: currentUser.fullName,
      email: currentUser.email,
      orgName: currentUser.organization.name,
    });
  }
};

export const datadogAction = (
  action: string,
  payload: Record<string, unknown>
): void => {
  const isImpersonating = checkCookieExistence("is_impersonating");
  if (config.datadogEnabled && !isImpersonating) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    datadogRum.addAction(action, payload);
  }
};
