import { mode } from "@chakra-ui/theme-tools";

function baseStyle(props: Record<string, any>): Record<string, any> {
  return {
    bg: mode("gray.700", "gray.300")(props),
    color: mode("white", "gray.900")(props),
    pointerEvents: "none",
    whiteSpace: "pre-wrap",
  };
}

export default {
  baseStyle,
};
