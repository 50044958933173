import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProps,
} from "@chakra-ui/react";
import React, { useLayoutEffect, useState } from "react";

import { asArray } from "../../../../utils/array";

interface SidebarTabProps<T extends string> {
  id: T;
  displayName: JSX.Element | string | React.FC<{ isSelected: boolean }>;
}

export const SidebarTab = <T extends string, C extends JSX.Element>({
  children,
}: SidebarTabProps<T> & { children: C }): C => {
  return children;
};

type SidebarTab<T extends string> = React.ReactElement<SidebarTabProps<T>>;
type Child<T extends string> =
  | SidebarTab<T>
  | string
  | number
  | boolean
  | null
  | undefined;

interface SidebarTabsProps<T extends string>
  extends Omit<TabsProps, "index" | "onChange" | "defaultIndex"> {
  currentTab?: T;
  onTabChange?(tab: T): void;
  children: Child<T>[] | Child<T>;
  menuButton?: JSX.Element;
}

const SidebarTabs = <T extends string>({
  currentTab,
  onTabChange,
  isLazy = true,
  lazyBehavior = "keepMounted",
  menuButton,
  children,
  ...tabsProps
}: SidebarTabsProps<T>): JSX.Element => {
  const { tabs, tabIds, tabNames } = useTabData(children);

  const [tabIndex, setTabIndexState] = useState(0);
  const setTabIndex = (idx: number): void => {
    if (idx !== tabIndex) {
      setTabIndexState(idx);
      onTabChange?.(tabIds[idx]);
    }
  };

  // Set tab based on the `currentTab` prop
  // useLayoutEffect to avoid one frame of rendering the prior tab
  useLayoutEffect(() => {
    if (currentTab) {
      const idx = tabIds.findIndex((tabId) => tabId === currentTab);
      if (idx !== -1) {
        setTabIndex(idx);
      }
    }
  }, [currentTab, tabIds]);

  return (
    <Tabs
      display="flex"
      flexDir="column"
      height="100%"
      index={tabIndex}
      onChange={setTabIndex}
      isLazy={isLazy}
      lazyBehavior={lazyBehavior}
      {...tabsProps}
    >
      <TabList
        alignItems="center"
        bg="white"
        borderRadius="base"
        display="flex"
        height="80px"
        justifyContent="center"
        overflow="hidden"
      >
        {tabNames.map((tabName, idx) => {
          return (
            <Tab
              _first={{
                borderLeft: "1px solid",
                borderTopLeftRadius: "base",
                borderBottomLeftRadius: "base",
              }}
              _hover={{
                color: "blue.600",
                backgroundColor: "blue.100",
              }}
              _last={{
                borderTopRightRadius: "base",
                borderBottomRightRadius: "base",
              }}
              _selected={{
                background: "gray.50",
                color: "gray.800 !important",
                fontWeight: "600",
                boxShadow: "sm",
              }}
              background="white"
              color="blue.600 "
              border="1px solid"
              borderLeft="none"
              borderColor="gray.100 !important"
              borderRadius="none"
              data-testid={`${tabIds[idx]}-tab`}
              h="40px"
              key={tabIds[idx]}
              p="8px 3px"
              w="100px"
              fontWeight="500"
            >
              <Box fontSize="14px" lineHeight="20px">
                {typeof tabName === "function"
                  ? tabName({ isSelected: idx === tabIndex })
                  : tabName}
              </Box>
            </Tab>
          );
        })}
      </TabList>

      <TabPanels overflowY="auto" flex="1">
        {tabs.map((tab, idx) => (
          <TabPanel key={tabIds[idx]} h="100%">
            {tab}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default SidebarTabs;

/**
 * Filter out falsy children and return tabs along with names and ids
 */
function useTabData<T extends string>(
  children: Child<T>[] | Child<T>
): {
  tabs: SidebarTab<T>[];
  tabIds: T[];
  tabNames: (string | JSX.Element | React.FC<{ isSelected: boolean }>)[];
} {
  const tabs = asArray(children).filter((c) => !!c) as SidebarTab<T>[];

  const [tabIds, tabNames] = tabs.reduce(
    ([ids, names], tab) => [
      ids.concat(tab.props.id),
      names.concat(tab.props.displayName),
    ],
    [[], []] as [
      T[],
      (JSX.Element | string | React.FC<{ isSelected: boolean }>)[]
    ]
  );

  return { tabs, tabIds, tabNames };
}
