import { Box, Flex, useTheme } from "@chakra-ui/react";
import React, { useMemo } from "react";
import Select, { components, ContainerProps } from "react-select";

import { Avatar } from "../../../components";
import useSelectTheme from "../../../hooks/useSelectTheme";
import { useTrainingProgramAddTraineesQuery } from "../../graphql";
import { TrainingProgram } from "./types";

export type TraineeSelectUser = Pick<
  TrainingProgram["trainingProgramTrainees"][0],
  "id" | "user"
>;

type AddTraineesSelectProps = {
  trainingProgramId: string;
  trainees: TraineeSelectUser[];
  canClearAll?: boolean;
  onSelect: (value: TraineeSelectUser) => void;
  onRemove: (value: TraineeSelectUser) => void;
  onClear: () => void;
};

type OptionType = {
  label: string;
  value: string;
  trainee: {
    id: string;
    user: TraineeSelectUser["user"];
  };
};

const formatOptionLabel: React.FC<{
  value: string;
  label: string;
  trainee: TraineeSelectUser;
}> = ({ label, trainee }) => (
  <Flex>
    <Avatar
      name={trainee.user.fullName}
      borderRadius="100%"
      user={trainee.user}
      mr="3"
    />
    <Box>{label}</Box>
  </Flex>
);

const SelectContainer: React.FC<ContainerProps<OptionType, true>> = (props) => {
  return (
    <components.SelectContainer
      {...props}
      innerProps={
        {
          "data-tour-id": "training-add-trainee",
          "data-testid": "training-add-trainee",
        } as any
      }
    />
  );
};

const AddTraineesSelect = React.forwardRef<any, AddTraineesSelectProps>(
  (
    {
      trainingProgramId,
      trainees,
      onSelect,
      onRemove,
      onClear,
      canClearAll = false,
    },
    ref
  ) => {
    const { colors } = useTheme();
    const customSelectStyle = useMemo(() => {
      return {
        control: (provided: Record<string, any>) => ({
          ...provided,
          fontSize: "14px",
          borderColor: colors.gray["200"],
          maxWidth: "625px",
        }),
        option: (provided: Record<string, any>) => ({
          ...provided,
          fontSize: "15px",
          fontWeight: "500",
          color: colors.gray["900"],
          borderBottom: "1px solid",
          borderBottomColor: colors.gray["200"],
          paddingTop: "14px",
          paddingBottom: "14px",
        }),
        input: (provided: Record<string, any>) => ({
          ...provided,
          width: "100%",
          color: colors.gray["500"],
        }),
        valueContainer: (provided: Record<string, any>) => ({
          ...provided,
          padding: "8px 16px 8px 16px",
        }),
        singleValue: (provided: Record<string, any>) => ({
          ...provided,
          color: colors.gray["900"],
        }),
        multiValue: (provided: Record<string, any>) => ({
          ...provided,
          color: colors.gray["900"],
          backgroundColor: colors.gray["100"],
          borderRadius: "4px",
          fontWeight: "500",
          marginTop: "8px",
          marginRight: "8px",
          marginBottom: "8px",
        }),
        multiValueLabel: (provided: Record<string, any>) => ({
          ...provided,
          paddingTop: "6px",
          paddingBottom: "6px",
          div: {
            flexDirection: "row",
            alignItems: "center",
          },
        }),
        multiValueRemove: (provided: Record<string, any>) => ({
          ...provided,
          color: colors.gray["500"],
          paddingLeft: "8px",
          paddingRight: "9px",
        }),
      };
    }, [colors]);

    const [theme, styles] = useSelectTheme(customSelectStyle);

    const { data, loading } = useTrainingProgramAddTraineesQuery({
      variables: { id: trainingProgramId },
      fetchPolicy: "no-cache",
    });

    const trainableUsers = data?.trainingProgram?.trainableUsers || [];

    const options = useMemo(() => {
      const currentTraineeUserIds = trainees.map((trainee) => trainee.user.id);
      return trainableUsers
        .map((user) => ({
          label: user.fullName,
          value: user.id,
          trainee: {
            id: user.id,
            user,
          },
        }))
        .filter(
          (user) => !currentTraineeUserIds.includes(user.trainee.user.id)
        );
    }, [trainees, trainableUsers]);

    return (
      <Select
        ref={ref}
        theme={theme}
        styles={styles}
        isClearable
        isMulti
        isLoading={loading}
        value={trainees.map((trainee) => {
          return {
            label: trainee.user.fullName,
            value: trainee.id,
            trainee,
          };
        })}
        closeMenuOnSelect={false}
        options={options}
        formatOptionLabel={formatOptionLabel}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          ClearIndicator: canClearAll ? components.ClearIndicator : () => null,
          SelectContainer,
        }}
        onChange={(value, actionMeta) => {
          if (actionMeta.action === "select-option") {
            const value = actionMeta.option;
            if (!value || !value.trainee) return;
            onSelect(value.trainee);
          } else if (actionMeta.action === "remove-value") {
            const value = actionMeta.removedValue;
            if (!value || !value.trainee) return;
            onRemove(value.trainee);
          } else if (actionMeta.action === "clear") {
            onClear();
          }
        }}
        placeholder="Search BrightHire users"
      />
    );
  }
);

export default AddTraineesSelect;
