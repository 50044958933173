import { Flex } from "@chakra-ui/react";
import React from "react";
import { isMobile } from "react-device-detect";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Card, CardProps } from "../Card";

const sharedProps = {
  minW: "320px", // zoom app opens at 320px
};

export const largeProps = {
  flex: 1,
  w: "100%",
  maxW: "700px",
  ...sharedProps,
};

export const xlProps = {
  flex: 1,
  w: "100%",
  maxH: "100%",
  ...sharedProps,
};

type CenteredMobileLayoutProps = {
  children: React.ReactNode;
  pageHeader?: React.ReactNode;
  size?: "sm" | "lg" | "xl";
  cardProps?: CardProps;
};

const CenteredMobileLayout: React.FC<CenteredMobileLayoutProps> = ({
  pageHeader,
  children,
  size = "sm",
  cardProps = null,
}) => {
  const { windowHeight } = useWindowDimensions();
  return isMobile ? (
    <Flex
      flexDirection="column"
      minH="-webkit-fill-available"
      w="100%"
      bg="white"
    >
      {pageHeader}
      {children}
    </Flex>
  ) : (
    <Flex minH={windowHeight} w="100%" flexDirection="column">
      {pageHeader}
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex="1"
      >
        <Card
          display="flex"
          flexDirection="column"
          {...cardProps}
          {...(size === "lg" ? largeProps : undefined)}
          {...(size === "xl" ? xlProps : undefined)}
        >
          {children}
        </Card>
      </Flex>
    </Flex>
  );
};

export default CenteredMobileLayout;
