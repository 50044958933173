import { Reference, StoreObject } from "@apollo/client";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Link,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import invariant from "invariant";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";

import { Avatar, LoadingIndicator, useToast } from "../../../components";
import useSelectTheme from "../../../hooks/useSelectTheme";
import {
  useCreateOrUpdateExcludedUserMutation,
  useOrgUserAutoJoinExclusionsQuery,
  useOrgUsersQuery,
  UserFragment,
  useUpdateAutoJoinExcludedUsersMutation,
} from "../../graphql";
import { useFeatureFlagForCurrentOrImpersonatedUser } from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";

interface FormValues {
  email: string;
}

const ExcludedUsers: React.FC = () => {
  const isSidebarNavEnabled =
    useFeatureFlagForCurrentOrImpersonatedUser("sidebar-nav");
  const toast = useToast();
  const currentUser = useCurrentUser();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();
  const [theme, styles] = useSelectTheme({
    container: (provided: Record<string, any>) => {
      return { ...provided, flex: 1 };
    },
  });
  const [selectedUsers, setSelectedUsers] = useState<UserFragment[]>([]);
  const [excludeByEmailIsHidden, setExcludeByEmailIsHidden] = useState(true);

  const { loading: orgUsersLoading, data } = useOrgUsersQuery({
    variables: { deleted: false, signUpCompleted: false },
    onError: (err) => {
      toast({
        title: "Error",
        description: `Failed to load user data: ${err.message}`,
        status: "error",
      });
    },
  });
  const { data: excludedUsersData, loading: excludedUsersLoading } =
    useOrgUserAutoJoinExclusionsQuery();
  const users = data?.currentUser?.organization?.users ?? [];
  const excludedUsers =
    excludedUsersData?.currentUser?.organization?.userAutoJoinExclusions ?? [];
  const excludedUserIds = excludedUsers.map((u) => u.id);
  const selectableUsers = users.filter((u) => !excludedUserIds.includes(u.id));

  const [updateExcludedUsers, { loading: updateExcludedUsersLoading }] =
    useUpdateAutoJoinExcludedUsersMutation({
      update(cache, { data }) {
        const organization =
          data?.updateAutoJoinExcludedUsers?.currentUser?.organization;
        if (organization) {
          cache.modify({
            id: cache.identify(organization),
            fields: {
              userAutoJoinExclusions: () => {
                return [...organization.userAutoJoinExclusions];
              },
            },
          });
        }
      },
      onError: (err) => {
        toast({
          status: "error",
          title: "Error",
          description: `Failed to update excluded users: ${err.message}`,
        });
      },
      onCompleted: (data) => {
        if (data?.updateAutoJoinExcludedUsers) {
          toast({
            title: "Success",
            description: "Users updated",
            status: "success",
          });
        }
      },
    });
  const submitUpdateExcludedUsers = (
    userIds: string[],
    exclude: boolean
  ): void => {
    updateExcludedUsers({
      variables: { userIds, exclude },
    });
    if (exclude === true) {
      setSelectedUsers([]);
    }
  };

  const [excludeUserByEmail, { loading: excludeUsersByEmailLoading }] =
    useCreateOrUpdateExcludedUserMutation({
      onError: (err) => {
        toast({
          title: "Error",
          description: `Failed to exlcude user: ${err.message}`,
          status: "error",
        });
      },
      onCompleted: (data) => {
        if (data.createOrUpdateExcludedUser?.excludedUser) {
          toast({
            title: "Success",
            description: `Excluded user: ${data.createOrUpdateExcludedUser?.excludedUser.email}`,
            status: "success",
          });
        }
      },
      update(cache, { data }) {
        const newUser = data?.createOrUpdateExcludedUser?.excludedUser;
        if (newUser) {
          cache.modify({
            id: cache.identify(currentUser.organization),
            fields: {
              userAutoJoinExclusions(prevUsers = [], { readField }) {
                if (
                  prevUsers.some(
                    (ref: StoreObject | Reference | undefined) =>
                      readField("id", ref) === newUser.id
                  )
                ) {
                  return prevUsers;
                }

                return [...prevUsers, newUser];
              },
            },
          });
        }
      },
    });
  const submitExcludeUserByEmail = handleSubmit(({ email }): void => {
    excludeUserByEmail({
      variables: {
        email: email.trim(),
      },
    });
    reset();
  });

  return (
    <Box>
      <Box maxW={900}>
        {!isSidebarNavEnabled && (
          <Text as="h1" color="gray.900" fontWeight="500" fontSize="xl" mb="8">
            Excluded Users
          </Text>
        )}
        <Text mb="6">
          BrightHire will not join any Zoom or Google Meet interviews that
          include the users added below. These users can still add and record
          Zoom interviews with BrightHire manually.
        </Text>
        <Text mb="8">
          Note: A BrightHire interview scheduled by inviting
          recorder@brighthire.ai may override the exclusion list and record
          automatically.
        </Text>
        <HStack mb="4" maxW="500px">
          <Select
            menuPortalTarget={document.getElementById("root")}
            data-testid="exclusion-select"
            theme={theme}
            styles={styles}
            isClearable
            isMulti
            placeholder="Add excluded users"
            isLoading={orgUsersLoading}
            value={selectedUsers.map((user) => ({
              label: `${user.fullName} - ${user.email}`,
              value: user,
            }))}
            options={
              selectableUsers?.map((user) => ({
                label: `${user.fullName} - ${user.email}`,
                value: user,
              })) ?? []
            }
            onChange={(selectedOptions) => {
              if (!selectedOptions) {
                setSelectedUsers([]);
                return;
              }
              invariant(
                Array.isArray(selectedOptions),
                "Invalid selected option"
              );
              const values = selectedOptions.map((o) => o.value);
              setSelectedUsers(values);
            }}
          />
          <Button
            type="submit"
            data-testid="exclude-submit-button"
            isLoading={updateExcludedUsersLoading}
            isDisabled={!excludedUsers}
            onClick={() =>
              submitUpdateExcludedUsers(
                selectedUsers.map((u) => u.id),
                true
              )
            }
          >
            Submit
          </Button>
        </HStack>
        <Box ml="2">
          <Link
            hidden={excludeByEmailIsHidden === false}
            onClick={() => setExcludeByEmailIsHidden(false)}
          >
            Add a non-BrightHire user to the exclusion list
          </Link>
        </Box>
        <form
          onSubmit={submitExcludeUserByEmail}
          hidden={excludeByEmailIsHidden}
        >
          <HStack maxW="500px">
            <FormControl>
              <Input
                {...register("email")}
                width={404}
                placeholder="Enter email address of non-BrightHire user"
              />
              {errors.email !== undefined && (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              )}
            </FormControl>
            <Button
              type="submit"
              isLoading={excludeUsersByEmailLoading}
              width="100%"
            >
              Submit
            </Button>
          </HStack>
        </form>
        {excludedUsersLoading ? (
          <LoadingIndicator />
        ) : (
          <Flex direction="column" wrap="wrap" mb="6" mt="8">
            {excludedUsers.length > 0 ? (
              excludedUsers?.map((user, index) => (
                <Tag
                  key={user.id}
                  size="lg"
                  py="2"
                  mr="4"
                  mb="2"
                  width="sm"
                  colorScheme="gray"
                  borderRadius="md"
                >
                  <Avatar user={user} size="sm" mr={2} />
                  <TagLabel>
                    <Text
                      fontSize="sm"
                      overflowX="hidden"
                      textOverflow="ellipsis"
                    >
                      {user.fullName}
                    </Text>
                    <Text
                      fontSize="xs"
                      color="placeholder"
                      overflowX="hidden"
                      textOverflow="ellipsis"
                    >
                      {user.email}
                    </Text>
                  </TagLabel>
                  <TagCloseButton
                    ml="auto"
                    onClick={() => {
                      submitUpdateExcludedUsers([user.id], false);
                    }}
                  />
                </Tag>
              ))
            ) : (
              <Box color="placeholder">No excluded users yet</Box>
            )}
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default ExcludedUsers;
