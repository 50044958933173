import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineBars } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaBookReader } from "react-icons/fa";
import { MdAttachMoney, MdRateReview } from "react-icons/md";

import {
  CallAiSummaryFormat,
  CallBetaFragment,
  useUpdateCallAiSummaryFormatMutation,
} from "../../../../graphql";
import { useAvailableAiSummaryFormats } from "../../../../hooks/useAvailableAiSummaryFormats";
import { AiSummaryLabels } from "./ai_summary_ui";

const AiFormatSelector: React.FC<{
  format: CallAiSummaryFormat | "AI_NOTES";
  setFormat: (f: CallAiSummaryFormat | "AI_NOTES") => void;
  call: Pick<CallBetaFragment, "id"> & {
    speakers: Array<{
      __typename?: "CallSpeaker";
      id: any;
      speakerTag: number;
      label: string;
    }>;
  };
}> = ({ format, setFormat, call }) => {
  const availableFormats = useAvailableAiSummaryFormats();

  const toast = useToast();
  const [update] = useUpdateCallAiSummaryFormatMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error generating summary",
        status: "error",
        position: "top",
      });
    },
  });
  const switchFormat = (format: CallAiSummaryFormat | "AI_NOTES"): void => {
    update({
      variables: {
        callId: call.id,
        format: format === "AI_NOTES" ? null : format,
        targetSpeakerTags: [],
      },
    }).then(() => {
      setFormat(format);
    });
  };
  return (
    <Menu autoSelect={false} variant="new">
      <MenuButton
        as={Box}
        size="sm"
        _focus={{}}
        onClick={(e) => e.stopPropagation()}
      >
        <Flex flexDir="row" alignItems="center" justifyContent="center">
          <Text>AI Notes</Text>
          <Icon
            as={BiChevronDown}
            color="blue.500"
            h="16px"
            ml="3px"
            w="16px"
          />
        </Flex>
      </MenuButton>
      <Portal>
        <MenuList>
          <MenuItem
            aria-label="Interview AI Notes"
            icon={<BsFillPeopleFill />}
            onClick={() => {
              switchFormat("AI_NOTES");
            }}
            isDisabled={format === "AI_NOTES"}
          >
            Full interview summary
          </MenuItem>
          {availableFormats.includes(CallAiSummaryFormat.Tldr) && (
            <MenuItem
              aria-label="Interview TLDR"
              icon={<AiOutlineBars />}
              onClick={() => {
                switchFormat(CallAiSummaryFormat.Tldr);
              }}
              isDisabled={format === CallAiSummaryFormat.Tldr}
            >
              {AiSummaryLabels[CallAiSummaryFormat.Tldr]}
            </MenuItem>
          )}
          {availableFormats.includes(CallAiSummaryFormat.Intake) && (
            <MenuItem
              aria-label="Intake AI Notes"
              icon={<FaBookReader />}
              onClick={() => {
                switchFormat(CallAiSummaryFormat.Intake);
              }}
              isDisabled={format === CallAiSummaryFormat.Intake}
            >
              {AiSummaryLabels[CallAiSummaryFormat.Intake]}
            </MenuItem>
          )}
          {availableFormats.includes(CallAiSummaryFormat.Debrief) && (
            <MenuItem
              aria-label="Debrief AI Notes"
              icon={<MdRateReview />}
              onClick={() => {
                switchFormat(CallAiSummaryFormat.Debrief);
              }}
              isDisabled={format === CallAiSummaryFormat.Debrief}
            >
              {AiSummaryLabels[CallAiSummaryFormat.Debrief]}
            </MenuItem>
          )}

          {availableFormats.includes(CallAiSummaryFormat.Sales) && (
            <MenuItem
              aria-label="Sales AI Notes"
              icon={<MdAttachMoney />}
              onClick={() => {
                switchFormat(CallAiSummaryFormat.Sales);
              }}
              isDisabled={format === CallAiSummaryFormat.Sales}
            >
              {AiSummaryLabels[CallAiSummaryFormat.Sales]}
            </MenuItem>
          )}
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default AiFormatSelector;
