import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ConfirmModal, useToast } from "../../../components";
import {
  CurrentUserFragment,
  SingleSignOnSetupDocument,
  useGenerateTokenMutation,
  useSetupSingleSignOnMutation,
  useSingleSignOnSetupQuery,
} from "../../graphql";

interface SingleSignOnSettingsProps {
  currentUser: CurrentUserFragment;
}

interface FormValues {
  oktaDomain: string;
  metadataUrl: string;
}

const SingleSignOnSettings: React.FC<SingleSignOnSettingsProps> = ({
  currentUser,
}) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [generateToken, { data: tokenData, loading: tokenLoading }] =
    useGenerateTokenMutation();
  const { data, loading } = useSingleSignOnSetupQuery();
  const [setupSingleSignOn, { loading: setupSingleSignOnLoading }] =
    useSetupSingleSignOnMutation({
      update: (cache, { data }) => {
        const settings = data?.setupSingleSignOn?.setup;
        if (settings) {
          cache.writeQuery({
            query: SingleSignOnSetupDocument,
            data: {
              SingleSignOnSetup: settings,
            },
          });
        }
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: `Failed to setup SingleSignOn: ${err.message}`,
          status: "error",
        });
      },
    });
  const { register, handleSubmit, setValue } = useForm<FormValues>({});

  const onSubmit = handleSubmit((formValues) => {
    setupSingleSignOn({
      variables: {
        oktaDomain: formValues.oktaDomain,
        metadataUrl: formValues.metadataUrl,
      },
    });
  });

  useEffect(() => {
    setValue("oktaDomain", data?.singleSignOnSetup?.oktaDomain || "");
    setValue("metadataUrl", data?.singleSignOnSetup?.metadataUrl || "");
  }, [data]);

  const isLoading = loading || setupSingleSignOnLoading;
  const scimToken =
    tokenData?.generateToken?.setup.scimToken ||
    data?.singleSignOnSetup?.scimToken;

  return (
    <Box w="1000px">
      <Heading as="h1" size="md">
        Single Sign On Setup
      </Heading>
      <Box my={8}>
        <Heading as="h3" size="sm" mb={2}>
          Instructions
        </Heading>
        <Text maxW="500px" mb={2}>
          Please see the{" "}
          <Link
            target="_blank"
            href="https://help.brighthire.ai/en/articles/6501000-sso-setup"
          >
            help documentation
          </Link>
          .
        </Text>
      </Box>
      <form onSubmit={onSubmit}>
        <VStack spacing="8" align="start">
          <FormControl id="oktaDomain" isRequired>
            <FormLabel>Unique Identifier or Okta Domain</FormLabel>
            <Input {...register("oktaDomain")} />
          </FormControl>
          <FormControl id="metadataUrl" isRequired>
            <FormLabel>Metadata Url</FormLabel>
            <Input {...register("metadataUrl")} type="text" />
          </FormControl>
          <FormControl id="scimToken" isRequired>
            <FormLabel>SCIM Token</FormLabel>
            <Box display="flex" flexDir="row">
              <Input
                name="scimToken"
                type="text"
                value={
                  scimToken ||
                  "Please click the button to the right to generate a new token."
                }
                isReadOnly
                minWidth="650px"
              />
              <Button
                disabled={isLoading || tokenLoading}
                variant="danger"
                ml={2}
                minWidth="200px"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                {scimToken ? "Regenerate" : "Generate"} SCIM token
              </Button>
              <ConfirmModal
                modalBodyText="This will regenerate your SCIM Token and you will need to update it in your SSO provider. Are you sure?"
                isOpen={isOpen}
                onCancel={() => {
                  setIsOpen(false);
                }}
                onConfirm={() => {
                  generateToken();
                  setIsOpen(false);
                }}
              />
            </Box>
          </FormControl>
          <Button type="submit" disabled={isLoading}>
            Save
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default SingleSignOnSettings;
