import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  BoxProps,
  Flex,
  Icon,
  Tag,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiOutlineHome, HiOutlineUser } from "react-icons/hi";
import { IconType } from "react-icons/lib";
import { useNavigate } from "react-router-dom";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import Sidebar, { SidebarItem } from "../../components/Sidebar";
import { AnalyticsDimension, MetricName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { MetricConfig } from "./MetricConfig";
import useSidebarConfig, { NavMetricGroup } from "./useSidebarConfig";

// nav state helpers
const setSearchSegment = (
  search: string,
  value: AnalyticsDimension
): string => {
  const params = new URLSearchParams(search);
  params.set("segment", value);
  return params.toString();
};
const getSearchSegment = (search: string): string | null => {
  const segment = new URLSearchParams(search).get("segment");
  return segment;
};

export type AnalyticsSidebarProps = {
  path: MetricName | "overview" | "my-insights";
  dashboardAvailable: boolean;
} & BoxProps;

const AnalyticsSidebar: React.FC<AnalyticsSidebarProps> = ({
  path,
  dashboardAvailable,
  ...rest
}) => {
  const personalInsightsDemoOn = useFeatureFlag("personal-insights-demo");
  const sidebarEnabled = useFeatureFlag("sidebar-nav");
  const navigate = useNavigate();
  const sendGAEvent = useSendGAEvent();
  const [navState, setNavState] = useState({
    path,
    segment:
      getSearchSegment(window.location.search) || AnalyticsDimension.None,
  });
  useEffect(() => {
    setNavState({
      path,
      segment:
        getSearchSegment(window.location.search) || AnalyticsDimension.None,
    });
  }, [path]);

  const navMetricGroups: NavMetricGroup[] = useSidebarConfig();

  const accordionDefaultIndices: number[] = [];
  navMetricGroups.forEach((group: NavMetricGroup, idx: number) => {
    accordionDefaultIndices.push(idx);
  });

  useEffect(() => {
    sendGAEvent("metric_view", "analytics", path.toLowerCase());
  }, [path]);

  return (
    <Sidebar
      data-tour-id="analytics-sidebar"
      mt={sidebarEnabled ? "2" : "0"}
      {...rest}
    >
      {dashboardAvailable ? (
        <SidebarItem
          text="Overview"
          icon={() => <Icon as={HiOutlineHome} mr="3" boxSize={4} />}
          iconBefore
          active={navState.path === "overview"}
          onClick={() => {
            navigate({
              pathname: `/insights/overview`,
              search: "",
            });
          }}
          mx="4"
          pl="7"
          borderRadius="base"
          mt="2"
        />
      ) : (
        <Text
          fontSize="sm"
          fontWeight="700"
          color="gray.400"
          ml="8"
          mt="8"
          mb="2"
        >
          Reports
        </Text>
      )}
      {personalInsightsDemoOn && (
        <SidebarItem
          text="My Insights"
          icon={() => <Icon as={HiOutlineUser} mr="3" boxSize={4} />}
          iconBefore
          active={navState.path === "my-insights"}
          onClick={() => {
            navigate({
              pathname: `/insights/my-insights`,
              search: "",
            });
          }}
          mx="4"
          pl="7"
          borderRadius="base"
        />
      )}
      <Accordion defaultIndex={accordionDefaultIndices} allowMultiple>
        {navMetricGroups.map((metricGroup) => (
          <AccordionItem border="0" key={metricGroup.title}>
            {({ isExpanded }: { isExpanded: boolean }) => (
              <>
                <AccordionHeader
                  title={metricGroup.title}
                  icon={metricGroup.icon}
                  badge={metricGroup.badge}
                  isExpanded={isExpanded}
                />
                <AccordionPanel
                  py="0"
                  data-testid={`analytics-sidebar-panel-${metricGroup.title
                    .toLowerCase()
                    .replace(" ", "-")}`}
                >
                  {metricGroup.metrics.map((metric) => {
                    const metricSegment =
                      metricGroup.segment || AnalyticsDimension.None;
                    const active =
                      navState.path === metric &&
                      navState.segment === metricSegment;
                    return (
                      <MetricSidebarItem
                        key={`${metric}${
                          metricGroup.segment ? "_segment" : "_none"
                        }`}
                        metric={metric}
                        active={active}
                        onClick={() => {
                          setNavState({
                            path: metric,
                            segment:
                              metricGroup.segment ?? AnalyticsDimension.None,
                          });
                          navigate({
                            pathname: `/insights/${metric.toLowerCase()}`,
                            search: setSearchSegment(
                              window.location.search,
                              metricSegment
                            ),
                          });
                        }}
                      />
                    );
                  })}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Sidebar>
  );
};

type MetricSidebarItemProps = {
  metric: MetricName;
  active: boolean;
  onClick: () => void;
};

const MetricSidebarItem: React.FC<MetricSidebarItemProps> = ({
  metric,
  active,
  onClick,
}) => {
  const { displayName } = MetricConfig[metric];
  return (
    <SidebarItem
      text={displayName}
      onClick={onClick}
      active={active}
      pl="55px" // magic number vertical alignment with parents
      pr="2"
      data-tour-id={`analytics-${metric.toLocaleLowerCase()}`}
    />
  );
};

const AccordionHeader: React.FC<{
  title: string;
  icon: IconType;
  badge?: string;
  isExpanded: boolean;
}> = ({ title, icon, badge, isExpanded }) => {
  return (
    <Text
      role="heading"
      mx="2"
      fontSize="md"
      color="gray.800"
      data-tour-id={`analytics-${title.toLowerCase()}`}
    >
      <AccordionButton
        _hover={{ bg: "transparent", color: "blue.600" }}
        borderRadius="base"
        px="4"
        py="0"
        height="10"
      >
        <AccordionIcon
          transform={isExpanded ? "rotate(0deg)" : "rotate(-90deg)"}
        />
        <Flex flex="1" alignItems="center" justifyContent="flex-start">
          <Icon as={icon} mr="3" />
          <Text fontWeight="500" fontSize="sm">
            {title}
          </Text>
          {badge && (
            <Tag size="sm" ml="auto" borderRadius="full">
              {badge}
            </Tag>
          )}
        </Flex>
      </AccordionButton>
    </Text>
  );
};

export default AnalyticsSidebar;
